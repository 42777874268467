import { countString, pluralize } from '@stellacontrol/utilities'
import { DeviceCommands } from './device-command'
import { FastSamplingSpeed } from './fast-sampling-speed'

/**
 * Device command definitions, for the UI
 */

export const DeviceCommandDefinitions = {
  // PING
  [DeviceCommands.Ping]: {
    // Unique name
    name: DeviceCommands.Ping,
    // User-friendly label
    label: 'Ping',
    // Detailed description.
    description: 'Pings the device',
    // Icon and color representing the command
    icon: 'settings',
    color: 'indigo-6',
    // Indicates whether the command requires additional parameters.
    parameters: false,
    // If true, device status should be requested after executing the command
    refreshStatus: false,
    // Conditions under which the command is available and can be executed
    conditions: {
      // Permissions required to execute the command
      permissions: [],
      // Confirmation message to ask.
      // You can use {$identification} placeholder to inject identification of the devices
      // which are subject to the command. Depending on device count it could be device
      // serial number or just a number of devices.
      confirmation: null,
      // If present, indicates whether the device has to be a connected/non-connected one, for the command to be applicable
      isConnectedDevice: true,
      // Indicates whether command can be executed on a batch of devices
      isBatchCommand: true,
      // Indicates that the command is issued not by sending
      // command payload to the device but rather by modifying device settings.
      // For example, shutting down device bands is done by setting _shutdown
      // variables on device shadow
      isSettingsCommand: false,
      // Indicates that the command is executed by some custom code action
      isCustomAction: false,
      // Indicates that device should be turned to fast sampling
      // after sending the command, to quickly see the impact of it
      watchStatus: false,
      // Indicates that device should be pinged
      // after sending the command, to quickly see the impact of it
      pingStatus: false
    },
    // Details of UI notifications,
    // shown to the user on executing the command
    notifications: {
      // Notification to display when command is about to be executed.
      // {String|Function|Boolean}
      // If not specified otherwise, no notification is shown.
      // Set to string or function returning string, to show custom notification.
      // The function will receive devices and command as parameters.
      // Set to true to run the command silently.
      executing: false,
      // Notification to display when command has been executed.
      // {String|Boolean|Function}
      // If not specified otherwise, default notification is shown.
      // Set to string or function returning string, to show custom notification.
      // The function will receive devices and command as parameters.
      // Set to false to run the command silently.
      executed: false,
      // Notification to display when command has failed.
      // {String|Boolean|Function}
      // If not specified otherwise, default notification is shown.
      // Set to string or function returning string, to show custom notification.
      // The function will receive devices and command as parameters.
      // Set to false to run the command silently.
      failed: false
    }
  },

  // RECALIBRATE
  [DeviceCommands.Recalibrate]: {
    name: DeviceCommands.Recalibrate,
    label: 'Recalibrate',
    description: 'Recalibration resets the variables in devices to an optimal level',
    icon: 'settings_backup_restore',
    color: 'orange-7',
    parameters: false,
    refreshStatus: true,
    conditions: {
      permissions: ['device-management-rebalance'],
      confirmation: null,
      isConnectedDevice: true,
      isBatchCommand: true,
      isSettingsCommand: false,
      watchStatus: true
    },
    notifications: {
      executing: devices => `Recalibrating ${countString(devices, 'device')} ...`,
      executed: devices => `${pluralize(devices, 'Device has', 'Devices have')} been recalibrated`,
      failed: devices => `${pluralize(devices, 'Device')} could not be recalibrated`
    }
  },

  // BLINK
  [DeviceCommands.Blink]: {
    name: DeviceCommands.Blink,
    label: 'Blink',
    description: 'Blinks device LEDs',
    icon: 'adjust',
    color: 'green-7',
    parameters: {
      BLINK_DURATION: Number
    },
    defaults: {
      BLINK_DURATION: 10
    },
    refreshStatus: false,
    conditions: {
      confirmation: null,
      isConnectedDevice: true,
      isBatchCommand: true,
      isSettingsCommand: false,
      watchStatus: false,
      pingStatus: true
    },
    notifications: {
      executing: devices => `Blinking the ${countString(devices, 'device')} ...`,
      executed: devices => `${pluralize(devices, 'Device has', 'Devices have')} been blinked`,
      failed: devices => `${pluralize(devices, 'Device')} could not be blinked`
    }
  },

  // FAST SAMPLING
  [DeviceCommands.StartFastSampling]: {
    name: DeviceCommands.StartFastSampling,
    label: 'Start Live',
    icon: 'podcasts',
    color: 'green-7',
    parameters: {
      FAST_SAMPLING_SPEED: String,
      FAST_SAMPLING_WINDOW_SECS: Number
    },
    defaults: {
      FAST_SAMPLING_SPEED: FastSamplingSpeed.OneSecond,
      FAST_SAMPLING_WINDOW_SECS: 60
    },
    conditions: {
      permissions: ['live-status'],
      isConnectedDevice: true,
      isBatchCommand: true,
      watchStatus: false
    },
    notifications: {
      executing: devices => `Switching ${countString(devices, 'device')} to live mode ...`,
      executed: devices => `${pluralize(devices, 'Device', 'Devices')} switched to live mode`,
      failed: devices => `${pluralize(devices, 'Device')} could not be switched to live mode`
    }
  },

  // HARD REBOOT
  [DeviceCommands.Reboot]: {
    name: DeviceCommands.Reboot,
    label: 'Reboot',
    description: 'During the reboot sequence device will behave similarly to when powering up.',
    icon: 'settings_backup_restore',
    color: 'red-8',
    parameters: false,
    refreshStatus: true,
    conditions: {
      confirmation: 'Reboot ${identification}?',
      permissions: ['device-management-reboot'],
      isConnectedDevice: true,
      isBatchCommand: true,
      watchStatus: false
    },
    notifications: {
      executing: devices => `Rebooting ${countString(devices, 'device')} ...`,
      executed: devices => `${pluralize(devices, 'Device has', 'Devices have')} been rebooted`,
      failed: devices => `${pluralize(devices, 'Device')} could not be rebooted`
    }
  },

  // EEPROM WIPE
  [DeviceCommands.WipeEEPROM]: {
    name: DeviceCommands.WipeEEPROM,
    label: 'Wipe device EEPROM',
    description: 'WARNING: During the process ALL EEPROM CONTENTS WILL BE ERASED from device!',
    icon: 'memory',
    color: 'red-8',
    parameters: false,
    refreshStatus: true,
    conditions: {
      confirmation: 'Wipe EEPROM of ${identification}?',
      permissions: ['device-management-eeprom-wipe'],
      isConnectedDevice: true,
      isBatchCommand: true,
      watchStatus: false
    },
    notifications: {
      executing: devices => `Wiping EEPROM on ${countString(devices, 'device')} ...`,
      executed: devices => `${pluralize(devices, 'Device\'s', 'Devices\'')} EEPROM has been wiped`,
      failed: devices => `${pluralize(devices, 'Device\'s', 'Devices\'')} EEPROM could not be wiped`
    }
  },

  // TOGGLE BANDS OF A DEVICE
  [DeviceCommands.ToggleBands]: {
    name: DeviceCommands.ToggleBands,
    label: 'Toggle bands',
    icon: 'router',
    color: 'green-8',
    parameters: {
      '07': Boolean,
      '08': Boolean,
      '09': Boolean,
      '18': Boolean,
      '21': Boolean,
      '26': Boolean
    },
    refreshStatus: true,
    conditions: {
      permissions: ['device-configuration-toggle-bands'],
      isConnectedDevice: true,
      isBatchCommand: true,
      isSettingsCommand: true,
      watchStatus: true
    }
  },

  // CELL SCAN
  [DeviceCommands.RequestCellScan]: {
    name: DeviceCommands.RequestCellScan,
    label: 'Live Scan',
    description: 'Requests the device to perform a live scan of neighbour cells and send a report',
    icon: 'radar',
    color: 'green-7',
    parameters: {
    },
    defaults: {
    },
    refreshStatus: false,
    conditions: {
      confirmation: null,
      isConnectedDevice: true,
      isBatchCommand: true,
      isSettingsCommand: false,
      watchStatus: false,
      pingStatus: false
    },
    notifications: {
      executing: devices => `Requesting the report from the ${pluralize(devices, 'device')} ...`,
      executed: devices => `The request has been sent to the ${pluralize(devices, 'device')}`,
      failed: devices => `The request was not sent to the ${pluralize(devices, 'device')}`
    }
  },

  // DEEP CELL SCAN
  [DeviceCommands.RequestDeepCellScan]: {
    name: DeviceCommands.RequestDeepCellScan,
    label: 'Cell ID Scan',
    description: 'Requests the device to perform a Cell ID scan and send a report',
    icon: 'radar',
    color: 'green-7',
    parameters: {
    },
    defaults: {
    },
    refreshStatus: false,
    conditions: {
      confirmation: null,
      isConnectedDevice: true,
      isBatchCommand: true,
      isSettingsCommand: false,
      watchStatus: false,
      pingStatus: false
    },
    notifications: {
      executing: devices => `Requesting the report from the ${pluralize(devices, 'device')} ...`,
      executed: devices => `The request has been sent to the ${pluralize(devices, 'device')}`,
      failed: devices => `The request was not sent to the ${pluralize(devices, 'device')}`
    }
  },

  // RETURNS THE DEVICE TO STOCK
  [DeviceCommands.MoveToStock]: {
    name: DeviceCommands.MoveToStock,
    label: 'Move to stock',
    icon: 'shelves',
    color: 'green-8',
    refreshStatus: true,
    conditions: {
      confirmation: 'Move ${identification} back to stock?',
      permissions: ['set-device-place'],
      isConnectedDevice: true,
      isBatchCommand: true,
      isCustomAction: true,
      watchStatus: true
    },
    notifications: {
      executing: (devices) =>
        `Moving ${countString(devices, 'device')} to stock ...`,
      executed: (devices) =>
        `${countString(devices, 'device')} moved to stock`,
      failed: (devices) =>
        `${countString(devices, 'device')} could not be moved to stock`,
    }
  },
}


/**
 * Default set of commands available to devices.
 */
export const DefaultDeviceCommands = [
  DeviceCommands.Recalibrate,
  DeviceCommands.Reboot,
  DeviceCommands.WipeEEPROM,
  DeviceCommands.ToggleBands,
  DeviceCommands.RequestCellScan,
  DeviceCommands.RequestDeepCellScan,
].map(name => DeviceCommandDefinitions[name])