import { startOfDay, endOfDay } from 'date-fns'
import { parseDate, formatDate } from '@stellacontrol/utilities'
import { Entity } from '../common/entity'
import { PrincipalType } from '../security/principal/principal-type'
import { AnnouncementChannel } from './announcement-channel'
import { AnnouncementCategory, AnnouncementCategories } from './announcement-category'
import { AnnouncementRecipient } from './announcement-recipient'
import { AnnouncementPriority, AnnouncementPriorities } from './announcement-priority'

/**
 * Announcements sent to customers
 */
export class Announcement extends Entity {
  constructor (data = {}) {
    super()
    this.assign(
      {
        ...data,
        category: data.category || AnnouncementCategory.Information,
        priority: data.priority || AnnouncementPriority.Normal,
        channels: data.channels || [AnnouncementChannel.Popup],
        recipients: data.recipients || []
      },
      {
        validFrom: parseDate,
        validUntil: parseDate,
        publishedAt: parseDate
      }
    )
  }

  __text

  normalize () {
    super.normalize()
    this.recipients = this.castArray(this.recipients, AnnouncementRecipient)
  }

  /**
   * Date and time when announcement has been published
   * @type {Date}
   */
  publishedAt

  /**
   * Identifier of user which published the announcement
   * @type {String}
   */
  publishedBy

  /**
   * Indicates that the announcement was published
   * @type {Boolean}
   */
  get wasPublished () {
    return this.id && this.publishedAt && this.publishedBy
  }

  /**
   * Announcement category
   * @type {AnnouncementCategory}
   */
  category

  /**
   * Name of the announcement category
   * @type {String}
   */
  get categoryName () {
    return this.category != null ? AnnouncementCategories[this.category].name : undefined
  }

  /**
   * Description of the announcement category
   * @type {String}
   */
  get categoryDescription () {
    return this.category != null ? AnnouncementCategories[this.category].description : undefined
  }

  /**
   * Announcement priority
   * @type {AnnouncementPriority}
   */
  priority

  /**
   * Name of the announcement priority
   * @type {String}
   */
  get priorityName () {
    return this.priority != null ? AnnouncementPriorities[this.priority].name : undefined
  }

  /**
   * Checks whether the announcement priority is elevated
   * @type {Boolean}
   */
  get isElevated () {
    return this.priority === AnnouncementPriority.Elevated
  }

  /**
   * Checks whether the announcement priority is urgent
   * @type {Boolean}
   */
  get isUrgent () {
    return this.priority === AnnouncementPriority.Urgent
  }

  /**
   * Icon representing the announcement, indicating the category
   * @type {String}
   */
  get icon () {
    return this.category != null ? AnnouncementCategories[this.category].icon : undefined
  }

  /**
   * Color representing the announcement, indicating the urgency
   * @type {String}
   */
  get color () {
    return this.isElevated ? 'orange-5' : (this.isUrgent ? 'red-5' : 'green-6')
  }

  /**
   * Announcement title
   * @type {String}
   */
  title

  /**
   * Announcement text.
   * Must be not-null at all times due to HTML editor requirements.
   * @type {String}
   */
  get text () {
    return this.__text || ''
  }

  set text (value) {
    this.__text = (value || '').toString().trim()
  }

  /**
   * Returns true if announcement has additional text
   * @type {Boolean}
   */
  get hasText () {
    return Boolean((this.__text || '').trim())
  }

  /**
   * Identifier of organization which created the announcement
   * @type {String}
   */
  organizationId

  /**
   * Identifier of user which created the announcement
   * @type {String}
   */
  userId

  /**
   * Announcement recipients
   * @type {Array[AnnouncementRecipient]}
   */
  recipients

  /**
   * True if announcement has any recipients
   * @type {Boolean}
   */
  get hasRecipients () {
    return this.recipients?.filter(recipient => recipient?.isValid).length > 0
  }

  /**
   * Number of recipients
   * @type {Number}
   */
  get recipientCount () {
    return this.recipients?.filter(recipient => recipient?.isValid).length || 0
  }

  /**
   * Returns recipient type.
   * It is not allowed to mix recipient types, so announcement has to be sent
   * only to organizations, only to profiles but not to both at the same time.
   * @type {Boolean}
   */
  get recipientType () {
    return this.recipients
      ? this.recipients.filter(recipient => recipient?.isValid)[0]?.type
      : undefined
  }

  /**
   * Recipient is organization
   * @type {Boolean}
   */
  get toOrganizations () {
    return this.recipientType === PrincipalType.Organization
  }

  /**
   * Recipient is organization profile
   * @type {Boolean}
   */
  get toOrganizationProfiles () {
    return this.recipientType === PrincipalType.OrganizationProfile
  }

  /**
   * Returns identifiers of announcement recipients
   */
  get recipientIdentifiers () {
    return (this.recipients || [])
      .filter(recipient => recipient?.isValid)
      .filter(recipient => recipient.type === this.recipientType)
      .map(({ id }) => id)
  }

  /**
   * Announcement channels (email, popup, sms etc)
   * @type {Array[AnnouncementChannel]}
   */
  channels

  /**
   * True if announcement has any publish channels
   * @type {Boolean}
   */
  get hasChannels () {
    return this.channels?.filter(channel => channel != null).length > 0
  }

  /**
   * True if announcement is to be sent by any of the specified channels
   * @param {Array[AnnouncementChannel]} channels Channels to check
   * @type {Boolean}
   */
  hasChannel (...channels) {
    return channels.some(channel => this.channels?.includes(channel))
  }

  /**
   * True if announcement can be published
   * @type {Boolean}
   */
  get canPublish () {
    return (this.title?.trim()) &&
      this.hasRecipients &&
      this.hasChannels
  }

  /**
   * Time from which the announcement should become visible
   * @type {Date}
   */
  validFrom

  /**
   * Time until which the announcement should remain visible
   * @type {Date}
   */
  validUntil

  /**
   * Returns human-readable period during which the announcement is valid
   * @type {String}
   */
  get validPeriodString () {
    const { validFrom, validUntil } = this

    if (validFrom && !validUntil) {
      return `From ${formatDate(validFrom)}`
    } else if (validUntil && !validFrom) {
      return `Until ${formatDate(validUntil)}`
    } else if (validUntil && validFrom) {
      return `From ${formatDate(validFrom)} until ${formatDate(validUntil)}`
    }
  }

  /**
   * Indicates that announcement has been acknowledged by the viewer
   * @type {Boolean}
   */
  acknowledgedAt

  /**
   * Identifer of user who acknowledged the announcement
   * @type {Boolean}
   */
  acknowledgedBy

  /**
   * Returns true if announcement has been acknowledged by the viewing user
   */
  get isAcknowledged () {
    return this.acknowledgedAt != null && this.acknowledgedBy != null
  }

  /**
   * Indicates whether announcement period includes the specified date
   * @param {Date} date Date to be checked
   * @returns {Boolean}
   */
  isApplicableOn (date = new Date()) {
    const { validFrom, validUntil } = this
    const isApplicable = (validFrom == null || date >= startOfDay(validFrom)) &&
      (validUntil == null || date <= endOfDay(validUntil))
    return isApplicable
  }

  /**
   * Indicates whether announcement is no longer applicable
   * @param {Date} date Date to be checked
   * @returns {Boolean}
   */
  isNoLongerApplicableOn (date = new Date()) {
    const { validUntil } = this
    const isNotApplicable = (validUntil != null && date > endOfDay(validUntil))
    return isNotApplicable
  }

  /**
   * Checks whether announcement can be seen by the specified user
   * @param {Organization} organization Organization to check
   * @param {User} user User to check
   * @param {Date} date Optional date at which applicability of the announcement is to be checked.
   * If not specified, time validity of the announcement is not verified
   * @returns {Boolean} True if announcement can be seen by the specified user
   */
  isApplicableFor (organization, user, date) {
    if (!organization) throw new Error('Organization is required')
    if (!user) throw new Error('User is required')

    const { deletedAt, canPublish, publishedAt, validFrom, validUntil } = this

    const isApplicable = organization &&
      user &&
      !deletedAt &&
      canPublish &&
      publishedAt &&
      (date == null || validFrom == null || date >= startOfDay(validFrom)) &&
      (date == null || validUntil == null || date <= endOfDay(validUntil))

    if (isApplicable) {
      const isRecipient = this.recipients.some(recipient => recipient.matches(organization, user))
      return isRecipient
    }

    return false
  }

  // Removes private members on serialization
  toJSON () {
    const { validFrom, validUntil, text } = this
    const result = {
      ...this,
      validFrom,
      validUntil,
      text
    }
    delete result.__text
    return result
  }

  // Creates an unpublished copy of the announcement
  copy () {
    return new Announcement({
      ...this,
      id: undefined,
      createdAt: new Date(),
      updatedAt: new Date(),
      publishedAt: undefined,
      publishedBy: undefined,
      acknowledgedAt: undefined,
      acknowledgedBy: undefined
    })
  }
}