<script>
import { sortItems } from '@stellacontrol/utilities'
import { FormMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { DeviceTreeViewMode } from './device-tree-view-mode'
import {
  EntityType,
  getDeviceLabel,
  getPlaceDescription,
  getPlaceIcon,
  getOrganizationIcon,
  getOrganizationColor,
  sortOrganizations,
  sortPlaces,
  sortDevices,
  OrganizationSortOrder,
  PlaceType,
  PlaceSortOrder,
  DeviceSortOrder
} from '@stellacontrol/model'

export default {
  mixins: [
    FormMixin,
    Secure
  ],

  props: {
    // Hierarchy of organizations, places and devices
    hierarchy: {
      type: Object,
      required: true
    },
    // Initial view mode,
    initialViewMode: {
      type: String,
      default: DeviceTreeViewMode.Tree
    },
    // Nodes initially expanded
    initiallyExpanded: {
      type: Array,
      default: () => []
    },
    // Node initially selected
    initiallySelected: {
      type: String
    },
    // Initial filter
    initialFilter: {
      type: String,
      default: ''
    },
    // If true, checking the checkboxes is allowed
    allowChecking: {
      type: Boolean,
      default: false
    },
    // If true, selecting organizations is allowed
    allowSelectingOrganizations: {
      type: Boolean,
      default: true,
    },
    // If true, selecting places is allowed
    allowSelectingPlaces: {
      type: Boolean,
      default: true,
    },
    // If true, selecting Unassigned place is allowed
    allowSelectingUnassignedPlace: {
      type: Boolean,
      default: true,
    },
    // Automatically collapse the tree after selection has been made
    autoCollapse: {
      type: Boolean,
      default: false
    },
    // If true, also device parts will be shown (by default, only their multi-device units)
    showParts: {
      type: Boolean,
      default: false
    },
    // Callback for determining node color
    getNodeColor: {
      type: Function,
      default: () => { }
    },
    // Callback for determining node tooltips
    getNodeTooltip: {
      type: Function,
      default: () => null
    },
    // Custom filter for organizations visible in the tree
    organizationFilter: {
      type: Function,
      default: () => true
    },
    // Custom filter for places visible in the tree
    placeFilter: {
      type: Function,
      default: () => true
    },
    // Custom filter for devices visible in the tree
    deviceFilter: {
      type: Function,
      default: () => true
    }
  },

  data () {
    return {
      // Indicates whether the tree has been initialized
      isInitialized: false,
      // Counter for generating unique identifiers of unassigned and shared places
      unassignedPlacesCount: 0,
      sharedPlacesCount: 0,
      // View mode: asset tree or building list
      viewMode: '',
      // Hierarchy of tree nodes
      tree: [],
      // Flat list of tree nodes, for easier traversals
      nodes: [],
      // List of devices in the hierarchy
      devices: [],
      // Node filter
      filter: '',
      // List of identifiers of expanded nodes
      expanded: [],
      // List of devices selected by the user
      ticked: [],
      // Currently selected node
      selected: null,
      // View modes
      DeviceTreeViewMode,
      // Place Types
      PlaceType
    }
  },

  computed: {
    // Tree view mode
    treeViewMode () {
      return this.viewMode === DeviceTreeViewMode.Tree
    },

    // Organizations view mode
    organizationsViewMode () {
      return this.viewMode === DeviceTreeViewMode.Organizations
    },

    // Places view mode
    placesViewMode () {
      return this.viewMode === DeviceTreeViewMode.Places
    },

    // Devices view mode
    devicesViewMode () {
      return this.viewMode === DeviceTreeViewMode.Devices
    },

    // Icon representing the current view mode
    viewModeIcon () {
      return {
        [DeviceTreeViewMode.Tree]: 'account_tree',
        [DeviceTreeViewMode.Organizations]: 'domain',
        [DeviceTreeViewMode.Places]: 'home',
        [DeviceTreeViewMode.Devices]: 'router',
      }[this.viewMode]
    },

    // List of all ticked devices
    tickedDevices () {
      return this.devices.filter(device => this.ticked.includes(device.id))
    },

    // Currently selected node
    selectedNode () {
      return this.nodes.find(node => node.id === this.selected)
    },

    // Currently expanded nodes
    expandedNodes () {
      const { nodes, expanded } = this
      return expanded
        .map(id => nodes.find(node => node.id === id))
        .filter(node => node)
    },

    // All top-level nodes, those just below the root node
    topLevelNodes () {
      return this.nodes.filter(node => node.level === 1)
    },

    // Returns true if tree is collapsed to top-level nodes
    topLevelNodesCollapsed () {
      const { expanded, topLevelNodes } = this
      return !topLevelNodes.find(({ id }) => expanded.includes(id))
    },

    // Flat list of nodes representing all organizations displayed on the dashboard
    organizationNodes () {
      const { nodes } = this
      const organizationNodes = nodes.filter(node => node.type === EntityType.Organization)
      return sortItems(organizationNodes, node => node.organization.name)
    },

    // Flat list of nodes representing places of all organizations displayed on the dashboard
    placeNodes () {
      const { nodes } = this
      const placeNodes = nodes
        .filter(node => node.type === EntityType.Place)
        .filter(node => !(node.place.placeType === PlaceType.NoPlace || node.place.placeType === PlaceType.SharedPlace))
      return sortItems(placeNodes, node => node.place.name + node.organization.name)
    },

    // Flat list of nodes representing devices of all organizations displayed on the dashboard
    deviceNodes () {
      const { nodes } = this
      const deviceNodes = nodes.filter(node => node.type === EntityType.Device && node.device.isConnectedDevice)
      return sortItems(deviceNodes, node => node.device.serialNumber)
    },

    // Flat list of organization nodes visible under the current filter
    visibleOrganizationNodes () {
      let { filter, organizationNodes } = this
      const find = (filter || '').trim().toLowerCase()
      if (find) {
        return organizationNodes.filter(({ organization }) => {
          const { name, profile } = organization
          return name.toLowerCase().includes(find) || (profile?.fullName || profile?.name || '').toLowerCase().includes(find)
        })
      } else {
        return organizationNodes
      }
    },

    // Flat list of place nodes visible under the current filter
    visiblePlaceNodes () {
      let { filter, placeNodes } = this
      const find = (filter || '').trim().toLowerCase()
      if (find) {
        return placeNodes.filter(({ place, organization }) =>
          place.name.toLowerCase().includes(find) || organization.name.toLowerCase().includes(find))
      } else {
        return placeNodes
      }
    },

    // Flat list of device nodes visible under the current filter
    visibleDeviceNodes () {
      let { filter, deviceNodes } = this
      const find = (filter || '').trim().toLowerCase()
      if (find) {
        const nodes = deviceNodes.filter(({ device, organization, place } = {}) => {
          const organizationName = organization ? organization.name : ''
          const placeName = (place && place.isRealPlace) ? (place.name || '') : ''
          const deviceName = `${device.acronym || ''}${device.name || ''}${device.serialNumber || ''}`
          const text = `${deviceName}${organizationName}${placeName}`
          return text.toLowerCase().includes(find)
        })
        return nodes
      } else {
        return deviceNodes
      }
    }
  },

  watch: {
    ticked () {
      this.$emit('ticked', {
        identifiers: this.ticked,
        devices: this.tickedDevices
      })
    },

    selected () {
      if (this.isInitialized) {
        this.$emit('selected', this.selectedNode)
      }
    },

    expanded () {
      if (this.isInitialized) {
        this.$emit('expanded', this.expanded)
      }
    },

    filter (newValue, oldValue) {
      // Expand the entire tree once the filter is 2-characters long
      if (this.treeViewMode) {
        if ((newValue || '').length >= 2 && (oldValue || '').length < 2) {
          this.togglePlaces(true)
        }
      }
    }
  },

  methods: {
    getPlaceDescription,
    getPlaceIcon,
    getOrganizationIcon,
    getOrganizationColor,

    /**
     * Recursively creates a hierarchic tree node
     * for the specified organization.
     * @param parentOrganization Parent organization
     */
    createNode (organization, parentOrganization) {
      if (!organization) return

      const { currentOrganization, allowChecking, allowSelectingOrganizations, allowSelectingPlaces, allowSelectingUnassignedPlace, getNodeColor, getNodeTooltip, getNodeClass } = this
      const { organizationFilter, placeFilter, deviceFilter } = this
      const isCurrentOrganization = organization.id === currentOrganization.id

      if (organizationFilter(organization) === false) return

      const iconColor = getOrganizationColor(organization)
      const node = {
        id: organization.id,
        parentId: parentOrganization ? parentOrganization.id : null,
        type: EntityType.Organization,
        selectable: allowSelectingOrganizations,
        expandable: true,
        label: (isCurrentOrganization || !organization.profile) ? organization.name : `${organization.name}, ${organization.profile?.fullName}`,
        children: [],
        level: organization.hierarchyLevel,
        color: getNodeColor(EntityType.Organization, organization),
        tooltip: getNodeTooltip(EntityType.Organization, organization),
        icon: getOrganizationIcon(organization),
        iconColor,
        organization
      }

      if (organization.places) {
        organization.places = sortPlaces(organization.places, PlaceSortOrder.Custom, true)
        for (const place of organization.places) {
          if (placeFilter(place) === false) continue

          const placeChildren = sortDevices(place.devices, DeviceSortOrder.SerialNumber)
            .map(device => {
              if (!this.showParts && device.partOf) return
              if (deviceFilter(device) === false) return

              const deviceOrganization = {
                id: organization.id,
                level: organization.level,
                name: organization.name
              }
              const deviceNode = {
                id: device.id,
                serialNumber: device.serialNumber,
                parentId: place.id,
                type: EntityType.Device,
                selectable: true,
                expandable: false,
                label: getDeviceLabel(device),
                color: getNodeColor(EntityType.Device, device),
                tooltip: getNodeTooltip(EntityType.Device, device),
                icon: allowChecking ? null : 'fiber_manual_record',
                iconSize: allowChecking ? null : '16px',
                iconColor,
                device,
                place,
                organization: deviceOrganization,
                level: device.hierarchyLevel
              }
              deviceNode.cssClass = getNodeClass(deviceNode)
              return deviceNode
            })
            .filter(node => node)

          if (placeChildren.length === 0) continue

          const placeOrganization = {
            id: organization.id,
            level: organization.level,
            name: organization.name
          }

          const placeNode = {
            id: place.id,
            parentId: organization.id,
            type: EntityType.Place,
            selectable: allowSelectingPlaces && allowSelectingUnassignedPlace,
            expandable: true,
            icon: getPlaceIcon(place.placeType, 'not_listed_location'),
            iconColor,
            label: getPlaceDescription(place),
            color: getNodeColor(EntityType.Place, place),
            tooltip: getNodeTooltip(EntityType.Place, place),
            place,
            organizationId: placeOrganization.id,
            organization: placeOrganization,
            children: placeChildren,
            level: place.hierarchyLevel
          }

          // Generate unique IDs for shared place or unassigned place,
          // as they don't have their IDs
          if (place.placeType === PlaceType.NoPlace) {
            placeNode.id = `unassigned-${this.unassignedPlacesCount++}`
          }
          if (place.placeType === PlaceType.SharedPlace) {
            placeNode.id = `shared-${this.sharedPlacesCount++}`
          }

          placeNode.cssClass = getNodeClass(placeNode)
          node.children.push(placeNode)
        }
      }

      if (organization.organizations) {
        const sortedOrganizations = sortOrganizations(organization.organizations, OrganizationSortOrder.Rank, { currentOrganization })
        const childOrganizationNodes = sortedOrganizations
          .map(o => this.createNode(o, organization))
          .filter(node => node)
        node.children.push(...childOrganizationNodes)
      }

      // Skip empty places, if places themselves cannot be selected
      if (node.type === EntityType.Place && !allowSelectingPlaces && node.children.length === 0) {
        return
      }

      // Skip empty organizations, if organizations themselves cannot be selected
      if (node.type === EntityType.Organization && !allowSelectingOrganizations && node.children.length === 0) {
        return
      }

      node.cssClass = getNodeClass(node)
      return node
    },

    // Determines CSS class for tree node
    getNodeClass (node) {
      const cssClass = {}
      if (node.color) {
        cssClass[`text-${node.color}`] = true
      }
      return cssClass
    },

    // Populate the tree
    async populate () {
      this.isInitialized = false
      this.unassignedPlacesCount = 0
      this.sharedPlacesCount = 0

      this.viewMode = this.initialViewMode

      // Build tree node hierarchy
      const node = this.createNode(this.hierarchy)

      // Accumulate flat list of nodes and devices
      const accumulate = node => {
        if (node) {
          this.nodes.push(node)
          if (node.type === EntityType.Device && node.device) {
            this.devices.push(node.device)
          }
          for (const child of node.children || []) {
            accumulate(child)
          }
        }
      }
      this.nodes = []
      this.devices = []
      accumulate(node)

      this.tree = node ? [node] : []
      this.selected = this.initiallySelected
      this.filter = this.initialFilter
      if (this.initiallyExpanded.length > 0) {
        this.expanded = [...this.initiallyExpanded]
      } else {
        this.expanded = [this.hierarchy.id]
      }
      this.isInitialized = true
    },

    // Refreshes the tree
    refresh () {
      this.unassignedPlacesCount = 0
      this.sharedPlacesCount = 0
      const expanded = this.expanded
      const selected = this.selected
      const node = this.createNode(this.hierarchy)
      this.tree = [node]
      this.expanded = expanded
      this.selected = selected
    },

    // Sets the specified view mode
    async setViewMode (mode) {
      this.viewMode = mode
      this.$emit('viewModeChanged', this.viewMode)
    },

    // Toggles the top level tree node
    toggleRoot (state) {
      this.expanded = state ? [this.hierarchy.id] : []
    },

    // Toggles the top level tree nodes in subscription hierarchy
    toggleTopLevelOrganizations (state) {
      this.expanded = this.nodes
        .filter(n => state || (n.type === EntityType.Organization && n.level <= 1))
        .map(n => n.id)
    },

    // Toggles the specified tree nodes in subscription hierarchy
    toggleNodes (entityType, state) {
      const identifiers = this.nodes.filter(n => n.type === entityType).map(n => n.id)
      this.expanded = this.expanded.filter(id => !identifiers.includes(id))
      if (state) {
        this.expanded = [...this.expanded, ...identifiers]
      }
    },

    // Toggles the organization nodes in subscription hierarchy
    toggleOrganizations (state) {
      this.toggleNodes(EntityType.Organization, state)
    },

    // Toggles the place nodes in subscription hierarchy
    togglePlaces (state) {
      if (state) {
        this.toggleNodes(EntityType.Organization, state)
      }
      this.toggleNodes(EntityType.Place, state)
    },

    // Signals that tree should become minimized
    minimizeTree () {
      this.$emit('minimized')
    },

    // Filter the tree
    filterTree (filter) {
      this.$emit('filtered', filter)
    },

    // Filter the organizations
    filterOrganizations (filter) {
      this.$emit('filtered', filter)
    },

    // Filter the places
    filterPlaces (filter) {
      this.$emit('filtered', filter)
    },

    // Filter the devices
    filterDevices (filter) {
      this.$emit('filtered', filter)
    },

    // Finds the specified tree node.
    findNode (type, id) {
      return this.nodes.find(n => n.type === type && n.id === id)
    },

    // Reveals the node with specified id, by expanding its parents
    revealNode (id) {
      let parents = []
      let node
      do {
        node = this.nodes.find(n => n.id === id)
        if (node && node.parentId) {
          id = node.parentId
          parents.push(id)
        } else {
          node = null
        }
      } while (node)

      for (let i = parents.length - 1; i >= 0; i--) {
        const id = parents[i]
        if (!this.expanded.includes()) {
          this.expanded.push(id)
        }
      }
    },

    // Selects the specified tree node.
    selectNode (id) {
      const node = this.nodes.find(n => n.id === id)
      if (node) {
        if (this.autoCollapse) {
          this.minimizeTree()
        }
        this.selected = id
        this.revealNode(id)
      }
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <div class="device-tree-container">

    <header v-if="isInitialized" class="row items-center q-pa-sm">
      <q-btn no-caps no-wrap round dense unelevated :ripple="false"
        class="q-mr-xs bg-indigo-8 text-white" icon="chevron_left" @click.stop="minimizeTree()">
      </q-btn>

      <q-input v-if="treeViewMode" v-model="filter" dense outlined clearable clear-icon="close"
        label="Filter the tree" class="content-stretch bg-white q-ml-xs q-mr-sm"
        style="width: 250px;" @update:model-value="value => filterTree(value)">
      </q-input>
      <q-input v-if="organizationsViewMode" v-model="filter" dense outlined clearable
        clear-icon="close" label="Filter the organizations"
        class="content-stretch bg-white q-ml-xs q-mr-sm" style="width: 290px;"
        @update:model-value="value => filterOrganizations(value)">
      </q-input>
      <q-input v-if="placesViewMode" v-model="filter" dense outlined clearable clear-icon="close"
        label="Filter the places" class="content-stretch bg-white q-ml-xs q-mr-sm"
        style="width: 290px;" @update:model-value="value => filterPlaces(value)">
      </q-input>
      <q-input v-if="devicesViewMode" v-model="filter" dense outlined clearable clear-icon="close"
        label="Filter the devices" class="content-stretch bg-white q-ml-xs q-mr-sm"
        style="width: 290px;" @update:model-value="value => filterDevices(value)">
      </q-input>

      <q-btn v-if="treeViewMode && topLevelNodesCollapsed" class="q-mr-sm" no-caps no-wrap round
        dense unelevated :ripple="false" icon="expand_more" @click="togglePlaces(true)">
        <sc-tooltip text="Expand to devices"></sc-tooltip>
      </q-btn>
      <q-btn v-if="treeViewMode && !topLevelNodesCollapsed" class="q-mr-sm" no-caps no-wrap round
        dense unelevated :ripple="false" icon="expand_less" @click="toggleRoot(true)">
        <sc-tooltip text="Collapse to organizations"></sc-tooltip>
      </q-btn>

      <q-btn no-caps no-wrap round dense unelevated :ripple="false" :icon="viewModeIcon">
        <q-popup-edit square :style="{ padding: 0 }" :cover="false" :model-value="isInitialized">
          <q-list dense>
            <q-item clickable v-close-popup @click="setViewMode(DeviceTreeViewMode.Tree)">
              <div class="row items-center q-pa-sm">
                <q-icon name="account_tree" size="sm" color="indigo-5" class="q-mr-md"></q-icon>
                <span>Show asset hierarchy</span>
              </div>
            </q-item>
            <q-item clickable v-close-popup @click="setViewMode(DeviceTreeViewMode.Organizations)">
              <div class="row items-center q-pa-sm">
                <q-icon name="domain" size="sm" color="indigo-5" class="q-mr-md"></q-icon>
                <span>Show list of organizations</span>
              </div>
            </q-item>
            <q-item clickable v-close-popup @click="setViewMode(DeviceTreeViewMode.Places)">
              <div class="row items-center q-pa-sm">
                <q-icon name="house" size="sm" color="indigo-5" class="q-mr-md"></q-icon>
                <span>Show list of places</span>
              </div>
            </q-item>
            <q-item clickable v-close-popup @click="setViewMode(DeviceTreeViewMode.Devices)">
              <div class="row items-center q-pa-sm">
                <q-icon name="router" size="sm" color="indigo-5" class="q-mr-md"></q-icon>
                <span>Show list of devices</span>
              </div>
            </q-item>
          </q-list>
        </q-popup-edit>
      </q-btn>

    </header>

    <main>
      <q-tree v-if="treeViewMode" ref="tree" color="indigo-6" control-color="indigo-6" :nodes="tree"
        node-key="id" label-key="label" children-key="children" no-connectors no-nodes-label=" "
        no-results-label=" " :duration="0" :default-expand-all="false"
        :tick-strategy="allowChecking ? 'leaf' : 'none'"
        v-model:selected="selected"
        v-model:ticked="ticked"
        v-model:expanded="expanded"
        :filter="filter"
        v-bind="{ ...$props, ...$attrs }">

        <template v-slot:default-header="prop">
          <div class="item row items-center">
            <q-icon v-if="prop.node.icon" :name="prop.node.icon"
              :color="prop.node.iconColor || 'indigo-5'" class="q-mr-xs"
              :size="prop.node.iconSize || '24px'"></q-icon>
            <div class="item-label" :class="prop.node.cssClass">
              {{ prop.node.label }}
              <sc-tooltip v-if="prop.node.tooltip" :text="prop.node.tooltip"></sc-tooltip>
            </div>
          </div>
        </template>

      </q-tree>

      <q-markup-table v-if="organizationsViewMode" dense flat class="places" borderless
        separator="none">
        <tbody>
          <tr v-for="node of visibleOrganizationNodes" :key="node.id"
            :class="{ selected: node.id === selected, 'bg-indigo-2': node.id === selected }">
            <td>
              <div class="organization" @click="selectNode(node.organization.id)">
                <q-icon :name="getOrganizationIcon(node.organization)" color="indigo-5" size="24px"
                  class="q-mr-md"></q-icon>
                <div>
                  <span>
                    {{ node.organization.name }}
                  </span>
                  <span class="text-grey-7" v-if="node.organization.profile">
                    &nbsp;/ {{ node.organization.profile.fullName }}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </q-markup-table>

      <q-markup-table v-if="placesViewMode" dense flat class="places" borderless separator="none">
        <tbody>
          <tr v-for="node of visiblePlaceNodes" :key="node.id"
            :class="{ selected: node.id === selected, 'bg-indigo-2': node.id === selected }">
            <td>
              <div class="place" @click="selectNode(node.place.id)">
                <q-icon :name="getPlaceIcon(node.place.placeType)" color="indigo-5" size="24px"
                  class="q-mr-md"></q-icon>
                <div>
                  <span>
                    {{ node.place.name }}
                  </span>
                  <span class="text-grey-7" v-if="currentOrganization.canHaveChildOrganizations">
                    &nbsp;/ {{ node.organization.name }}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </q-markup-table>

      <q-markup-table v-if="devicesViewMode" dense flat class="places" borderless separator="none">
        <tbody>
          <tr v-for="(node, index) of visibleDeviceNodes" :key="`dev-${index}`"
            :class="{ selected: node.id === selected, 'bg-indigo-2': node.id === selected }">
            <td>
              <div class="device" @click="selectNode(node.device.id)">
                <q-icon name="router" color="indigo-5" size="24px" class="q-mr-md"></q-icon>
                <div>
                  <span>
                    {{ node.device.name || (node.device.acronym + ' ' + node.device.serialNumber) }}
                  </span>
                  <span class="text-grey-7"
                    v-if="node.place && node.place.placeType !== PlaceType.NoPlace">
                    &nbsp;/&nbsp;
                    {{ node.place.placeType === PlaceType.SharedPlace ? 'shared device' :
                        node.place.name
                    }}
                  </span>
                  <span class="text-grey-7" v-if="currentOrganization.canHaveChildOrganizations">
                    &nbsp;/&nbsp;
                    {{ node.organization.name }}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </main>
  </div>
</template>

<style lang='scss'>
.device-tree-container {
  width: 400px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  header {
    flex: 0;
    display: flex;
    flex-direction: row;
    background-color: #f1f1ff;
    border-bottom: solid #0000001f 1px;
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .q-tree__node-header {
    &.q-tree__node--selected {
      background-color: #c5cae9;
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    overflow: hidden;

  }

  .item-label {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .places {
    table.q-table {
      tr {
        &.selected {}

        td {
          padding: 8px;

          .organization,
          .place,
          .device {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 380px;

            i {
              flex: 0;
            }

            div {
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>
