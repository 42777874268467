import { sortItems, createClock } from '@stellacontrol/utilities'
import { isMegaParameterApplicable } from '@stellacontrol/mega'
import { Place } from '@stellacontrol/model'
import { Scan } from '@stellacontrol/devices'
import { DeviceAPI } from '@stellacontrol/client-api'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initializes the Device Dashboard view
   * @param {Device} device Device to view
   * @param {Device} devicePart Multi-device part to view
   */
  async initializeDeviceDashboard ({ commit, dispatch, getters }, { device, devicePart } = {}) {
    const { currentOrganization } = getters

    // Get the owner of the device
    const organization = getters.organizations.find(o => device.ownerId
      ? o.id === device.ownerId
      : currentOrganization.isSuperOrganization && o.id === currentOrganization.id)

    // Get the place where the device is located
    const place = device.placeId ? getters.allPlaces.find(p => p.id === device.placeId) : Place.NoPlace

    // Get guardian for the specified organization
    const organizationGuardian = await dispatch('getGuardian', { principal: organization, force: true })

    // Get the pending premium service associated with device, if any
    const pendingPremiumService = device?.isPremiumServiceNotStarted
      ? await dispatch('getPremiumService', { id: device.premiumServiceId })
      : undefined

    commit('initializeDeviceDashboard', {
      organization,
      organizationGuardian,
      place,
      device,
      devicePart,
      pendingPremiumService
    })
  },

  /**
    * Navigates to device dashboard
    * @param {Device} device Device to show
    */
  async gotoDeviceDashboard ({ dispatch }, { device: { serialNumber } = {} } = {}) {
    if (!(serialNumber)) throw new Error('Device serial number is required')

    await dispatch('gotoRoute', {
      name: AppletRoute.DeviceDashboard,
      params: { serialNumber }
    })
  },

  /**
   * Navigates to multi-device board on the current dashboard
   * @param {Device} device Device to show
   * @param {String} part Serial number of the device board to show
   */
  async gotoDevicePartDashboard ({ dispatch }, { device: { serialNumber } = {}, part } = {}) {
    if (!serialNumber) throw new Error('Device identifier or serial number is required')
    if (!part) throw new Error('Device part serial number is required')

    await dispatch('gotoRoute', {
      name: AppletRoute.DeviceDashboard,
      params: { serialNumber },
      query: { part }
    })
  },

  /**
   * Get the scan results sent by device
   * @param {Device} device Device whose scan results to fetch
   * @param {Number} age Number of recent days for which to return the results. If not specified, all scan results are returned.
   * @returns {Promise<Array[Attachment>]}
   */
  async getDeviceScanResults ({ commit }, { device, age } = {}) {
    const items = await DeviceAPI.getScans({ device, age })
    const scans = sortItems(
      items.map(s => new Scan(s)),
      'createdAt'
    )
    commit('storeDeviceScanResults', { device, scans })
    return scans
  },

  /**
   * Starts watching device scan results
   * @param {Device} device Device whose scan results to watch
   * @param {DeviceStatus} status Recent device status, required to determine whether to watch the scan results at all (only applicable for certain devices)
   * @param {Number} age Number of recent days for which to return the results. If not specified, all scan results are returned.
   */
  async watchDeviceScanResults ({ commit, dispatch }, { device, status, age } = {}) {
    if (!isMegaParameterApplicable('can_send_scans', device, status)) return

    await dispatch('unwatchDeviceScanResults')
    await dispatch('getDeviceScanResults', { device, age })

    const clock = createClock({
      name: 'device-dashboard-scan-results',
      frequency: 60000
    })

    clock.addEventListener('tick', async () => await dispatch('getDeviceScanResults', { device, age }))
    clock.start()

    commit('watchDeviceScanResults', { clock })
  },

  /**
   * Stops watching device scan results
   */
  async unwatchDeviceScanResults ({ commit }) {
    commit('unwatchDeviceScanResults')
  }
}
