<script>
import { Confirmation } from '../../services'
import { ActionItem } from './action-item'

/**
 * Button with drop-down menu for executing actions
 */
export default {
  props: {
    // Custom data associated with the action, such as record
    data: {
      type: Object
    },

    // Button label
    label: {
      type: String,
      default: ''
    },

    // Icon to display on the button
    icon: {
      type: String,
      default: 'more_vert'
    },

    // Color of the icon on the button
    color: {
      type: String,
      default: 'indigo-6'
    },

    // Button round attribute
    round: {
      type: Boolean,
      default: true
    },

    // Button flat attribute
    flat: {
      type: Boolean,
      default: true
    },

    // Dropdown title
    title: {
      type: String
    },

    // Actions to display,
    // a list of { name, label, icon, confirmation, visible } objects
    // Property `visible` can be a boolean or boolean-returning predicate.
    actions: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      state: {}
    }
  },

  computed: {
    // Indicates that there's no label to show on the button
    noLabel () {
      return !this.label
    },

    // List of visible actions
    visibleActions () {
      const { data, actions = [] } = this

      return actions
        .map(action => (new ActionItem({
          ...action,
          // Determine action label, possibly by a callback
          label: typeof action.label === 'function'
            ? action.label(data)
            : action.label
        })))
        .filter(action => action.canExecute(data))
    },

    // Determines action label
    getActionLabel () {
      return action => typeof action.label === 'function'
        ? action.label(this.data)
        : action.label
    },

    // Determines action icon
    getActionIcon () {
      return action => typeof action.icon === 'function'
        ? action.icon(this.data)
        : action.icon
    },

    // Determines action icon color
    getActionColor () {
      return action => typeof action.color === 'function'
        ? action.color(this.data)
        : action.color
    }
  },

  methods: {
    async executeAction (action) {
      const yes = action.confirmation
        ? await Confirmation.ask({ message: action.confirmation })
        : true
      if (yes) {
        if (action.handler) {
          await action.handler()
        }
        this.$emit('action', action)
      }
    }
  },

  created () {
    this.$nextTick(() => {
      const { button } = this.$refs
      if (this.noLabel && button) {
        button.$el.classList.add('no-label')
      }
    })
  }
}
</script>

<template>
  <q-btn
    class="button-actions pointer"
    v-if="visibleActions.length > 0"
    ref="button"
    size="md"
    padding="2px"
    flat
    v-bind="{...$props, ...$attrs}"
    :round="noLabel || round"
    @click.stop="() => {}">

    <q-popup-edit square class="q-pa-none" :cover="false" :model-value="state">
      <q-list>
        <q-item v-if="title">
          <q-item-section>
            <q-item-label>
              <div class="label-actions" style="font-weight: bold; white-space: nowrap; text-align: center;">
                {{ title }}
              </div>
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-separator v-if="title" />

        <template v-for="action in visibleActions">
          <q-separator v-if="action.separator"></q-separator>

          <q-item :class="{ [`action-${action.name}`]: true }" clickable v-close-popup @click.stop="executeAction(action)">
            <q-item-section side v-if="action.icon">
              <q-icon :name="getActionIcon(action)" :color="getActionColor(action)" size="24px"></q-icon>
            </q-item-section>
            <q-item-section v-if="action.label">
              <q-item-label class="action-label">
                {{ getActionLabel(action) }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>

      </q-list>
    </q-popup-edit>

  </q-btn>
</template>

<style scoped lang="scss">

.action-label {
  white-space: nowrap;
}

</style>