<script>
import { mapState, mapActions } from 'vuex'
import { ViewMixin } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { WalletTransactionType, getOrganizationIcon, OrganizationColors, OrganizationSortOrder, sortOrganizations, OrganizationHierarchy } from '@stellacontrol/model'
import PremiumSubscriberDialog from './components/premium-subscriber-dialog.vue'
import { resolve } from './premium-subscribers.resolve'

const name = 'premium-subscribers'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  components: {
    'sc-premium-subscriber-dialog': PremiumSubscriberDialog
  },

  data () {
    return {
      name,
      // Organizations currently collapsed in the hierarchy view
      collapsedOrganizations: [],
      // Transaction types
      WalletTransactionType,
      // Visibility of detailed stats columns
      showStats: false
    }
  },

  computed: {
    ...mapState({
      // Customer hierarchy of the currently logged in organization
      customers: state => state.serviceManagement.customers,
      // All available organizations
      organizations: state => state.organizations.items || [],
      // All available devices, per owner
      devices: state => state.serviceManagement.devices,
      // Wallet of the currently logged in organization
      myWallet: state => state.serviceManagement.wallet,
      // List columns
      columns: state => state.serviceManagement.premiumSubscribersView.columns,
      // Selected customer
      customer: state => state.serviceManagement.premiumSubscribersView.customer
    }),

    // Visible columns
    visibleColumns () {
      return this.columns.filter(c => c.stats ? this.showStats : true)
    },

    // Premium customers
    customersList () {
      const { currentOrganization, customers, organizations } = this
      if (customers && organizations && currentOrganization) {
        return [
          OrganizationHierarchy.fromOrganization(currentOrganization),
          ...customers
            .toList(false)
            .filter(c => {
              const o = organizations.find(o => o.id === c.id)
              c.permissions = o.permissions
              return c.isPremiumCustomer ||
                c.isPremiumReseller ||
                c.hasPremiumCustomers
            })
        ]
      } else {
        return []
      }
    },

    // List of customers currently in display
    visibleCustomers () {
      const { currentOrganization, customers, customersList, collapsedOrganizations } = this

      if (collapsedOrganizations !== null) {
        // Sort organizations by hierarchy,
        // Hide collapsed child organizations
        const visibleCustomers = sortOrganizations(customersList, OrganizationSortOrder.Hierarchy, { currentOrganization, hierarchy: customers })
          .filter(o => o && this.parentExpanded(o))
        return visibleCustomers
      }
    },

    // Returns indentation to apply to a customer,
    // to visualise the hierarchy of organizations.
    // This will be zero when filtering organizations by text.
    organizationIndent () {
      return customer => {
        const levelIndent = (customer.hierarchyLevel - 1) * 24
        return Math.max(8, 8 + levelIndent)
      }
    },

    // Returns root organization
    rootOrganization () {
      return this.customersList.find(o => o.hierarchyLevel === 0)
    },

    // Returns true if root organization has child organizations
    hasOrganizations () {
      return this.customersList.length > 0
    },

    // Returns top-level organizations under root organization
    topLevelOrganizations () {
      return this.customersList.filter(o => o.hierarchyLevel === 1)
    },

    // Returns true if specified organization is my organization
    isMyOrganization () {
      return organization => this.currentOrganization?.id === organization.id
    },

    // Returns true if specified organization is now expanded
    isOrganizationExpanded () {
      return organization => !this.collapsedOrganizations.includes(organization.id)
    },

    // Returns true if specified organization is now collapsed
    isOrganizationCollapsed () {
      return organization => this.collapsedOrganizations.includes(organization.id)
    },

    // Returns true if children of top-level organization are all expanded
    isHierarchyExpanded () {
      return this.topLevelOrganizations.every(o => this.isOrganizationExpanded(o))
    },

    // Returns icon representing the organization
    getOrganizationIcon () {
      return organization => organization.isFavorite
        ? 'star'
        : getOrganizationIcon(organization)
    },

    getOrganizationColor () {
      return organization => organization.isFavorite
        ? 'orange-5'
        : OrganizationColors[organization.level] || 'indigo-5'
    },

    // Customer's devices
    devicesOf () {
      return ({ id }) => this.devices[id] || []
    },

    // Customer's devices with active premium subscriptions
    activeDevices () {
      return (devices = []) => {
        return devices.filter(d => d.isPremiumServiceActive)
      }
    },

    // Customer's devices with not-activated premium services
    pendingDevices () {
      return (devices = []) => {
        return devices.filter(d => d.isPremiumServiceNotStarted)
      }
    },

    // Customer's devices with all premium subscriptions expired
    expiredDevices () {
      return (devices = []) => {
        return devices.filter(d => d.hasPremiumServiceExpired)
      }
    },

    // Customer's devices without any premium subscriptions
    standardDevices () {
      return (devices = []) => {
        return devices.filter(d => !d.hasPremiumService)
      }
    }
  },

  methods: {
    ...mapActions([
      'gotoRoute',
      'showDialog',
      'hideDialog'
    ]),

    // Checks whether we can edit wallet of the specified customer
    canEditWallet (customer) {
      return customer.isPremiumCustomer && !this.isMyOrganization(customer)
    },

    // Opens the details of the currently selected customer
    openCustomer () {
      this.hideDialog({ dialog: 'premium-subscriber' })
      setTimeout(() => {
        if (this.customer) {
          this.showDialog({ dialog: 'premium-subscriber' })
        }
      }, 100)
    },

    // Closes the customer
    closeCustomer () {
      this.gotoRoute({ name: 'premium-subscribers' })
    },

    // Triggered when customer has been selected
    // Loads the wallet of that customer
    customerSelected (item) {
      const { currentOrganization } = this
      if (item && item.id) {
        const query = item.id === currentOrganization.id
          ? {}
          : { organization: item.id }
        this.gotoRoute({ name: 'premium-subscribers', query })
      }
    },

    // Determines whether organization should be visible or hidden, depending on parent status
    parentExpanded (organization) {
      const parent = organization ? this.customersList.find(o => o.id === organization.parentOrganizationId) : undefined
      const expanded = parent ? (this.isOrganizationExpanded(parent) && this.parentExpanded(parent)) : true
      return expanded
    },

    // Applies filter to organizations.
    // Takes care of showing the hierarchy up and down, if organization is a match.
    // Default filtering of the list will only show matching rows and hide all other rows.
    isOrganizationVisible (organization, filter) {
      filter = (filter || '').trim().toLowerCase()

      let isMatch = !filter
      if (!isMatch) {
        isMatch = organization.name.toLowerCase().includes(filter)
      }

      if (!isMatch) {
        const ancestors = this.customers.getParentsOf(organization.id)
        isMatch = ancestors.some(o => o.name.toLowerCase().includes(filter))
      }

      if (!isMatch) {
        const descendants = this.customers.getChildrenOf(organization.id)
        isMatch = descendants.some(o => o.name.toLowerCase().includes(filter))
      }

      return isMatch
    },

    // Toggles visibility of hierarchy of child organizations
    // under the specified organization
    toggleOrganization ({ id }) {
      const organization = this.customersList.find(o => o.id === id)
      if (organization && organization.hasChildOrganizations) {
        const expand = this.collapsedOrganizations.includes(organization.id)
        const present = this.collapsedOrganizations.includes(id)
        if (expand && present) {
          this.collapsedOrganizations = this.collapsedOrganizations.filter(item => item !== id)
        } else if (!expand && !present) {
          this.collapsedOrganizations = [...this.collapsedOrganizations, id]
        }
      }
    },

    // Toggles all organizations
    toggleAll () {
      if (this.isHierarchyExpanded) {
        this.collapsedOrganizations = this.topLevelOrganizations.map(o => o.id)
      } else {
        this.collapsedOrganizations = []
      }
    },

    // Opens dialog for purchasing tokens
    purchaseTokens () {
      const { customer } = this
      this.showDialog({ dialog: 'purchase-tokens', data: { customer } })
    }
  },

  watch: {
    customer () {
      this.openCustomer()
    }
  },

  // Reload data on navigation to another organization or subscription
  async beforeRouteUpdate (to, from, next) {
    await resolve({ from, to })
    await next()
  }
}

</script>

<template>
  <sc-view v-if="canUse('premium-services-tokens')" :name="name" :headerStyle="{ padding: 0 }"
    :titleStyle="{ padding: '20px 20px 20px 15px' }"
    :toolbarStyle="{ padding: '20px 20px 20px 20px', 'background-color': '#e8eaf6', 'border-left': 'solid #ced1d8 1px' }">
    <template #toolbar>
      <div class="column my-wallet q-pl-lg q-pr-lg">
        <div class="text-grey-9 row justify-center">{{ currentOrganization.name }}</div>
        <div v-if="guardian.isBank" class="text-grey-9 justify-center">Token Bank</div>
        <div
          v-else-if="canUse('premium-services-sell') || canUse('premium-services-tokens') || mustUse('premium-services-buy')"
          class="text-grey-9 row justify-center">
          <span v-if="myWallet?.balance > 0">
            <b>{{ myWallet.balanceDescription }}</b>
          </span>
          <span v-else class="text-red-9">Your wallet is empty!</span>
        </div>
        <div v-if="mustUse('premium-services-buy')" class="row justify-center text-grey-9 q-mt-md">
          <q-btn flat unelevated dense class="bg-green-5 text-white" label="Purchase more tokens!"
            @click="purchaseTokens()">
          </q-btn>
        </div>
      </div>
    </template>

    <template #header>
      <sc-list-filter v-if="hasOrganizations" :name="name">
        <template #buttons>
          <q-btn no-caps dense unelevated :ripple="false" class="q-mr-sm"
            :label="isHierarchyExpanded ? 'Collapse all' : 'Expand all'"
            :icon="isHierarchyExpanded ? 'expand_more' : 'chevron_right'" @click="toggleAll()">
          </q-btn>
        </template>
      </sc-list-filter>
    </template>

    <main class="q-pa-md">
      <sc-list class="organization-hierarchy" v-if="hasOrganizations" :name="name"
        :columns="visibleColumns" :items="visibleCustomers" :hide-pagination="true" row-key="key"
        :filterMethod="isOrganizationVisible">

        <template v-slot:header-cell-hierarchy="props">
          <q-th :props="props" :style="{ 'width': '500px' }">
            Organization
          </q-th>
        </template>

        <template v-slot:body-cell-hierarchy="props">
          <q-td :props="props"
            :style="{ 'width': '500px', 'padding-left': `${organizationIndent(props.row)}px` }"
            @click="toggleOrganization(props.row)">
            <div class="row items-center no-wrap">
              <q-icon size="22px" :name="getOrganizationIcon(props.row)"
                :color="getOrganizationColor(props.row)"></q-icon>
              <span class="q-ml-sm">
                <router-link class="item-link" v-if="canEditWallet(props.row)"
                  :to="{ name: 'premium-subscribers', query: { organization: props.row.id } }"
                  @click.stop>
                  {{ props.row.name }}
                  <sc-tooltip :text="`Open wallet of ${props.row.name}`"></sc-tooltip>
                </router-link>
                <span v-else>
                  {{ props.row.name }}
                </span>
              </span>
              <q-icon size="22px" class="q-ml-sm" color="indigo-5" style="margin-top: 2px;"
                v-if="props.row.hasChildOrganizations"
                :name="isOrganizationExpanded(props.row) ? 'expand_more' : 'chevron_right'">
              </q-icon>
            </div>
          </q-td>
        </template>

        <template v-slot:header-cell-tokens="props">
          <q-th :props="props">
            <div class="row items-center justify-end">
              <sc-hint size="20px">
                Amount of tokens in customer's wallet, which can be used to purchase premium
                services
              </sc-hint>
              <span>Tokens</span>
            </div>
          </q-th>
        </template>

        <template v-slot:body-cell-tokens="props">
          <q-td :props="props">
            <span v-if="props.row.isBank">
              Super Organization
            </span>
            <span v-else-if="props.row.isPremiumCustomer && !isMyOrganization(props.row)">
              {{ props.row.wallet.balance }}
            </span>
            <span v-else-if="props.row.isPremiumCustomer && isMyOrganization(props.row)">
              {{ myWallet?.balance }}
            </span>
            <span v-else>
              PS not required
            </span>
          </q-td>
        </template>

        <template v-slot:header-cell-devices="props" v-if="showStats">
          <q-th :props="props">
            <div class="row items-center justify-end">
              <sc-hint size="20px">
                All devices owned by the customer
              </sc-hint>
              <span>
                <b>Devices</b>
              </span>
            </div>
          </q-th>
        </template>

        <template v-slot:body-cell-devices="props" v-if="showStats">
          <q-td :props="props">
            {{ devicesOf(props.row).length }}
          </q-td>
        </template>

        <template v-slot:header-cell-active="props" v-if="showStats">
          <q-th :props="props">
            <div class="row items-center justify-end">
              <sc-hint size="20px">
                Devices with active premium subscriptions
              </sc-hint>
              <span>
                Active Premium Devices
              </span>
            </div>
          </q-th>
        </template>

        <template v-slot:body-cell-active="props" v-if="showStats">
          <q-td :props="props">
            {{ activeDevices(devices[props.row.id]).length }}
          </q-td>
        </template>

        <template v-slot:header-cell-pending="props" v-if="showStats">
          <q-th :props="props">
            <div class="row items-center justify-end">
              <sc-hint size="20px">
                Devices with basic functionality, which have premium subscriptions waiting to be
                activated
              </sc-hint>
              <span>
                Pending Premium Devices
              </span>
            </div>
          </q-th>
        </template>

        <template v-slot:body-cell-pending="props" v-if="showStats">
          <q-td :props="props">
            {{ pendingDevices(devices[props.row.id]).length }}
          </q-td>
        </template>

        <template v-slot:header-cell-expired="props" v-if="showStats">
          <q-th :props="props">
            <div class="row items-center justify-end">
              <sc-hint size="20px">
                Devices with only basic functionality, whose premium subscriptions expired
              </sc-hint>
              <span>
                Expired Devices
              </span>
            </div>
          </q-th>
        </template>

        <template v-slot:body-cell-expired="props" v-if="showStats">
          <q-td :props="props">
            {{ expiredDevices(devices[props.row.id]).length }}
          </q-td>
        </template>

        <template v-slot:header-cell-standard="props" v-if="showStats">
          <q-th :props="props">
            <div class="row items-center justify-end">
              <sc-hint size="20px">
                Devices with basic functionality, without any premium subscriptions
              </sc-hint>
              <span>Standard Devices</span>
            </div>
          </q-th>
        </template>

        <template v-slot:body-cell-standard="props" v-if="showStats">
          <q-td :props="props">
            {{ standardDevices(devices[props.row.id]).length }}
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props" class="actions">
            <q-btn v-if="canEditWallet(props.row)" no-caps unelevated dense
              :to="{ name: 'premium-subscribers', query: { organization: props.row.id } }">
              Open wallet
            </q-btn>
          </q-td>
        </template>
      </sc-list>

      <div class="q-ma-md" v-else>
        There are no child organizations yet.
      </div>

    </main>

    <sc-premium-subscriber-dialog @close="closeCustomer()">
    </sc-premium-subscriber-dialog>

    <sc-purchase-tokens-dialog>
    </sc-purchase-tokens-dialog>

  </sc-view>
</template>

<style lang='scss' scoped>
header {
  flex: 0;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #0000001f;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .customers {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}

.organization-hierarchy:deep(.q-table) tr .q-td.actions * {
  visibility: hidden;
}

.organization-hierarchy:deep(.q-table) tr:hover .q-td.actions * {
  visibility: visible;
}

.my-wallet {
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-content: center;
}
</style>
