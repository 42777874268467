import { differenceInSeconds } from 'date-fns'
import { Log, wait, randomInt, getId } from '@stellacontrol/utilities'
import { APISession } from '../api-session'

/**
 * Push notification client
 * Use it as a base class to implement domain-specific push clients.
 * @description Implemented using the standard `WebSocket` class
 * and reconnecting websocket wrapper from
 * @link https://github.com/joewalnes/reconnecting-websocket
 */
export class PushClient {
  constructor () {
    this.id = getId('push')
    this.name = 'generic'
  }

  /**
   * Infinite reconnect attempts
   */
  static RECONNECT_INFINITE = Number.MAX_VALUE

  /**
   * Default reconnect interval
   */
  static RECONNECT_INTERVAL = 5000

  /**
   * Unique identifier
   * @type {String}
   */
  id

  /**
   * User-friendly name
   * @type {String}
   */
  name

  /**
   * Websocket handle
   * @type {WebSocket}
   */
  socket

  /**
   * Connected websocket URL
   * @type {String}
   */
  url

  /**
   * Connected websocket options
   * @type {Object}
   */
  options

  /**
   * If greater than zero, the connection will be automatically restored when lost,
   * until the reconnection attempts run out
   * @type {Number}
   */
  reconnectAttempts

  /**
   * Number of attempted connections used to far
   * @type {Number}
   */
  attemptedConections

  /**
   * Interval between reconnect attempts, in milliseconds
   * @type {Number}
   */
  reconnectInterval

  /**
   * If true, the connection has been once established
   * @type {Boolean}
   */
  hasConnected

  /**
   * If true, the connection has been closed forcefully
   * and no attempts to reconnect automatically should be made
   * @type {Boolean}
   */
  forcedClose

  /**
   * Connected websocket's string or array of WS protocols
   * @type {String|Array[String]}
   */
  protocols

  /**
   * Message and error handlers specified when connecting to the web socket server
   * @type {Dictionary<String, Function>}
   */
  handlers = {}
  internalHandlers = {}

  /**
   * Indicates that listener has been suspended.
   * Messages might still arrive but message event handler won't be dispatched
   * @type {Boolean}
   */
  isSuspended = false

  /**
   * Last received message
   * @type {PushMessage}
   */
  message

  /**
   * Return true if push client has received any message
   * @type {Boolean}
   */
  hasMessage () {
    return this.message != null
  }

  /**
   * Date and time of the last received message
   * @type {Date}
   */
  messageTime

  /**
   * Number of messages received since last (re)connection
   * @type {Number}
   */
  messageCount

  /**
   * Age of the last received message, in seconds
   * @type {Number}
   */
  get messageAge () {
    if (this.messageTime) {
      return differenceInSeconds(new Date(), this.messageTime)
    }
  }

  /**
 * Returns true if websocket is now connected
 * @type {Boolean}
 */
  get isOpen () {
    // Socket states:
    // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
    const { socket } = this
    return socket?.readyState === 1
  }

  /**
   * Returns true if websocket is now closed
   * @type {Boolean}
   */
  get isClosed () {
    // Socket states:
    // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
    const { socket } = this
    return !socket || socket.readyState === 3
  }

  /**
   * Returns true if websocket is now open and client is listening to messages on it
   * @type {Boolean}
   */
  get isListening () {
    return this.isOpen && !this.isSuspended
  }

  /**
   * Determines whether the client is allowed to attempt to connect again
   * in case of failure of the initial connection
   */
  get canRetryConnection () {
    return !this.forcedClose &&
      this.reconnectInterval > 0 &&
      this.attemptedConections < this.reconnectAttempts
  }

  /**
   * Determines whether the client is allowed to automatically reconnect
   * in case of an error or lost connection
   */
  get canReconnect () {
    return this.reconnectAttempts > 0 &&
      this.reconnectInterval > 0 &&
      this.attemptedConections < this.reconnectAttempts &&
      !this.forcedClose
  }

  /**
   * Connects to a websocket
   * @param {String} url URL of a websocket to connect to
   * @param {Object} options Optional additional options
   * @param {String|Array[String]} protocols Optional string or array of WS protocols to support
   * @param {Number} reconnectAttempts If greater than zero, the connection will be automatically restored when lost, until the reconnection attempts run out
   * @param {Number} reconnectInterval Interval between reconnect attempts, in milliseconds
   * @param {Function} onConnect Optional handler triggered when websocket has been opened
   * @param {Function<Object>} onMessage Optional handler for the incoming messages, receiving tuple `{ id, data, origin }`
   * @param {Function} onReconnect Optional custom handler for websocket reconnections
   * @param {Function<Error>} onError Optional custom handler for websocket errors
   */
  async open ({
    url,
    options = {},
    protocols,
    reconnectAttempts,
    reconnectInterval,
    onConnect,
    onMessage,
    onReconnect,
    onError
  } = {}) {
    if (!url) throw new Error('Websocket URL is required')

    this.url = url
    this.protocols = protocols
    this.reconnectAttempts = reconnectAttempts > 0 ? reconnectAttempts : 1
    this.reconnectInterval = reconnectInterval > 0 ? reconnectInterval : 5000
    this.options = { ...(options || {}) }
    this.handlers = { onMessage, onReconnect, onError, onConnect }

    const tryOpen = () => new Promise((resolve, reject) => {
      try {
        this.message = undefined
        this.messageTime = undefined
        this.messageCount = 0

        if (this.isConnected) {
          this.close(false)
        }

        const socket = new WebSocket(this.url, this.protocols, this.options)
        socket.binaryType = 'blob'

        const onOpenHandler = () => {
          Log.debug(`[${url}] Connected to push server [${this.name}]`)
          this.attemptedConections = 0
          this.hasConnected = true
          this.forcedClose = false
          resolve(this)
          if (onConnect) {
            onConnect(this)
          }
        }

        const onCloseHandler = () => {
          // Detach all event handlers
          for (const [name, handler] of Object.entries(this.internalHandlers)) {
            socket.removeEventListener(name, handler)
          }
          // Try reconnect if closed due to errors and allowed to resume
          if (this.canReconnect) {
            this.reconnect()
          } else {
            // Otherwise just disconnect,
            // but allow for reconnection in later time
            if (this.hasConnected) {
              const message = `[${url}] Disconnected from push server [${this.name}]`
              Log.debug(message)
              this.close(true)
              reject(new Error(message))
            } else {
              this.close(false)
              reject(new Error(`[${url}] Could not connect to the push server [${this.name}]`))
            }
          }
        }

        const sessionEnded = error => error?.message?.toLowerCase()?.includes('not logged in')

        const onErrorHandler = onError
          ? (error) => {
            this.close(!sessionEnded(error))
            if (!onError(error)) {
              reject(error.message ? error : new Error('Web socket error'))
            }
          }
          : (error) => {
            this.close(!sessionEnded(error))
            Log.error(`[${url}] Web socket error ${error.message || ''}`)
            reject(error.message ? error : new Error('Web socket error'))
          }

        socket.addEventListener('open', onOpenHandler)
        socket.addEventListener('close', onCloseHandler)
        socket.addEventListener('error', onErrorHandler)

        this.internalHandlers = { open: onOpenHandler, close: onCloseHandler, error: onErrorHandler }
        this.socket = socket

        if (onMessage) {
          this.listen(onMessage)
        }

      } catch (error) {
        this.close()
        reject(error)
      }
    })

    this.attemptedConections = 0
    let canRetry
    do {
      try {
        if (this.attemptedConections > 0) {
          await wait(this.reconnectInterval)
        }
        this.attemptedConections++

        canRetry = this.canRetryConnection
        if (canRetry) {
          Log.debug(`[${url}] Connecting to push server [${this.name}] ...`)
          const connected = await tryOpen()
          if (connected) {
            return connected
          }
        } else {
          if (!this.hasConnected) {
            Log.debug(`[${url}] Stopped connecting to push server [${this.name}]`)
          }
        }
      } catch {
        if (canRetry) {
          Log.debug(`[${url}] Trying again in ${this.reconnectInterval}ms ...`)
        } else {
          Log.debug(`[${url}] Stopped connecting to push server [${this.name}]`)
        }
      }
    } while (canRetry)
  }

  /**
   * Disconnects from the web socket
   * @param {Boolean} allowReconnect If true, the connection will be automatically retried.
   * This parameter will be ignored if the listener has already been forcefully closed.
   */
  async close (allowReconnect) {
    try {
      const { socket } = this
      this.socket = undefined
      if (socket) {
        await socket.close()
      }
    } finally {
      if (!this.forcedClose) {
        this.forcedClose = !allowReconnect
        if (this.forcedClose) {
          this.hasConnected = false
        }
      }
    }
  }

  /**
   * Tries reconnecting to a disconnected socket
   */
  async reconnect () {
    const { url, isOpen, reconnectInterval, socket } = this
    if (!isOpen && socket) {
      const { options, protocols, reconnectAttempts } = this
      const { onConnect, onMessage, onError, onReconnect } = this.handlers
      try {
        Log.debug(`[${url}] Connection to [${this.name}] lost, reconnecting in ${reconnectInterval} ms ...`)
        await wait(reconnectInterval)

        if (this.canReconnect) {
          this.forcedClose = false
          const connected = await this.open({
            url,
            options,
            protocols,
            reconnectAttempts,
            reconnectInterval,
            onConnect,
            onMessage,
            onError,
            onReconnect
          })

          if (connected) {
            if (this.handlers.onReconnect) {
              this.handlers.onReconnect(this)
            }
            return connected
          }
        }

      } catch {
        // lint-disable-line no-empty
      }
    }
  }


  /**
   * Connects to a web socket and starts listening to incoming messages.
   * A shortcut to combination of `open`, `send` and `listen`.
   * @param {String} url URL of a websocket to connect to
   * @param {Object} options Optional additional options
   * @param {String|Array[String]} protocols Optional string or array of WS protocols to support
   * @param {Number} reconnectAttempts If greater than zero, the connection will be automatically restored when lost, until the reconnection attempts run out
   * @param {Number} reconnectInterval Interval between reconnect attempts, in milliseconds
   * @param {Function<Object>} onMessage Handler for the incoming messages
   * @param {Function<Error>} onError Optional custom handler for websocket errors
   * @param {Object} message Optional initial message to send to the URL on succesful connection
   * @param {Number} delay Optional delay in milliseconds before sending the initial message
   * @param {Boolean} authenticate If true, JWT token is taken from current client session and sent with the request.
   */
  async subscribe ({
    url,
    options,
    protocols,
    reconnectAttempts,
    reconnectInterval,
    onMessage,
    onError,
    message,
    delay,
    authenticate
  } = {}) {
    if (!url) throw new Error('Websocket URL is required')
    if (!onMessage) throw new Error('Message handler is required')

    // When connected, send the initial message
    const onConnect = async () => {
      if (this.isOpen && message) {
        if (delay) {
          await wait(delay)
        }
        this.send({ message, authenticate })
      }
    }

    const connected = await this.open({
      url,
      protocols,
      options,
      reconnectAttempts,
      reconnectInterval,
      onMessage,
      onConnect,
      onError
    })

    return connected
  }

  /**
   * Starts listening to incoming messages and triggers the handler
   * @param {Function} onMessage Message handler, receiving `{ data, message }`
   * where `data` is message payload ready to consume, and `message` is an envelope
   * with extra information such as message `id`, `origin`, `socket` details etc.
   */
  listen (onMessage) {
    if (!onMessage) throw new Error('Message handler is required')
    const { socket } = this

    if (socket) {
      const onMessageHandler = async (event) => {
        const data = await this.parseMessage(event.data)
        const origin = event.origin
        const id = event.lastEventId || `${new Date().getTime()}${randomInt(1000, 10000)}`

        this.message = { id, data, origin, socket }
        this.messageTime = new Date()
        this.messageCount++

        if (!this.isSuspended) {
          onMessage(data, this.message)
        }
      }

      socket.addEventListener('message', onMessageHandler)
      this.internalHandlers.message = onMessageHandler

    } else {
      throw new Error('The socket is not yet open')
    }
  }

  /**
   * Parses the received websocket message to JSON
   * @param {blob} blob Data blob received from the web socket
   * @returns {Object} Message parsed to JSON
   */
  async parseMessage (blob) {
    if (blob) {
      try {
        const text = await blob.text()
        const data = JSON.parse(text)
        return data
      } catch (error) {
        throw new Error(`Error parsing web socket message: ${error.message}`)
      }
    }
  }

  /**
   * Sends a message to the previously connected websocket
   * @param {Object} message Message to send to the websocket
   * @param {Boolean} authenticate If true, JWT token is taken from current client session and sent with the request.
   */
  send ({ message, authenticate } = {}) {
    const { socket, isOpen } = this

    if (message && isOpen) {
      if (authenticate) {
        if (APISession.hasSession) {
          message.authorization = APISession.token
        } else {
          this.forcedClose = true
          Log.error('Cannot send the message, user is not logged in', message)
          return
        }
      }
      socket.send(JSON.stringify(message))
    }
  }

  /**
   * Suspends the listener. Messages might still arrive but message event handler won't be dispatched.
   */
  suspend () {
    this.isSuspended = true
  }

  /**
   * Resumes the suspended listener
   */
  resume () {
    this.isSuspended = false
  }
}
