<script>
import AlertConfigurationComponent from './alert-configuration-component'

export default {
  mixins: [
    AlertConfigurationComponent
  ]
}
</script>

<template>
  <!-- empty -->
</template>

<style scoped lang="scss">
</style>