import { Wallet, WalletTransaction, Pricelist, PremiumService, PremiumServiceSubscription, AuditItem, Notification } from '@stellacontrol/model'
import { APIClient } from './api-client'

/**
 * Service Management API client
 */
export class ServiceManagementAPIClient extends APIClient {
  /**
   * Returns API name served by this client
   */
  get name () {
    return 'ServiceManagement'
  }

  /**
   * Returns service pricelists
   * @returns {Promise<Array[Pricelist]>}
   */
  async getPricelists () {
    try {
      const method = 'get'
      const url = this.endpoint('pricelist')
      const { pricelists } = await this.request({ method, url })
      return this.asPricelists(pricelists || [])
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns a specified pricelist
   * @param {String} id Pricelist identifier
   * @returns {Promise<Pricelist>}
   */
  async getPricelist ({ id } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('pricelist', id)
      const { pricelist } = await this.request({ method, url })
      return this.asPricelist(pricelist)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves a specified pricelist
   * @param pricelist Pricelist to save
   */
  async savePricelist ({ pricelist: data } = {}) {
    try {
      const method = data.id ? 'put' : 'post'
      const url = this.endpoint('pricelist', data.id)
      const { pricelist } = await this.request({ method, url, data })
      return this.asPricelist(pricelist)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes a specified pricelist
   * @param pricelist Pricelist to delete
   */
  async removePricelist ({ pricelist: { id } } = {}) {
    try {
      const method = 'delete'
      const url = this.endpoint('pricelist', id)
      const { pricelist } = await this.request({ method, url })
      return pricelist
    } catch (error) {
      this.handleError(error)
    }
  }


  /**
   * Returns the specified premium service
   * @param id Premium service identifier
   */
  async getPremiumService ({ id }) {
    try {
      const method = 'get'
      const url = this.endpoint('premium-service', id)
      const { premiumService } = await this.request({ method, url })
      return this.asPremiumService(premiumService)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns all premium services
   */
  async getPremiumServices () {
    try {
      const method = 'get'
      const url = this.endpoint('premium-service')
      const { premiumServices } = await this.request({ method, url })
      return this.asPremiumServices(premiumServices)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Stores a premium service belonging to a pricelist
   * @param premiumService Premium service to store
   */
  async savePremiumService ({ premiumService: data }) {
    try {
      const method = data.id ? 'put' : 'post'
      const url = this.endpoint('premium-service', data.id)
      const { premiumService } = await this.request({ method, url, data })
      return this.asPremiumService(premiumService)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes a premium service from pricelists
   * @param id Identifier of a premium service to delete
   */
  async removePremiumService ({ premiumService: { id } }) {
    try {
      const method = 'delete'
      const url = this.endpoint('premium-service', id)
      const { premiumService } = await this.request({ method, url })
      return this.asPremiumService(premiumService)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns organization's wallet
   * @param organization Organization. If not specified, current organization's wallet
   * will be retrieved
   */
  async getWallet ({ organization: { id } = {} } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('wallet', id)
      const { wallet } = await this.request({ method, url })
      return this.asWallet(wallet)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Credits tokens into organization's wallet
   * @param {Organization} organization Organization
   * @param {Number} amount Amount of tokens to credit
   * @param {String} notes User's notes related to the transaction
   * @returns {WalletTransaction} Transaction details
   */
  async credit ({ organization: { id } = {}, amount, notes }) {
    if (id) {
      try {
        const method = 'put'
        const url = this.endpoint('wallet', id, 'credit')
        const data = { amount, notes }
        const { transaction } = await this.request({ method, url, data, retry: false })
        return this.asWalletTransaction(transaction)
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Debits tokens from organization's wallet
   * @param {Organization} organization Organization
   * @param {Number} amount Amount of tokens to debit
   * @param {String} notes User's notes related to the transaction
   * @returns {WalletTransaction} Transaction details
   */
  async debit ({ organization: { id } = {}, amount, notes }) {
    if (id) {
      try {
        const method = 'put'
        const url = this.endpoint('wallet', id, 'debit')
        const data = { amount, notes }
        const { transaction } = await this.request({ method, url, data, retry: false })
        return this.asWalletTransaction(transaction)
      } catch (error) {
        this.handleError(error)
      }
    }
  }


  /**
   * Transfers tokens from one organization's wallet into another
   * @param {Organization} sender Sender organization
   * @param {Organization} receiver Receiver organization
   * @param {Number} amount Amount of tokens to transfer
   * @param {String} notes User's notes related to the transaction
   * @returns Transaction details from both sender's and receiver's perspectives
   */
  async transfer ({ sender: { id: senderId } = {}, receiver: { id: receiverId } = {}, amount, notes } = {}) {
    if (senderId && receiverId) {
      try {
        const method = 'put'
        const url = this.endpoint('wallet', senderId, 'transfer', receiverId)
        const data = { amount, notes }
        const { transaction } = await this.request({ method, url, data, retry: false })
        if (transaction) {
          const { sender, receiver } = transaction
          return {
            sender: this.asWalletTransaction(sender),
            receiver: this.asWalletTransaction(receiver)
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Returns premium service subscriptions, optionally only of the specified organization
   * @param {Organization} organization Organization
   * @param {Boolean} onlyActive If true, only non-expired subscriptions will be returned, otherwise also the expired subscriptions will be included
   * @returns {Promise<Array[PremiumServiceSubscription]}
   */
  async getPremiumServiceSubscriptions ({ organization, onlyActive } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('subscription')
      const params = {
        organization: organization?.id,
        onlyActive
      }
      const { subscriptions } = await this.request({ method, url, params })
      return subscriptions?.map(subscription => this.asPremiumServiceSubscription(subscription))
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the specified premium service subscription
   * @param {String} id Identifier of premium service subscription to retrieve
   */
  async getPremiumServiceSubscription ({ id }) {
    try {
      const method = 'get'
      const url = this.endpoint('subscription', id)
      const { subscription } = await this.request({ method, url })
      return this.asPremiumServiceSubscription(subscription)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Stores a premium service subscription in the specified wallet
   * @param {PremiumServiceSubscription} subscription Premium service subscription to store
   */
  async savePremiumServiceSubscription ({ subscription: data }) {
    try {
      const method = data.id ? 'put' : 'post'
      const url = this.endpoint('subscription', data.id)
      const { subscriptions, transaction } = await this.request({ method, url, data, retry: false })
      return {
        subscriptions: this.asPremiumServiceSubscriptions(subscriptions),
        transaction: this.asWalletTransaction(transaction)
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes a premium service from pricelists
   * @param {String} id Identifier of a premium service subscription to delete
   */
  async removePremiumServiceSubscription ({ subscription: { id } }) {
    try {
      const method = 'delete'
      const url = this.endpoint('subscription', id)
      const { subscription } = await this.request({ method, url })
      return this.asPremiumServiceSubscription(subscription)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Migrates active subscriptions of specified devices
   * to their new owners
   * @param {Array[Device]} devices Devices whose subscriptions have to be migrated
   */
  async migrateDeviceSubscriptions ({ devices = [] }) {
    try {
      const method = 'put'
      const url = this.endpoint('device', 'subscription', 'migrate')
      const data = {
        devices: devices.map(({ id, serialNumber }) => ({ id, serialNumber }))
      }
      const { subscriptions } = await this.request({ method, url, data })
      return this.asPremiumServiceSubscriptions(subscriptions)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Removes devices from specified premium subscription
   * @param {PremiumServiceSubscription} subscription Premium subscription
   * @param {Array[Devices]} devices Devices to remove from premium subscription
   * @returns {PremiumServiceSubscription} Modified subscription
   */
  async unsubscribeDevices ({ subscription, devices } = {}) {
    try {
      const method = 'delete'
      const url = this.endpoint('subscription', subscription.id, 'devices')
      const data = {
        devices: devices.map(({ id, serialNumber }) => ({ id, serialNumber }))
      }
      const { subscription: updatedSubscription } = await this.request({ method, url, data })
      return this.asPremiumServiceSubscription(updatedSubscription)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Assigns free-of-charge premium service to specified devices
   * @param {Array[Device]} devices Devices
   * @param {PremiumService} service Service to assign
   */
  async assignPremiumServiceToDevices ({ devices = [], service }) {
    if (devices.length > 0 && service) {
      try {
        const method = 'put'
        const url = this.endpoint('premium-service', service.id, 'device')
        const data = {
          devices: devices.map(({ id }) => ({ id }))
        }
        const { results } = await this.request({ method, url, data })
        return {
          service,
          results
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Clears free-of-charge premium services assigned to specified devices
   * @param {Array[Device]} devices Devices
   */
  async clearPremiumServicesOnDevices ({ devices = [] }) {
    if (devices.length > 0) {
      try {
        const method = 'delete'
        const url = this.endpoint('premium-service', 'device')
        const data = {
          devices: devices.map(({ id }) => ({ id }))
        }
        const { results } = await this.request({ method, url, data })
        return {
          results
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Starts premium services attached to specified devices
   * @param {Array[Device]} devices Devices where premium services are to be started
   * @param {Date} startsAt Optional alternative date when subscriptions should start
   * @param {String} details Optional description of circumstances under which subscriptions are started, for audit purposes
   */
  async startDeviceSubscriptions ({ devices = [], startsAt = new Date(), details }) {
    try {
      const method = 'put'
      const url = this.endpoint('device', 'subscription', 'start')
      const data = {
        devices: devices.map(({ id }) => ({ id })),
        startsAt,
        details
      }
      const { subscriptions } = await this.request({ method, url, data })
      return this.asPremiumServiceSubscriptions(subscriptions)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves premium services audit trail for the current organization
   * @param {Organization} organization Organization whose audit trail to return
   * @param {Boolean} includeChildOrganizations If true, also audit trail
   * of all child organizations will be retrieved
   * @param {Date} newerThan If specified, only events after the specified date will be retrieved
   * @param {Date} olderThan If specified, only events before the specified date will be retrieved
   * @param {Array[AuditSubject]} subjects If specified, only events related to these subjects will be retrieved
   * @returns {Array[AuditItem]} List of audit items matching the specified conditions
   */
  async getAudit ({ organization, includeChildOrganizations, newerThan, olderThan, subjects } = {}) {
    try {
      const url = this.endpoint('audit')
      const params = {
        includeChildOrganizations,
        organization: organization ? organization.id : undefined,
        newerThan,
        olderThan,
        subjects: subjects ? subjects.join(',') : undefined
      }
      const { audit } = await this.request({ url, params })
      return audit.map(item => this.asAuditItem(item))
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Obtains a notification related to the specified premium service subscription.
   * @param {PremiumServiceSubscription} subscription Subscription to notify about
   * @param {NotificationType} type Notification type
   * @param {NotificationPriority} priority Notification priority
   * @returns {Promise<Array[Notification]>} Any notifications sent
   * @description Notification is neither saved nor sent with this call.
   * It needs to be done with separate calls to {@link NotificationsAPI}.
   */
  async getSubscriptionNotifications ({ subscription, type, priority } = {}) {
    if (subscription && type && priority) {
      try {
        const method = 'get'
        const url = this.endpoint('subscription', subscription.id, 'notification')
        const params = {
          type,
          priority
        }
        const { notifications } = await this.request({ method, url, params })
        return notifications?.map(item => this.asNotification(item))
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Registers the notification related to the specified premium service subscription
   * @param {PremiumServiceSubscription} subscription Subscription about which a notification has been sent
   * @param {Notification} notification Sent notification
   * @returns {Promise<Notification>} Updated subscription
   */
  async subscriptionNotified ({ subscription, notification }) {
    if (subscription && notification) {
      try {
        const method = 'put'
        const url = this.endpoint('subscription', subscription.id, 'notification', notification.id)
        const { subscription: updatedSubscription } = await this.request({ method, url })
        return this.asPremiumServiceSubscription(updatedSubscription)
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Converts the specified data item
   * received from API to Pricelist instance
   * @param item Data item
   * @returns Instance initialized with the content of the data item
   */
  asPricelist (item) {
    if (item) {
      return new Pricelist(item)
    }
  }

  /**
   * Converts the specified data items
   * received from API to Pricelist instances
   * @param items Data items
   * @returns Instances initialized with the content of the data items
   */
  asPricelists (items) {
    if (items) {
      return items.map(item => new Pricelist(item))
    }
  }

  /**
   * Converts the specified data item
   * received from API to PremiumService instance
   * @param item Data item
   * @returns Instance initialized with the content of the data item
   */
  asPremiumService (item) {
    if (item) {
      return new PremiumService(item)
    }
  }

  /**
   * Converts the specified data item
   * received from API to PremiumService instance
   * @param item Data items
   * @returns Instances initialized with the content of the data item
   */
  asPremiumServices (items) {
    if (items) {
      return items.map(item => new PremiumService(item))
    }
  }

  /**
   * Converts the specified data item
   * received from API to PremiumServiceSubscription instance
   * @param item Data item
   * @returns Instance initialized with the content of the data item
   */
  asPremiumServiceSubscription (item) {
    if (item) {
      return new PremiumServiceSubscription(item)
    }
  }

  /**
   * Converts the specified data items
   * received from API to PremiumServiceSubscription instances
   * @param items Data items
   * @returns Instances initialized with the content of the data items
   */
  asPremiumServiceSubscriptions (items) {
    if (items) {
      return items.map(item => new PremiumServiceSubscription(item))
    }
  }

  /**
   * Converts the specified data item
   * received from API to Wallet instance
   * @param item Data item
   * @returns Instance initialized with the content of the data item
   */
  asWallet (item) {
    if (item) {
      return new Wallet(item)
    }
  }

  /**
   * Converts the specified data item
   * received from API to WalletTransaction instance
   * @param item Data item
   * @returns Instance initialized with the content of the data item
   */
  asWalletTransaction (item) {
    if (item) {
      return new WalletTransaction(item)
    }
  }

  /**
   * Converts the specified data item to AuditItem instance
   * @param item Data item
   */
  asAuditItem (item) {
    if (item) {
      return new AuditItem(item)
    }
  }

  /**
   * Converts the specified data item to Notification instance
   * @param item Data item
   */
  asNotification (item) {
    if (item) {
      return new Notification(item)
    }
  }
}
