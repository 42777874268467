import AlertConfiguration from './alert-configuration/alert-configuration.vue'
import AlertPopup from './alert-popup/alert-popup.vue'

/**
 * Installs Alerts UI components into the application
 * @param application Vue application instance
 */
export function useAlertsComponents (application) {
  const plugin = {
    install (application) {
      const items = [
        {
          tag: 'sc-alert-configuration',
          component: AlertConfiguration
        },
        {
          tag: 'sc-alert-popup',
          component: AlertPopup
        },
      ]

      for (const { tag, component } of items) {
        application.component(tag, component)
      }
    }
  }

  application.use(plugin)
}
