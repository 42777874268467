import { PlanItem, PlanItemType } from './plan-item'

/**
 * Cable Riser - a tube for cables connecting equipment placed on different floors
 */
export class PlanRiser extends PlanItem {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Riser
  }

  /**
   * Riser colors
   */
  static Colors = [
    'yellow',
    'cyan',
    'orange',
    'pink',
    'purple',
    'green',
    'grey',
    'red',
    'indigo',
    'blue',
    'teal',
    'lime',
    'amber',
    'brown',
    'grey',
  ]

  /**
   * Returns the color representing a specified riser
   * @param {Number} index Riser inde
   * @returns {String}
   */
  static getColor (index) {
    return PlanRiser.Colors[index % PlanRiser.Colors.length]
  }

  normalize () {
    super.normalize()
    this.connectors = this.connectors || []
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = super.toJSON()

    // Remove fixed styles
    delete result.coordinates
    delete result.backgroundStyle
    delete result.lineStyle
    delete result.textStyle

    return result
  }

  /**
   * Identifier of a group of equipment served by this riser,
   * equal to the identifier of the repeater at the top of the group
   * @type {String}
   */
  group

  /**
   * Identifiers of connectors passing through the riser
   * @type {Array[String]}
   */
  connectors

  /**
   * Indicates a joint riser, grouping connectors
   * for a bunch of repeaters
   * @type {Boolean}
   */
  isJointRiser

  /**
   * Indicates that the riser has connectors passing through it
   * @type {Boolean}
   */
  get hasConnectors () {
    return this.connectors?.length > 0
  }

  /**
   * Number of connectors passing through the riser
   * @type {Number}
   */
  get connectorCount () {
    return this.connectors?.length || 0
  }

  /**
   * Checks whether the specified connector is passing through this riser
   * @param {PlanConnector} connector Cross-floor connector
   * @returns {Boolean}
   */
  contains (connector) {
    return connector && this.connectors.includes(connector.id)
  }

  /**
   * Marks the specified connectors as passing through the riser
   * @param {Array[PlanConnector]} connectors Cross-floor connectors
   */
  add (...connectors) {
    for (const connector of connectors) {
      connector.riser = this.id
      if (!this.connectors.includes(connector.id)) {
        this.connectors.push(connector.id)
      }
    }
  }

  /**
   * Unlinks the specified connector from the riser
   * @param {PlanConnector} connector Cross-floor connector
   */
  remove (connector) {
    if (connector) {
      delete connector.riser
      this.connectors = this.connectors.filter(id => id !== connector.id)
    }
  }
}
