import { wait } from './wait'

/**
 * Runs the specified async functions sequentially
 * @param {Array[Function]} functions Functions to run
 * @returns {Array[Object]} Function results
 * @param {Number} pause Pause between steps, in milliseconds
 * @param {Function<Number>} onStarted Callback executed before running another function.
 * Receives the ordinal index of the executed function.
 * @param {Function<any, Number>} onCompleted Callback executed after succesful completion of another function.
 * Receives the the returned result and the index of the executed function.
 * @param {Function<Error, Number>} onError If specified and any of the functions fails,
 * error will be passed to this handler while execution of the batch will continue.
 * The handler receives the error and the index of the function which has failed.
 * The result of the error handler will be returned in the final results.
 * If no error handler is provided, execution of the sequence will be interrupted on first exception.
 * @param {Array} parameters Optional, parameters to pass to called functions
 * @returns {Promise<Array>} Promise returning array of results of executed async functions
 */
export async function runSequence (functions, { pause = 0, onStarted, onCompleted, onError, parameters = [] } = {}) {
  if (!functions) {
    return
  }

  const results = []
  for (let i = 0; i < functions.length; i++) {
    try {
      if (onStarted) {
        onStarted(i)
      }

      const result = await functions[i](...parameters)
      results.push(result)

      if (onCompleted) {
        onCompleted(result, i)
      }

    } catch (error) {
      if (onError) {
        results.push(onError(error, i))
      } else {
        throw error
      }
    }

    if (i < functions.length - 1) {
      await wait(pause)
    }
  }

  return results
}

/**
 * Runs the specified async functions in batches,
 * each batch executed in parallel.
 * Resolves with a promise containing all results.
 * Additionally it will call an optional callback reporting results of each batch.
 * @param {Array[Function]|Dictionary<String,Function>} functions Functions to run, either a list or dictionary of functions.
 * Dictionary can be useful when you use `onBatchStart` callback
 * and need to know the identifiers of functions executed in the batch.
 * @param {Number} batchSize Batch size
 * @param {Number} pause Pause between batches, in milliseconds
 * @param {Function<Array, Array, Number>} onBatchStarted Callback executed before running another batch of functions.
 * Receives the list of items, list of identifiers and batch ordinal index.
 * @param {Function<Array, Array, Number>} onBatchCompleted Callback executed after succesful completion of another batch of functions.
 * Receives the list of items, list of identifiers and batch ordinal index.
 * @param {Function<Error, Number>} onError If specified and any of the functions fails,
 * error will be passed to this handler while execution of the batch will continue.
 * The result of the error handler will be returned in the final results.
 * If no error handler is provided, execution of the batch will be interrupted on first exception.
 * @param {Array} parameters Optional, parameters to pass to called functions
 * @returns {Promise<Array>} Promise returning array of results of executed async functions
 */
export async function runBatch (functions, { batchSize = 10, pause = 0, onBatchStarted, onBatchCompleted, onError, parameters = [] } = {}) {
  if (!functions) {
    return
  }

  let handlers = []
  let identifiers = []
  const results = []
  let i = 0
  let batchIndex = 1

  if (Array.isArray(functions)) {
    handlers = functions.filter(fn => fn)
  } else {
    handlers = Object.values(functions)
    identifiers = Object.keys(functions)
  }

  let error

  while (i < handlers.length) {
    try {
      const batch = handlers
        .slice(i, i + batchSize)
        .map(fn => {
          const execute = async () => {
            try {
              return await fn(...parameters)
            } catch (error) {
              if (onError) {
                return onError(error)
              } else {
                throw error
              }
            }
          }
          return execute()
        })

      const batchIdentifiers = identifiers.slice(i, i + batchSize)
      if (onBatchStarted) {
        onBatchStarted(batch, batchIdentifiers, batchIndex)
      }

      error = null
      const batchResults = await Promise
        .all(batch)
        .catch(e => {
          error = e
        })

      if (onBatchCompleted) {
        onBatchCompleted(batchResults, batchIdentifiers, batchIndex)
      }

      if (error && !onError) {
        break
      }

      results.push(...(batchResults || []))

      i += batchSize
      batchIndex++

      if (pause) {
        await wait(pause)
      }

    } catch (e) {
      error = e
      i = handlers.length
    }
  }

  if (error && !onError) {
    throw error
  } else {
    return results
  }
}

/**
 * Processes items with a handler, in batches, each batch executed in parallel.
 * Resolves with a promise containing all results.
 * Additionally it will call an optional callback reporting results of each batch.
 * @param {Array|Dictionary<String,Object>} items Items to process, either a list or dictionary of instances.
 * @param {Function<Array, Array, Number>} handler Callback for processing another batch of items.
 * It receives the list of items in the batch, list of idenfiers of the items and batch ordinal index.
 * Dictionary can be useful when you use `onBatchStart` callback
 * and need to know the identifiers of items executed in the batch.
 * @param {Number} batchSize Batch size
 * Receives the list of items, list of identifiers and batch index on input.
 * @param {Number} pause Pause between batches, in milliseconds
 * @param {Function<Array, Array, Number>} onBatchStarted Callback executed before running another batch of functions.
 * Receives the list of items, list of identifiers and batch ordinal index.
 * @param {Function<Array, Array, Number>} onBatchCompleted Callback executed after succesful completion of another batch of functions.
 * Receives the list of items, list of identifiers and batch ordinal index.
 * @param {Function<Error, Number>} onError If specified and any of the functions fails,
 * error will be passed to this handler while execution of the batch will continue.
 * The result of the error handler will be returned in the final results.
 * If no error handler is provided, execution of the batch will be interrupted on first exception.
 * @returns {Promise<Array>} Array of results returned by each promise
 */
export async function processBatch ({ items, handler, batchSize = 10, pause = 0, onBatchStarted, onBatchCompleted, onError } = {}) {
  // Items can be also specified as dictionary
  let identifiers
  if (!Array.isArray(items)) {
    identifiers = Object.keys(items)
    items = Object.values(items)
  }

  // Split items in batches
  const batches = []
  let i = 0
  let batchIndex = 0
  while (i < items.length) {
    batches.push({
      batch: items.slice(i, i + batchSize),
      identifiers: identifiers?.slice(i, i + batchSize),
      index: batchIndex
    })
    i += batchSize
    batchIndex++
  }

  // Create handlers for each batch
  const handlers = batches
    .map(({ batch, index, identifiers }) =>
      () => handler(batch, index, identifiers))

  // Process batches sequentially
  const results = await runSequence(
    handlers,
    {
      pause,
      onStarted: onBatchStarted,
      onCompleted: onBatchCompleted,
      onError
    })

  return results
    ? results.flat()
    : undefined

}

