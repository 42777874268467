<script>
import { pluralize } from '@stellacontrol/utilities'
import { AvailableCableTypes } from '@stellacontrol/planner'
import ItemPropertiesMixin from './item-properties.js'
import ItemProperties from './item-properties.vue'
import LineStyleProperties from './line-style-properties.vue'
import { PlanActions } from '../../../renderer/actions/plan-action'

export default {
  mixins: [
    ItemPropertiesMixin
  ],

  components: {
    'sc-item-properties': ItemProperties,
    'sc-line-style-properties': LineStyleProperties
  },

  data () {
    return {
      AvailableCableTypes
    }
  },

  computed: {
    defaults () {
      return this.item.defaults
    },

    // Riser through which the cable goes
    riser () {
      const { item, renderer } = this
      const { layout: { crossSection } } = renderer
      return crossSection.getRiser(item.riser || item.goesIntoRiser)
    },

    // Indicates that the riser is shared with other cables
    isSharedRiser () {
      return this.riser?.connectorCount > 1
    },

    // Riser details
    riserDetails () {
      const { riser, isSharedRiser } = this
      const otherCount = riser.connectorCount - 1
      if (riser) {
        return [
          `The cable goes into riser ${riser.tagText}`,
          isSharedRiser
            ? `shared with ${pluralize(otherCount, 'one other cable', `${otherCount} other cables`)}`
            : ''
        ].join(' ')
      }
    }
  },

  methods: {
    // Moves the cable to a separate riser
    moveToOwnRiser () {
      const { item } = this
      if (item) {
        this.executeAction({
          action: PlanActions.MoveToOwnRiser,
          items: [item]
        })
      }
    }
  }
}

</script>

<template>
  <sc-item-properties :renderer="renderer" :items="items" @action="executeAction">
  </sc-item-properties>

  <main class="plan-item-properties">
    <section>
      <label>
        Cable Type
      </label>
      <q-select outlined square dense emit-value map-options :options="AvailableCableTypes"
        :style="styles.select.wide" :model-value="getValue('cableType', defaults.cableType)"
        @update:model-value="value => setProperties({ 'cableType': value })">
      </q-select>
    </section>
  </main>

  <sc-line-style-properties :renderer="renderer" :items="items" @action="executeAction"
    v-if="item.isCustom">
  </sc-line-style-properties>

  <main v-if="!isBatch && isSharedRiser" class="q-mt-md">
    <section>
      <span class="text-orange9">
        {{ riserDetails }}
      </span>
    </section>
    <section class="q-mt-md">
      <q-btn label="Move to own riser" @click="moveToOwnRiser()" dense unelevated>
      </q-btn>
    </section>
  </main>

</template>

<style lang="scss" scoped></style>
