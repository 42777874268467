import { ListViewMode, Confirmation, Notification } from '@stellacontrol/client-utilities'
import { DeviceAPI } from '@stellacontrol/client-api'
import { FloorPlan } from '@stellacontrol/model'
import { SecurityRules } from '@stellacontrol/security'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initialize the floor plans store
   */
  async initializeApplet ({ dispatch }) {
    // Initialize lists
    await dispatch('initializeList', [
      { name: 'floor-plans', viewMode: ListViewMode.Normal }
    ])
  },

  /**
   * Retrieves floor plans of the organization.
   * This can optionally include all floor plans of child organizations.
   * @param organizationId FloorPlan whose floor plans to retrieve.
   * If not specified, plans belonging to the current organization are retrieved.
   * @param withDetails If true, details are returned such as associated place, owner etc.
   * @param withChildren If true, also floor plans of child organizations are retrieved
   */
  async getFloorPlans ({ commit, dispatch, getters }, { organizationId, withDetails = true, withChildren = true } = {}) {
    await dispatch('loading')

    // Fetch floor plans
    organizationId = organizationId || getters.currentOrganization.id
    const items = await DeviceAPI.getFloorPlans({ organizationId, withDetails, withChildren })

    // Enrich with organization details
    for (const item of items) {
      item.organization = getters.getOrganization(item.organizationId)
    }

    // Store in state
    commit('storeFloorPlans', { items })
    await dispatch('done')
    return items
  },

  /**
   * Retrieves floor plans of the current organization if not retrieved yet
   */
  async requireFloorPlans ({ state, dispatch }) {
    if (state.items) {
      return state.items
    } else {
      return await dispatch('getFloorPlans')
    }
  },

  /**
   * Retrieves the specified floor plan with all details such as permissions,
   * creator, etc.
   * @param id Floor plan identifier
   * @param withDetails If true, details are returned such as associated place, owner etc.
   */
  async getFloorPlan ({ commit, dispatch }, { id, withDetails = true } = {}) {
    if (!id) throw new Error('Floor plan identifier is required')

    await dispatch('loading')
    const floorPlan = await DeviceAPI.getFloorPlan({ id, withDetails })
    commit('storeFloorPlan', { floorPlan })
    await dispatch('done')
    return floorPlan
  },

  /**
   * Returns a new floor plan
   * @param floorPlan Initial properties of the floor plan
   * @param organizationId Organization to assign the floor plan to
   * @param placeId Place to assign the floor plan to
   */
  async newFloorPlan ({ dispatch, getters }, { floorPlan, organizationId, placeId } = {}) {
    await dispatch('loading')

    // Currently logged in organization is default owner
    const organization = organizationId
      ? await dispatch('getOrganization', { id: organizationId })
      : getters.currentOrganization

    // Place
    const place = placeId
      ? await dispatch('getPlace', { id: placeId })
      : undefined

    // Create new floor plan, assign to organization
    const data = FloorPlan.createEmpty({
      ...floorPlan,
      organization,
      organizationId: organization.id,
      placeId,
      place
    })
    await dispatch('done')
    return data
  },

  /**
   * Checks whether the specified floor plan exists
   * @param name Floor plan name
   * @param organizationId Owner organization of the floor plan
   */
  async floorPlanExists (_, { name, organizationId } = {}) {
    if (name) {
      const { id, exists } = await DeviceAPI.floorPlanExists({ name, organizationId })
      return { id, exists }
    } else {
      return { exists: false }
    }
  },

  /**
   * Edits a new floor plan
   */
  async createFloorPlan ({ dispatch }, { organization } = {}) {
    await dispatch('gotoRoute', {
      name: AppletRoute.FloorPlan,
      params: { id: 'new' },
      query: organization ? { organization: organization.id } : undefined
    })
  },

  /**
   * Edits an existing floor plan
   * @param id Floor plan to edit
   */
  async editFloorPlan ({ dispatch }, { floorPlan: { id } = {} } = {}) {
    if (!id) throw new Error('Floor plan identifier is required')

    await dispatch('gotoRoute', { name: AppletRoute.FloorPlan, params: { id } })
  },

  /**
   * Edits a clone of an existing floor plan
   * @param {String} id Identifier of a floor plan to clone
   * @returns {Promise<FloorPlan>} Cloned floor plan
   */
  async cloneFloorPlan ({ dispatch }, { floorPlan: { id, name } = {} } = {}) {
    if (!id) throw new Error('Floor plan identifier is required')

    const clone = await DeviceAPI.getFloorPlan({ id })
    if (!clone) throw new Error(`Floor plan ${id} no longer exists`)

    clone.id = undefined
    clone.name = `Copy of ${name} (${new Date().getTime()})`
    const floorPlan = await dispatch('saveFloorPlan', { floorPlan: clone, message: `Cloning floor plan ${name} ...` })

    if (floorPlan) {
      await dispatch('gotoRoute', { name: AppletRoute.FloorPlan, params: { id: floorPlan.id } })
    }
  },

  /**
   * Saves an floor plan
   * @param {FloorPlan} floorPlan Floor plan to save
   * @param {String} message Custom message to display when saving
   * @param {Boolean} silent If true, no notifications will be shown
   * @returns {Promise<FloorPlan>} Saved floor plan
   */
  async saveFloorPlan ({ commit, dispatch }, { floorPlan, silent, message } = {}) {
    if (!floorPlan) throw new Error('Floor plan is required')

    const { isNew } = floorPlan
    message = isNew ? `Creating floor plan ${floorPlan.name} ...` : `Saving floor plan ${floorPlan.name} ...`
    await dispatch('busy', { message, data: floorPlan, silent })
    const result = await DeviceAPI.saveFloorPlan({ floorPlan })
    commit('storeFloorPlan', { floorPlan: result.floorPlan })
    message = `Floor plan ${floorPlan.name} has been saved.`
    await dispatch('done', { message, silent })
    return result
  },

  /**
   * Removes an floor plan
   * @param confirm If true, user has to confirm
   * @param id Floor plan to remove
   * @param silent If true, no notifications will be shown
   */
  async removeFloorPlan ({ commit, dispatch, getters }, { confirm = true, floorPlan: { id } = {}, silent } = {}) {
    if (!id) throw new Error('Floor plan identifier is required')

    const floorPlan = await DeviceAPI.getFloorPlan({ id })
    if (!floorPlan) throw new Error(`Floor plan ${id} no longer exists`)

    const { currentUser } = getters
    const { canDeleteFloorPlan, reason } = SecurityRules.canDeleteFloorPlan(currentUser, floorPlan)
    if (!canDeleteFloorPlan) {
      Notification.error({ message: reason, silent })
      return
    }

    const yes = await Confirmation.ask({
      title: 'Delete',
      message: `Delete floor plan ${floorPlan.name}?`,
      confirm
    })

    if (yes) {
      await dispatch('busy', { message: `Deleting floor plan ${floorPlan.name} ...`, data: floorPlan, silent })
      await DeviceAPI.deleteFloorPlan({ floorPlan })
      await dispatch('done', { message: `Floor plan ${floorPlan.name} has been deleted`, silent })
      commit('removeFloorPlan', { floorPlan })
    }
  }
}
