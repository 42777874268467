/**
 * Represents a (part of) an imported image.
 * During the image import users can import entire images,
 * or crop them into one or more parts.
 */
export class ImagePart {
  constructor (data = {}) {
    Object.assign(this, data)
  }

  /**
   * Part index, starting from 0
   * @type {Number}
   */
  index

  /**
   * Indicates that image part has been cropped out of the original image
   * @type {Boolean}
   */
  isCropped

  /**
   * Part hash
   * @type {String}
   */
  hash

  /**
   * Image file
   * @type {String}
   */
  name

  /**
   * Image description
   * @type {String}
   */
  description

  /**
   * Name of the floor which the part represents
   * @type {String}
   */
  floorName

  /**
   * Binary data of the image part
   * @type {Array}
   */
  data

  /**
   * Base64-encoded image part
   * @type {String}
   */
  dataURL

  /**
   * URL of remote location where image part is saved,
   * an alternative to {@link dataURL} and {@link data}
   * @type {String}
   */
  reference
}