<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { Log, delay, pluralize, notNull } from '@stellacontrol/utilities'
import { Confirmation, Notification } from '@stellacontrol/client-utilities'
import { PlannerMode } from '@stellacontrol/model'
import { PlanFloors, PlanLegend } from '@stellacontrol/planner'
import { PlanRenderer, executePlanAction, PlanActions } from '../../renderer'
import PlanPalette from '../palette/palette.vue'
import ItemsMenu from '../menus/items-menu.vue'
import Tooltip from '../tooltips/tooltip.vue'
import Help from '../help/help.vue'
import FloorToolbar from './floor-toolbar.vue'
import PlanItemDetails from './item-details.vue'
import EditMode from './edit-mode.vue'
import SnapshotDialog from '../../dialogs/snapshot-dialog.vue'
import PlanImportDialog from '../../dialogs/plan-import-dialog'

export default {
  components: {
    'scp-palette': PlanPalette,
    'scp-items-menu': ItemsMenu,
    'scp-tooltip': Tooltip,
    'scp-help': Help,
    'scp-floor-toolbar': FloorToolbar,
    'scp-item-details': PlanItemDetails,
    'scp-edit-mode': EditMode,
    'scp-snapshot-dialog': SnapshotDialog,
    'scp-plan-import-dialog': PlanImportDialog,
  },

  props: {
    // Indicates that we're in DEBUG mode
    isDebugging: {
      default: false
    },
    // List of devices to use on the plan
    devices: {
      default: () => []
    },
    // Place to which the plan belongs
    place: {
    },
    // Edited plan
    plan: {
    },
    layout: {
    },
    // Floor to show
    floorId: {
    },
    // Interval at which changes to the plan should be reported.
    // Used for example for autosave.
    changeInterval: {
      default: 5000
    },
    // Indicates that users wants to see maximized plan with all tooling hidden
    isMaximized: {
      type: Boolean,
      default: false
    },
    // Async callback triggered when plan has been initialized
    initialized: {
      type: Function
    },
    // Async callback triggered when shape requests additional data
    dataRequested: {
      type: Function
    },
    // Async callback triggered when renderer state changes
    stateChanged: {
      type: Function
    },
    // Async callback triggered when plan layout changes
    changed: {
      type: Function
    },
    // Async callback triggered when floor is changed
    floorSelected: {
      type: Function
    },
    // Async callback triggered when cross-section is selected
    crossSectionSelected: {
      type: Function
    },
    // Async callback for saving floor images
    saved: {
      type: Function
    },
    // Async callback for saving floor images
    imageSaved: {
      type: Function
    },
    // Async callback for saving plan snapshots
    snapshotSaved: {
      type: Function
    },
    // Async callback for deleting plan snapshots
    snapshotDeleted: {
      type: Function
    },
    // Async callback for restoring plan snapshots
    snapshotRestored: {
      type: Function
    }
  },

  data () {
    return {
      // Indicates that we're now loading the plan
      isLoading: false,
      // Used to re-create the plan container
      hasContainer: true,
      // Plan renderer
      renderer: null,
      // Floor being dragged in the tabs
      draggedFloor: null,
      // Default plan floors
      PlanFloors
    }
  },

  computed: {
    ...mapState({
      // Ticker, for updating stubborn values
      ticker: state => state.client.ticker,
      // Items selected on the previously visited floor
      recentlySelectedItems: state => state.planner.recentlySelectedItems,
      // Indicates that there's content in the clipboard
      // that can be pasted into the plan
      canPaste: state => state.planner.canPaste
    }),

    ...mapGetters([
      'planEditingMode'
    ]),

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.planEditingMode === PlannerMode.Advanced
    },

    // Determines whether the planner allows only a limited feature set
    isRegularMode () {
      return this.planEditingMode === PlannerMode.Regular
    },

    // Determines whether the planner works in readonly mode
    isReadOnly () {
      return this.planEditingMode === PlannerMode.ReadOnly
    },

    // Indicates that we're in cross-section view
    isCrossSection () {
      return this.renderer?.isCrossSection
    },

    // Plan state - messages about loading, saving etc.
    planState () {
      return this.ticker > 0 ? this.renderer?.stateLabel : null
    },

    // Plan floors
    floors () {
      return this.layout?.floors || []
    },

    // Floor count
    floorCount () {
      return this.floors.length
    },

    // Plan floors shown to the user
    visibleFloors () {
      return this.floors.filter(f => !f.isDeleted)
    },

    // Floor shown to the user
    floor () {
      return this.floorId === PlanFloors.CrossSection
        ? this.layout?.crossSection
        : this.floors?.find(f => f.id === this.floorId)
    },

    // Floor label
    floorLabel () {
      return this.isCrossSection ? 'cross-section view' : 'floor plan'
    },

    // Palette CSS style
    paletteStyle () {
      const { plan } = this
      const { palettePosition } = plan.settings
      return {
        right: `${notNull(palettePosition?.right, 5)}px`,
        top: `${notNull(palettePosition?.top, 150)}px`
      }
    },

    // Action history
    history () {
      return this.renderer?.history
    },

    // Last action which can be undone
    undoableAction () {
      return this.ticker > 0 ? this.history?.undoableAction || {} : {}
    },

    // Previous action which can be redone
    redoableAction () {
      return this.ticker > 0 ? this.history?.redoableAction || {} : {}
    }
  },

  methods: {
    ...mapActions([
      'showDialog',
      'storePlanSelection',
    ]),

    ...mapMutations([
      'movePlanPalette',
      'scrollPlan',
      'planCopyToClipboard',
      'planPasteFromClipboard'
    ]),

    // Initializes the floor plan
    async loadFloor () {
      // Dispose of the previous renderer
      if (this.renderer) {
        this.renderer.unmount()
        this.renderer = null
        this.hasContainer = false
      }

      if (!this.layout) return

      this.isLoading = true
      this.$nextTick(async () => {
        await delay(100)
        this.hasContainer = true

        this.$nextTick(async () => {
          try {
            const { layout, floorId, floor, recentlySelectedItems, isDebugging, plan } = this
            const container = this.$refs.container
            if (container && floorId) {
              const {
                onInitialized,
                onData,
                onSave,
                onSaveImage,
                onSelectFloor,
                onSelectCrossSection,
                onScrollPlan,
                onCopy,
                onPaste,
                onChanged,
                onSnapshotSave,
                onSnapshotDelete,
                onSnapshotRestore,
                changeInterval
              } = this

              container.innerHTML = ''

              // Render the plan
              this.renderer = new PlanRenderer({
                layout,
                container,
                floor,
                changeInterval,
                recentlySelectedItems,
                onData,
                onSave,
                onSaveImage,
                onSelectFloor,
                onSelectCrossSection,
                onScrollPlan,
                onCopy,
                onPaste,
                onChanged,
                onSnapshotSave,
                onSnapshotDelete,
                onSnapshotRestore,
                debug: isDebugging
              })

              this.renderer.setConfirmationHandler(message => this.confirmAction(message))
              await this.renderer.render()

              // Restore layers visibility (skip internal layers, which user cannot toggle)
              const { hiddenLayers, scroll } = plan.settings.getFloor(this.renderer.floor)
              const layers = this.renderer.layers.filter(layer => !layer.isInternal)
              for (const { id } of layers) {
                const isVisible = !hiddenLayers[id]
                this.renderer.toggleLayer(id, isVisible)
              }

              // Restore plan scroll position
              this.renderer.scrollPlan(scroll)
              this.renderer.focus()

              onInitialized()
            }

          } catch (error) {
            this.renderer = null
            Log.error('Error rendering the plan')
            Log.exception(error)

          } finally {
            this.isLoading = false
          }
        })
      })
    },

    // Asks user for confirmation of an action
    async confirmAction (message) {
      const yes = await Confirmation.ask({ title: 'Confirmation', message })
      return yes
    },

    // Notifies about the plan having been initialized
    onInitialized () {
      const { renderer, layout, floor } = this
      if (renderer && this.initialized) {
        const { equipmentHierarchy: hierarchy } = renderer
        this.initialized({ renderer, layout, hierarchy, floor })
      }
    },

    // Retrieves additional data for layout items such as devices
    onData (item) {
      const { renderer, layout, floor } = this
      if (renderer && item && this.dataRequested) {
        this.dataRequested({ renderer, layout, floor, item })
      }
    },

    // Notifies about changes to the plan layout
    async onChanged ({ action } = {}) {
      const { renderer } = this
      if (renderer && this.changed) {
        this.changed({ renderer, action })
      }
    },

    // Request to save the layout
    async onSave ({ renderer }) {
      renderer = renderer || this.renderer

      if (renderer && this.saved) {
        await this.saved({ renderer })
      }
    },

    // Request to save the floor image
    async onSaveImage ({ renderer, floor, image } = {}) {
      renderer = renderer || this.renderer

      if (renderer && this.imageSaved) {
        await this.imageSaved({ renderer, floor, image })
      }
    },

    // Request to save the plan snapshot
    async onSnapshotSave ({ renderer }) {
      renderer = renderer || this.renderer

      if (renderer && this.snapshotSaved) {
        const { plan } = this
        renderer.blur()
        const { isOk, data } = await this.showDialog({
          dialog: 'plan-snapshot',
          data: { plan }
        })
        renderer.focus()
        if (isOk) {
          const { name, description } = data
          this.snapshotSaved({ renderer, plan, name, description })
        }
      }
    },

    // Request to delete a plan snapshot
    async onSnapshotDelete ({ renderer, snapshot }) {
      renderer = renderer || this.renderer

      if (renderer && this.snapshotDeleted) {
        this.snapshotDeleted({ renderer, snapshot })
      }
    },

    // Request to restore a plan snapshot
    async onSnapshotRestore ({ renderer, snapshot }) {
      renderer = renderer || this.renderer

      if (renderer && this.snapshotRestored) {
        this.snapshotRestored({ renderer, snapshot })
      }
    },

    // Request to navigate to another floor or to cross-section view
    async onSelectFloor ({ renderer, floor: { id } = {} } = {}) {
      renderer = renderer || this.renderer

      // Skip tabs which are action buttons
      if (id.startsWith('action-')) {
        return
      }

      if (id) {
        if (id === PlanFloors.CrossSection) {
          await this.onSelectCrossSection({ renderer })

        } else {
          if (this.floorSelected) {
            renderer = renderer || this.renderer
            if (renderer) {
              const items = renderer.selectedItems
              this.storePlanSelection({ items })
              const floor = this.layout.getFloor(id) || this.layout.getMainFloor()
              await this.floorSelected({ renderer, floor })
            }
          }
        }
      }
    },

    // Request to navigate to cross section view
    async onSelectCrossSection ({ renderer }) {
      renderer = renderer || this.renderer

      if (this.crossSectionSelected) {
        renderer = renderer || this.renderer
        if (renderer) {
          const items = renderer.selectedItems
          this.storePlanSelection({ items })
          await this.crossSectionSelected({ renderer })
        }
      }
    },

    // Triggered when user pans the plan
    async onScrollPlan ({ floor, x, y }) {
      this.scrollPlan({ floor, x, y })
    },

    // Called when user copies plan items into plan clipboard
    async onCopy () {
      this.planCopyToClipboard()
    },

    // Called when user pastes plan items from plan clipboard
    async onPaste () {
      this.planPasteFromClipboard()
    },

    // Signals that the user wants to start adding an item to the plan
    startAddingItem (item, layer) {
      const { renderer } = this
      renderer.startAddingItem(item, layer)
    },

    // Signals that the user wants to stop adding an item to the plan
    stopAddingItem () {
      const { renderer } = this
      renderer.stopAddingItem()
    },

    // Adds new floor to the plan
    async addFloor () {
      const { layout } = this

      const label = await Confirmation.prompt({
        title: 'Floor name',
        message: 'Please enter the name of the floor',
        initial: `Floor ${layout.floorCount}`,
      })

      if (label && label.trim()) {
        await this.executeAction({ action: PlanActions.AddFloor, label })
      }
    },

    // Executes an action
    async executeAction (details = {}) {
      const { renderer, floor } = this
      await executePlanAction({ renderer, floor, ...details })
    },

    // Executes an action at the current position
    async executeActionAt (details = {}) {
      const { renderer } = this
      const position = renderer.currentPosition
      await this.executeAction({ position, ...details })
    },

    // Undoes the last action
    async undo () {
      const { renderer, undoableAction } = this
      if (undoableAction) {
        await renderer.undo()
      }
    },

    // Redoes the previously undone action
    async redo () {
      const { renderer, redoableAction } = this
      if (redoableAction) {
        await renderer.redo()
      }
    },

    // Pastes the content from the clipboard
    async paste () {
      const { renderer, canPaste } = this
      if (renderer && canPaste) {
        await executePlanAction({
          renderer,
          action: PlanActions.PasteItems,
          position: renderer.selectedPosition
        })
      }
    },

    // Focus/defocus the plan
    focus (state) {
      this.renderer?.focus(state)
    },

    // Displays help
    showHelp () {
      this.executeAction({ action: PlanActions.ShowHelp })
    },

    // Shows the dialog for selecting and editing the background image(s)
    async importPlanImages ({ renderer } = {}) {
      const { plan } = this
      renderer = renderer || this.renderer
      if (!renderer) return

      renderer.blur()
      const { isOk, data } = await this.showDialog({ dialog: 'plan-import', data: { plan } })
      renderer.focus()

      if (isOk && data?.images?.length > 0) {
        const { images, settings } = data
        const { layout } = renderer

        // Reset the renderer
        renderer.cancelEditing()
        renderer.setZoom(1)

        // If plan is empty, remove the default floor
        if (layout.isEmpty) {
          layout.floors = []
        }

        // Add the floors to the plan
        let mapScale
        for (const image of images) {
          const label = image.description
          mapScale = image.mapScale
          const floor = layout.addFloor({ label, mapScale })

          // Add legend to the newly added floor
          if (settings.addLegends) {
            const legend = PlanLegend.createLegend()
            legend.setCoordinates({ x: 20, y: 20 }, false)
            floor.addItem(legend)
          }

          // Assign the new image
          floor.background.setImage(image)
        }

        // Add legend to the cross-section
        const crossSectionHasLegend = layout.crossSection.findItem(i => i.isLegend)
        if (settings.addLegends && !crossSectionHasLegend) {
          const legend = PlanLegend.createLegend({ x: 20, y: 20, showOnlyOnCrossSection: true })
          layout.crossSection.addItem(legend)
        }

        // Add a roof to the plan
        const hasRoof = layout.findFloor(({ label }) => label.toLowerCase().includes('roof'))
        if (settings.addRoof && !hasRoof) {
          const floor = layout.addFloor({ label: 'Roof', mapScale })
          if (settings.addLegends) {
            const legend = PlanLegend.createLegend()
            legend.setCoordinates({ x: 20, y: 20 }, false)
            floor.addItem(legend)
          }
        }

        // Navigate to the cross-section.
        // If already on the cross-section, navigate to the first floor and back, to enforce full reload.
        await renderer.save()
        if (renderer.isCrossSection) {
          await renderer.refresh()
        } else {
          await renderer.selectCrossSection()
        }

        const message = pluralize(images, 'Plan image has been imported', `${images.length} plan images have been imported`)
        Notification.success({ message })
      }
    },

    // Floor tab dragging started
    floorDrag (event, floor) {
      this.draggedFloor = floor
      if (floor) {
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.setData('text/plain', floor.id)
      }
    },

    // Floor tab dragging ended
    floorDragEnd () {
      this.draggedFloor = null
    },

    // Floor tab is being dragged over another tab
    floorDragOver (event, floor) {
      const { draggedFloor } = this
      if (floor && draggedFloor) {
        if (floor && floor.id !== draggedFloor.id) {
          event.preventDefault()
        }
      }
    },

    // Floor tab is dropped over another tab, reorder
    floorDrop (event, floor) {
      const { renderer, draggedFloor } = this
      if (floor && draggedFloor) {
        if (floor && floor.id !== draggedFloor.id) {
          event.preventDefault()
          renderer.moveFloorTo(draggedFloor, floor)
        }
      }
      this.draggedFloor = null
    },

    // Toggles debugging
    debug (status) {
      this.renderer?.debug(status)
    },

    // Triggered when palette has been moved
    onPaletteMoved (position) {
      const { floor } = this
      this.movePlanPalette({ floor, position })
    }
  },

  watch: {
    floorId () {
      // Reload the plan when floor changes
      this.loadFloor()
    },

    // Notifies about state change of the renderer.
    // Can be used to display state labels, such as 'Loading...', 'Saving...' etc.
    planState () {
      if (this.stateChanged) {
        const { renderer } = this
        if (renderer) {
          this.stateChanged({
            state: renderer.state,
            label: renderer.stateLabel
          })
        }
      }
    }
  },

  beforeUnmount () {
    this.renderer?.unmount()
  },

  created () {
    this.loadFloor()
  }
}

</script>

<template>
  <div class="tabs">
    <q-tabs :model-value="floorId" no-caps inline-label class="bg-indigo-2"
      active-bg-color="indigo-3" outside-arrows align="left"
      @update:model-value="id => onSelectFloor({ renderer, floor: { id } })">
      <q-tab :name="PlanFloors.CrossSection" label="Cross Section" icon="corporate_fare"
        :ripple="false">
      </q-tab>

      <q-tab class="floor" v-for="floor in visibleFloors" :name="floor.id" :key="floor.id"
        :icon="layout.hasOneFloor ? 'grid_view' : undefined" :ripple="false">
        <div class="floor-label" draggable="true" @dragstart="event => floorDrag(event, floor)"
          @dragend="event => floorDragEnd(event, floor)"
          @dragenter="event => floorDragOver(event, floor)"
          @dragover="event => floorDragOver(event, floor)" @drop="event => floorDrop(event, floor)">
          {{ floor.label }}
        </div>
      </q-tab>

      <q-tab name="action-add-floor" icon="add_circle" :ripple="false" @click.stop="addFloor()"
        class="text-indigo-8 tab-right-border">
        <sc-tooltip>Add a new floor</sc-tooltip>
      </q-tab>
      <q-tab name="action-undo" icon="undo" :ripple="false" @click.stop="undo()" class="tab-action"
        :class="undoableAction.action ? 'text-indigo-8' : 'text-grey-6'"
        :disable="!undoableAction.action">
        <sc-tooltip>Undo the last action</sc-tooltip>
      </q-tab>
      <q-tab name="action-undo" icon="redo" :ripple="false" @click.stop="redo()" class="tab-action"
        :class="redoableAction.action ? 'text-indigo-8' : 'text-grey-6'"
        :disable="!redoableAction.action">
        <sc-tooltip>Redo the last action</sc-tooltip>
      </q-tab>
      <q-tab name="action-paste" icon="content_paste" :ripple="false" @click.stop="paste()"
        class="tab-action" :disable="!canPaste"
        :class="canPaste ? 'text-indigo-8' : 'text-grey-6'">
        <sc-tooltip>Paste from clipboard</sc-tooltip>
      </q-tab>
      <q-space>
      </q-space>
      <scp-floor-toolbar :renderer="renderer" :layout="layout" :floor="floor">
      </scp-floor-toolbar>
    </q-tabs>

    <!-- Shows the current editing mode, indicating multi-step actions such as drawing lines, walls etc. -->
    <div class="plan-edit-mode" v-if="renderer?.editMode">
      <scp-edit-mode :renderer="renderer">
      </scp-edit-mode>
    </div>
  </div>

  <div class="plan" tabindex="1" @focus="focus(true)" @blur="focus(false)">
    <div v-if="hasContainer && !floor?.isDeleted" class="plan-container"
      :class="renderer ? { [renderer.state]: true } : null" ref="container" tabindex="0">
    </div>

    <!-- Make focused palette capture all keyboard events, otherwise they'd be passed to canvas! -->
    <div v-if="renderer?.isRendered" class="plan-palette" :style="paletteStyle" @keydown.stop
      v-moveable.right="{ handle: '.palette-header', onMoved: args => onPaletteMoved(args) }">
      <scp-palette :renderer="renderer"
        @start-adding-item="({ item, layer }) => startAddingItem(item, layer)"
        @stop-adding-item="stopAddingItem()" @action="executeActionAt">
      </scp-palette>
    </div>

    <scp-items-menu v-if="renderer?.isRendered" container=".plan" :menu="renderer.itemsMenu"
      @execute="executeActionAt">
    </scp-items-menu>

    <scp-tooltip v-if="renderer?.isRendered" :tooltip="renderer.tooltip" container=".plan">
    </scp-tooltip>

    <scp-help v-if="renderer?.isRendered" :help="renderer.helpVisible" @close="renderer.hideHelp()">
    </scp-help>

    <scp-item-details>
    </scp-item-details>

    <scp-snapshot-dialog>
    </scp-snapshot-dialog>

    <scp-plan-import-dialog>
    </scp-plan-import-dialog>

  </div>
</template>

<style lang="scss" scoped>
.toolbar-toggles {
  border-left: solid #ced2ea 1px;
}

.toolbar-floor {
  border-left: solid #abb0d0 1px;
}

:deep(.q-tab.floor) {
  padding: 0 0 0 4px;
}

.tabs {
  position: relative;

  .floor-label {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 0 16px 0 8px;
    user-select: none;
  }

  .tab-right-border {
    border-right: solid grey 1px;
    margin-right: 8px;
  }

  .tab-action {
    padding-left: 4px;
    padding-right: 4px;
  }

  .plan-edit-mode {
    z-index: 100000;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap
  }
}

.plan {
  --palette-width: 354px;
  background-color: #fafafa;

  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;

  .plan-container {
    position: relative;
    overflow: auto;
    background-color: #ffffff;
    border-right: solid #0000001f 1px;
    border-bottom: solid #0000001f 1px;

    &.initializing {
      cursor: wait;
    }

    &.saving {}

    &.pointing {
      cursor: pointer;
    }

    &.selecting-transparent-color {
      cursor: crosshair;
    }

    &.panning {
      cursor: move;
    }
  }

  .plan-palette {
    position: fixed;
    width: var(--palette-width);
    z-index: 11;
  }

  .plan-zoom {
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 10;
  }
}
</style>
