/**
 * Async wrapper around IndexedDB
 */
export class IndexDbService {
  __database
  __stores

  /**
   * Database name
   * @type {String}
   */
  name

  /**
   * Database version
   * @type {Number}
   */
  version

  /**
   * Connected database
   * @type {IDBDatabase}
   */
  get database () {
    return this.__database
  }

  /**
   * Verifies whether the {@link database} is connected
   * @type {Boolean}
   */
  get isConnected () {
    return this.__database != null
  }

  /**
   * Database stores
   * @type {Array[IndexDbStore]}
   */
  get stores () {
    return this.__stores
  }

  /**
   * Opens the database instance
   * @param {String} name Database name
   * @param {Number} version Database version
   * @param {Array[IndexDbStore]} stores Data stores to create
   * @returns {Promise<IDBDatabase>} Connected database
   */
  async connect ({ name = 'database', version = 1, stores = [] }) {
    if (!(name?.trim())) throw new Error('Database name is required')
    if (!(version > 0)) throw new Error('Database version is required')

    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(name, version)

      request.onupgradeneeded = (event) => {
        this.__database = request.result

        switch (event.oldVersion) { // existing db version
          case 0:
            // version 0 means that the client had no database:
            // perform initialization
            for (const store of stores) {
              this.addStore(store)
            }
          // case 1:
          // client had version 1:
          // update
        }
      }

      request.onerror = () => {
        reject(request.error)
      }

      request.onsuccess = () => {
        this.name = name
        this.version = version
        this.__database = request.result

        for (const store of stores) {
          this.addStore(store)
        }

        resolve(this)
      }
    })
  }

  /**
   * Disconnects the connected {@link database}
   * @returns {Promise}
   */
  async disconnect () {
    if (this.isConnected) {
      this.database.close()
    }
  }

  /**
   * Deletes the database with a specified name
   * @param {String} name Name of the database to delete
   * @returns {Promise}
   */
  async deleteDatabase ({ name }) {
    if (!(name?.trim())) throw new Error('Database name is required')

    return new Promise((resolve, reject) => {
      const request = indexedDB.deleteDatabase(name)

      request.onerror = () => {
        reject(request.error)
      }

      request.onsuccess = () => {
        resolve(this.__database)
      }
    })
  }

  /**
   * Adds a store to the connected {@link database}
   * @param {String} name Store name
   * @param {String} key Property serving as key for objects in the store
   * @param {Boolean} autoincrement If `true` incremental numeric keys are created automatically.
   * If {@link key} is not specified, autoincrement is assumed by default.
   * @returns {Array[IndexDbStore]}
   */
  addStore ({ name, key, autoincrement }) {
    if (!this.isConnected) throw new Error('Database is not connected')
    if (!name) throw new Error('Store is not specified')

    const store = new IndexDbStore({ name, key, autoincrement })

    if (!this.__stores) {
      this.__stores = []
    }

    if (!this.__database.objectStoreNames.contains(name)) {
      this.__database.createObjectStore(name, { keyPath: key, autoincrement })
    }

    if (!this.__stores.some(s => s.name === name)) {
      this.__stores.push(store)
    }

    return this.__stores
  }

  /**
   * Removes a store from the connected {@link database}
   * @param {String} name Store name
   * @returns {Array[IndexDbStore]}
   */
  deleteStore ({ name }) {
    if (!this.isConnected) throw new Error('Database is not connected')
    if (!name) throw new Error('Store is not specified')

    if (this.__database.contains(name)) {
      this.__database.deleteObjectStore(name)
    }

    this.__stores = this.__stores.filter(s => s.name !== name)

    return this.__stores
  }

  /**
   * Adds data to a store in the connected {@link database}
   * @param {String} name Store name
   * @param {Object} data Data to store
   * @param {String} key Explicit data key, optional
   * @param {Boolean} overwrite If `true` and object with the same key exists, it will be overwritten, otherwise an exception will be raised
   * @param {IDBTransaction} transaction Database transaction. If not specified, transaction will be created
   * automatically for this one operation
   * @param {String} mode Transaction mode
   * @returns {Promise}
   */
  put ({ name, data, key, overwrite = true, transaction, mode = 'readwrite' }) {
    if (!this.isConnected) throw new Error('Database is not connected')
    if (!name) throw new Error('Store is not specified')
    if (!this.__stores?.some(s => s.name === name)) throw new Error('Unknown store')

    return new Promise((resolve, reject) => {
      try {
        transaction = transaction || this.database.transaction(name, mode)

        transaction.onabort = () => {
          reject(transaction.error)
        }

        transaction.oncomplete = () => {
          resolve()
        }

        const store = transaction.objectStore(name)
        const request = overwrite
          ? store.put(data, key)
          : store.add(data, key)

        request.onerror = () => {
          reject(request.error)
        }

      } catch (error) {
        return reject(error)
      }
    })
  }

  /**
   * Returns an object from the store
   * @param {String} name Store name
   * @param {String} key Data key
   * @param {IDBTransaction} transaction Database transaction. If not specified, transaction will be created
   * automatically for this one operation
   * @param {String} mode Transaction mode
   * @returns {Promise<Object>}
   */
  get ({ name, key, transaction, mode = 'readonly' }) {
    if (!this.isConnected) throw new Error('Database is not connected')
    if (!name) throw new Error('Store is not specified')
    if (!this.__stores?.some(s => s.name === name)) throw new Error('Unknown store')

    return new Promise((resolve, reject) => {
      try {
        transaction = transaction || this.database.transaction(name, mode)

        transaction.onabort = () => {
          reject(transaction.error)
        }

        const store = transaction.objectStore(name)
        const request = store.get(key)

        request.onerror = () => {
          reject(request.error)
        }

        request.onsuccess = () => {
          resolve(request.result)
        }

      } catch (error) {
        return reject(error)
      }
    })
  }

  /**
   * Returns all objects from the store
   * @param {String} name Store name
   * @param {IDBTransaction} transaction Database transaction. If not specified, transaction will be created
   * automatically for this one operation
   * @param {String} mode Transaction mode
   * @returns {Promise<Array>}
   */
  getAll ({ name, transaction, mode = 'readonly' }) {
    if (!this.isConnected) throw new Error('Database is not connected')
    if (!name) throw new Error('Store is not specified')
    if (!this.__stores?.some(s => s.name === name)) throw new Error('Unknown store')

    return new Promise((resolve, reject) => {
      try {
        transaction = transaction || this.database.transaction(name, mode)

        transaction.onabort = () => {
          reject(transaction.error)
        }

        const store = transaction.objectStore(name)
        const request = store.getAll()

        request.onerror = () => {
          reject(request.error)
        }

        request.onsuccess = () => {
          resolve(request.result)
        }

      } catch (error) {
        return reject(error)
      }
    })
  }

  /**
   * Deletes an object from the store
   * @param {String} name Store name
   * @param {String} key Data key
   * @param {IDBTransaction} transaction Database transaction. If not specified, transaction will be created
   * automatically for this one operation
   * @param {String} mode Transaction mode
   * @returns {Promise}
   */
  delete ({ name, key, transaction, mode = 'readwrite' }) {
    if (!this.isConnected) throw new Error('Database is not connected')
    if (!name) throw new Error('Store is not specified')
    if (!this.__stores?.some(s => s.name === name)) throw new Error('Unknown store')

    return new Promise((resolve, reject) => {
      try {
        transaction = transaction || this.database.transaction(name, mode)

        transaction.onabort = () => {
          reject(transaction.error)
        }

        const store = transaction.objectStore(name)
        const data = store.delete(key)
        resolve(data)

      } catch (error) {
        return reject(error)
      }
    })
  }

  /**
   * Deletes all objects from the store
   * @param {String} name Store name
   * @param {IDBTransaction} transaction Database transaction. If not specified, transaction will be created
   * automatically for this one operation
   * @param {String} mode Transaction mode
   * @returns {Promise}
   */
  deleteAll ({ name, transaction, mode = 'readwrite' }) {
    if (!this.isConnected) throw new Error('Database is not connected')
    if (!name) throw new Error('Store is not specified')
    if (!this.__stores?.some(s => s.name === name)) throw new Error('Unknown store')

    return new Promise((resolve, reject) => {
      try {
        transaction = transaction || this.database.transaction(name, mode)

        transaction.onabort = () => {
          reject(transaction.error)
        }

        const store = transaction.objectStore(name)
        const data = store.clear()
        resolve(data)

      } catch (error) {
        return reject(error)
      }
    })
  }
}

/**
 * Definition of a database store
 */
export class IndexDbStore {
  constructor (data = {}) {
    Object.assign(this, data)
    if (!this.key && this.autoincrement == null) {
      this.autoincrement = true
    }
  }

  /**
   * Store name
   * @type {String}
   */
  name

  /**
   * Property serving as key for objects in the store
   * @type {String}
   */
  key

  /**
   * If `true` incremental numeric keys are created automatically.
   * Defaults to `true` unless {@link key} is explicitly specified.
   * @type {Boolean}
   */
  autoincrement
}