<script>
import { startOfDay, endOfDay, differenceInDays } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import { PremiumService, getDevicesLabel } from '@stellacontrol/model'
import { convertPeriod, Period, countString } from '@stellacontrol/utilities'
import { Confirmation } from '@stellacontrol/client-utilities'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'

export default {
  mixins: [
    DeviceActionMixin
  ],

  components: {
    'sc-device-action': DeviceAction
  },

  data () {
    return {
      // Indicates whether owner is obliged to use paid subscriptions
      ownerHasToBuyPremiumServices: false,
      // All premium services available in my pricelist
      applicableServices: [],
      // Premium service to add to selected devices
      premiumServiceId: null,
      // Start date for the premium service
      premiumServiceStartsAt: new Date(),
      // Additional notes to register
      notes: '',
      // Indicates that uses wants to sell additional premium services,
      // on top of the already activated ones
      wantsToSellAdditionalServices: false,
      // Indicates whether the component is now loading
      isLoading: false
    }
  },

  computed: {
    ...mapGetters([
      'configuration',
      'myWallet',
      'pricelist',
      'fullHierarchy',
      'organizationHierarchy'
    ]),

    // Indicates whether current user is permitted to assign premium services on devices
    canAssignServices () {
      return this.isBank
    },

    // Indicates whether current user is permitted to sell premium services
    // to the selected new owner of devices
    canSellServices () {
      const { canUse, allDevicesAreNotMine } = this
      return canUse('premium-services-sell') && allDevicesAreNotMine
    },

    // Indicates whether current user is permitted to cancel
    // the specified premium service subscription
    canCancelSubscriptions () {
      return subscription => {
        const { canUse, isMySubscription } = this
        return canUse('premium-services-cancel') && (!isMySubscription(subscription) || canUse('premium-services-buy'))
      }
    },

    // Indicates whether to show services waiting to be activated
    canSeeServicesToActivate () {
      return this.currentOrganization.isSuperOrganization
    },

    // Indicates whether current user is permitted to clear
    // an non-activated premium services on devices
    canClearPendingServices () {
      const { canUse, canSeeServicesToActivate } = this
      return canUse('premium-services-sell') && canSeeServicesToActivate
    },

    // Returns true if there are any pending premium services waiting to be activated
    // and the current user is allowed to start them
    canActivatePendingService () {
      const { ownerHasToBuyPremiumServices, servicesToActivate, canSeeServicesToActivate } = this
      return ownerHasToBuyPremiumServices && canSeeServicesToActivate && servicesToActivate?.length > 0
    },

    // Indicates that current user is permitted to sell tokens to the device owner
    canSellTokens () {
      const { canSellServices } = this
      return canSellServices
    },

    // Indicates that current user can take notes for the audit
    canTakeNotes () {
      return this.canUse('premium-services-audit')
    },

    // List of services that the user can sell.
    // Skip services which are already active on the device, unless Super Administrator does it.
    // Skip free services, unless current user is allowed to assign free services to devices.
    // Skip pending premium service currently waiting to be activated.
    sellableServices () {
      const { device, applicableServices, canAssignServices, activeSubscriptions, isSuperAdministrator } = this
      const services = canAssignServices
        ? applicableServices
        : applicableServices.filter(s => !s.isFree)
      return services
        .filter(s => isSuperAdministrator || !activeSubscriptions?.some(sub => sub.serviceId === s.id))
        .filter(s => !(device.isPremiumServiceNotStarted && device.premiumServiceId === s.id))
    },

    // Returns true if there are any services available to sell
    hasAvailableServices () {
      return this.sellableServices.length > 0
    },

    // Selected service to assign to devices, free of charge
    selectedService () {
      const { applicableServices, premiumServiceId } = this
      return applicableServices.find(s => s.id === premiumServiceId)
    },

    // Returns true if a service has been selected to assign to devices, free of charge
    isServiceSelected () {
      return Boolean(this.selectedService)
    },

    // Indicates whether the user is now selling a premium service
    isSellingService () {
      return this.canSellServices &&
        this.isServiceSelected &&
        !this.canActivatePendingService &&
        (!this.activeSubscriptions || this.wantsToSellAdditionalServices)
    },

    // Indicates whether selected devices belong to the current organization,
    // and the organization is permitted to purchase premium services themselves
    isBuyingService () {
      return this.allDevicesAreMine &&
        this.mustUse('premium-services-buy') &&
        this.canUse('premium-services-self-activate')
    },

    // Indicates that we're selling the service for the money, instead of
    // assigning it for free
    isPaidService () {
      return this.selectedService?.isPaid
    },

    // Returns those of the selected devices
    // which have premium services assigned
    devicesWithActiveServices () {
      const devices = this.devices.filter(d => d.isPremiumServiceActive)
      return devices.length > 0 ? devices : undefined
    },

    // Returns those of the selected devices
    // which do not have premium services yet
    devicesWithoutActiveServices () {
      const devices = this.devices.filter(d => !d.isPremiumServiceActive)
      return devices.length > 0 ? devices : undefined
    },

    // Returns those of the selected services
    // which have premium services on them still waiting to be started
    devicesWithServicesToActivate () {
      const devices = this.devices
        .filter(d => d.isPremiumServiceNotStarted)
        .filter(d => !this.activeSubscriptions?.some(s => s.forDevice(d) && s.serviceId === d.premiumServiceId))
      return devices.length > 0 ? devices : undefined
    },

    // Premium services which can be activated on the selected devices
    servicesToActivate () {
      const { applicableServices, devicesWithServicesToActivate } = this
      if (applicableServices && devicesWithServicesToActivate) {
        const unique = new Set(devicesWithServicesToActivate.map(d => d.premiumServiceId))
        const services = Array.from(unique)
          .map(id => applicableServices.find(s => s.id === id))
          .filter(s => s)
          .map(s => new PremiumService({
            ...s,
            devices: devicesWithServicesToActivate.filter(d => d.premiumServiceId === s.id)
          }))
        return services.length > 0 ? services : undefined
      }
    },

    // Calculates duration of the subscription for the selected service
    subscriptionDays () {
      const { selectedService } = this
      if (selectedService && selectedService.isChargedPeriodically) {
        return convertPeriod(selectedService.periodUnit, Period.Day) * selectedService.period
      }
    },

    // When service is to be activated in the past, returns the amount of days already used up
    subscriptionStartsBefore () {
      const { premiumServiceStartsAt, selectedService, subscriptionDays } = this
      const today = endOfDay(new Date())
      const difference = differenceInDays(today, startOfDay(premiumServiceStartsAt))
      return selectedService
        ? Math.min(difference, subscriptionDays)
        : difference
    },

    // When service is to be activated in the future, returns the amount of days before the service kicks in
    subscriptionStartsAfter () {
      const today = startOfDay(new Date())
      return differenceInDays(startOfDay(this.premiumServiceStartsAt), today)
    },

    // Total price of selected premium service
    totalPrice () {
      const { selectedService, devices } = this
      if (selectedService) {
        return selectedService.price * devices.length
      } else {
        return 0
      }
    },

    // Tokens available for purchasing subscriptions - sum of device owner's
    // and current reseller's tokens
    availableTokens () {
      const { myWallet, ownerWallet } = this
      const availableTokens = myWallet.id === ownerWallet.id
        ? myWallet.balance
        : myWallet.balance + ownerWallet.balance
      return availableTokens
    },

    // Owner wallet balance AFTER purchase of the selected service
    availableTokensAfterPurchase () {
      const { ownerWallet, totalPrice, selectedService, canAffordSubscription } = this
      if (selectedService && canAffordSubscription) {
        const balance = Math.max(0, ownerWallet.balance - totalPrice)
        return balance
      }
    },

    // Indicates whether the owner can afford to buy the premium services.
    // If owner doesn't have tokens, this can still be done with tokens of current reseller!
    canAffordSubscription () {
      const { selectedService, totalPrice, availableTokens } = this
      return selectedService && (selectedService.isFree || availableTokens >= totalPrice)
    },

    // Indicates whether start date of the service can be selected
    canEditStartDate () {
      const { isSellingService, isBuyingService, isServiceSelected, isPaidService, canAffordSubscription } = this
      return (isSellingService || isBuyingService) && isServiceSelected && isPaidService && canAffordSubscription
    },

    // Active subscriptions of the current owner of the devices,
    // which are related to the selected devices
    activeSubscriptions () {
      const { ownerWallet, devices } = this
      const subscriptions = ownerWallet ? (ownerWallet.subscriptions || []) : []
      const activeSubscriptions = subscriptions.filter(s =>
        s.isStarted && !s.isExpired && devices.some(device => s.forDevice(device)))
      return activeSubscriptions.length > 0 ? activeSubscriptions : undefined
    },

    // Returns devices which belong to the specified subscription
    subscriptionDevices () {
      return subscription => (subscription.devices || [])
        .map(serialNumber => this.devices.find(d => d.serialNumber === serialNumber) || { serialNumber })
    },

    // Indicates whether subscription belongs to the current organization
    isMySubscription () {
      return subscription => {
        const { currentOrganization } = this
        return subscription.walletId === currentOrganization.id
      }
    },

    // Label on the EXECUTE button
    executeLabel () {
      if (this.canActivatePendingService) return 'Activate Free Service'
      if (this.isPaidService) return this.isBuyingService ? 'Buy Service' : 'Sell Service'
      return 'Assign Service'
    }
  },

  methods: {
    ...mapActions([
      'setInventoryActionLabel',
      'resetInventoryActionLabel',
      'getPremiumServices',
      'getPremiumService',
      'startDeviceSubscriptions',
      'sellPremiumService',
      'assignPremiumServiceToDevices',
      'clearPremiumServicesOnDevices',
      'unsubscribeDevices',
      'showDialog',
    ]),

    getDevicesLabel,

    // Load premium services details
    async populate () {
      this.isLoading = true
      await this.populatePremiumServices()
      await this.populateOwner()
      this.isLoading = false
    },

    // Populates data related to owner of selected devices
    async populateOwner () {
      const { owner } = this
      if (owner) {
        this.ownerHasToBuyPremiumServices = this.ownerGuardian?.mustUse('premium-services-buy')
        this.setInventoryActionLabel({
          label: this.isBuyingService ? 'My Premium Services' : `Premium Services of ${owner.name}`,
          description: `Wallet: ${countString(this.ownerWallet.balance, 'token', 'empty')}`
        })
      } else {
        this.ownerHasToBuyPremiumServices = false
        this.resetInventoryActionLabel()
      }
    },

    // Populates premium services available for sale
    async populatePremiumServices () {
      const { currentOrganization, isBank, pricelist, owner, fullHierarchy } = this
      if (currentOrganization && pricelist) {
        const applicableServices = pricelist.hasOwnPremiumServices(owner, fullHierarchy)
          ? pricelist.resellerPremiumServices(owner, fullHierarchy, isBank)
          : pricelist.publicPremiumServices(currentOrganization)
        this.applicableServices = applicableServices.filter(s => s.isPerDevice)
      } else {
        this.applicableServices = []
      }
    },

    // Exxecutes the action
    async execute () {
      if (await this.validate()) {
        const {
          isBank,
          canActivatePendingService,
          isSellingService,
          isBuyingService,
          isPaidService,
          selectedService,
          devices,
          devicesWithServicesToActivate,
          premiumServiceStartsAt,
          notes,
          owner,
          ownerWallet
        } = this

        // Start services on devices
        if (canActivatePendingService) {
          await this.startDeviceSubscriptions({
            devices: devicesWithServicesToActivate,
            startsAt: premiumServiceStartsAt
          })
        } else if (isSellingService || isBuyingService) {
          // Sell or buy services
          if (isBank && !isPaidService) {
            // If free service assigned by the bank,
            // just assign to device but don't activate yet
            await this.assignPremiumServiceToDevices({
              devices,
              service: selectedService
            })
          } else {
            // Activate the service immediately
            await this.sellPremiumService({
              devices,
              service: selectedService,
              customer: owner,
              wallet: ownerWallet,
              startsAt: premiumServiceStartsAt,
              notes
            })
          }
        }

        this.executed()
      }
    },

    // Clears pending premium service on devices
    async reset () {
      const { devicesWithServicesToActivate: devices } = this
      if (devices) {
        const message = `Remove free services assigned to ${getDevicesLabel(devices)}?<br>
                         This will remove only those services which were not yet activated.
                         <b>Services already active will remain unaffected</b>.`
        const yes = await Confirmation.ask({ message, html: true })
        if (yes) {
          // Remove premium services assigned to devices
          await this.clearPremiumServicesOnDevices({ devices })
          this.executed({ action: this.action })
        }
      }
    },

    // Removes devices from specified subscription
    async cancelSubscription (subscription) {
      const { canCancelSubscriptions, devices } = this
      if (canCancelSubscriptions && devices) {
        const message = `Stop premium service ${subscription.service.label} on ${getDevicesLabel(devices)}?`
        const yes = await Confirmation.ask({ message })
        if (yes) {
          await this.unsubscribeDevices({ subscription, devices })
          subscription.devices = subscription.devices.filter(serialNumber => !devices.find(d => d.serialNumber === serialNumber))
        }
      }
    },

    // Opens dialog for purchasing tokens
    async purchaseTokens () {
      const { owner } = this
      await this.showDialog({ dialog: 'purchase-tokens', data: { customer: owner } })
    },

    // Opens dialog for selling tokens to customer
    async sellTokens () {
      const { owner, ownerWallet } = this
      if (owner && ownerWallet) {
        const { isOk } = await this.showDialog({ dialog: 'sell-tokens', data: { customer: owner, customerWallet: ownerWallet } })
        if (isOk) {
          this.ownerWallet = await this.getWallet({ organization: owner })
        }
      }
    }
  },

  watch: {
    // Load premium services details on selection change
    device () {
      this.populate()
    }
  },

  async created () {
    await this.populate()
  }
}
</script>

<template>
  <sc-device-action ref="actionComponent" :action="action" :devices="devices"
    :allowWhenDifferentOwners="false" :execute-label="executeLabel" reset-label="Clear Free Service"
    :reset-tooltip="`Clear pending premium services from ${getDevicesLabel(devicesWithServicesToActivate)}`"
    :execute-tooltip="canActivatePendingService ? `Activate pending premium services on ${getDevicesLabel(devicesWithServicesToActivate)}` : ''"
    :canExecute="canActivatePendingService || (isServiceSelected && canAffordSubscription)"
    :executeVisible="canActivatePendingService || isServiceSelected"
    :canReset="canClearPendingServices && devicesWithServicesToActivate" :isLoading="isLoading"
    @closing="closing()" @close="close()" @execute="execute()" @reset="reset()">

    <q-form v-if="ownerHasToBuyPremiumServices" ref="form" autofocus class="q-mt-md q-gutter-xs"
      @submit.prevent>
      <!-- Warn if there are no tokens available for purchasing services -->
      <div v-if="availableTokens === 0" class="row q-mb-md items-center">
        <template v-if="canSellServices">
          <span>
            {{ owner.name }} has no tokens left.
          </span>
          <span v-if="(canSellTokens && myWallet.balance > 0)" class="q-ml-xs">
            <a class="item-link" @click="sellTokens()">
              Click here to add tokens to their wallet.
            </a>
          </span>
          <sc-hint v-if="(canSellTokens && myWallet.balance > 0)" class="q-ml-xs">
            You can add tokens to the wallets of your customers.
            They will not see these transactions. This is purely
            to allow you to organise and distribute your tokens
            as you see fit.
          </sc-hint>
        </template>
        <template v-else-if="isBuyingService">
          <span>
            You have no tokens left.
          </span>
          <span class="q-ml-xs">
            <a class="item-link" @click="purchaseTokens">
              Click here
            </a>
            to purchase more tokens.
          </span>
        </template>
      </div>

      <!-- List of currently active subscriptions -->
      <div v-if="activeSubscriptions" class="q-mb-md">
        <div class="q-mb-sm">
          <label class="text-body2">
            Services currently active on {{ devicesLabel }}:
          </label>
        </div>

        <div class="active-subscriptions">
          <q-markup-table class="services" flat dense square separator="cell" wrap-cells>
            <tbody>
              <tr v-for="subscription in activeSubscriptions" :key="subscription.id">
                <td>
                  {{ subscription.service.label }}
                </td>
                <td>{{ subscription.statusLabel }}</td>
                <td
                  :style="{ 'white-space': subscription.devices.length === 1 ? 'nowrap' : 'normal' }">
                  {{ getDevicesLabel(subscriptionDevices(subscription)) }}
                  <sc-tooltip
                    v-if="subscription.devices.length > 5 && subscription.devices.length <= 20">
                    {{ subscription.devices.join(', ') }}
                  </sc-tooltip>
                </td>
                <td v-if="canCancelSubscriptions(subscription)">
                  <q-btn round flat dense unelevated icon="close" color="red"
                    @click="cancelSubscription(subscription)">
                    <sc-tooltip>
                      Stop premium service {{ subscription.service.label }}
                      on {{ getDevicesLabel(devices) }}
                    </sc-tooltip>
                  </q-btn>
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </div>
      </div>

      <!-- Activate any pending premium services -->
      <div v-if="(servicesToActivate && canSeeServicesToActivate)" class="q-mb-md">
        <div>
          <label class="text-body2" v-if="canActivatePendingService">
            Free premium {{ pluralize(servicesToActivate, 'service is', 'services are') }} ready to
            be activated.
          </label>
          <label class="text-body2" v-else>
            {{ capitalize(selectionLabel) }} {{ pluralize(devices, 'has', 'have') }}
            free premium services. They cannot be activated yet,
            because device owner is not required to use paid
            services:
          </label>
        </div>

        <q-markup-table class="services q-mt-sm" flat dense bordered square separator="cell"
          wrap-cells>
          <tbody>
            <tr v-for="service in servicesToActivate" :key="service.id">
              <td>{{ service.label }}</td>
              <td>{{ service.periodDescription }}</td>
              <td>
                {{ getDevicesLabel(service.devices) }}
                <sc-tooltip v-if="service.devices.length > 5">{{ service.devices.map(d => d.name ||
                    d.serialNumber).join(', ')
                }}</sc-tooltip>
              </td>
            </tr>
          </tbody>
        </q-markup-table>

        <div v-if="canActivatePendingService && canSellServices" class="q-mt-md">
          <label class="text-body2">
            If you want to sell another service,
            you must clear the free
            {{ pluralize(servicesToActivate, 'service', 'services') }}
            from {{ devicesLabel }}.
          </label>
        </div>

        <div class="q-mt-md" v-if="isBank && canActivatePendingService">
          <div class="row items-center q-mb-sm">
            <label class="text-body2">
              Choose when the service will start:
            </label>
            <sc-hint>
              The service will start today by default.
              You can choose any date in the future or in the past.
            </sc-hint>
          </div>
          <sc-date-input :dense="false" v-model="premiumServiceStartsAt"
            :rules="[rules.required('Date is required')]">
          </sc-date-input>
        </div>

        <div class="q-mt-md" v-if="(canActivatePendingService && canTakeNotes)">
          <div class="row items-center q-mb-sm">
            <label class="text-body2">
              Add a note for audit
            </label>
            <sc-hint>
              The transaction and eventual notes will be
              registered in Premium Services / Audit.
            </sc-hint>
          </div>
          <q-input square outlined autogrow v-model="notes" type="textarea"></q-input>
        </div>

        <div v-else-if="allDevicesAreMine && !activeSubscriptions">
          {{ capitalize(selectionLabel) }}
          {{ pluralize(devices, `doesn't have`, `don't have`) }}
          any premium services yet.
        </div>
      </div>

      <!-- Sell premium services to customers -->
      <div
        v-else-if="canSellServices && hasAvailableServices && (availableTokens > 0 || canAssignServices)"
        class="q-mb-md">
        <!-- Button for showing option to sell additional services -->
        <div v-if="activeSubscriptions && !wantsToSellAdditionalServices">
          <q-btn unelevated dense no-caps label="Sell additional services"
            @click="wantsToSellAdditionalServices = true">
          </q-btn>
        </div>

        <!-- Service to sell -->
        <div v-if="!activeSubscriptions || wantsToSellAdditionalServices">
          <div class="row items-center q-mb-sm">
            <label class="text-body2">
              Choose a service:
            </label>
            <sc-hint>
              Choose a service to activate on {{ selectionLabel }} of {{ owner.name }}.
              The cost of the service will be deducted from customer's wallet.
              If customer doesn't have enough tokens, your wallet will be charged.
            </sc-hint>
          </div>

          <sc-premium-service-selector v-model="premiumServiceId" :items="sellableServices">
          </sc-premium-service-selector>
        </div>
      </div>

      <!-- Purchase premium services for yourself -->
      <div v-else-if="isBuyingService" class="q-mb-md">
        <div class="row items-center q-mb-sm">
          <label class="text-body2">
            Choose your service:
          </label>
          <sc-hint>
            Choose a service to activate on {{ selectionLabel }}
          </sc-hint>
        </div>

        <sc-premium-service-selector v-model="premiumServiceId" :items="sellableServices">
        </sc-premium-service-selector>
      </div>

      <!-- Can't buy, can't sell, tell'em to ask the reseller -->
      <div v-else-if="!activeSubscriptions" class="q-mb-md">
        Please contact your reseller to activate premium services on {{ selectionLabel }}.
      </div>

      <!-- Allow to set the date when paid service is sold -->
      <div v-if="canEditStartDate" class="q-mb-md">
        <div class="row items-center q-mb-sm">
          <label class="text-body2">
            Choose when the service will start:
          </label>
          <sc-hint>
            The service will start today by default.
            You can choose any date in the future or in the past.
          </sc-hint>
        </div>
        <sc-date-input :dense="false" v-model="premiumServiceStartsAt"
          :rules="[rules.required('Date is required')]">
        </sc-date-input>
      </div>

      <div class="q-mb-md"
        v-if="(canSellServices && isServiceSelected && canAffordSubscription && canTakeNotes)">
        <div class="row items-center q-mb-sm">
          <label class="text-body2">
            Add a note for audit
          </label>
          <sc-hint>
            The transaction and eventual notes will be registered in Premium Services / Audit.
          </sc-hint>
        </div>
        <q-input square outlined autogrow v-model="notes" type="textarea"></q-input>
      </div>

      <!-- Price and details of the sold/purchased service -->
      <div v-if="(canSellServices || isBuyingService) && isServiceSelected" class="q-mb-md">
        <span v-if="isPaidService">
          <!-- can afford service, show terms -->
          <span v-if="canAffordSubscription">
            <span v-if="canSellServices">
              {{ capitalize(selectedService.periodDescription) }}
              of <b>{{ selectedService.label }}</b> will be
              {{ selectedService.isFree ? 'given for free.' : `sold to ${owner.name}.` }}
            </span>
            <span v-else>
              You're buying {{ capitalize(selectedService.periodDescription) }}
              of <b>{{ selectedService.label }}</b>.
            </span>
            <span v-if="!selectedService.isFree">
              The price is {{ `${selectedService.priceLabel}${isBatch ? ' per device' : '.'}` }}
            </span>
            <span v-if="!selectedService.isFree && isBatch">
              ({{ totalPrice }} tokens in total).
            </span>
            <span v-if="!selectedService.isFree && availableTokensAfterPurchase != null">
              {{ isBuyingService ? ' Your wallet' : ' Wallet' }}
              balance after the purchase will be
              <b>{{ count(availableTokensAfterPurchase, 'token') }}</b>.
            </span>
          </span>

          <!-- cannot afford service, show token balance -->
          <span v-else-if="selectedService" class="text-orange-8">
            <span v-if="canSellServices">
              Premium service <b>{{ selectedService.label }}</b>
              cannot be sold to {{ owner.name }}.
            </span>
            <span v-else-if="isBuyingService">
              You cannot purchase the
              <b>{{ selectedService.label }}</b>
              service.
            </span>
            <span>
              {{ isBuyingService ? 'You' : 'They' }}
              don't have enough tokens.
              The total price is {{ totalPrice }} {{ totalPrice === 1 ? 'token' : 'tokens' }}.
            </span>
          </span>
        </span>

        <!-- note about assigned service -->
        <span v-else>
          <span v-if="selectedService.isChargedPeriodically">
            {{ capitalize(selectedService.periodDescription) }} of
          </span>
          <span>
            <b>{{ selectedService.label }}</b>
            service will be assigned to {{ devicesLabel }}
            <b class="text-orange-9">for free</b>.
            The customer will decide when to start using it.
            Once they begin using the device, the service will be
            activated automatically after
            {{ configuration.services.devices.activatePremiumServicesAfter }}
            days.
          </span>
        </span>

        <!-- Info when the subscription will start, warning that subscription is in the past / in the future -->
        <span v-if="(canAffordSubscription && !selectedService?.isFree)">
          <span v-if="subscriptionStartsBefore > 0" class="text-orange-6">
            <span v-if="subscriptionDays <= subscriptionStartsBefore">
              The service will never be available, because activation date is in the past.
            </span>
            <span v-else>
              Activation date is in the past.
              It means that {{ subscriptionStartsBefore }}
              {{ pluralize(subscriptionStartsBefore, 'day', 'days') }}
              of the subscription
              {{ pluralize(subscriptionStartsBefore, 'has been', 'have been') }}
              already used.
            </span>
          </span>
          <span v-else-if="subscriptionStartsAfter > 0" class="text-orange-6">
            The service will be activated
            in {{ subscriptionStartsAfter }}
            {{ pluralize(subscriptionStartsAfter, 'day', 'days') }}
            from now.
          </span>
          <span v-else>
            The service will be activated today.
          </span>
        </span>

      </div>

    </q-form>

    <!-- Device owner is not required to buy PS -->
    <q-form v-else ref="form" autofocus class="q-mt-md q-gutter-sm" @submit.prevent>
      <div class="q-pa-sm">
        <p>
          {{ owner.name }} is not required to buy premium services.
          They can use all available features, limited only by
          customer permissions.
        </p>
      </div>
    </q-form>

  </sc-device-action>
</template>

<style lang="scss" scoped>
.services {
  td:first-child {
    width: 250px;
    max-width: 250px;
  }
}

.active-subscriptions {
  max-height: 400px;
  overflow: auto;
  border: solid #0000001f 1px;
}
</style>
