<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { delay } from '@stellacontrol/utilities'
import { Viewport } from '@stellacontrol/client-utilities'
import ApplicationUser from './sidebar-components/application-user.vue'
import ApplicationSearch from './sidebar-components/application-search.vue'
import ApplicationMenu from './sidebar-components/application-menu.vue'
import NavigationIndicator from './navigation-indicator.vue'

export default {
  name: 'ApplicationSidebar',

  components: {
    'sc-application-user': ApplicationUser,
    'sc-application-search': ApplicationSearch,
    'sc-application-menu': ApplicationMenu,
    'sc-navigation-indicator': NavigationIndicator
  },

  data () {
    return {
      Viewport,
      isExpanding: false
    }
  },

  computed: {
    ...mapState({
      isLoadingView: state => state.inventoryView.isReloading
    }),

    ...mapGetters([
      'application',
      'branding',
      'company',
      'currentUser',
      'currentOrganization',
      'menu',
      'isCurrentRoute',
      'isRedirecting',
      'isReloading',
      'isSidebarCollapsed',
      'termsAndConditionsAccepted',
      'isLoggedIn',
      'isNavigating'
    ]),

    // Indicates whether the sidebar is visible at all:
    // * The user must be logged in
    // * No reloading or redirecting must be taking place right now
    // * User must not be in mobile mode
    isSidebarVisible () {
      return this.isLoggedIn &&
        !(this.isRedirecting || this.isReloading)
    },

    // Indicates whether items panel is visible
    areItemsVisible () {
      return this.isNavigationEnabled &&
        !this.isSidebarCollapsed &&
        !this.isExpanding &&
        this.menu &&
        this.menu.length > 0
    },

    // Checks whether user is now free to navigate through
    // the application. This might not be possible,
    // if user hasn't approved Terms and Conditions yet
    isNavigationEnabled () {
      return this.isLoggedIn &&
        (this.termsAndConditionsAccepted)
    },

    // Indicates whether loading indicator should be visible
    isLoadingIndicatorVisible () {
      return this.isLoggedIn &&
        (this.isNavigating || this.isLoadingView)
    }
  },

  methods: {
    ...mapActions([
      'toggleSidebar',
      'collapseSidebar'
    ]),

    // Toggles the sidebar,
    // taking care of proper animation timeouts
    async toggle () {
      this.isExpanding = this.isSidebarCollapsed
      this.toggleSidebar()
      if (this.isExpanding) {
        await delay(150)
        this.isExpanding = false
      }
    },

    // Collapses sidebar after user action,
    // if in mobile view
    collapse () {
      if (Viewport.isSmallScreen) {
        this.collapseSidebar({ persistent: false })
      }
    }
  }
}
</script>

<template>
  <!-- Regular application sidebar -->
  <aside class="sidebar" v-if="isSidebarVisible"
    :class="{ collapsed: isSidebarCollapsed || !isNavigationEnabled }"
    @click.stop="isSidebarCollapsed && isNavigationEnabled && toggle()">

    <section class="toolbar">

      <div class="toggle">
        <sc-navigation-indicator></sc-navigation-indicator>
        <q-icon @click.stop="toggle()" v-if="!isLoadingIndicatorVisible && isNavigationEnabled"
          :title="isSidebarCollapsed ? 'Show menu' : 'Hide menu'" name="apps" class="icon"
          size="md">
        </q-icon>

        <q-icon v-if="!isNavigationEnabled" name="privacy_tip" class="button toggle-sidebar q-mt-sm"
          size="32px">
        </q-icon>
      </div>

      <div class="search">
        <sc-application-search :enabled="isNavigationEnabled">
        </sc-application-search>
      </div>

    </section>

    <section class="user" v-if="areItemsVisible">
      <sc-application-user :enabled="isNavigationEnabled">
      </sc-application-user>
    </section>

    <section class="items" v-if="areItemsVisible">
      <div class="menu">
        <sc-application-menu :enabled="isNavigationEnabled" @selected="collapse()">
        </sc-application-menu>
      </div>

      <div class="logo row justify-center q-pb-sm" v-if="branding.logoReverse">
        <a v-if="company.website" :href="company.website" :title="`Go to ${company.name} website`">
          <img class="logo-image" :src="branding.logoReverse">
        </a>
        <img v-else class="logo-image" :src="branding.logoReverse">
      </div>
    </section>
  </aside>

  <!-- Header and expanding menu for mobile phones -->
  <aside v-else>
  </aside>

</template>

<style scoped lang="scss">
.sidebar {
  flex: 0;
  flex-basis: 260px;
  display: flex;
  flex-direction: column;
  background-color: #182354;
  transition: all ease-in-out 0.15s;
  overflow: auto;

  .toolbar {
    flex: 0;
    display: flex;
    flex-direction: row;
    background-color: #182354;
    padding: 12px 16px 0 4px;

    >.toggle {
      padding-top: 4px;

      >.icon {
        color: #b2bcf3;
        cursor: pointer;
      }
    }

    >.search {
      padding-left: 12px;
    }

    a {
      text-decoration: none;
      color: #b2bcf3;
      font-size: 16px;

      &:hover {
        color: #ffffff !important;
      }

      &.selected {
        color: #ffffff !important;
      }
    }
  }

  >.user {
    padding: 20px 4px 16px 8px;
  }

  >.items {
    flex: 1;
    padding: 0 8px 0 10px;
    background-color: #182354;
    display: flex;
    flex-direction: column;

    .menu {
      flex: 1;
    }

    .logo-image {
      max-width: 200px;
    }
  }

  &.collapsed {
    flex-basis: 40px;
  }

}


/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .sidebar {
    flex-basis: 40px;
    display: flex;
    background-color: none;

    >.toolbar {
      flex-basis: 40px;
      padding-top: 4px;

      >.toggle {
        padding-top: 0;
      }

      >.search {
        display: none;
      }
    }

    >.items {
      position: absolute;
      top: 40px;
      bottom: 0;
      padding: 8px 8px 0 8px;
      background-color: #182354;
      border-top: solid #263472 1px;
      z-index: 10000;
    }
  }
}
</style>
