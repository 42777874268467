import { RecordedPlanAction } from './recorded-action'
import { createPlanAction } from '../../actions/execute-action'

/**
 * Record of an executed action
 */
export class PlanActionHistory {
  constructor () {
  }

  __items = []
  __pointer = 0

  /**
   * Indicates whether any actions are recorded
   * @type {Boolean}
   */
  get isEmpty () {
    return this.__items.length === 0
  }

  /**
   * The number of recorded actions
   * @type {Number}
   */
  get count () {
    return this.__items.length
  }

  /**
   * Current item pointer
   * @type {Number}
   */
  get pointer () {
    return this.__pointer
  }

  /**
   * The last recorded action which can be undone
   * @type {RecordedPlanAction}
   */
  get undoableAction () {
    return this.__items[this.pointer - 1]
  }

  /**
   * The last undone action which can be redone
   * @type {RecordedPlanAction}
   */
  get redoableAction () {
    return this.__items[this.pointer]
  }

  /**
   * Clears the history
   */
  clear () {
    this.__items = []
    this.__pointer = 0
  }

  /**
   * Stores the executed action on the stack
   * @param {PlanAction|String} action Executed action or action type
   * @param {Object} parameters Action parameters
   * @param {PlanLayout} layout Plan layout
   * @param {PlanFloor} floor Floor impacted by the action
   * @param {Array[PlanItem]} items Items impacted by the action
   * @returns {RecordedPlanAction}
   * @description Pushing another action effectively moves the action pointer to the end
   * of the stack, so we can no longer perform a redo of the prior actions
   */
  push ({ action, parameters, layout, floor, items } = {}) {
    if (!action) return
    if (typeof action === 'string') {
      action = createPlanAction({ action })
    }
    if (RecordedPlanAction.canStore(action, parameters)) {
      const item = new RecordedPlanAction({ action, parameters, layout, floor, items })
      // Remove any recently undone actions from the stack
      this.__items.splice(this.pointer)
      // Store the new action on the stack
      this.__items.push(item)
      this.__pointer = this.count
      return item
    }
  }

  /**
   * Picks the last executed action from the stack.
   * @param {Boolean} remove If true, the action is removed from the stack.
   * Normally, when popping items for the purpose of undo, we don't remove the actions
   * but only move the action pointer, so that later we can still perform a redo
   * on the following actions
   * @returns {RecordedPlanAction}
   */
  pop (remove) {
    if (!this.isEmpty) {
      if (remove) {
        const item = this.__items.splice(this.__items.length - 1)
        this.__pointer = this.__items.length
        return item

      } else if (this.pointer > 0) {
        this.__pointer--
        const item = this.__items[this.pointer]
        return item
      }
    }
  }

  /**
   * Undoes the last executed action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async undo ({ renderer } = {}) {
    const data = this.pop()
    if (data) {
      const action = createPlanAction({
        action: data.action,
        layout: renderer.layout,
        floor: renderer.floor,
      })

      await action.undo({ renderer, data })
    }
  }

  /**
   * Redoes the last undone action
   * @param {PlanRenderer} renderer Plan renderer
   */
  async redo ({ renderer } = {}) {
    const data = this.__items[this.pointer]
    if (data) {
      this.__pointer++

      const action = createPlanAction({
        action: data.action,
        layout: renderer.layout,
        floor: renderer.floor,
      })

      await action.redo({ renderer, data })
    }
  }
}
