<script>
import { mapActions } from 'vuex'
import { Log } from '@stellacontrol/utilities'
import { bandsToIdentifiers, getBandMegaParameters, getDeviceFamily, DeviceFamily, BypassMode, inAttenuationGroup, AttenuationGroups } from '@stellacontrol/model'
import { getMegaParameter, getFactorySettings } from '@stellacontrol/mega'
import { Secure } from '@stellacontrol/security-ui'
import BandOnOffEditor from './editors/band-on-off.vue'
import RFSlotOnOffEditor from './editors/rf-slot-on-off.vue'
import BandAttenuationEditor from './editors/band-attenuation.vue'
import GroupAttenuationEditor from './editors/group-attenuation.vue'
import BandUplinkLevelEditor from './editors/band-uplink-level.vue'
import RebalanceTimeEditor from './editors/rebalance-time.vue'
import MessageFrequencyEditor from './editors/message-frequency.vue'
import TFTHostNameEditor from './editors/tft-hostname.vue'
import RFRegionEditor from './editors/rf-region.vue'
import RFRegionControlEditor from './editors/rf-region-control.vue'
import FanTemperatureEditor from './editors/fan-temp.vue'
import TransitModeEditor from './editors/transit-mode.vue'
import LPASEditor from './editors/lpas.vue'
import LPASLevelEditor from './editors/lpas-level.vue'
import LPASCheckBandsEditor from './editors/lpas-check-bands.vue'
import BypassControlEditor from './editors/bypass-control.vue'
import BypassLevelEditor from './editors/bypass-level.vue'
import PortSenseModeEditor from './editors/portsense-mode.vue'
import UnattendedScanIntervalEditor from './editors/unattended-scan-interval.vue'

export default {
  mixins: [
    Secure
  ],

  components: {
    'sc-band-on-off-editor': BandOnOffEditor,
    'sc-rf-slot-on-off-editor': RFSlotOnOffEditor,
    'sc-band-attenuation-editor': BandAttenuationEditor,
    'sc-group-attenuation-editor': GroupAttenuationEditor,
    'sc-band-uplink-level-editor': BandUplinkLevelEditor,
    'sc-rebalance-time-editor': RebalanceTimeEditor,
    'sc-message-frequency-editor': MessageFrequencyEditor,
    'sc-tft-hostname-editor': TFTHostNameEditor,
    'sc-rf-region-editor': RFRegionEditor,
    'sc-rf-region-control-editor': RFRegionControlEditor,
    'sc-fan-temperature-editor': FanTemperatureEditor,
    'sc-transit-mode-editor': TransitModeEditor,
    'sc-lpas-editor': LPASEditor,
    'sc-lpas-level-editor': LPASLevelEditor,
    'sc-lpas-check-bands-editor': LPASCheckBandsEditor,
    'sc-bypass-control-editor': BypassControlEditor,
    'sc-bypass-level-editor': BypassLevelEditor,
    'sc-portsense-mode-editor': PortSenseModeEditor,
    'sc-unattendedscaninterval-editor': UnattendedScanIntervalEditor
  },

  props: {
    device: {
      required: true
    },
    status: {
      required: true
    }
  },

  data () {
    return {
      BypassMode,
      AttenuationGroups
    }
  },

  computed: {
    // Checks whether the device is currently online
    isOnline () {
      return this.status?.timings?.isRealTime
    },

    // Returns true if any band settings on the device have been customized
    hasCustomBandSettings () {
      const { status, bandIdentifiers, isParameterAvailable } = this
      if (status) {
        const parameters = ['_dl_atten_group', ...bandIdentifiers.flatMap(i => ([`_shutdown_${i}`, `_mgn_dw_${i}`]))]
        return parameters
          .filter(parameter => isParameterAvailable(parameter))
          .some(parameter => status.isCustomized(parameter))
      }
    },

    // Returns true if all band values are currently at their factory settings
    hasFactoryBandSettings () {
      const { device, status, bandIdentifiers } = this
      if (device && status) {
        const parameters = ['_dl_atten_group', ...bandIdentifiers.flatMap(i => ([`_shutdown_${i}`, `_mgn_dw_${i}`]))]
        const factorySettings = getFactorySettings(device, parameters)
        return parameters.every(parameter => status.has(parameter, factorySettings[parameter]))
      }
    },

    // Returns true if any band settings on the device are not reconciled with the device
    hasUnreconciledSettings () {
      const unreconciled = this.status?.getUnreconciledParameters()
      return (unreconciled && Object.keys(unreconciled).length > 0)
    },

    // Device family
    deviceFamily () {
      const { device } = this
      return device ? getDeviceFamily(device) : DeviceFamily.EU
    },

    // Mega parameters used in this dialog
    megaParameters () {
      const { device, status } = this
      return [
        'product_bands',
        '_shutdown',
        '_mgn_dw',
        '_dl_atten_group',
        '_switchon_level',
        '_timer_long_mins',
        '_default_sampling_speed',
        '_ship_setaws',
        '_ship_auto_switchoff',
        '_ship_auto_switchoff_led_level',
        '_ship_check_bands_every',
        '_tft_hostname',
        '_rf_region',
        '_rf_region_control',
        '_fan_temp',
        '_bypass_control',
        '_bypass_level',
        '_portsense_mode',
        '_unattendedscaninterval',
        '_fw_update_pending',
        '_rf_slot_enable'
      ]
        .map(name => ({ name, parameter: getMegaParameter(name, device, status) }))
        .reduce((all, { name, parameter }) => ({ ...all, [name]: parameter }), {})
    },

    // Returns true if specified mega parameter can be edited
    // in context of the current device, organization and user.
    // Factors that can prevent availability of the parameter:
    // * lack of required permissions
    // * lack of support for the parameter in the current firmware of the selected device
    isParameterAvailable () {
      return (name, ...permissions) => {
        const key = name.replace(/_\d{2}$/, '')
        const parameter = this.megaParameters[key]
        if (parameter) {
          const editPermissions = parameter.editPermissions || permissions || []
          return (!parameter.notApplicable) && (editPermissions.length === 0 || this.canUseAll(editPermissions))
        }
      }
    },

    // Available device bands
    bands () {
      const { device } = this
      return (device ? device.bands : '') || ''
    },

    // Identifiers of the available bands
    bandIdentifiers () {
      return bandsToIdentifiers(this.bands)
    },

    // Band details
    bandDetails () {
      const { bandIdentifiers, deviceFamily, status } = this
      return bandIdentifiers.map(identifier => {
        const { label } = getBandMegaParameters(identifier, deviceFamily)
        const details = {
          identifier,
          label,
          status: status?.getBandStatus(identifier),
          details: status?.getBandsDetails(identifier)
        }
        return details
      })
    },

    // Bands belonging to the specified attenuation group
    attenuationGroupBands () {
      return group => this.bandDetails.filter(band => inAttenuationGroup(band.identifier, group))
    },

    // Returns true if current user is allowed to edit any of the band settings
    canEditBands () {
      return this.isParameterAvailable('product_bands') &&
        ((this.canUse('device-configuration-toggle-bands') && this.isParameterAvailable('_shutdown')) ||
          (this.canUse('device-configuration-attenuate-bands') && this.isParameterAvailable('_mgn_dw')) ||
          (this.canUse('device-configuration-uplink-on-level') && this.isParameterAvailable('_switchon_level')) ||
          (this.canUse('device-parameters-rf-slot-enable') && this.isParameterAvailable('_rf_slot_enable')))
    },

    // Returns true if current user is allowed to reset bands to their factory defaults
    canResetBands () {
      return !this.hasFactoryBandSettings &&
        this.isParameterAvailable('_shutdown') &&
        this.isParameterAvailable('_mgn_dw') &&
        this.isParameterAvailable('_switchon_level') &&
        this.canUseAll([
          'device-configuration-toggle-bands',
          'device-configuration-attenuate-bands',
          'device-configuration-reset-bands'
        ])
    },

    // Returns true if current user is allowed to clear customized band settings
    canClearBands () {
      return this.canUse('device-configuration-clear-shadow') && this.hasCustomBandSettings
    },

    // Returns true if current user is allowed to take over band settings from the device
    canTakeOverBands () {
      return this.canUse('device-configuration-clear-shadow') && !this.hasCustomBandSettings
    },

    // Returns true whether bands can be toggled on and off.
    // This must not be possible when device region is OFF or LPAS is on
    canToggleBands () {
      const { status: { connection, bands } = {} } = this
      if (bands && bands.isShip && bands.isAutoSwitchOff) return false
      if (connection && connection.isRFOff) return false
      return true
    },

    // Returns true if device is currently in SHIP mode
    isShip () {
      return this.status?.has('_ship_setaws', true)
    },

    // Indicates whether there are any advanced settings to edit
    hasAdvancedSettings () {
      const parameters = [
        '_timer_long_mins',
        '_unattendedscaninterval',
        '_fw_update_pending'
      ]

      return parameters.some(p => this.isParameterAvailable(p)) &&
        this.canUseAny([
          'device-configuration-rebalance',
          'device-configuration-message-frequency',
          'device-configuration-rf-region',
          'device-configuration-fan-temp',
          'device-configuration-bypass'
        ])
    }
  },

  methods: {
    ...mapActions([
      'resetDeviceBands',
      'updateDeviceSettings',
      'clearCustomDeviceSettings',
      'reconcileDeviceSettings'
    ]),

    // Checks if the specified parameter is a band parameter
    isBandParameter (name) {
      const { device, status } = this
      const parameter = getMegaParameter(name, device, status)
      return parameter?.isBandParameter
    },

    // Returns a dictionary of parameters to change.
    // When changing band parameter, other band-related parameters should be also marked as customized,
    // while retaining their current value
    getChangedParameters (name, value) {
      const { bandIdentifiers, isParameterAvailable, device, status } = this
      const parameters = { [name]: value }
      const parameter = getMegaParameter(name, device, status)

      const relatedParameters = ['_mgn_dw', '_dl_atten_group', '_shutdown']
        .filter(p => isParameterAvailable(p))
      const platformInControl = relatedParameters.includes(parameter.key)

      if (platformInControl) {
        // Add other related parameters to updated parameters,
        // so they're too marked as customized,
        // indicating that platform is now in control of the device.
        for (const key of relatedParameters.filter(p => p !== name)) {
          const parameter = getMegaParameter(key, device, status)
          if (parameter.isBandParameter) {
            for (const band of bandIdentifiers) {
              const bandParameterName = `${parameter.key}_${band}`
              if (bandParameterName !== name) {
                const value = status.getCurrent(bandParameterName)
                if (value != null) {
                  parameters[bandParameterName] = value
                }
              }
            }
          } else {
            if (parameter.name !== name) {
              const value = status.getCurrent(parameter.name)
              parameters[parameter.name] = value
            }
          }
        }
      }

      return parameters
    },

    // Clears desired shadow parameter from MEGA
    async clear ({ parameter } = {}) {
      const { isOnline } = this
      const { device } = this
      const parameters = Object.keys(this.getChangedParameters(parameter))
      await this.clearCustomDeviceSettings({
        device,
        parameters,
        peekStatus: !isOnline
      })
      Log.debug(`[${device.serialNumber}] Custom settings cleared`)
    },

    // Sets band control, either by the device or by the platform.
    // If by the device, the custom values are deleted from shadow.
    // If by the platform, the currently reported values are written as desired into the shadow.
    async setBandControl (byDevice) {
      const { device, bandIdentifiers, isParameterAvailable, isOnline } = this
      const { custom, reported } = this.status || {}
      const parametersToClear = [
        '_dl_atten_group',
        ...bandIdentifiers.flatMap(i => ([
          `_shutdown_${i}`,
          `_mgn_dw_${i}`])
        )]
      const parametersToTakeOver = parametersToClear.filter(p => isParameterAvailable(p))

      if (byDevice) {
        if (custom) {
          await this.clearCustomDeviceSettings({
            device,
            parameters: parametersToClear,
            confirmation: 'Return control of band settings from the platform back to the device?',
            peekStatus: !isOnline
          })
        }
      } else {
        if (reported) {
          const parameters = parametersToTakeOver.reduce((all, name) => {
            return {
              ...all,
              [name]: reported[name]
            }
          }, {})
          await this.updateDeviceSettings({
            device,
            parameters,
            confirmation: 'Assign control of band settings to the platform?',
            peekStatus: !isOnline
          })
        }
      }
    },

    // Changes the specified parameter's value.
    async change ({ parameter, value }) {
      const { device, isOnline } = this
      const parameters = this.getChangedParameters(parameter, value)
      await this.updateDeviceSettings({ device, parameters, peekStatus: !isOnline })
      Log.debug(`[${device.serialNumber}] Settings changed`, { parameter, value })
    },

    // Reconciles all currently unreconciled parameters
    async reconcile () {
      const { device } = this
      this.reconcileDeviceSettings({ device })
      Log.debug(`[${device.serialNumber}] Settings reconciled with the device`)
    },

    // Removes custom values from all bands
    async resetBands () {
      const { device, isOnline } = this
      await this.resetDeviceBands({ device, peekStatus: !isOnline })
    },
  }
}
</script>

<template>
  <div class="settings-container">
    <div class="column q-mb-md">
      <section class="settings band" v-if="canEditBands">
        <header class="row items-center no-wrap q-mt-sm">
          <span>Band Settings</span>
          <q-space></q-space>
          <div class="row items-center" v-if="canResetBands">
            <q-checkbox v-if="canClearBands || canTakeOverBands" size="sm"
              label="The platform controls the band settings" class="text-body2"
              :model-value="hasCustomBandSettings"
              @update:model-value="setBandControl(hasCustomBandSettings)">
              <sc-tooltip v-if="canClearBands"
                text="Uncheck to returns control of the band settings back to the device"></sc-tooltip>
              <sc-tooltip v-if="canTakeOverBands"
                text="Check to take over control of the band settings from the device"></sc-tooltip>
            </q-checkbox>
            <q-badge v-else-if="hasCustomBandSettings" color="yellow-2">
              <div class="row items-center">
                <q-icon size="20px" class="q-pa-xs q-mr-sm" name="info" color="grey-9"></q-icon>
                <span class="text-body2 text-grey-9">The platform controls the band settings</span>
              </div>
            </q-badge>
            <q-badge v-else color="grey-2">
              <div class="row items-center">
                <q-icon size="20px" class="q-pa-xs q-mr-sm" name="info" color="grey-9"></q-icon>
                <span class="text-body2 text-grey-9">Band settings are controlled by the
                  device</span>
              </div>
            </q-badge>

            <q-btn :disable="!canResetBands" no-caps unelevated dense class="q-ml-md"
              label="Reset Bands to Factory Settings" icon="clear_all" @click="resetBands()">
              <sc-tooltip>
                Reverts the modified band settings back to the factory defaults
              </sc-tooltip>
            </q-btn>

            <q-btn v-if="hasUnreconciledSettings" no-caps unelevated dense class="q-ml-md"
              label="Reconcile Settings" icon="clear_all" @click="reconcile()">
              <sc-tooltip>
                Re-sends unreconciled settings to device
              </sc-tooltip>
            </q-btn>
          </div>
        </header>
        <main>
          <q-markup-table flat bordered>
            <thead>
              <tr>
                <th class="parameter">Band / Parameter</th>
                <th class="band" v-for="band in bandDetails" :key="`band-${band.identifier}`">
                  {{ band.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="band-on-off" v-if="isParameterAvailable('_shutdown')">
                <td class="parameter-name">
                  <div class="row items-center no-wrap">
                    <span>{{ megaParameters['_shutdown'].label }}</span>
                    <q-space></q-space>
                    <sc-hint size="20px">{{ megaParameters['_shutdown'].description }}</sc-hint>
                  </div>
                </td>
                <td v-for="band in bandDetails" :key="`value-on-off-${band.identifier}`"
                  class="parameter-value band-on-off">
                  <sc-band-on-off-editor :enabled="canToggleBands" :device="device" :status="status"
                    :band="band" :parameter="`_shutdown_${band.identifier}`" @change="change"
                    @clear="clear"></sc-band-on-off-editor>
                </td>
              </tr>
              <tr class="group-attenuation" v-if="isParameterAvailable('_dl_atten_group')">
                <td class="parameter-name">
                  <div class="row items-center no-wrap">
                    <span>{{ megaParameters['_dl_atten_group'].label }}</span>
                    <q-space></q-space>
                    <sc-hint size="20px">{{ megaParameters['_dl_atten_group'].description
                      }}</sc-hint>
                  </div>
                </td>
                <template v-for="group in AttenuationGroups"
                  :key="`value-group-attenuation-${group}`">
                  <td :colspan="attenuationGroupBands(group).length"
                    class="parameter-value group-attenuation">
                    <sc-group-attenuation-editor :device="device" :status="status"
                      :bands="attenuationGroupBands(group)" parameter="_dl_atten_group"
                      :group="group" show-unit @change="change" @clear="clear">
                    </sc-group-attenuation-editor>
                  </td>
                </template>
              </tr>
              <tr class="manual-attenuation" v-if="isParameterAvailable('_mgn_dw')">
                <td class="parameter-name">
                  <div class="row items-center no-wrap">
                    <span>{{ megaParameters['_mgn_dw'].label }}</span>
                    <q-space></q-space>
                    <sc-hint size="20px">{{ megaParameters['_mgn_dw'].description }}</sc-hint>
                  </div>
                </td>
                <td v-for="band in bandDetails" :key="`value-manual-attenuation-${band.identifier}`"
                  class="parameter-value">
                  <sc-band-attenuation-editor :device="device" :status="status" :band="band"
                    :parameter="`_mgn_dw_${band.identifier}`" show-unit @change="change"
                    @clear="clear">
                  </sc-band-attenuation-editor>
                </td>
              </tr>
              <tr class="uplink-on-level" v-if="isParameterAvailable('_switchon_level')">
                <td class="parameter-name">
                  <div class="row items-center no-wrap">
                    <span>{{ megaParameters['_switchon_level'].label }}</span>
                    <q-space></q-space>
                  </div>
                </td>
                <td v-for="band in bandDetails" :key="`value-uplink-level-${band.identifier}`"
                  class="parameter-value">
                  <sc-band-uplink-level-editor :device="device" :status="status" :band="band"
                    :parameter="`_switchon_level_${band.identifier}`" @change="change"
                    @clear="clear" />
                </td>
              </tr>
              <tr class="rf-slot-enable" v-if="isParameterAvailable('_rf_slot_enable')">
                <td class="parameter-name">
                  <div class="row items-center no-wrap">
                    <span>{{ megaParameters['_rf_slot_enable'].label }}</span>
                    <q-space></q-space>
                  </div>
                </td>
                <td v-for="band in bandDetails" :key="`rf-slot-enable-${band.identifier}`"
                  class="parameter-value">
                  <sc-rf-slot-on-off-editor :device="device" :status="status" :band="band"
                    parameter="_rf_slot_enable" @change="change" @clear="clear">
                  </sc-rf-slot-on-off-editor>
                </td>
              </tr>
            </tbody>
          </q-markup-table>
        </main>
      </section>
    </div>

    <div class="row q-mb-md">
      <div class="col-6 column q-pr-md" v-if="hasAdvancedSettings">
        <!-- ADVANCED SETTINGS -->
        <section class="settings advanced">
          <header>Advanced Settings</header>
          <main>
            <q-markup-table flat bordered>
              <tbody>
                <tr class="rebalance-time" v-if="isParameterAvailable('_timer_long_mins')">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_timer_long_mins'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_timer_long_mins'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-rebalance-time-editor :device="device" :status="status"
                      parameter="_timer_long_mins" @change="change" @clear="clear">
                    </sc-rebalance-time-editor>
                  </td>
                </tr>

                <tr class="message-frequency"
                  v-if="isParameterAvailable('_default_sampling_speed')">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_default_sampling_speed'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_default_sampling_speed'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-message-frequency-editor :device="device" :status="status"
                      parameter="_default_sampling_speed" @change="change" @clear="clear">
                    </sc-message-frequency-editor>
                  </td>
                </tr>

                <tr class="tft-hostname" v-if="isParameterAvailable('_tft_hostname')">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_tft_hostname'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_tft_hostname'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-tft-hostname-editor :device="device" :status="status"
                      parameter="_tft_hostname" @change="change" @clear="clear">
                    </sc-tft-hostname-editor>
                  </td>
                </tr>

                <tr class="rf-region" v-if="isParameterAvailable('_rf_region')">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_rf_region'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_rf_region'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-rf-region-editor :device="device" :status="status" parameter="_rf_region"
                      @change="change" @clear="clear">
                    </sc-rf-region-editor>
                  </td>
                </tr>

                <tr class="rf-region-control" v-if="isParameterAvailable('_rf_region_control')">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_rf_region_control'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_rf_region_control'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-rf-region-control-editor :device="device" :status="status"
                      parameter="_rf_region_control" @change="change" @clear="clear">
                    </sc-rf-region-control-editor>
                  </td>
                </tr>

                <tr class="fan-temp" v-if="isParameterAvailable('_fan_temp')">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_fan_temp'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_fan_temp'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-fan-temperature-editor :device="device" :status="status"
                      parameter="_fan_temp" @change="change" @clear="clear">
                    </sc-fan-temperature-editor>
                  </td>
                </tr>

                <tr class="bypass" v-if="isParameterAvailable('_bypass_control')">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>{{ megaParameters['_bypass_control'].label }}</span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_bypass_control'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-bypass-control-editor :device="device" :status="status"
                      parameter="_bypass_control" @change="change" @clear="clear">
                    </sc-bypass-control-editor>
                  </td>
                </tr>

                <tr class="bypass"
                  v-if="isParameterAvailable('_bypass_level') && status?.has('_bypass_control', BypassMode.Auto)">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_bypass_level'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_bypass_level'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-bypass-level-editor :device="device" :status="status"
                      parameter="_bypass_level" @change="change" @clear="clear">
                    </sc-bypass-level-editor>
                  </td>
                </tr>

                <tr class="unattendedscaninterval"
                  v-if="isParameterAvailable('_unattendedscaninterval')">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>{{ megaParameters['_unattendedscaninterval'].label }}</span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_unattendedscaninterval'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-unattendedscaninterval-editor :device="device" :status="status"
                      parameter="_unattendedscaninterval" @change="change" @clear="clear">
                    </sc-unattendedscaninterval-editor>
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
          </main>
        </section>
      </div>

      <div class="col-6 column">
        <!-- SHIP SETTINGS -->
        <section class="settings vehicle-mode q-mb-md" v-if="isParameterAvailable('_ship_setaws')">
          <header>
            Device Mode
          </header>
          <main>
            <q-markup-table flat bordered>
              <tbody>
                <tr class="vehicle-mode">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_ship_setaws'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_ship_setaws'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-transit-mode-editor :device="device" :status="status"
                      parameter="_ship_setaws" @change="change" @clear="clear">
                    </sc-transit-mode-editor>
                  </td>
                </tr>

                <tr class="lpas" v-if="isParameterAvailable('_ship_auto_switchoff') && isShip">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_ship_auto_switchoff'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_ship_auto_switchoff'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-lpas-editor :device="device" :status="status"
                      parameter="_ship_auto_switchoff" @change="change" @clear="clear">
                    </sc-lpas-editor>
                  </td>
                </tr>

                <tr class="lpas-level"
                  v-if="isParameterAvailable('_ship_auto_switchoff_led_level') && isShip">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_ship_auto_switchoff_led_level'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_ship_auto_switchoff_led_level'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-lpas-level-editor :device="device" :status="status"
                      parameter="_ship_auto_switchoff_led_level" @change="change" @clear="clear">
                    </sc-lpas-level-editor>
                  </td>
                </tr>

                <tr class="check-bands"
                  v-if="isParameterAvailable('_ship_check_bands_every') && isShip">
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>
                        {{ megaParameters['_ship_check_bands_every'].label }}
                      </span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_ship_check_bands_every'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-lpas-check-bands-editor :device="device" :status="status"
                      parameter="_ship_check_bands_every" @change="change" @clear="clear">
                    </sc-lpas-check-bands-editor>
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
          </main>
        </section>

        <!-- PORT SENSE SETTINGS -->
        <section class="settings portsense" v-if="isParameterAvailable('_portsense_mode')">
          <header>
            PortSense Mode
          </header>
          <main>
            <q-markup-table flat bordered>
              <tbody>
                <tr>
                  <td class="parameter-name">
                    <div class="row items-center no-wrap">
                      <span>{{ megaParameters['_portsense_mode'].label }}</span>
                      <q-space></q-space>
                      <sc-hint size="20px">
                        {{ megaParameters['_portsense_mode'].description }}
                      </sc-hint>
                    </div>
                  </td>
                  <td class="parameter-value">
                    <sc-portsense-mode-editor :device="device" :status="status"
                      parameter="_portsense_mode" @change="change" @clear="clear">
                    </sc-portsense-mode-editor>
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
          </main>
        </section>
      </div>
    </div>

    <div class="column">
      <!-- HINTS -->
      <section class="hints">
        <header>Information</header>
        <main>
          <ul>
            <li>To modify settings, click on the values and select.</li>
            <li>Modified settings are indicated with yellow background.</li>
            <li>Settings which are not yet received by the device will display both the current and
              the requested value.</li>
          </ul>
        </main>
      </section>

    </div>
  </div>

</template>


<style scoped lang="scss">
.settings-container {
  padding: 16px;
  padding-top: 0;

  section {
    header {
      font-size: 17px;
      margin-bottom: 8px;
    }

    &.settings {
      th {
        background-color: #f0f0f0;
        text-align: center;
        padding-left: 0;
        padding-right: 0;

        &.parameter {
          border-right-width: 1px;
          border-color: #0000001f;
        }

        &.band {
          border-right-width: 1px;
          border-color: #0000001f;

          &:last-child {
            border-right: none;
          }
        }
      }

      tr {
        td {
          &.parameter-name {
            background-color: #f0f0f0;
            border-right-width: 1px;
            border-color: #0000001f;
            max-width: 100px;
            white-space: nowrap;
          }

          &.parameter-value {
            border-right-width: 1px;
            border-color: #0000001f;
            padding: 0;

            &:last-child {
              border-right: none;
            }

            .parameter-editor {
              height: 100%;
              display: flex;
              flex: 1;
              cursor: pointer;
            }
          }
        }

        &.manual-attenuation,
        &.band-on-off,
        &.uplink-on-level {
          td.parameter-value {
            width: 135px;
            max-width: 135px;
          }
        }
      }


      &.advanced {
        td.parameter-value {
          width: 240px;
          max-width: 240px;
        }
      }

      &.vehicle-mode {
        td.parameter-name {
          width: 230px;
          min-width: 230px;
        }
      }

      &.portsense {
        td.parameter-name {
          width: 230px;
          min-width: 230px;
        }
      }
    }

    &.hints {
      ul {
        margin-left: 0;
        margin-top: 0;
        padding-left: 16px;

        li {
          color: #313131;
          line-height: 1.5em;
        }
      }
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .settings-container {
    padding: 8px;
    padding-top: 0;
  }
}
</style>