/**
 * v-focus directive making elements moveable.
 * The element must be absolutely positioned using CSS.
 * @description
 * HANDLE ELEMENT
 *
 *    Value properties:
 *      handle    By default, the entire moveable element serves as grab handle.
 *                Specify here CSS selector of an alternative element which should serve as handle.
 *                for example `v-moveable="{ handle: 'header' }"`.
 *                The handle element will be sought first inside the moved element, then outside in the entire document.
 *      onMoving  Callback to call as the element is being moved
 *      onMoved   Callback to call once the element has been moved
 *
 * ANCHOR
 *    By default the moved element is anchored to `left` `bottom` position.
 *    You can change this by using modifiers `left` or `right` for `X` coordinate
 *    and `top` or `bottom` for `Y` coordinate. For example, `v-moveable.right.bottom`
 *    keeps the moved element attached to the right-bottom corner.
 */
export const MoveableDirective = {
  attribute: 'moveable',

  directive: {
    mounted: (element, binding) => {
      const { handle, onMoving, onMoved } = binding.value
      const handleElement = handle
        ? element.querySelector(handle) || document.querySelector(handle) || element
        : element

      let elementMoved = false
      let previousCursor, previousUserSelect
      let start = { x: null, y: null, left: null, right: null, top: null, bottom: null }

      // Returns CSS coordinates of the dragged element
      function getElementPosition () {
        const { left, right, top, bottom } = window.getComputedStyle(element)
        const position = {
          left: parseInt(left),
          right: parseInt(right),
          top: parseInt(top),
          bottom: parseInt(bottom)
        }

        for (const [key, value] of Object.entries(position)) {
          if (isNaN(value)) {
            delete position[key]
          }
        }

        return position
      }

      // Event handler for mousemove event reported by the dragged handle
      const moveHandler = ({ movementX: dx, movementY: dy, clientX: x, clientY: y }) => {
        // Calculate movement delta

        // Use `move` cursor and prevent marking text as selected
        document.body.style.cursor = 'move'
        element.style['user-select'] = 'none'

        const delta = {
          x: x - start.x,
          y: y - start.y
        }

        const toRight = binding.modifiers.right
        const toBottom = binding.modifiers.bottom

        if (Math.abs(dx) > 0) {
          if (toRight) {
            elementMoved = true
            element.style.right = `${start.right - delta.x}px`
          } else {
            element.style.left = `${start.left + delta.x}px`
            elementMoved = true
          }
        }

        if (Math.abs(dy) > 0) {
          if (toBottom) {
            element.style.bottom = `${start.bottom - delta.y}px`
            elementMoved = true
          } else {
            element.style.top = `${start.top + delta.y}px`
            elementMoved = true
          }
        }

        if (onMoving) {
          const position = getElementPosition()
          onMoving.call(element, { ...position })
        }
      }

      // Event handler for mouseup event reported by the dragged handle
      const movedHandler = (e) => {
        // Restore pointer style and user selection style
        document.body.style.cursor = previousCursor
        element.style['user-select'] = previousUserSelect

        // Unhook mouse event handlers
        window.removeEventListener('mousemove', moveHandler)
        window.removeEventListener('mouseup', movedHandler)
        window.removeEventListener('click', movedHandler)

        if (elementMoved) {
          e.stopPropagation()
          e.preventDefault()
          elementMoved = false

          if (onMoved) {
            const position = getElementPosition()
            onMoved.call(element, { ...position })
          }
        }
      }


      // Start dragging on mouse down
      handleElement.addEventListener('mousedown', ({ clientX: x, clientY: y }) => {
        // Store the initial position of the element
        // and the position of the pointer at the start of the movement
        const { left, right, top, bottom } = getElementPosition()
        Object.assign(start, { x, y, left, right, top, bottom })

        // Preserve pointer style and user selection style
        previousCursor = document.body.style.cursor
        previousUserSelect = element.style['user-select']

        // Watch the element being dragged and released
        window.addEventListener('mousemove', moveHandler)
        window.addEventListener('mouseup', movedHandler)
        window.addEventListener('click', movedHandler)
      })

    }
  }
}
