<script>
import { mapState, mapActions } from 'vuex'
import { parseIntList, createList } from '@stellacontrol/utilities'
import { Notification } from '@stellacontrol/client-utilities'
import { getMegaParameter, getMegaValue, getMegaBandValue } from '@stellacontrol/mega'
import { DeviceType, getDeviceFamily, getBandMegaParameters, getDeviceBandIdentifiers, DeviceBandIdentifiers } from '@stellacontrol/model'
import DeviceAction from './device-action.vue'
import { DeviceActionMixin } from './device-action-mixin'

export default {
  mixins: [
    DeviceActionMixin
  ],

  components: {
    'sc-device-action': DeviceAction
  },

  data () {
    return {
      // Parameters which can be edited for the selected devices
      parameters: [],
      // Parameter selected for editing
      selectedParameterName: null,
      // Parameter item to modify, if array parameter has been selected
      selectedItem: null,
      // Band to modify, if band parameter has been selected
      selectedBand: null,
      // New parameter value
      parameterValue: null,
      // Loading message
      loadingMessage: null,
      // Indicates that devices are being updated
      isBusy: false,
      // The progress of updating of device status,
      // list of { serialNumber, isBusy, error, message } records
      progress: []
    }
  },

  computed: {
    ...mapState({
      // Recent status of devices
      deviceStatus: state => state.deviceStatus.devices
    }),

    // Checks whether the devices are currently online.
    isOnline () {
      const { devices, deviceStatus } = this
      return devices.every(d => deviceStatus ? deviceStatus[d.serialNumber]?.timings?.isRealTime : false)
    },

    // List of updatable devices
    updatableDevices () {
      return this.devices.filter(d => d.isConnectedDevice)
    },

    // MEGA parameter selected for editing
    selectedParameter () {
      return this.parameters.find(p => p.name === this.selectedParameterName)
    },

    // Label of the MEGA parameter selected for editing
    selectedParameterLabel () {
      return this.selectedParameter?.label
    },

    // Detailed label describing the edited MEGA parameter, item and band
    selectedParameterDetails () {
      if (this.selectedParameter) {
        const { selectedParameter: { label }, selectedBandDetails, isBandParameter, selectedItemDetails, isArrayParameter } = this
        if (isBandParameter && selectedBandDetails) {
          return `${label}: ${selectedBandDetails.label}`
        }
        if (isArrayParameter && selectedItemDetails) {
          return `${label}: ${selectedItemDetails.label}`
        }
        return label
      }
    },

    // Indicates whether the selected MEGA parameter is configured per band
    isBandParameter () {
      const { selectedParameter } = this
      return selectedParameter?.isBandParameter
    },

    // Device bands
    bands () {
      const { device, isBandParameter, isBatch } = this
      const bands = isBatch ? DeviceBandIdentifiers : getDeviceBandIdentifiers(device)
      const family = getDeviceFamily(device)
      return isBandParameter
        ? bands.map(id => ({ id, ...getBandMegaParameters(id, family) }))
        : []
    },

    // Details of the selected device band
    selectedBandDetails () {
      const { bands, isBandParameter, selectedBand } = this
      return isBandParameter && selectedBand != null
        ? bands.find(band => band.id === selectedBand)
        : undefined
    },

    // Indicates whether the selected MEGA parameter is configured per band
    isArrayParameter () {
      const { selectedParameter } = this
      return selectedParameter?.isArray
    },

    // Array parameter items
    parameterItems () {
      const { selectedParameter, isArrayParameter } = this
      return isArrayParameter
        ? selectedParameter.itemLabels.map((label, index) => ({ label, index }))
        : undefined
    },

    // Details of the selected parameter item
    selectedItemDetails () {
      const { parameterItems, isArrayParameter, selectedItem } = this
      return isArrayParameter && selectedItem != null
        ? parameterItems.find(item => item.index === selectedItem)
        : undefined
    },

    // Values of the selected parameter for all devices
    reportedValuesOf () {
      const { devices, deviceStatus } = this
      return name => devices
        .map(device => deviceStatus[device.serialNumber])
        .map(status => status?.getReported(name))
        .filter(value => value != null)
    },

    // Values of the selected parameter for all devices
    parameterValues () {
      const { updatableDevices: devices, deviceStatus, selectedParameterName: name, isBandParameter } = this
      if (devices && name && deviceStatus) {
        return devices.map(device => {
          const { id, serialNumber } = device
          const status = deviceStatus[serialNumber] || { reported: {}, custom: {} }
          let reported, custom

          if (isBandParameter) {
            reported = {}
            custom = {}

            for (const band of DeviceBandIdentifiers) {
              const bandParameter = `${name}_${band}`
              const hasReported = status.isReported(bandParameter)
              const hasCustom = status.isCustomized(bandParameter)
              reported[band] = hasReported ? getMegaBandValue(name, band, status.reported, device, status) : {}
              custom[band] = hasCustom ? getMegaBandValue(name, band, status.custom, device, status) : {}
            }

          } else {
            const hasReported = status.isReported(name)
            const hasCustom = status.isCustomized(name)
            reported = hasReported ? getMegaValue(name, status.reported, device, status) : null
            custom = hasCustom ? getMegaValue(name, status.custom, device, status) : null
          }

          return { id, serialNumber, reported, custom }
        })

      } else {
        return []
      }
    },

    // Returns currently reported and customized status
    // of the selected parameter of a specified device
    parameterValuesOnDevice () {
      return (device) => {
        let { reported, custom } = this.parameterValues.find(v => v.serialNumber === device.serialNumber) || {}
        return { reported, custom }
      }
    },

    // Color representing the status of selected device
    statusColor () {
      return (device) => {
        const deviceStatus = this.deviceStatus[device.serialNumber]
        return deviceStatus?.connection?.color || '#a0a0a0'
      }
    },

    // Parameters to submit
    editedParameters () {
      const { selectedParameter, selectedParameterName, isBandParameter, selectedBand, parameterValue, isArrayParameter, selectedItem } = this
      const parameters = {}

      if (selectedParameter) {
        const value = selectedParameter.isNumber && !selectedParameter.deviceValues
          ? parseFloat(parameterValue)
          : parameterValue

        if (isArrayParameter) {
          if (selectedItem != null) {
            parameters[selectedParameterName] = value
          }
        } else if (isBandParameter) {
          if (selectedBand != null) {
            parameters[`${selectedParameterName}_${selectedBand}`] = value
          }
        } else {
          parameters[selectedParameterName] = value
        }
      }

      return parameters
    },

    // Parameters to submit to a specific device.
    // When changing band parameter, all other parameters should be also marked as customized,
    // while retaining their current value
    editedDeviceParameters () {
      return device => {
        const parameters = { ...(this.editedParameters || {}) }
        const { deviceStatus, selectedParameter, selectedParameterName, isBandParameter, selectedBand, bands, isArrayParameter, selectedItem, parameterItems } = this
        const { custom = {}, reported = {} } = this.parameterValuesOnDevice(device)
        const status = deviceStatus[device.serialNumber] || { reported: {}, custom: {} }

        if (selectedParameter) {
          if (isArrayParameter) {
            const values = parseIntList(status.custom[selectedParameterName] || status.reported[selectedParameterName]) || []

            for (let i = 0; i < parameterItems.length; i++) {
              if (i === selectedItem) {
                values[i] = parameters[selectedParameterName]
              } else if (values[i] == null) {
                values[i] = selectedParameter.defaultValue == null ? '' : selectedParameter.defaultValue
              }
            }
            parameters[selectedParameterName] = createList(values)

          } else if (isBandParameter) {
            for (const { id } of bands) {
              const key = `${selectedParameterName}_${id}`
              const currentValue = custom[id]?.value || reported[id]?.value
              if (id !== selectedBand && currentValue != null) {
                parameters[key] = currentValue
              }
            }
          }
        }

        // If Manual Attenuation is changed, also mark Band On/Off as customized
        if (selectedParameterName === '_mgn_dw') {
          for (const { id } of bands) {
            const key = `_shutdown_${id}`
            const currentValue = status?.custom[key] || status?.reported[key]
            parameters[key] = currentValue
          }
        }

        return parameters
      }
    },

    // Returns true if the specified parameter value has been customized
    isValueCustomized () {
      return (device, band) => {
        const progress = this.progressOf(device.serialNumber)
        if (progress.isBusy) return true
        let { reported, custom } = this.parameterValuesOnDevice(device)
        if (band && reported) reported = reported[band]
        if (band && custom) custom = custom[band]
        return custom && custom.value != null && reported && reported.value == custom.value
      }
    },

    // Returns true if the specified parameter value has not been reconciled with the device yet
    isValueInconsistent () {
      return (device, band) => {
        const progress = this.progressOf(device.serialNumber)
        if (progress.isBusy) return false
        let { reported, custom } = this.parameterValuesOnDevice(device)
        if (band && reported) reported = reported[band]
        if (band && custom) custom = custom[band]
        return custom && custom.value != null && (!reported || (reported.value != custom.value))
      }
    },

    // Returns description of the parameter value,
    // indicating whether it's been customized and reconciled with device
    valueDescription () {
      return (device, band) => {
        let { isArrayParameter, parameterItems } = this
        let { reported, custom } = this.parameterValuesOnDevice(device)
        if (band && reported) reported = reported[band]
        if (band && custom) custom = custom[band]

        let reportedText, customText
        if (isArrayParameter) {
          const reportedValues = parseIntList(reported?.value) || []
          const customValues = parseIntList(custom?.value) || []
          reportedText = reported
            ? reportedValues.map((value, index) => `${parameterItems[index].label}: ${value || 0}`).join(', ')
            : null
          customText = custom
            ? customValues.map((value, index) => `${parameterItems[index].label}: ${value || 0}`).join(', ')
            : null
        } else {
          reportedText = reported ? reported.label : null
          customText = custom ? custom.label : null
        }

        if (reportedText === customText) {
          return reportedText == null ? '-' : reportedText
        } else {
          if (reportedText == null) {
            return `${customText} → NONE`
          } else if (customText == null) {
            return reportedText
          } else {
            return `${customText} → ${reportedText}`
          }
        }
      }
    },

    // Returns CSS class representing the specified value
    valueClass () {
      return (device, band) => {
        return {
          customized: this.isValueCustomized(device, band),
          inconsistent: this.isValueInconsistent(device, band)
        }
      }
    },

    // Returns true if edited parameter has a discrete set of allowed values
    isSelectEditor () {
      const { selectedParameter } = this
      if (selectedParameter) {
        return (selectedParameter.values || []).length > 0
      }
    },

    // Returns true if edited parameter is a true/false selection
    isBooleanEditor () {
      const { selectedParameter } = this
      if (selectedParameter) {
        return selectedParameter.type === 'boolean'
      }
    },

    // Returns true if edited parameter is a numeric value
    isNumberEditor () {
      const { selectedParameter, isSelectEditor } = this
      if (selectedParameter && !isSelectEditor) {
        return selectedParameter.type === 'number'
      }
    },

    // Returns true if edited parameter is an array of values
    isArrayEditor () {
      const { selectedParameter, isSelectEditor } = this
      if (selectedParameter && !isSelectEditor) {
        return selectedParameter.type === 'array'
      }
    },

    // Minimal allowed value for numeric parameter
    minParameterValue () {
      const { selectedParameter, isNumberEditor } = this
      if (selectedParameter && isNumberEditor) {
        return selectedParameter.min == null ? 0 : selectedParameter.min
      }
    },

    // Maximal allowed value for numeric parameter
    maxParameterValue () {
      const { selectedParameter, isNumberEditor } = this
      if (selectedParameter && isNumberEditor) {
        return selectedParameter.max == null ? 100000 : selectedParameter.max
      }
    },

    // Returns a list of options for select editor
    selectOptions () {
      const { isSelectEditor, selectedParameter, isBooleanEditor } = this
      if (isSelectEditor) {
        const { labels = [], unit } = selectedParameter
        let values = selectedParameter.deviceValues || selectedParameter.selectableValues || selectedParameter.values || []
        let options
        if (values.length === 0 && isBooleanEditor) {
          options = [{ value: true, label: 'ON' }, { value: false, label: 'OFF' }]

        } else {
          options = values.map((value, index) => ({
            value,
            label: labels[index] || `${value.toString()} ${unit || ''}`
          }))
        }

        return options
      }
    },

    // Indicates whether save action can be executed
    canExecute () {
      const { selectedParameter, isBandParameter, selectedBand, parameterValue, isBusy } = this
      return selectedParameter && parameterValue != null && (!isBandParameter || selectedBand != null) && !isBusy
    },

    // Indicates whether clear action can be executed
    canReset () {
      const { selectedParameter } = this
      return selectedParameter != null
    },

    // Finds progress of the specified device
    progressOf () {
      return serialNumber => this.progress.find(p => p.serialNumber === serialNumber) || {}
    },

    // Indicates that preferences are currently being assigned to the specified device
    isDeviceBusy () {
      return serialNumber => this.progressOf(serialNumber).isBusy == true
    },

    // Indicates that preferences of the specified devices have been assigned
    hasDeviceSucceeded () {
      return serialNumber => this.progressOf(serialNumber).isBusy == false && this.progressOf(serialNumber).error == null
    },

    // Indicates that preferences of the specified device have not been assigned
    hasDeviceFailed () {
      return serialNumber => this.progressOf(serialNumber).isBusy == false && this.progressOf(serialNumber).error != null
    }
  },

  watch: {
    // Refresh on device selection change
    devices () {
      this.populate()
    },

    // Clear edited value when MEGA parameter selected
    selectedParameter (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.parameterValue = null
        this.selectedBand = null
        this.selectedItem = null
        this.progress = []
      }
    }
  },

  methods: {
    ...mapActions([
      'updateDeviceSettings',
      'clearCustomDeviceSettings',
      'getLiveStatus',
      'watchDeviceStatus',
      'unwatchDeviceStatus'
    ]),

    // Populates the dialog
    async populate () {
      const { devices } = this
      await this.getLiveStatus({ devices })
      await this.watchStatus()
      await this.populateParameters()
    },

    // Editable mega parameters
    async populateParameters () {
      const parameters = [
        '_shutdown',
        '_dl_atten_group',
        '_mgn_dw',
        '_switchon_level',
        '_timer_long_mins',
        '_default_sampling_speed',
        '_ship_setaws',
        '_ship_auto_switchoff',
        '_ship_auto_switchoff_led_level',
        '_ship_check_bands_every',
        '_tft_hostname',
        '_rf_region',
        '_rf_region_control',
        '_fan_temp',
        '_bypass_control',
        '_bypass_level',
        '_portsense_mode'
      ]

      const shipModeParameters = [
        '_ship_auto_switchoff',
        '_ship_auto_switchoff_led_level',
        '_ship_check_bands_every',
      ]

      const { updatableDevices, reportedValuesOf } = this
      const anyShipMode = reportedValuesOf('_ship_setaws').some(value => value == true)
      const anyLandMode = reportedValuesOf('_ship_setaws').some(value => value == false)
      const anyCombiner = updatableDevices.some(device => device.type === DeviceType.Combiner)
      const device = updatableDevices.length === 1 ? updatableDevices[0] : undefined

      const allowedParameters = parameters
        .map(name => getMegaParameter(name, device))
        .filter(parameter => device ? parameter.isApplicable : true)
        .filter(parameter => this.canUseAll(parameter.editPermissions || []))
        .filter(parameter => {
          // Don't allow editing manual attenuation when ship mode is on, or combiner devices
          if (parameter.name === '_mgn_dw' && (anyShipMode || anyCombiner)) {
            return false
          }
          // Don't allow editing LPAS-related parameters if any device is in land mode
          if (shipModeParameters.includes(parameter.name) && anyLandMode) {
            return false
          }
          return true
        })

      this.parameters = allowedParameters
    },

    // Starts watching device status updates
    async watchStatus () {
      const { devices } = this
      if (devices?.length > 0) {
        await this.watchDeviceStatus({
          name: 'device-configuration',
          devices,
          fastSampling: true
        })
      }
    },

    // Stops watching device status updates
    async unwatchStatus () {
      this.unwatchDeviceStatus({ name: 'device-configuration' })
    },

    // Assigns the new value to all selected devices
    async execute () {
      const { updatableDevices: devices, canExecute, isOnline } = this
      if (await this.validate() && canExecute) {

        this.progress = []
        this.isBusy = true

        try {
          for (const device of devices) {
            const { serialNumber } = device
            const parameters = this.editedDeviceParameters(device)
            const deviceProgress = { serialNumber, isBusy: true, error: null, message: null }
            this.progress.push(deviceProgress)
            const { error } = await this.updateDeviceSettings({
              device,
              parameters,
              silent: true,
              peekStatus: !isOnline
            })
            deviceProgress.error = error
            deviceProgress.message = error ? error.message : null
            deviceProgress.isBusy = false
          }
        } catch (error) {
          Notification.error({ message: `Error while configuring ${this.devicesLabel}`, details: error.message })
        } finally {
          this.isBusy = false
        }
      }
    },

    // Clears the custom value on all selected devices
    async clear () {
      const { updatableDevices: devices, canReset, isOnline } = this
      if (canReset) {
        this.isBusy = true
        this.progress = []

        try {
          for (const device of devices) {
            const { serialNumber } = device
            const parameters = Object.keys(this.editedDeviceParameters(device))
            const deviceProgress = { serialNumber, isBusy: true, error: null, message: null }
            this.progress.push(deviceProgress)
            const { error } = await this.clearCustomDeviceSettings({
              device,
              parameters,
              silent: true,
              peekStatus: !isOnline
            })
            deviceProgress.error = error
            deviceProgress.message = error ? error.message : null
            deviceProgress.isBusy = false
          }
        } catch (error) {
          Notification.error({ message: `Error while configuring ${this.devicesLabel}`, details: error.message })
        } finally {
          this.isBusy = false
        }
      }
    },

    // Closes configuration dialog
    closeConfiguration () {
      this.unwatchStatus()
      this.close()
    }
  },

  async created () {
    await this.populate()
  },

  // Stop listening to device status when component is destroyed
  async beforeUnmount () {
    this.unwatchStatus()
  }
}
</script>

<template>
  <sc-device-action :isLoading="isLoading" :loadingMessage="loadingMessage" :action="action"
    :devices="devices" execute-label="Confirm" close-label="Close"
    :execute-tooltip="selectedParameter ? `Assigns the new value to ${selectedParameterLabel} on ${selectionLabel}` : ''"
    :can-execute="canExecute" reset-label="Clear"
    :reset-tooltip="selectedParameter ? `Returns control of ${selectedParameterLabel} back to ${selectionLabel}` : ''"
    :can-reset="canReset" @closing="closing" @close="closeConfiguration" @execute="execute"
    @reset="clear">

    <q-form ref="form" autofocus class="q-mt-md q-gutter-sm" @submit.prevent>
      <div>
        <label class="text-body2 text-grey-9">Select a device parameter to edit:</label>
      </div>

      <div>
        <q-select square outlined class="q-mt-sm" label="Device Parameter"
          v-model="selectedParameterName" :options="parameters" emit-value map-options
          option-value="name" option-label="label"></q-select>
      </div>

      <div v-if="selectedParameter && parameterValues.length > 0">
        <q-markup-table class="values" flat bordered square separator="cell" :dense="isBandParameter">
          <thead>
            <tr>
              <th>Device</th>
              <th v-if="!isBandParameter">
                <span class="q-mr-sm">{{ selectedParameter.label }}</span>
                <sc-hint size="20px" v-if="selectedParameter.description"
                  :text="selectedParameter.description"></sc-hint>
              </th>
              <th v-for="band in bands">{{ band.label }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="device in updatableDevices" :key="device.serialNumber">
              <td class="device">
                <div class="row justify-between">
                  <div class="row items-center">
                    <q-icon size="16px" name="fiber_manual_record"
                      :style="{ color: statusColor(device) }"></q-icon>
                    <span class="q-ml-sm">{{ device.acronym }} {{ device.serialNumber }}</span>
                  </div>
                  <q-icon size="20px" v-if="isDeviceBusy(device.serialNumber)" name="change_circle"
                    color="orange-5" class="rotate-reverse"></q-icon>
                  <q-icon size="20px" v-if="hasDeviceSucceeded(device.serialNumber)"
                    name="check_circle" color="green-6"></q-icon>
                  <q-icon size="20px" v-if="hasDeviceFailed(device.serialNumber)" name="error"
                    color="red-6">
                    <sc-tooltip :text="progressOf(device.serialNumber).message"></sc-tooltip>
                  </q-icon>
                </div>
              </td>
              <td :class="valueClass(device)" v-if="!isBandParameter">{{ valueDescription(device) }}
              </td>
              <td v-for="band in bands"
                :class="{ band: true, selected: band.id === selectedBand, ...valueClass(device, band.id) }"
                @click="selectedBand = band.id">
                {{ valueDescription(device, band.id) }}
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>

      <div v-if="selectedParameter && parameterValues.length > 0" class="q-mt-md">
        <q-select v-if="isBandParameter" square outlined class="q-mt-sm"
          label="Select the band to modify" :options="bands" v-model="selectedBand" emit-value
          map-options option-value="id" option-label="label"></q-select>
        <q-select v-if="isArrayParameter" square outlined class="q-mt-sm" v-model="selectedItem"
          :label="`${selectedParameter.label} for`" :options="parameterItems" emit-value map-options
          option-value="index" option-label="label">
        </q-select>
        <q-select v-if="isSelectEditor" square outlined class="q-mt-sm"
          :label="`New value for ${selectedParameterDetails}`" :options="selectOptions"
          v-model="parameterValue" emit-value map-options option-value="value"
          option-label="label"></q-select>
        <q-input v-if="isNumberEditor" square outlined type="number" :min="minParameterValue"
          :max="maxParameterValue" v-model="parameterValue"
          :label="`New value for [${selectedParameterDetails}] (${minParameterValue} - ${maxParameterValue} ${selectedParameter.unit ? ' ' : ''}${selectedParameter.unit || ''})`"></q-input>
      </div>
    </q-form>
  </sc-device-action>
</template>

<style lang="scss" scoped>
.values {
  th {
    text-align: left;
    background-color: #e0e0e0;
  }

  td {
    &.customized {
      background-color: #fff9c4;
    }

    &.inconsistent {
      background-color: #ffdac4;
    }

    &.device {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
    }

    &.band {
      cursor: pointer;
    }

    &.selected {
      background-color: #cfe4ff;
    }
  }
}

label {
  font-size: 14px;
}
</style>
