<script>
import { mapState, mapMutations } from 'vuex'
import { notNull } from '@stellacontrol/utilities'
import { PlanLayers } from '@stellacontrol/planner'
import { Secure } from '@stellacontrol/security-ui'
import Section from './section.vue'
import Toolbox from './section-toolbox.vue'
import ItemProperties from './section-item-properties.vue'
import LayerProperties from './section-layer-properties.vue'
import Snapshots from './section-snapshots.vue'
import { PlanActions } from '../../renderer/actions/plan-action'
import Zoom from './zoom.vue'

export default {
  mixins: [
    Secure
  ],

  props: {
    // Plan renderer
    renderer: {
      required: true
    }
  },

  components: {
    'scp-section': Section,
    'scp-toolbox': Toolbox,
    'scp-layer-properties': LayerProperties,
    'scp-item-properties': ItemProperties,
    'scp-snapshots': Snapshots,
    'scp-plan-zoom': Zoom
  },

  data () {
    return {
      PlanLayers,
      PlanActions,

      // Indicates whether the toolbox is expanded
      isExpanded: true,

      // Items currently selected in the renderer
      selectedItems: null,

      // Equipment hierarchy
      equipmentHierarchy: null
    }
  },

  computed: {
    ...mapState({
      // Edited plan
      plan: state => state.planner.plan,
    }),

    // Plan settings
    settings () {
      return this.plan?.settings
    },

    // Plan layout
    layout () {
      return this.renderer?.layout
    },

    // Current floor
    floor () {
      return this.renderer?.floor
    },

    // Section status
    expandedSections () {
      const { settings, floor } = this
      return settings?.getFloor(floor).expandedSections || {}
    },

    // Plan layers to show
    layers () {
      const { renderer } = this
      if (renderer) {
        return renderer.layers.filter(layer => !layer.isInternal)
      } else {
        return []
      }
    },

    // Layer visibility on the current floor
    visibleLayers () {
      const { settings, floor } = this
      if (settings) {
        const floorSettings = settings.getFloor(floor)
        const visibleLayers = this.layers.reduce((all, layer) => ({
          ...all,
          [layer.id]: !floorSettings.hiddenLayers[layer.id]
        }), {})
        return visibleLayers

      } else {
        return {}
      }
    },

    // Indicates that we're editing the cross-section view
    isCrossSection () {
      return this.renderer?.isCrossSection
    },

    // Indicates that we're editing the floor plan
    isFloor () {
      return this.renderer?.isFloor
    },

    // Identifiers of the selected items
    selectedIdentifiers () {
      return (this.selectedItems || []).map(i => i.id)
    },

    // Indicates whether any items are currently selected
    hasSelectedItems () {
      return this.selectedItems?.length > 0
    },

    // Indicates whether property editor for selected items should be visible
    canEditItemProperties () {
      return this.hasSelectedItems &&
        this.visibleLayers[PlanLayers.Items] &&
        this.selectedItems.every(item => item.canEditProperties)
    },

    // Indicates
    canUseSnapshots () {
      return this.canUse('planner-snapshots')
    }
  },

  emits: [
    'start-adding-item',
    'stop-adding-item',
    'action'
  ],

  methods: {
    ...mapMutations([
      'togglePlanPalette',
      'togglePlanPaletteSection',
      'togglePlanLayer'
    ]),

    // Populates the component
    populate () {
      const { renderer, plan, floor, layers } = this

      // Restore expanded/collapsed state
      const { paletteExpanded } = plan.settings
      this.isExpanded = notNull(paletteExpanded, true)

      // Restore layers visibility
      const { hiddenLayers } = plan.settings.getFloor(floor)
      for (const { id } of layers) {
        const isLayerVisible = !hiddenLayers[id]
        renderer.toggleLayer(id, isLayerVisible)
      }

      // Keep an eye on selections on the plan
      renderer.selection.addEventListener('select', (e) => {
        const { items } = e.detail
        this.selectedItems = [...items]
      })

      renderer.selection.addEventListener('deselect', () => {
        this.selectedItems = []
      })

      // Keep an eye on changes to equipment hierarchy, re-assign item tags
      this.equipmentHierarchy = renderer.equipmentHierarchy
      renderer.events.addEventListener('hierarchy-changed', ({ detail: { hierarchy } }) => {
        this.equipmentHierarchy = hierarchy
      })
    },

    // Signals that the user wants to start adding an item to the plan
    startAddingItem ({ item, layer }) {
      this.$emit('start-adding-item', { item, layer })
    },

    // Signals that the user wants to stop adding an item to the plan
    stopAddingItem () {
      this.$emit('stop-adding-item')
    },

    // Toggles the palette
    togglePalette () {
      this.isExpanded = !this.isExpanded
      const { floor, isExpanded } = this
      this.togglePlanPalette({ floor, isExpanded })
    },

    // Toggles the section
    toggleSection (section, isExpanded) {
      const { floor } = this
      this.togglePlanPaletteSection({ floor, section, isExpanded })
    },

    // Toggles the layer visibility
    toggleLayer (id) {
      if (id) {
        const { floor } = this
        this.renderer.toggleLayer(id)
        this.togglePlanLayer({ floor, id })
      }
    },

    // Passes the action requested in the properties
    executeAction (details) {
      this.$emit('action', details)
    },
  },

  watch: {
    // When selection changes, expand properties for the selected items
    selectedIdentifiers () {
      if (this.hasSelectedItems) {
        this.toggleSection('properties', true)
      }
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <div class="palette shadow-2">
    <header class="palette-header bg-black row items-center q-pl-xs q-pr-sm q-pt-sm q-pb-sm"
      @dblclick="togglePalette()">
      <q-icon size="28px" class="icon" :name="isExpanded ? 'expand_more' : 'chevron_right'"
        color="grey-2" @click="togglePalette()">
      </q-icon>
      <span class="text text-bold text-grey-2 q-ml-xs">
        Toolbox
      </span>
      <q-space></q-space>
      <div class="zoom">
        <scp-plan-zoom :renderer="renderer">
        </scp-plan-zoom>
      </div>
    </header>

    <template v-if="isExpanded">
      <scp-section section="background" title="Plan" icon="home_work" :sections="expandedSections"
        v-if="isFloor" :layer="PlanLayers.Background" :layers="visibleLayers" :renderer="renderer"
        @toggle-section="section => toggleSection(section)"
        @toggle-layer="layer => toggleLayer(layer)" @action="executeAction">
        <scp-layer-properties :renderer="renderer" :id="PlanLayers.Background"
          :enabled="visibleLayers[PlanLayers.Background]"
          @start-adding-item="({ item, layer }) => startAddingItem({ item, layer })"
          @stop-adding-item="stopAddingItem()" @action="executeAction">
        </scp-layer-properties>
      </scp-section>

      <scp-section section="cross-section" title="Plan" icon="home_work"
        :sections="expandedSections" v-if="isCrossSection" :layer="PlanLayers.Items"
        :layers="visibleLayers" :renderer="renderer"
        @toggle-section="section => toggleSection(section)"
        @toggle-layer="layer => toggleLayer(layer)" @action="executeAction">
        <scp-layer-properties :renderer="renderer" :id="PlanLayers.Items"
          :enabled="visibleLayers[PlanLayers.Items]"
          @start-adding-item="({ item, layer }) => startAddingItem({ item, layer })"
          @stop-adding-item="stopAddingItem()" @action="executeAction">
        </scp-layer-properties>
      </scp-section>

      <scp-section section="radiation" title="Radiation" icon="podcasts" v-if="isFloor"
        :sections="expandedSections" :layer="PlanLayers.Radiation" :layers="visibleLayers"
        :renderer="renderer" @toggle-section="section => toggleSection(section)"
        @toggle-layer="layer => toggleLayer(layer)" @action="executeAction">
        <scp-layer-properties :renderer="renderer" :id="PlanLayers.Radiation"
          :enabled="visibleLayers[PlanLayers.Radiation]"
          @start-adding-item="({ item, layer }) => startAddingItem({ item, layer })"
          @stop-adding-item="stopAddingItem()" @action="executeAction">
        </scp-layer-properties>
      </scp-section>

      <scp-section section="toolbox" title="Add Items" icon="add" :sections="expandedSections"
        :isSectionExpanded="expandedSections.layers" hides-layer="items" :layers="visibleLayers"
        :renderer="renderer" @toggle-section="section => toggleSection(section)"
        @toggle-layer="layer => toggleLayer(layer)" @action="executeAction">
        <scp-toolbox :renderer="renderer" :enabled="visibleLayers[PlanLayers.Items]"
          @start-adding-item="({ item, layer }) => startAddingItem({ item, layer })"
          @stop-adding-item="stopAddingItem()" @action="executeAction">
        </scp-toolbox>
      </scp-section>

      <scp-section section="properties" title="Item Properties" icon="tune"
        :action="PlanActions.RemoveItems" action-icon v-if="canEditItemProperties"
        :sections="expandedSections" :isSectionExpanded="expandedSections.layers"
        :renderer="renderer" :items="selectedItems"
        @toggle-section="section => toggleSection(section)" @action="executeAction">
        <scp-item-properties :renderer="renderer" :items="selectedItems" @action="executeAction">
        </scp-item-properties>
      </scp-section>

      <scp-section section="snapshots"
        :title="plan.hasSnapshots ? count(plan.snapshots, 'Snapshot') : 'Snapshots'"
        icon="photo_camera" :action="PlanActions.SavePlanSnapshot" action-icon
        v-if="canUseSnapshots" :sections="expandedSections"
        :isSectionExpanded="expandedSections.layers" :renderer="renderer"
        @toggle-section="section => toggleSection(section)" @action="executeAction">
        <scp-snapshots :renderer="renderer" @action="executeAction">
        </scp-snapshots>
      </scp-section>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.palette {
  background-color: #f8f8f8;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  border-radius: 5px;
  border: solid #5a5a5a 1px;

  .palette-header {
    border-bottom: solid #393939 1px;

    .icon {
      cursor: pointer;
    }
  }
}
</style>
