/**
 * Returns true if specified function is arrow function
 */
export function isArrowFunction (fn) {
  return (typeof fn === 'function') && /^[^{]+?=>/.test(fn.toString())
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds.
 * @param {Function} fn Function to debounce
 * @param {Number} delay Delay time, in milliseconds
 * @returns {Function}
 */
export const debounce = (fn, delay) => {
  if (delay > 0) {
    let timerId
    return (...args) => {
      clearTimeout(timerId)
      timerId = setTimeout(() => fn(...args), delay)
    }
  } else {
    return fn
  }
}

/**
 * Returns a function, that, if called very frequently, will be called
 * not more frequently than at the specified intervals
 * @param {Function} fn Function to throttle
 * @param {Number} interval Interval between calls, in milliseconds
 * @returns {Function}
 */
export function throttle (fn, interval) {
  if (interval > 0) {
    let throttling = false
    return (...args) => {
      if (!throttling) {
        throttling = true
        fn(...args)
        setTimeout(() => {
          throttling = false
        }, interval)
      }
    }
  } else {
    return fn
  }
}
