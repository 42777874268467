import { getDeviceDescription, PlaceType, versionCompare, megaVersionCompare, DeviceConnectionStatus, DeviceConnectionStatusOrder } from '@stellacontrol/model'
import { capitalize, stringCompare, dateCompare, numberCompare, findBiggest } from '@stellacontrol/utilities'
import { InventoryGridColumns } from './inventory-view.state'

/**
 * Device presenter for rendering user-friendly
 * texts with various properties of a device
 */
export class DevicePresenter {
  constructor (device, user, organizationHierarchy, guardian) {
    if (!device) throw Error('Device is required')
    this.device = device
    this.organizationHierarchy = organizationHierarchy
    this.guardian = guardian
    this.user = user
    this.isSelected = false
    this.isVisible = true
    this.model = this.device.getModel(user)
  }

  /**
   * Device associated with the presenter
   * @type {Device}
   */
  device

  /**
   * Received status of the device
   * @type {DeviceStatus}
   */
  status

  /**
   * Current connection status of the device: online, heartbeat etc.
   * Initially this comes from the most-recent device snapshot.
   * Eventually this might be overridden with device status
   * received via push.
   * @type {DeviceConnectionStatus}
   */
  get connection () {
    return this.status?.connection?.status || this.device?.snapshot?.connection
  }

  /**
   * Organization hierarchy
   * @type {OrganizationHierarchy}
   */
  organizationHierarchy

  /**
   * Security guardian
   * @type {Guardian}
   */
  guardian

  /**
   * Device identifier
   * @type {String}
   */
  get id () {
    return this.device.id
  }

  /**
   * Device serial number
   * @type {String}
   */
  get serialNumber () {
    return this.device.serialNumber
  }

  /**
   * Device firmware version
   * @type {String}
   */
  get firmwareVersion () {
    const { device } = this
    return device.firmwareVersionLong || device.firmwareVersion || '-'
  }

  /**
   * Brief device label
   * @type {String}
   */
  get label () {
    return getDeviceDescription(this.device)
  }

  /**
   * Model, evaluated depending on who's watching
   * @type {String}
   */
  model

  /**
   * Indicates whether the item has been selected with a checkbox
   * @type {Boolean}
   */
  isSelected

  /**
   * Indicates whether the item is visible after applying a filter
   * @type {Boolean}
   */
  isVisible

  /**
   * Indicates whether the item has been recently added to inventory
   * @type {Boolean}
   */
  isRecentlyAdded


  /**
   * Device type
   * @type {Boolean}
   */
  get type () {
    return capitalize(this.device.type || '')
  }

  /**
   * Indicates whether the device is monitored for alerts
   * @type {Boolean}
   */
  get isMonitored () {
    return this.device.isConnectedDevice && this.guardian.canUse('alerts')
  }

  /**
   * Device owner name
   * @type {String}
   */
  get owner () {
    const { owner } = this.device
    return owner ? owner.name : '-'
  }

  /**
   * Date and time when device has been
   * sold most recently by the viewing organization.
   * If device is owned by viewing organization,
   * no sell date is shown.
   * If current organization did not sell the device,
   * it shows the date of any last sale registered.
   * @type {Date}
   */
  get soldAt () {
    const { device: { soldAt, ownerId }, user } = this
    return ownerId === user.organizationId
      ? undefined
      : (this.device.soldByAt(user.organizationId) || soldAt)
  }

  /**
   * Device place name
   * @type {String}
   */
  get place () {
    const { place } = this.device
    return place ? place.name : '-'
  }

  /**
   * Device place type
   * @type {PlaceType}
   */
  get placeType () {
    const { place } = this.device
    return place ? place.placeType : '-'
  }

  /**
   * Device location in place
   * @type {String}
   */
  get location () {
    const { location, customLocation } = this.device
    return customLocation || location || ''
  }

  /**
   * Most recent device note.
   * It returns the last note created by user's organization or addressed to it,
   * unless super administrator is watching, who has access to all notes.
   * @type {String}
   */
  get recentNote () {
    const { user: { organizationId, isSuperAdministrator }, device: { notes } } = this
    let note

    if (isSuperAdministrator) {
      note = findBiggest(notes, 'createdAt')
    } else {
      note = findBiggest(
        (notes || []).filter(n => n.organizationId === organizationId || n.recipientId === organizationId),
        'createdAt'
      )
    }

    return note?.text || '-'
  }

  /**
   * Device notes, all combined
   * @type {String}
   */
  get notes () {
    const { notes = [] } = this.device
    return notes.map(note => note.text).join('\n\n')
  }

  /**
   * Indicates whether the device is favorited by the current user
   * @type {Boolean}
   */
  get isFavorite () {
    return this.device.isFavorite()
  }

  /**
   * Direct reseller of the device
   * @type {Organization}
   */
  get reseller () {
    const { device, organizationHierarchy } = this
    if (device && organizationHierarchy) {
      const { owner } = device
      return owner ? (owner.isResellerOrganization ? owner : organizationHierarchy.findResellerOf(owner.id)) : undefined
    } else {
      return null
    }
  }

  /**
   * Device ownership path, from owner all the way up to the super-organization
   * @type {Array}
   */
  get ownership () {
    const { device, organizationHierarchy } = this
    return device.getOwnershipPath(organizationHierarchy)
  }

  /**
   * Ownership path as text
   * @type {String}
   */
  get ownershipText () {
    return (this.ownership || []).map(o => o.name).join(' > ')
  }

  /**
   * Checks whether the device matches the specified filter
   * @param columns Column definitions with their current filter selections
   * @param options Grid options
   */
  filter (columns, options) {
    let matches = true

    // Filter out decommissioned devices unless requested
    if (!options.showDecommissionedDevices && this.device.isDecommissioned) {
      matches = false
    }

    // Filter out non-connected devices unless requested
    if (!options.showNonConnectedDevices && this.device.isNonConnectedDevice) {
      matches = false
    }

    // Filter out multi-device parts unless requested
    if (!options.showDeviceParts && this.device.partOf) {
      matches = false
    }

    // Filter out simulated devices unless requested
    if (!options.showSimulatedDevices && this.device.isSimulatedDevice) {
      matches = false
    }

    if (matches) {
      const { device, guardian } = this
      let value

      for (let { name, filter } of columns) {
        const column = InventoryGridColumns[name]
        filter = (filter || '').trim().toLowerCase()
        if (!filter || filter === 'all') continue

        // For free-text filters allow multiple conditions with AND & OR operators
        let conditions
        let operator
        if (column.filterType === 'text') {
          if (filter.includes(' or ')) {
            conditions = filter.split(' or ').flatMap(condition => condition.split(' '))
            operator = 'or'
          } else if (filter.includes(' and ')) {
            conditions = filter.split(' and ').flatMap(condition => condition.split(' '))
            operator = 'and'
          } else if (filter.includes('only ')) {
            conditions = [filter.replace('only', '').trim()]
            operator = 'only'
          } else {
            // Assume OR if values separated with spaces or comma's
            conditions = filter
              .split(' ')
              .flatMap(f => f.split(','))
              .filter(condition => condition.trim())
            operator = 'or'
          }
        } else {
          conditions = [filter.trim().toLowerCase()]
        }

        let negate
        let isDeviceMonitored
        for (let condition of conditions.map(c => c.trim()).filter(c => c)) {
          if (condition !== filter && (condition === '-' || condition === '!' || condition === 'not')) {
            negate = true
            continue
          }

          switch (name) {
            case 'isOnline':
              matches = condition === 'all' ||
                this.connection === condition ||
                (this.connection === DeviceConnectionStatus.Heartbeat && condition === DeviceConnectionStatus.Online) ||
                (condition === DeviceConnectionStatus.NeverConnected && !this.connection)
              break

            case 'tags':
              matches = device.hasTag({ name: condition, userId: this.user.id })
              break

            case 'type':
              value = (device.acronym || '').toLowerCase()
              matches = value.includes(condition)
              break

            case 'alerts':
              if (condition === 'all') {
                matches = true
              } else {
                isDeviceMonitored = device.canTriggerAlerts &&
                  (!guardian.requiresPremiumSubscription('alerts') || device.canUse('alerts'))
                matches = (condition === 'monitored' && isDeviceMonitored) ||
                  (condition !== 'monitored' && !isDeviceMonitored)
              }
              break

            case 'premiumService':
              value = device.premiumServiceLabel.toLowerCase()
              matches = operator === 'only'
                ? stringCompare(value, condition, false) === 0
                : value.includes(condition)
              break

            case 'premiumServiceStatus':
              matches = device.premiumServiceStatus === condition
              break

            case 'serialNumber':
            case 'name':
            case 'model':
            case 'hardwareVersion':
            case 'eepromVersion':
            case 'megaVersion':
              value = (device[name] || '').toLowerCase()
              matches = operator === 'only'
                ? stringCompare(value, condition, false) === 0
                : value.includes(condition)
              break

            case 'firmwareVersion':
              value = this.firmwareVersion || ''
              matches = operator === 'only'
                ? stringCompare(value, condition, false) === 0
                : value.includes(condition)
              break

            case 'soldAt':
            case 'manufacturedAt':
              value = column.text(this) || '-'
              matches = value.includes(condition)
              break

            case 'ownership':
              if (operator === 'only') {
                value = this.owner.toLowerCase()
              } else {
                value = this.ownershipText.toLowerCase()
              }
              matches = value.includes(condition)
              break

            case 'placeType':
              value = device.place ? device.place.placeType : PlaceType.NoPlace
              matches = value === condition
              break

            case 'place':
            case 'location':
            case 'notes':
              if (condition !== '-') {
                value = (this[name] || '').toLowerCase()
                matches = operator === 'only'
                  ? stringCompare(value, condition, false) === 0
                  : value.includes(condition)
              }
              break
          }

          // Apply negation operator if specified
          if (negate) {
            matches = !matches
          }

          if (matches && operator === 'or') {
            // If multiple OR conditions, just one match is enough
            break
          } else if (!matches && operator === 'and') {
            // If multiple AND conditions, just one mismatch is enough
            break
          }
        }

        // Break if any of the filtered columns is a mismatch
        if (!matches) {
          break
        }

        // Reset negation, it applies to each condition separately
        negate = false
      }
    }

    this.isVisible = matches
    return matches
  }

  /**
   * Compare function for sorting devices in the spreadsheet
   * @param item Item to compare to
   * @param column Spreadsheet column to sort by
   */
  compareTo (item, column) {
    if (!column) return 0

    let result

    if (item.isRecentlyAdded) {
      result = 1
    }

    const { name } = column
    switch (name) {
      // Special cases of sorting for columns
      case 'isOnline':
        result = numberCompare(
          DeviceConnectionStatusOrder[this.connection] || 100,
          DeviceConnectionStatusOrder[item.connection] || 100
        )
        break

      case 'alerts':
        result = this.isMonitored && item.device.canTriggerAlerts ? 1 : -1
        break

      // Date columns
      case 'manufacturedAt':
        result = dateCompare(this.device[name], item.device[name])
        break

      case 'soldAt':
        result = dateCompare(this[name], item[name])
        break

      // Version columns
      case 'firmwareVersion':
      case 'hardwareVersion':
      case 'eepromVersion':
        result = versionCompare(this.device[name], item.device[name])
        break

      case 'megaVersion':
        result = megaVersionCompare(this.device[name], item.device[name])
        break

      // Device ownership path
      case 'ownership':
        result = stringCompare(this.ownershipText, item.ownershipText)
        break

      // Default sorting of columns
      default:
        // Use column text function if specified,
        // to sort exactly on the text displayed in the spreadsheet
        if (column.text) {
          result = stringCompare(column.text(this), column.text(item))
        } else {
          // As last resort, directly use the device property associated with the column
          result = stringCompare(this.device[name], item.device[name])
        }
        break
    }
    return result
  }
}
