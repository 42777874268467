import { formatTime } from '@stellacontrol/utilities'
import { Assignable, Attachment, AttachmentDataType, AntennaName, AntennaOrientationName } from '@stellacontrol/model'
import { getScanNeighbourCells } from './get-neighbour-cells'
import { getScanSurvey } from './get-survey'
import { getScanSpectrumChart } from './get-spectrum-chart'

/**
 * Results of a TT scan
 */
export class Scan extends Assignable {
  constructor (data = {}) {
    super()
    this.assign(data)
  }

  /**
   * Parses the attachment into {@link Scan} instance
   * @param {Attachment} attachment Attachment to parse
   * @returns {Scan}
   */
  static fromAttachment (attachment) {
    if (!attachment) throw new Error('Attachment is required')
    if (!attachment.isScan) throw new Error('The attachment does not contain scan results')

    return new Scan({ raw: attachment })
  }

  normalize () {
    super.normalize()
    this.raw = this.cast(this.raw, Attachment)

    // If attachment with content provided, parse the results
    const { raw } = this
    if (raw?.data?.results) {
      // Parse the summary
      const { time, antennaOrientation, antennaType, wasOutdoorScan, wasRepeaterPresent, distanceFromRepeater, notes } = raw.data?.results || {}
      const places = raw.hasLinks
        ? raw.links.map(l => `${l.organization.name} - ${l.principal.name}`).join(', ')
        : null
      this.summary = [
        { label: 'Place', value: places },
        { label: 'Location', value: wasOutdoorScan ? 'Outdoor' : 'Indoor' },
        { label: 'Scan Time', value: formatTime(time) },
        { label: 'Antenna Type', value: AntennaName[antennaType] || '-' },
        { label: 'Antenna Direction', value: AntennaOrientationName[antennaOrientation] || '-' },
        { label: 'Repeater Status', value: wasRepeaterPresent ? 'On' : 'Off' },
        { label: 'Distance from Internal Antennas', value: wasRepeaterPresent ? `${distanceFromRepeater}m` : null },
        { label: 'Notes', value: notes },
      ].filter(item => item.value != null)

      // Parse detailed scan results
      this.live = getScanNeighbourCells(raw)
      this.survey = getScanSurvey(raw)
      this.spectrum = getScanSpectrumChart(raw)
    }
  }

  /**
   * Record id
   * @type {String}
   */
  get id () {
    return this.raw?.id
  }

  /**
   * Record creation time
   * @type {Date}
   */
  get createdAt () {
    return this.raw?.createdAt
  }

  /**
   * Scan type
   * @type {String}
   */
  get type () {
    return this.raw?.dataType
  }

  /**
   * Indicates an automatic full scan
   * @type {Boolean}
   */
  get wasAutomatic () {
    return this.raw?.dataType === AttachmentDataType.TTFullScan
  }

  /**
   * Indicates an outdoor scan
   * @type {Boolean}
   */
  get wasOutdoorScan () {
    return Boolean(this.raw?.details?.wasOutdoorScan)
  }

  /**
   * Scan name
   * @type {String}
   */
  get name () {
    return this.raw?.name
  }

  /**
   * File icon
   * @type {String}
   */
  get icon () {
    return this.raw?.icon
  }

  /**
   * Scan description
   * @type {String}
   */
  get description () {
    return this.raw?.description
  }

  /**
   * Scan folder
   * @type {String}
   */
  get folder () {
    return this.raw?.folder
  }

  /**
   * Scan note
   * @type {String}
   */
  get note () {
    return this.raw?.recentNote
  }

  /**
   * Scan summary
   * @type {Object}
   */
  summary

  /**
   * Scanned mobile operators
   * @type {Object}
   */
  operators

  /**
   * Live scan (neighbour cells)
   * @type {Object}
   */
  live

  /**
   * Cell ID scan (survey)
   * @type {Object}
  */
  survey

  /**
   * Spectrum scan
    * @type {Object}
    */
  spectrum

  /**
   * Raw data
   * @type {Attachment}
   */
  raw
}