import { safeParseInt, Point } from '@stellacontrol/utilities'
import { PlanRectangle } from './plan-rectangle'
import { PlanItemType } from './plan-item'
import { PlanTextStyle, PlanBackgroundStyle } from '../styles'

/**
 * Cable Plug - a connection point where cross-floor cable enters/exits a {@link PlanRiser}
 */
export class PlanPlug extends PlanRectangle {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Item type
   * @type {PlanItemType}
   */
  static get type () {
    return PlanItemType.Plug
  }

  /**
   * Object defaults
   */
  get defaults () {
    const size = 30

    const defaults = {
      ...super.defaults,
      coordinates: new Point({ x: size, y: size }),
      width: size,
      height: size,
      thickness: 6,
      backgroundStyle: new PlanBackgroundStyle({ color: '#080808' }),
      lineStyle: new PlanBackgroundStyle({ color: 'yellow' }),
      textStyle: PlanTextStyle.Small
    }

    return defaults
  }

  normalize () {
    super.normalize()

    const { defaults } = this
    this.thickness = safeParseInt(this.thickness, defaults.thickness)

    this.clearPorts()
    this.addPort()
  }

  /**
   * Serializes the plan item to JSON
   * @returns {Object}
  */
  toJSON () {
    const result = super.toJSON()

    // Remove runtime properties
    delete result.ports

    // Remove fixed styles
    delete result.width
    delete result.height
    delete result.thickness
    delete result.backgroundStyle
    delete result.lineStyle
    delete result.textStyle

    return result
  }

  /**
   * Wall thickness
   * @type {Number}
   */
  thickness

  /**
   * Identifier of a riser to which the plug belongs
   * @type {Array[String]}
   */
  riser

  /**
   * Plug label is fixed in place
   */
  get isLabelFixed () {
    return true
  }
}
