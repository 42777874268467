<script>
import { DaySchedule, padLeft, coalesce, range } from '@stellacontrol/utilities'
import AlertConfigurationComponent from './alert-configuration-component'

// Simplified schedule selector, allowing to select only one time range
// using two dropdowns.
// For more elaborate schedules use alert-configuration-schedule.vue
export default {
  mixins: [
    AlertConfigurationComponent
  ],

  data () {
    return {
      schedule: new DaySchedule(),
      checkAlertDuringPeriod: false
    }
  },

  computed: {
    times () {
      return DaySchedule.AllHours.map(hour => ({
        label: `${padLeft(hour, '0', 2)}:00`,
        value: hour
      }))
    },

    from () {
      const { from } = this.scheduleToHours(this.schedule)
      return from == null ? 0 : from
    },

    until () {
      const { until } = this.scheduleToHours(this.schedule)
      return until == null ? 6 : until
    },

    scheduleIsModified () {
      return this.isModified('schedule')
    },

    scheduleIsNotDefault () {
      return this.isNotDefault('schedule', value => `<br>Default schedule is ${value}. `)
    },

    scheduleText () {
      return this.schedule.toString({ readable: true, hours: true })
    }
  },

  watch: {
    configuration () {
      this.populate()
    },

    schedule (value) {
      if (value) {
        this.configuration.schedule = value.toString()
      } else {
        this.configuration.schedule = ''
      }
    }
  },

  methods: {
    padLeft,

    // Populates the editor
    populate () {
      const { configuration } = this
      if (configuration) {
        this.schedule = DaySchedule.fromString(this.configuration.schedule)
        this.checkAlertDuringPeriod = this.configuration.checkAlertDuringPeriod
      } else {
        this.schedule = new DaySchedule()
        this.checkAlertDuringPeriod = false
      }
    },

    // Converts a schedule to start and end hour
    scheduleToHours (schedule) {
      let from
      let until

      if (schedule.items.length === 1) {
        // Simple schedule within the day
        const hours = schedule.toHours()
        from = hours[0]
        until = hours[hours.length - 1] + 1
      } else if (schedule.items.length === 2) {
        // Schedule going over midnight
        const beforeMidnight = schedule.items[1]
        const afterMidnight = schedule.items[0]
        from = beforeMidnight.from / (3600 * 1000)
        until = (afterMidnight.to / (3600 * 1000))
      } else {
        // Unsupported case, fall back to defaults
        from = 0
        until = 6
      }

      return { from, until }
    },

    // Converts the specified start and end hour to a schedule
    hoursToSchedule (from, until) {
      const hours = until > from
        ? Array.from(range(from, until))
        : Array
          .from(range(from, until + 24))
          .map(hour => hour % 24)
          .sort()
      return DaySchedule.fromHours(hours)
    },

    // Changes the schedule
    setSchedule ({ from, until } = {}) {
      const defaultSchedule = DaySchedule.fromString(this.defaultConfiguration.schedule)
      from = coalesce(from, this.from, defaultSchedule.from)
      until = coalesce(until, this.until, defaultSchedule.until)
      this.schedule = this.hoursToSchedule(from, until)
    }
  },

  created () {
    this.populate()
  }
}
</script>

<template>
  <article>
    <section>
      <label :class="{ modified: scheduleIsModified }">
        <span v-if="checkAlertDuringPeriod">
          Check alert between
        </span>
        <span v-else>
          Analyse the data between
        </span>
      </label>
      <div v-if="isEditable" class="row items-center">
        <q-select class="q-mr-md" dense emit-value :model-value="from" :options="times" map-options
          outlined square option-value="value" option-label="label"
          @update:model-value="from => setSchedule({ from })">
        </q-select>
        <span class="q-mr-md">
          and
        </span>
        <q-select class="" dense emit-value borderless :model-value="until" :options="times"
          outlined square map-options option-value="value" option-label="label"
          @update:model-value="until => setSchedule({ until })">
        </q-select>
      </div>
      <div v-else>
        {{ scheduleText }}
      </div>
      <div class="q-ml-md">
        <sc-hint size="20px" v-if="checkAlertDuringPeriod">
          The alert will be checked from
          {{ padLeft(from, 2, '0') }}:00
          until
          {{ padLeft(until, 2, '0') }}:00.
        </sc-hint>
        <sc-hint size="20px" v-else>
          The data will be collected from
          {{ padLeft(from, 2, '0') }}:00
          until
          {{ padLeft(until, 2, '0') }}:00.
          After this time the collected data will be analysed and alert will be checked.
        </sc-hint>
      </div>
    </section>
  </article>
</template>

<style scoped lang="scss"></style>