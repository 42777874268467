import { Log, delay } from '@stellacontrol/utilities'
import { PlannerAPI, CommonAPI } from '@stellacontrol/client-api'
import { Plan, EntityType, Attachment, AttachmentType, PlannerMode } from '@stellacontrol/model'
import { PlanLayout, PlanFloors } from '@stellacontrol/planner'

/**
 * Initialize the applet
 */
export async function initializeApplet () {
}

/**
 * Retrieves plans of the current organization
 * @returns {Promise<Array[Plan]>}
 */
export async function getPlans ({ commit, getters }) {
  const { currentOrganization: organization } = getters
  const plans = await PlannerAPI.getPlans({ organization })
  commit('storePlans', { plans })
  return plans
}

/**
 * Retrieves the specified plan
 * @param {String} id Plan identifier
 * @returns {Promise<Plan>}
 */
export async function getPlan ({ commit }, { id } = {}) {
  if (id) {
    const plan = await PlannerAPI.getPlan({ id })
    if (plan) {
      // Parse plan layout
      plan.layout = new PlanLayout(plan.layout, plan.name)

      // Determine URLs for floor images
      for (const floor of plan.layout.floors) {
        const { image } = floor.background
        if (image) {
          image.downloadUrl = CommonAPI.getAttachmentUrl({
            attachment: image,
            decode: true
          })
        }
      }
      commit('storePlan', { plan })
      return plan
    }
  }
}

/**
 * Retrieves a plan of the specified building
 * @param {String} id Building identifier
 * @param {Boolean} create If plan for the building does not exist, create it now
 * @returns {Promise<Plan>}
 */
export async function getBuildingPlan ({ commit, getters }, { id, create = true } = {}) {
  if (id) {
    const building = getters.allPlaces.find(p => p.id === id)
    if (building) {
      const plan = await PlannerAPI.getBuildingPlan({ id: building.id, create })
      if (plan) {
        plan.layout = new PlanLayout(plan.layout, plan.name)
        commit('storePlan', { plan })
        return plan
      }
    }
  }
}

/**
 * Creates a new plan belonging to the current organization
 * @returns {Promise<Plan>}
 */
export async function newPlan ({ getters }) {
  const { currentOrganization } = getters

  const plan = new Plan({
    organizationId: currentOrganization.id
  })

  return plan
}

/**
 * Selects the plan for editing
 * @param {Plan} plan Plan to edit
 * @param {String} floorId Floor to edit
 */
export async function editPlan ({ commit }, { plan, floorId } = {}) {
  commit('editPlan', { plan })
  commit('editPlanFloor', { floorId })
}

/**
 * Selects the plan floor for editing
 * @param {String} floorId Floor to edit
 */
export async function editPlanFloor ({ commit }, { floorId } = {}) {
  commit('editPlanFloor', { floorId })
}


/**
 * Saves a plan
 * @param {Plan} plan Plan to save
 * @returns {Promise<Plan>}
 */
export async function savePlan ({ commit }, { plan } = {}) {
  if (!plan) throw new Error('Plan is required')
  if (!plan.organization) throw new Error('Plan owner is required')
  if (!plan.place) throw new Error('Plan place is required')

  // Save the plan.
  // Floor images will be removed during serialization.
  // They are saved separately as attachments.
  const planToSave = new Plan(plan)
  planToSave.layout = new PlanLayout(plan.layout, plan.name)
  for (const floor of planToSave.layout.floors) {
    if (floor.hasImage) {
      floor.background.image.content = null
    }
  }
  plan = await PlannerAPI.savePlan({ plan: planToSave })

  if (plan) {
    // Parse the saved layout
    plan.layout = new PlanLayout(plan.layout, plan.name)

    // Store the updated plan in the state
    commit('storePlan', { plan })
  }

  return plan
}

/**
 * Saves a floor image
 * @param {Plan} plan Plan
 * @param {PlanFloor} floor Plan floor whose image to save
 * @param {Attachment} image Floor image, `null` if image has been cleared
 * @returns {Promise<Plan>}
 */
export async function saveFloorImage ({ commit, getters }, { plan, floor, image } = {}) {
  if (!plan) throw new Error('Plan is required')
  if (!plan.id) throw new Error('Plan must be saved first')
  if (!floor) throw new Error('Floor is required')

  try {
    // Delete the previous floor image when image has been cleared,
    // or when new image has been uploaded,
    const imageCleared = floor.background && !image
    const imageAssigned = floor.background && !!image
    const imageChanged = floor.background && floor.background.wasImageChanged

    // Delete previous image if image cleared or new image uploaded
    if (imageCleared) {
      floor.background.clearImage()
    }
    if (imageCleared || imageChanged) {
      await CommonAPI.deleteAttachments({
        ownerId: plan.organizationId,
        entityId: plan.id,
        folder: floor.id
      })
    }

    if (imageAssigned) {
      // Save the new floor image
      let attachment = new Attachment({
        ...image,
        type: AttachmentType.Image,
        name: image.name || floor.label,
        description: plan.name,
        ownerId: plan.organizationId,
        entityId: plan.id,
        entityType: EntityType.Plan,
        folder: floor.id,

        // The image is passed as binary file
        dataUrl: null,
        content: null,
        mimeType: image.mimeType,
        file: image.file,

        // The attachment is stored in the external data storage
        external: true
      })

      attachment = await CommonAPI.saveAttachment({ attachment })
      if (attachment) {
        // From now on, the attachment can be downloaded via URL
        attachment.downloadUrl = CommonAPI.getAttachmentUrl({ attachment })
        attachment.mimeType = image.mimeType
        attachment.content = undefined
        floor.background.image = attachment
        // Remove transparent colors from the floor, the image is now stored with transparency
        floor.background.clearTransparentColors()
      }

    }

    // Save the plan
    plan = await PlannerAPI.savePlan({ plan })
    plan.layout = new PlanLayout(plan.layout, plan.name)
    commit('storePlan', { plan })

    return plan

  } catch (error) {
    if (CommonAPI.isEntityTooLargeError(error) || CommonAPI.isNetworkError(error)) {
      const limits = getters.configuration.upload.limits
      const details = `The uploaded image is too large. Maximal allowed size is ${Math.round(limits.fileSize / (1024 * 1024))} MB`
      throw new Error(details)
    } else {
      throw error
    }
  }
}

/**
 * Sets the planner editing mode
 * @param {PlannerMode} mode Planner editing mode
 */
export async function setPlannerMode ({ commit, getters }, { mode } = {}) {
  if (getters.guardian.canUse('planner-advanced')) {
    commit('setPlannerMode', { mode })
  } else {
    commit('setPlannerMode', { mode: PlannerMode.Regular })
  }
}

/**
 * Maximizes the plan editor / restores to normal view
 * @param {Boolean} isMaximized Planner view size
 */
export function setPlannerView ({ commit }, { isMaximized } = {}) {
  commit('setPlannerView', { isMaximized })
}

/**
 * Creates a snapshot of a plan
 * @param {Plan} plan Plan
 * @param {String} name Snapshot name
 * @param {String} description Snapshot details
 * @returns {Promise<Plan>} Saved snapshot
 */
export async function createPlanSnapshot ({ getters }, { plan, name, description } = {}) {
  if (!plan) throw new Error('Plan is required')

  const { currentUser: user } = getters
  const snapshot = await PlannerAPI.savePlan({
    plan: plan.createSnapshot({
      name,
      description,
      user
    })
  })

  if (snapshot) {
    // Store the updated plan in the state
    plan.storeSnapshot({ snapshot })

    // Mark the snapshot as being now the source of the currently edited plan
    plan.restoredFrom = snapshot.id
    plan.restoredAt = new Date()

    Log.debug(`[${plan.name}] Plan snapshot [${snapshot.name}] has been saved`)

    return snapshot
  }
}

/**
 * Restores the specified plan snapshot into the currently edited plan
 * @param {Plan} snapshot Snapshot to store
 */
export async function restorePlanSnapshot ({ state, getters, dispatch }, { snapshot } = {}) {
  const { currentUser: user } = getters
  const { plan } = state
  if (!plan) throw new Error('Not editing any plan at the moment')

  // Fetch the snapshot details
  snapshot = await PlannerAPI.getPlan({ id: snapshot.id })
  if (!snapshot) return

  // Remove the recent snapshot
  const recentSnapshot = plan.removeRecentSnapshot()
  if (recentSnapshot) {
    await PlannerAPI.deletePlan({ plan: recentSnapshot })
  }

  // Store the current plan as recent snapshot,
  // unless we are restoring a recent snapshot
  if (!snapshot.isRecentSnapshot) {
    let recentSnapshot = plan.createSnapshot({
      name: Plan.RecentSnapshot,
      description: `Plan before restoring the ${snapshot.name.toUpperCase()} snapshot`,
      user
    })
    recentSnapshot = await PlannerAPI.savePlan({ plan: recentSnapshot })
    if (recentSnapshot) {
      plan.storeSnapshot({ snapshot: recentSnapshot })
    }
  }

  // Unload the current plan
  await dispatch('editPlan')
  await delay(500)

  // Replace the plan layout with the snapshot
  plan.layout = new PlanLayout({ ...snapshot.layout, version: plan.layout.version++ }, plan.name)
  plan.restoredFrom = snapshot.id
  plan.restoredAt = new Date()

  // Load the restored plan, revert to the same floor (if it's still available)
  const floorId = plan.layout.hasFloor(state.floorId)
    ? state.floorId
    : PlanFloors.CrossSection
  await dispatch('editPlan', { plan, floorId })

  Log.debug(`[${plan.name}] Plan snapshot [${snapshot.name}] has been restored`, snapshot)
}

/**
 * Deletes the specified plan snapshot
 * @param {Plan} snapshot Snapshot to delete
 */
export async function deletePlanSnapshot ({ state, dispatch }, { snapshot } = {}) {
  const { plan, floorId } = state
  if (!plan) throw new Error('Not editing any plan at the moment')

  plan.removeSnapshot({ id: snapshot.id })
  await PlannerAPI.deletePlan({ plan: snapshot })
  dispatch('editPlan', { plan, floorId })

  Log.debug(`[${plan.name}] Plan snapshot [${snapshot.name}] has been deleted`, snapshot)
  return snapshot
}

/**
 * Uploads plan image to the file store, for further processing
 * @param {Plan} plan Plan whose image to upload
 * @param {File} file File to upload
 * @returns {Promise<Array[Attachment]>} Stored image or multiple images, if multi-page PDF
 */
export async function uploadPlanImage ({ getters }, { plan, file } = {}) {
  if (!plan) throw new Error('Plan is required')
  if (!file) throw new Error('File is required')
  if (file.size === 0) throw new Error('File is empty')

  const { configuration } = getters
  const imageTypes = ['image/jpeg', 'image/png']
  const pdfTypes = ['application/pdf']
  const isImage = imageTypes.includes(file.type)
  const isPDF = pdfTypes.includes(file.type)
  if (!(isImage || isPDF)) throw new Error(`File type [${file.type}] is not allowed as plan image`)

  if (isPDF) {
    const { size, quality, density } = configuration.services.planner.pdf
    const { images, error } = await CommonAPI.pdfToImages({
      file,
      quality,
      density,
      width: size,
      format: 'png',
      store: false,
      external: true,
      preserveAspectRatio: true
    })

    if (error) {
      return { error }
    }

    if (images?.length > 0) {
      Log.debug(`[${plan.name}] Plan images were extracted from PDF document [${file.name}] and saved`, images)
      return { images }
    }
  }

  if (isImage) {
    const attachment = await Attachment.fromFile(file)
    attachment.description = file.name
    attachment.external = true
    attachment.bucket = getters.configuration.upload.buckets.plan
    const image = await CommonAPI.saveAttachment({ attachment, store: false })

    if (image) {
      Log.debug(`[${plan.name}] Plan image [${file.name}] has been saved`, image)
      return { images: [image] }
    }
  }

  return { images: [] }
}

/**
 * Updates a previously uploaded plan image with new image data
 * @param {Plan} plan Plan whose image to update
 * @param {Attachment} image Image to update
 * @returns {Promise<Attachment>} Updated image
 */
export async function updatePlanImage ({ getters }, { plan, image } = {}) {
  if (!plan) throw new Error('Plan is required')
  if (!image) throw new Error('Image is required')

  image.external = true
  image.bucket = getters.configuration.upload.buckets.plan

  const updated = await CommonAPI.saveAttachment({ attachment: image, store: false })
  if (updated) {
    Log.debug(`[${plan.name}] Plan image [${updated.name}] has been updated`, updated)
    return updated
  }
}

/**
 * Removes the uploaded plan image from the file store
 * @param {Plan} plan Plan whose image to remove
 * @param {Attachment} image Image to remove
 * @returns {Promise>}
 */
export async function removePlanImage ({ getters }, { plan, image } = {}) {
  if (!plan) throw new Error('Plan is required')
  if (!image) throw new Error('Image is required')

  const { name } = image
  const bucket = getters.configuration.upload.buckets.plan
  const external = true
  await CommonAPI.deleteAttachments({ name, external, bucket })
  Log.debug(`[${plan.name}] Plan image [${image.name}] has been deleted`, image)
}

/**
 * Stores the items recently selected on the plan
 * @param {Array[PlanItem]} items Selected items
 */
export async function storePlanSelection ({ commit }, { items = [] } = {}) {
  commit('storePlanSelection', { items })
}
