<script>
import { Viewport } from '@stellacontrol/client-utilities'
import Widget from './widget.vue'

export default {
  props: {
    // Items displayed in the text widget,
    // list of { text, class, route } entries
    items: {
      type: Array,
      default: () => []
    },

    alerts: {
      type: Array,
      default: () => []
    },

    // Icon to show in the widget
    icon: {
      type: String
    },

    // Icon size
    iconSize: {
      type: String
    },

    // Icon color
    iconColor: {
      type: String,
      default: 'indigo-4'
    },

    // Icon margin
    iconMargin: {
      type: String,
      default: ''
    },

    // Icon class
    iconClass: {
      type: String,
      default: ''
    },

    // Icon rotation
    iconRotate: {
      default: false
    },
    iconRotateReverse: {
      default: false
    },

    // Icon style
    iconStyle: {
      type: Object
    },

    // Text item class
    itemClass: {
      type: Object,
      default: () => ({ 'text-body1': true, 'text-grey-9': true })
    },

    // Text item style
    itemStyle: {
      type: Object
    }
  },

  components: {
    'sc-widget': Widget
  },

  computed: {
    hasIcon () {
      return this.icon || this.$slots['icon']
    },

    visibleItems () {
      return this.items.filter(item => item && (item.text || '').trim() && !item.hidden)
    },

    isSmallScreen () {
      return Viewport.isSmallScreen
    },

    adjustedIconSize () {
      return this.iconSize || (this.isSmallScreen ? '32px' : '56px')
    },

    iconElement () {
      return this.$refs.iconElement
    }
  },

  methods: {
    // Executes the action
    executeAction (action) {
      this.$emit('action', action)
    }
  },

  async created () {
    await this.$nextTick()
    const { contentElement, iconElement, linesElement } = this.$refs
    this.$emit('initialized', { contentElement, iconElement, linesElement })
  }
}
</script>

<template>
  <sc-widget v-bind="{ ...$props, ...$attrs }" class="text-widget">
    <div class="widget-content" ref="contentElement">
      <div ref="iconElement" class="icon" v-if="hasIcon">
        <slot name="icon">
          <q-icon :size="adjustedIconSize" :color="iconColor" :name="icon"
            :class="{ rotate: iconRotate, 'rotate-reverse': iconRotateReverse, [iconClass || 'default']: true }"
            :style="{ margin: iconMargin, ...iconStyle || {} }">
          </q-icon>
        </slot>
      </div>

      <div ref="linesElement" class="lines q-gutter-xs">
        <template v-for="(item, index) in visibleItems">
          <router-link v-if="item.route" :key="`link-${index}`"
            :class="item.class || { 'item-link': true, ...itemClass, 'text-grey-9': false }"
            :style="item.style || itemStyle" :to="item.route">
            <span v-html="item.text"></span>
          </router-link>

          <span v-else :key="`label-${index}`"
            :class="{ tooltip: item.tooltip != null, ...(item.class || itemClass) }"
            :style="item.style || itemStyle" v-html="item.text">
          </span>

          <sc-tooltip v-if="item.tooltip" :text="item.tooltip"></sc-tooltip>
          <sc-alert-popup v-if="alerts.length > 0" :alerts="alerts" />
        </template>
      </div>
    </div>

    <slot name="toolbar"></slot>
  </sc-widget>
</template>
