<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { Log } from '@stellacontrol/utilities'
import { PlannerMode } from '@stellacontrol/model'
import { PlanFloors, PlanState } from '@stellacontrol/planner'
import { ViewMixin, Notification } from '@stellacontrol/client-utilities'
import { Secure } from '@stellacontrol/security-ui'
import { resolvePlan, resolvePlanFloor } from './planner.resolve'

const name = 'plan'

// Plan editor
export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  data () {
    return {
      name,
      // Indicates that changes to the plan should be saved automatically
      autoSave: true,
      // Indicates whether to show the SAVE button
      showSaveButton: false,
      // Plan state
      state: null,
      stateLabel: null,
      // Indicates whether we're setting up live status watcher
      startingStatusWatch: false,
      // Devices on the plan
      planDevices: [],
      // Indicates whether we're currently in debug mode
      isDebugging: false,
      // Mouse pointer position over the plan, used in debug mode
      pointerPosition: null,
      // Plan owner's guardian
      organizationGuardian: null
    }
  },

  computed: {
    ...mapState({
      // Edited plan
      plan: state => state.planner.plan,
      // Place associated with the edited plan
      place: state => state.planner.plan?.place,
      // Edited plan floor
      floorId: state => state.planner.floorId,
      // Normal/maximized status of the view
      isMaximized: state => state.planner.isMaximized,
      // All devices available to the current organization
      devices: state => state.devices.devices || [],
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices || {}
    }),

    ...mapGetters([
      'isDevelopmentEnvironment',
      'isLoggedIn',
      'devices',
      'organizations',
      'allPlaces',
      'planEditingMode'
    ]),

    // View title
    title () {
      const { place } = this
      return place?.name
    },

    // Organization to which the plan belongs
    organization () {
      const { plan, organizations } = this
      return plan ? organizations.find(o => o.id === plan.organizationId) : null
    },

    // Place to which the plan belongs
    place () {
      const { plan, allPlaces } = this
      return plan ? allPlaces.find(p => p.id === plan.placeId) : null
    },

    // Indicates that the plan is initialized
    isInitialized () {
      return this.plan?.layout != null
    },

    // View breadcrumbs
    breadcrumbs () {
      const { place, organization, getViewTitle } = this

      const breadcrumbs = [
        {
          name: 'home',
          title: getViewTitle('home')
        },
        {
          name: 'installations',
          title: getViewTitle('installations')
        },
        organization
          ? {
            name: 'installations',
            title: organization.name,
            route: 'installations',
            query: {
              filter: organization.name,
              exact: true
            }
          }
          : null,
        place
          ? {
            name: 'building-dashboard',
            route: 'building-dashboard',
            title: place.name,
            params: { id: place.id, organizationId: place.organizationId }
          }
          : null,
        {
          title: 'Plan'
        }
      ]

      return breadcrumbs.filter(b => b)
    },

    // Indicates that we're now saving the plan
    isSaving () {
      return this.plan?.layout.isSaving
    },

    // Indicates that we're now saving the plan
    saveFailed () {
      return this.plan?.layout.saveError != null
    },

    // Color of SAVE icon, indicating the status - idle, saving, failed
    saveIconColor () {
      if (this.isSaving) return 'green-6'
      if (this.saveFailed) return 'orange-6'
      return 'green-4'
    },

    // Determines whether the planner allows full feature set
    isAdvancedMode () {
      return this.planEditingMode === PlannerMode.Advanced
    },

    // Determines whether the planner allows only a limited feature set
    isRegularMode () {
      return this.planEditingMode === PlannerMode.Regular
    },

    // Determines whether the planner works in readonly mode
    isReadOnly () {
      return this.planEditingMode === PlannerMode.ReadOnly
    },

    // Checks whether advanced mode is available
    canUseAdvancedMode () {
      return !this.isReadOnly && this.canUse('planner-advanced')
    },

    // Checks whether debugging is available
    canDebug () {
      return this.isSuperAdministrator
    },

    // Returns a list of devices which are permitted to be monitored for live status.
    // If customer is on premium plan, this requires live status subscription.
    liveDevices () {
      const { planDevices, organizationGuardian, currentOrganizationGuardian } = this
      let liveDevices
      if (currentOrganizationGuardian && organizationGuardian) {
        liveDevices = currentOrganizationGuardian.requiresPremiumSubscription('live-status')
          ? planDevices.filter(({ serialNumber }) => organizationGuardian.canDeviceUse('live-status', serialNumber, currentOrganizationGuardian))
          : (currentOrganizationGuardian.canUse('live-status') ? planDevices : [])
      } else {
        liveDevices = []
      }

      // Return only actual communicating boards
      return liveDevices.filter(d => d.isConnectedDevice && !d.isMultiDevice)
    },

    // Indicates whether any devices can be monitored live
    hasLiveDevices () {
      return this.liveDevices.length > 0
    },

    // Icon for place notes, indicating whether there are file attachments as well
    notesIcon () {
      const { place } = this.plan
      if (place) {
        return place.hasAttachments || place.hasNotes ? 'mode_comment' : 'comment'
      }
    },
  },

  methods: {
    ...mapActions([
      'goBack',
      'getGuardian',
      'toggleSidebar',
      'editPlan',
      'editPlanFloor',
      'savePlan',
      'createPlanSnapshot',
      'deletePlanSnapshot',
      'restorePlanSnapshot',
      'deletePlan',
      'saveFloorImage',
      'updatePlanImage',
      'updateRoute',
      'setPlannerMode',
      'setPlannerView',
      'getLiveStatus',
      'watchDeviceStatus',
      'unwatchDeviceStatus',
      'getPlace',
      'showPlaceNotes',
    ]),

    // Triggered when plan has been loaded
    async planInitialized ({ renderer, layout, hierarchy }) {
      this.isDebugging = renderer.isDebugging

      const { plan, organization } = this
      if (!(plan && organization)) return

      // Get the guardian for the plan owner
      this.organizationGuardian = await this.getGuardian({ principal: organization, force: true })

      // Initialize the device list
      this.populateDevices(layout, hierarchy)

      renderer.events.addEventListener('hierarchy-changed', ({ detail: { layout, hierarchy } }) => {
        this.populateDevices(layout, hierarchy)
      })

      // Show current position in debug mode
      renderer.events.addEventListener('position', ({ detail: { position } }) => {
        this.pointerPosition = position
      })
    },

    // Returns the renderer
    getRenderer () {
      const renderer = this.$refs.plan?.renderer
      return renderer
    },

    // Additional data requested when rendering a plan item
    dataRequested ({ item }) {
      if (item && item.isDevice && item.serialNumber) {
        const { planDevices, deviceStatus } = this
        const device = planDevices.find(d => d.serialNumber === item.serialNumber)
        const status = deviceStatus[item.serialNumber]
        return { device, status }
      }
    },

    // Triggered when plan has been changed
    planChanged ({ renderer }) {
      if (this.autoSave) {
        return this.save({ renderer })
      }
    },

    // Notifies about changes to the equipment hierarchy
    hierarchyChanged ({ layout, hierarchy }) {
      this.populateDevices(layout, hierarchy)
    },

    // Determines which actual devices are currently being viewed on the plan
    async populateDevices (layout, hierarchy) {
      if (layout && hierarchy) {
        const { devices = [] } = hierarchy
        const serials = devices
          .map(d => d.serialNumber)
          .reduce((all, s) => ({ ...all, [s]: true }), {})
        this.planDevices = this.devices.filter(d => serials[d.serialNumber])
        await this.watchStatus()
      } else {
        this.planDevices = []
      }
    },

    // Triggered when user selects another floor
    async selectFloor ({ renderer, floor }) {
      await this.save({ renderer })
      const floorId = floor?.id || PlanFloors.CrossSection
      this.updateRoute({ params: { floorId } })
    },

    // Triggered when user selects cross-section
    async selectCrossSection ({ renderer }) {
      await this.save({ renderer })
      const floorId = PlanFloors.CrossSection
      this.updateRoute({ params: { floorId } })
    },

    // Saves the changed layout
    async save ({ renderer, force } = {}) {
      if (renderer) {
        const { plan, place } = this
        if (!(plan && place)) return

        const { layout } = renderer
        plan.layout = layout
        if (layout.isSaving) return
        if (!(layout.isDirty || force)) return

        // Clear out any leftovers from image import tool
        for (const floor of layout.floors) {
          if (floor.background.image) {
            delete floor.background.image.file
            delete floor.background.image.parts
          }
        }

        // Bump layout version
        layout.saving()
        layout.changed(true)

        try {
          // Save with a brief delay so the user notices
          await this.savePlan({ plan })
          Log.debug(`Plan [${place.name}] saved`)
          layout.saved()
        } catch (e) {
          Log.error(`Plan [${place.name}] could not be saved`)
          Log.exception(e)
          layout.saved(e)
        }
      }
    },

    // Saves the floor image
    async saveImage ({ renderer, floor, image } = {}) {
      if (renderer) {
        const { plan } = this
        const { layout } = renderer
        if (layout.isSaving) return

        // Bump layout version
        layout.saving()
        layout.changed(true)

        try {
          // Save content as binary file, rather than Base64-encoded.
          // With larger files Base64 can exceed the file upload size.
          if (image) {
            image.file = new File([image.content], image.name, { type: image.mimeType })
            image.content = null
          }
          // Save with a brief delay so the user notices
          await this.saveFloorImage({ plan, floor, image })
          Log.debug(image ? `${floor.label}: Image saved` : `${floor.label}: Image removed`)

        } finally {
          layout.saved()
        }
      }
    },

    // Saves the plan snapshot
    async saveSnapshot ({ name, description } = {}) {
      const { plan } = this
      const snapshot = await this.createPlanSnapshot({ plan, name, description })
      if (snapshot) {
        Notification.success({ message: `Plan snapshot ${snapshot.name.toUpperCase()} has been created` })
      }
    },

    // Deletes the specified plan snapshot
    async deleteSnapshot ({ snapshot } = {}) {
      await this.deletePlanSnapshot({ snapshot })
      Notification.success({ message: `Plan snapshot ${snapshot.name.toUpperCase()} has been deleted` })
    },

    // Restores the specified plan snapshot
    async restoreSnapshot ({ renderer, snapshot } = {}) {
      await this.restorePlanSnapshot({ snapshot })
      renderer.layout = this.plan.layout
      renderer.save()
      Notification.success({ message: `Plan snapshot ${snapshot.name.toUpperCase()} has been restored` })
    },

    // Cancels changes and goes back to the previous view
    async cancel () {
      await this.goBack()
    },

    // Displays help
    showHelp () {
      this.$refs.plan.showHelp()
    },

    // Displays the current plan state
    showState ({ state, label }) {
      this.state = state
      // Suppress SAVE label
      this.stateLabel = state === PlanState.Saving ? null : label
    },

    // Toggles between regular and advanced mode
    toggleAdvancedMode () {
      const { canUseAdvancedMode, isAdvancedMode } = this
      if (canUseAdvancedMode) {
        const mode = isAdvancedMode ? PlannerMode.Regular : PlannerMode.Advanced
        this.setPlannerMode({ mode })
      }
    },

    // Toggles renderer debug mode
    toggleDebugging () {
      const { plan } = this.$refs
      if (plan) {
        this.isDebugging = !this.isDebugging
        plan.debug(this.isDebugging)
      }
    },

    // Toggles maximal/normal view
    async toggleMaximize () {
      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen()
        await this.setPlannerView({ isMaximized: false })

      } else if (!document.fullscreenElement && document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
        await this.setPlannerView({ isMaximized: true })
      }

      await this.toggleSidebar({
        isCollapsed: this.isMaximized,
        persistent: false
      })
    },

    // Starts watching for device status
    async watchStatus () {
      if (!this.startingStatusWatch) {
        this.startingStatusWatch = true
        try {
          await this.unwatchStatus()

          // Start watching live status of permitted devices
          if (this.hasLiveDevices) {
            const devices = this.liveDevices
            await this.watchDeviceStatus({
              name,
              devices,
              onStatus: (device, status) => this.onDeviceStatus(device, status)
            })
          }

          // Fetch most-recent status of remaining devices
          await this.getLiveStatus({
            devices: this.nonLiveDevices
          })

        } finally {
          this.startingStatusWatch = false
        }
      }
    },

    // Stops watching the device status
    async unwatchStatus () {
      await this.unwatchDeviceStatus({ name })
    },

    // Triggered when device status arrives
    onDeviceStatus (device, status) {
      if (device && status) {
        const renderer = this.getRenderer()
        if (renderer) {
          const item = renderer.layout.getDevice(device.serialNumber)
          renderer.updateData(item, { device, status })
        }
      }
    },

    // Starts editing the plan notes
    async editNotes () {
      const building = await this.getPlace({ id: this.plan.placeId, withAttachments: true })
      if (building) {
        const renderer = this.getRenderer()
        try {
          renderer?.blur()
          this.showPlaceNotes({ place: building })
        } finally {
          renderer?.focus()
        }
      }
    },

    // Shows the dialog for selecting and editing the background image(s)
    async importPlanImages () {
      const renderer = this.getRenderer()
      if (renderer) {
        this.$refs.plan.importPlanImages({ renderer })
      }
    }
  },

  created () {
    // For super-admin running locally turn on the advanced mode by default
    const mode = this.isSuperAdministrator && this.isDevelopmentEnvironment
      ? PlannerMode.Advanced
      : (this.isAdministrator ? PlannerMode.Regular : PlannerMode.ReadOnly)
    this.setPlannerMode({ mode })
  },

  // Reload data on navigation to another plan or floor
  async beforeRouteUpdate (to, from, next) {
    const isPlanChanged = this.plan.id !== to.params.id
    const isFloorChanged = this.plan.id === to.params.id && this.floorId !== to.params.floorId

    if (isPlanChanged) {
      // If plan changed, do a full reload
      if (await resolvePlan({ from, to })) {
        return next()
      }
    } else if (isFloorChanged) {
      // If the same plan but the floor changed, just select that floor
      if (await resolvePlanFloor({ from, to })) {
        return next()
      }
    }

    next({ name: 'home' })
  }
}

</script>

<template>
  <sc-view :name="name" :title="title" :breadcrumbs="breadcrumbs" :noHeader="isMaximized"
    bgColor="#fafafa">
    <template #toolbar>
      <div class="q-gutter-xs row items-center" v-if="!isMaximized">
        <div class="plan-state q-mr-xl bg-green-6 text-white" v-if="stateLabel">
          {{ stateLabel }}
        </div>
        <q-icon v-if="isInitialized" unelevated name="cloud_upload" size="md" class="q-mr-sm"
          :color="saveIconColor">
          <sc-tooltip>
            {{ saveFailed
    ? 'The last save has failed. It will be repeated again'
    : 'The plan is saved automatically'
            }}
          </sc-tooltip>
        </q-icon>
        <q-btn v-if="isInitialized && isDebugging && pointerPosition"
          :label="pointerPosition.toString() || ''" unelevated
          :class="isDebugging ? 'bg-orange-7 text-white' : undefined">
        </q-btn>
        <q-btn v-if="isInitialized && canDebug" icon="memory" unelevated label="Debug"
          :class="isDebugging ? 'bg-orange-7 text-white' : undefined" @click="toggleDebugging()">
          <sc-tooltip>Enable debug mode</sc-tooltip>
        </q-btn>
        <q-btn v-if="isInitialized && canUseAdvancedMode" label="Advanced"
          :icon="isAdvancedMode ? 'lock_open' : 'lock'" unelevated @click="toggleAdvancedMode()">
          <sc-tooltip>
            {{ isAdvancedMode ? 'Enter the advanced mode' : 'Back to normal mode' }}
          </sc-tooltip>
        </q-btn>
        <q-btn v-if="isInitialized" icon="fullscreen" unelevated @click="toggleMaximize()"
          label="Full Screen">
          <sc-tooltip>Maximize the plan</sc-tooltip>
        </q-btn>
        <q-btn v-if="isInitialized" icon="help" unelevated @click="showHelp()" label="Help">
          <sc-tooltip>Show help</sc-tooltip>
        </q-btn>
        <q-btn v-if="isInitialized" label="Import" unelevated @click="importPlanImages()">
          <sc-tooltip nowrap>Import plan images</sc-tooltip>
        </q-btn>
        <q-btn v-if="isInitialized" dense unelevated :icon="notesIcon" textColor="indigo-5"
          @click.stop="editNotes()">
          <sc-tooltip nowrap>Edit place notes</sc-tooltip>
        </q-btn>
      </div>
    </template>

    <sc-plan v-if="plan" ref="plan" :is-debugging="isDebugging" :devices="devices" :place="place"
      :plan="plan" :layout="plan.layout" :floorId="floorId" :isMaximized="isMaximized"
      :initialized="(args) => planInitialized(args)" :data-requested="(args) => dataRequested(args)"
      :changed="(args) => planChanged(args)" :saved="(args) => save(args)"
      :image-saved="(args) => saveImage(args)" :snapshot-saved="(args) => saveSnapshot(args)"
      :snapshot-deleted="(args) => deleteSnapshot(args)"
      :snapshot-restored="(args) => restoreSnapshot(args)"
      :floor-selected="(args) => selectFloor(args)"
      :cross-section-selected="(args) => selectCrossSection(args)"
      :state-changed="(args) => showState(args)">
    </sc-plan>

    <div class="toolbar-maximized row items-center" v-if="isMaximized">
      <div class="plan-state q-mr-lg bg-green-6 text-white" v-if="stateLabel">
        {{ stateLabel }}
      </div>
      <q-btn v-if="isInitialized && !isReadOnly"
        :label="isAdvancedMode ? 'Advanced Mode' : 'Normal Mode'" icon="tune" class="q-mr-xs"
        :class="isAdvancedMode ? 'bg-orange-7 text-white' : undefined" unelevated
        @click="toggleAdvancedMode()">
      </q-btn>
      <q-btn unelevated flat v-if="isInitialized" class="button-minimize bg-orange-7 text-white"
        icon="fullscreen_exit" @click="toggleMaximize()">
      </q-btn>
    </div>
    <sc-document-upload-dialog></sc-document-upload-dialog>
  </sc-view>
</template>

<style lang="scss" scoped>
.toolbar-maximized {
  position: absolute;
  right: 280px;
  top: 6px;
}

:deep(.q-tab-panel) {
  padding: 0 !important;
}

.plan-state {
  padding: 4px 8px 5px 8px;
  border-radius: 4px;
  font-size: 13px;
}
</style>