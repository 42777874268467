import { Log } from '@stellacontrol/utilities'
import { dispatch } from '@stellacontrol/client-utilities'
import { Place, getDeviceLabel } from '@stellacontrol/model'

/**
 * Prepares the data required by device dashboard
 */
export async function resolve ({ from, to }) {
  let { serialNumber } = to.params || {}
  let { part: partNumber } = to.query || {}

  // Fetch the required prerequisites
  const currentOrganization = await dispatch('getCurrentOrganization')
  if (!currentOrganization) {
    return {
      redirectTo: {
        name: 'login'
      }
    }
  }

  const organizations = await dispatch('requireOrganizations')
  const places = await dispatch('requirePlaces')
  const devices = await dispatch('requireDevices')

  // Determine the device to see
  const device = await dispatch('getDeviceBySerialNumber', { serialNumber, withDetails: true })
  if (!device) {
    Log.warn(`[${serialNumber}] Device not found`)
    return {
      redirectTo: {
        name: 'installations'
      }
    }
  }

  // Get the device part to see, its owner, place etc.
  const devicePart = partNumber ? devices.find(d => d.serialNumber === partNumber) : undefined
  const organization = device.ownerId
    ? organizations.find(o => o.id === device.ownerId)
    : (currentOrganization.isSuperOrganization ? currentOrganization : undefined)
  const place = device.placeId
    ? places.find(p => p.id === device.placeId)
    : Place.NoPlace

  // Check whether the user is allowed to see this device
  const hasData = (!partNumber || devicePart) && !device.isDecommissioned && place && organization
  if (!hasData) {
    Log.warn(`[${serialNumber}${partNumber ? ':' : ''}${partNumber || ''}] Unauthorized access to device dashboard`)
    return {
      redirectTo: { name: 'installations' }
    }
  }

  // Show the device dashboard
  const title = [getDeviceLabel(device), place?.name, organization?.name]
    .filter(s => s?.trim())
    .join(' | ')
  await dispatch('setRouteData', { from, to, title, replaceTitle: true })
  await dispatch('initializeDeviceDashboard', { device, devicePart })

  return { device, devicePart }
}
