import Konva from 'konva'
import { Rectangle } from '@stellacontrol/utilities'
import { PlanItemType, PlanItemState } from '@stellacontrol/planner'
import { Shape } from './shape'

/**
 * Line
 */
export class LineShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Line
  }

  /**
   * Line shape
   * @type {Konva.Line|Konva.Arrow}
   */
  lineShape

  /**
   * Returns an array of coordinates of shape points
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Array[Point]}
   */
  // eslint-disable-next-line no-unused-vars
  getShapePoints ({ renderer }) {
    return this.item.points
  }

  createShapes () {
    super.createShapes()
    this.lineShape = new Konva.Arrow()
    this.content.add(this.lineShape)
  }

  /**
   * Line labels need a border
   * @type {Number}
   */
  get labelMargin () {
    return 5
  }

  /**
   * Returns shape boundaries
   * @param {PlanRenderer} renderer Plan renderer
   * @type {Rectangle}
   */
  getBounds ({ renderer }) {
    const points = this.getShapePoints({ renderer })
    return Rectangle.fromPoints(points)
  }

  /**
   * Show line boundaries, used for debugging
   */
  get showShapeBoundaries () {
    return false
  }

  /**
   * Returns coordinates of the label.
   * Label should be rendered on the central segment of the line.
   * @param {PlanRenderer} renderer Plan renderer
   * @returns {Point}
   */
  getLabelPosition (renderer) {
    super.getLabelPosition(renderer)
    const points = this.getShapePoints({ renderer })

    if (points.length > 1) {
      // Find longest line
      let longest = 0
      let maxLength = 0
      for (let i = 0; i < points.length - 1; i++) {
        const point = points[i]
        const next = points[i + 1]
        const distance = next.distance(point)
        if (distance >= maxLength) {
          longest = i
          maxLength = distance
        }
      }

      // Place the label in the middle of it
      const { label, labelText, labelMargin } = this
      const position = Rectangle
        .fromPoints([points[longest], points[longest + 1]])
        .center
        .moveBy({
          x: -labelText.width() * label.scaleX() / 2 - labelMargin * label.scaleX(),
          y: -labelText.height() * label.scaleY() / 2 - labelMargin * label.scaleY()
        })

      return position

      // This older algorithm places the label in the middle segment of the line,
      // regardless of its length
      //
      // const { label, labelText, labelMargin } = this
      // const i = Math.floor((points.length - 1) / 2)
      // const position = Rectangle
      //   .fromPoints([points[i], points[i + 1]])
      //   .center
      //   .moveBy({
      //     x: -labelText.width() * label.scaleX() / 2 - labelMargin * label.scaleX(),
      //     y: -labelText.height() * label.scaleY() / 2 - labelMargin * label.scaleY()
      //   })
      //
      // return position
    }
  }

  render (renderer) {
    super.render(renderer)

    const { lineShape, item, isPointedAt } = this
    const isSelected = renderer.isItemSelected(item)
    const isActive = isPointedAt || isSelected

    if (lineShape && item) {
      const { hasArrows, arrowStyle } = item
      const lineStyle = this.getLineStyle(item, item.lineStyle, isActive ? PlanItemState.Hover : PlanItemState.Normal, renderer)
      const { dash, width, color, lineCap, lineJoin } = lineStyle
      const flatPoints = this.toCoordinates(this.getShapePoints({ renderer }))

      lineShape.points(flatPoints)
      lineShape.stroke(color)
      lineShape.strokeWidth(width)
      lineShape.dash(dash)
      lineShape.lineJoin(lineJoin)
      lineShape.lineCap(lineCap)

      if (hasArrows) {
        const ratio = Math.max(2, 6 - width)
        const pointerWidth = Math.round(arrowStyle.width || width * ratio)
        const pointerHeight = Math.round(arrowStyle.height || width * ratio)
        lineShape.pointerWidth(pointerWidth)
        lineShape.pointerLength(pointerHeight)
        lineShape.pointerAtBeginning(arrowStyle.start)
        lineShape.pointerAtEnding(arrowStyle.end)
        lineShape.fill(color)
      } else {
        lineShape.pointerAtBeginning(false)
        lineShape.pointerAtEnding(false)
      }
      this.applyFilters()
    }
  }

  renderLabel (renderer) {
    super.renderLabel(renderer)
    const { item, labelBorder } = this
    labelBorder.stroke(item.lineStyle.color)
  }

  /**
   * Triggered after moving item to new position
   * @param {Point} position Position to which the item was moved
   */
  moveTo (position) {
    super.moveTo(position)
    // Since line is rendered using absolute point coordinates,
    // move the group shape back to 0,0
    this.content.x(0)
    this.content.y(0)
  }
}
