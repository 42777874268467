<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { sortItemsReverse } from '@stellacontrol/utilities'
import { Secure } from '@stellacontrol/security-ui'
import { AttachmentDataTypeLabel } from '@stellacontrol/model'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    Secure,
    DeviceWidget
  ],

  data () {
    return {
      results: [],
      AttachmentDataTypeLabel
    }
  },

  computed: {
    ...mapState({
      // Network scans collected by the device
      scans: state => state.deviceDashboard.scans,
    }),

    ...mapGetters([
      'toLocalDateTime'
    ]),

    // Returns true if device scan results are available
    hasScanResults () {
      return this.scans?.length > 0
    },

    // Scan groups
    groups () {
      const { scans = [] } = this
      const automatic = sortItemsReverse(scans.filter(s => s.wasAutomatic), 'createdAt')
      const manual = sortItemsReverse(scans.filter(s => !s.wasAutomatic), 'createdAt')

      return [
        { label: 'Manual Scans', scans: manual, isEmpty: manual.length === 0 },
        { label: 'Automatic Scans', scans: automatic, isEmpty: automatic.length === 0 }
      ]
    }
  },

  methods: {
    ...mapActions([
      'getAttachment',
      'previewAttachment'
    ]),

    // Displays preview of the scan
    async previewScan (item) {
      const attachment = await this.getAttachment({
        id: item.id,
        withContent: true
      })

      if (attachment?.canPreview || attachment?.canPreviewInFrame) {
        this.previewAttachment({ attachment })
      }
    }
  }
}
</script>

<template>
  <sc-widget v-bind="{ ...$props, ...$attrs }" v-if="hasScanResults" width="320px">
    <div class="scans">
      <template v-for="group in groups">
        <header v-if="!group.isEmpty">
          {{ group.label }} ({{ group.scans.length }})
        </header>

        <section v-if="!group.isEmpty">
          <a class="item item-link" v-for="(item, index) in group.scans" :key="index"
            @click="previewScan(item)">
            <q-badge rounded outline :color="item.wasOutdoorScan ? 'blue' : 'green'">
              <span class="badge">
                {{ item.wasOutdoorScan ? 'O' : 'I' }}
              </span>
              <sc-tooltip>
                {{ item.wasOutdoorScan ? 'Outdoor scan' : 'Indoor scan' }}
              </sc-tooltip>
            </q-badge>
            <span class="note">
              {{ item.note || AttachmentDataTypeLabel[item.type] }}
              <sc-tooltip v-if="item.note?.length > 20">
                {{ item.note }}
              </sc-tooltip>
            </span>
            <span class="time">
              {{ datetime(toLocalDateTime(item.createdAt), 'dd/MM/yyyy HH:mm') }}
            </span>
          </a>
        </section>
      </template>
    </div>
  </sc-widget>
</template>

<style lang="scss" scoped>
.scans {
  padding: 8px 0px 8px 12px;
  background-color: white;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;

  >header {
    font-size: 13px;
    font-weight: bold;
  }

  >section {
    padding-right: 8px;
    padding-bottom: 8px;

    &:last-child {
      padding-bottom: 0;
    }

    .item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 6px 0 6px 4px;
      font-size: 13px;
      color: #4a4a4a;

      &:hover {
        background-color: #fafafa;
      }

      .badge {
        font-family: monospace;
      }

      .note {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 8px;
      }

      .time {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 5px;
        width: 110px;
      }
    }

  }
}
</style>
