<script>
import { mapGetters, mapActions } from 'vuex'
import { User } from '@stellacontrol/model'
import { ViewMixin, TabsMixin, EditorMixin } from '@stellacontrol/client-utilities'
import { Secure } from '../../components'
import MyAccountGeneral from './my-account-general.vue'
import MyAccountActivity from './my-account-activity.vue'
import ChangePassword from '../user/change-password.vue'

const name = 'my-account'

export default {
  mixins: [
    ViewMixin,
    TabsMixin,
    EditorMixin,
    Secure
  ],

  components: {
    'sc-my-account-general': MyAccountGeneral,
    'sc-my-account-activity': MyAccountActivity,
    'sc-change-password': ChangePassword
  },

  data () {
    return {
      name,
      tab: 'general'
    }
  },

  computed: {
    ...mapGetters([
    ]),

    forms () {
      return [
        'general'
      ]
    }
  },

  methods: {
    ...mapActions([
      'showDialog',
      'saveUser',
      'goBack',
      'updateSessionUser',
      'cancelUser',
      'changePassword'
    ]),

    // Saves changes
    async save (goBack) {
      if (await this.validate()) {
        // Save, but without permissions!
        const user = new User({
          ...this.data,
          permissions: undefined
        })
        await this.saveUser({ user, goBack: true })

        // Update user data in the session,
        // so any changes to user details are reflected immediately
        await this.updateSessionUser({ user: this.data })
        if (goBack) {
          await this.goBack()
        } else {
          return true
        }
      } else {
        this.tab = 'general'
      }
    },

    // Cancels changes and goes back to the previous view
    async cancel () {
      await this.goBack()
    }
  }
}
</script>

<template>
  <sc-view :name="name" v-if="isLoggedIn">
    <template #toolbar>
      <q-btn label="Change Password" unelevated @click="changePassword({ user: data })"
        v-if="canUse('can-change-password')" />
      <q-btn label="Save" unelevated class="primary" @click="save()" />
      <q-btn label="Close" unelevated @click="cancel()" />
    </template>

    <sc-tabs v-model="tab">
      <q-tab name="general" icon="create" label="My Details" :ripple="false"></q-tab>
      <q-tab name="activity" v-if="false" icon="today" label="My Activity" :ripple="false"></q-tab>
    </sc-tabs>

    <sc-tab-panels v-model="tab">
      <q-tab-panel name="general">
        <sc-my-account-general :data="data" ref="general"></sc-my-account-general>
      </q-tab-panel>
      <q-tab-panel name="activity" v-if="false">
        <sc-my-account-activity :data="data" ref="activity"></sc-my-account-activity>
      </q-tab-panel>
    </sc-tab-panels>

    <sc-change-password></sc-change-password>

  </sc-view>
</template>

<style lang="scss" scoped></style>