/**
 * Safe string comparison
 * @param {String} a String to compare to
 * @param {String} b String to compare with
 * @param {Boolean} caseSensitive If true, comparison is case-sensitive
 * @returns {Number} Zero if values are equal, 1 if left value is greater than right value, -1 if otherwise.
 * If some of the input values are not defined, the function does not return anything.
 */
export function stringCompare (a, b, caseSensitive = true) {
  return caseSensitive
    ? (a || '').toString().localeCompare(b || '')
    : (a || '')
      .toString()
      .toLowerCase()
      .localeCompare((b || '')
        .toString()
        .toLowerCase())
}

/**
 * Safe string comparison, in reverse order
 * @param {String} a String to compare to
 * @param {String} b String to compare with
 * @param {Boolean} caseSensitive If true, comparison is case-sensitive
 * @returns {Number} Zero if values are equal, -1 if left value is greater than right value, 1 if otherwise.
 * If some of the input values are not defined, the function does not return anything.
 */
export function stringCompareReverse (a, b, caseSensitive = true) {
  const result = stringCompare(a, b, caseSensitive)
  return result === 0 ? 0 : -result
}

/**
 * Safe number comparison
 * @param {Number} a Number to compare to
 * @param {Number} b Number to compare with
 * @returns {Number} Zero if values are equal, 1 if left value is greater than right value, -1 if otherwise.
 * If some of the input values are not defined, the function does not return anything.
 */
export function numberCompare (a, b) {
  if (a == null && b == null) {
    return 0
  } else if (a != null && b != null) {
    const na = parseFloat(a)
    const nb = parseFloat(b)
    if (isNaN(na) && isNaN(b)) return 0
    if (isNaN(na)) return -1
    if (isNaN(nb)) return 1
    return (na > nb) ? 1 : (na < nb ? -1 : 0)
  } else if (a != null && b == null) {
    return 1
  } else {
    return -1
  }
}

/**
 * Safe number comparison, in reverse order
 * @param {Number} a Number to compare to
 * @param {Number} b Number to compare with
 * @returns {Number} Zero if values are equal, -1 if left value is greater than right value, 1 if otherwise.
 * If some of the input values are not defined, the function does not return anything.
 */
export function numberCompareReverse (a, b) {
  const result = numberCompare(a, b)
  return result === 0 ? 0 : -result
}

/**
 * Safe boolean comparison
 * @param {Boolean} a Boolean to compare to
 * @param {Boolean} b Boolean to compare with
 * @returns {Number} Zero if values are equal, 1 if left value is greater than right value, -1 if otherwise.
 * If some of the input values are not defined, the function does not return anything.
 */
export function booleanCompare (a, b) {
  if (a == null && b == null) {
    return 0
  } else if (a != null && b != null) {
    return (a && !b) ? 1 : (!a && b ? -1 : 0)
  } else if (a != null && b == null) {
    return 1
  } else {
    return -1
  }
}

/**
 * Safe boolean comparison, in reverse order
 * @param {Boolean} a Boolean to compare to
 * @param {Boolean} b Boolean to compare with
 * @returns {Number} Zero if values are equal, -1 if left value is greater than right value, 1 if otherwise.
 * If some of the input values are not defined, the function does not return anything.
 */
export function booleanCompareReverse (a, b) {
  const result = booleanCompare(a, b)
  return result === 0 ? 0 : -result
}

/**
 * Safe date comparison
 * @param {Date} a Date to compare to
 * @param {Date} b Date to compare with
 * @returns {Number} Zero if values are equal, 1 if left value is greater than right value, -1 if otherwise.
 * If some of the input values are not defined, the function does not return anything.
 */
export function dateCompare (a, b) {
  if (a == null && b == null) {
    return 0
  } else if (a != null && b != null) {
    return (a > b) ? 1 : (a < b ? -1 : 0)
  } else if (a != null && b == null) {
    return 1
  } else {
    return -1
  }
}

/**
 * @param {Date} a Date to compare to
 * @param {Date} b Date to compare with
 * @returns {Number} Zero if values are equal, -1 if left value is greater than right value, 1 if otherwise.
 * If some of the input values are not defined, the function does not return anything.
 */
export function dateCompareReverse (a, b) {
  const result = dateCompare(a, b)
  return result === 0 ? 0 : -result
}

/**
 * Extracts value from the specified item
 * depending on value types
 * @param {String|Number|Boolean|Date|Object} a Item to extract a value
* @param {String|Function} getter Optional property name or function for extracting value
* to compare from compared items. If not specified, input value is returned.
 * @returns {String|Number|Boolean|Date|Object} Value extracted from the item
 */
export function itemValue (item, getter) {
  if (item == null) {
    return item
  } else {
    const isProperty = typeof getter === 'string' || typeof getter === 'number'
    const isFunction = typeof getter === 'function'

    return isFunction ? getter(item) : (isProperty ? item[getter] : item)
  }
}

/**
* Compares two values, using the best comparison method
* depending on value types
* @param {String|Number|Boolean|Date|Object} a Item to compare
* @param {String|Number|Boolean|Date|Object} b Item to compare with
* @param {String|Function} getter Optional property name or function for extracting value
* to compare from compared items. If not specified, values are compared directly.
* @returns {Number} Zero if values are equal, 1 if {@link a} is greater than {@link b}, otherwise -1
 * @param {Boolean} caseSensitive If true and comparing strings, comparison is case-sensitive
*/
export function itemCompare (a, b, getter, caseSensitive = true) {
  if (a === b) return 0
  if (a == null && b == null) return 0

  const isProperty = typeof getter === 'string' || typeof getter === 'number'
  const isFunction = typeof getter === 'function'
  let va = isFunction ? getter(a) : (isProperty ? a[getter] : a)
  let vb = isFunction ? getter(b) : (isProperty ? b[getter] : b)

  let result

  if (typeof va === 'number' && typeof vb === 'number') {
    result = numberCompare(va, vb)
  } else if (typeof va === 'boolean' && typeof vb === 'boolean') {
    result = booleanCompare(va, vb)
  } else if (va instanceof Date && vb instanceof Date) {
    result = dateCompare(va, vb)
  } else if (Array.isArray(va) && Array.isArray(vb)) {
    return objectCompare(va, vb) ? 0 : -1
  } else if (typeof va === 'object' && typeof vb === 'object') {
    return objectCompare(va, vb) ? 0 : -1
  } else {
    result = stringCompare(va, vb, caseSensitive)
  }

  return result
}

/**
 * Recursively compares two objects for differences in properties.
 * @param {Object} a Instance to compare
 * @param {Object} b Instance to compare with
 * @param {Boolean} strict If set to `true`, the order of properties and array elements also matters.
 * By default, the order is ignored and properties or elements can be ordered freely, as long as
 * they are all present and have identical values.
 * @returns {Boolean} Returns `true` when objects are identical, `false` when they are not
 */
export function objectCompare (a, b, strict) {
  // Quick check for identity
  if (a === b) return true

  // Handle nulls and undefineds
  if (a == null && b != null) return false
  if (b == null && a != null) return false
  if (a == null && b == null && a !== b) return false

  // Check for type mismatch
  const aType = typeof a
  const bType = typeof b
  const aIsArray = Array.isArray(a)
  const bIsArray = Array.isArray(b)
  if (aType !== bType) return false
  if (aIsArray !== bIsArray) return false

  // Check for key/element length mismatch
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  if (aKeys.length !== bKeys.length) return false


  // Strict check for key/element order
  if (strict) {
    if (aIsArray) {
      const aValues = Object.values(a)
      const bValues = Object.values(b)
      if (aValues.some((value, index) => !objectCompare(value, bValues[index], strict))) return false
    } else {
      if (aKeys.some((key, index) => key !== bKeys[index])) return false
    }
  }

  // Recursively check identity of array values
  if (aIsArray) {
    for (const aValue of a) {
      if (!b.some(bValue => objectCompare(aValue, bValue, strict))) return false
    }
    return true
  }

  // Recursively check identity of object properties
  if (aType === 'object') {
    for (const key of aKeys) {
      const aValue = a[key]
      const bValue = b[key]
      if (!objectCompare(aValue, bValue, strict)) return false
    }
    return true
  }

  // All checks failed, values are not the same
  return false
}

/**
 * Compares multiple object instances using {@link objectCompare},
 * returns true if all are the same
 * @param {Array} instances Instances to compare
 * @param {Boolean} strict If true, properties / elements are checked for identical position as well
 * @returns {Boolean} Returns `true` when objects are identical, `false` when they are not
 */
export function objectsCompare (instances, strict) {
  if (instances?.length > 0) {
    if (instances?.length === 1) return true
    const first = instances[0]
    return instances.slice(1).every(i => objectCompare(i, first, strict))
  }
}

/**
 * Returns the first of the values which is not null or undefined
 * @param {Array} values Values to scan
 * @returns {any} The first value which is not null or undefined
 */
export function notNull (...values) {
  return values.find(value => value != null)
}

/**
 * Alias for {@link notNull} using the same terminology as SQL
 * @param {Array} values Values to scan
 * @returns {any} The first value which is not null or undefined
 */
export function coalesce (...values) {
  return notNull(...values)
}
