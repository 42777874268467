export const actions = {
  /**
   * Triggered when new user session has started
   */
  async initializeApplet ({ commit, dispatch }) {
    const isCollapsed = await dispatch('getUserPreference', { name: 'sidebar-collapsed', defaultValue: false })
    commit('toggleSidebar', { isCollapsed })
  },

  /**
   * Toggles sidebar visibility
   * @param {Boolean} isCollapsed If specified, visibility is set to this value otherwise it's toggled
   * @param {Boolean} persistent If true, the state of the sidebar is stored in the preferences
   */
  async toggleSidebar ({ state, commit, dispatch }, { isCollapsed, persistent = true } = {}) {
    commit('toggleSidebar', { isCollapsed })
    // Don't let casual mobile viewers permanently impact the regular web view
    if (persistent) {
      await dispatch('storeUserPreference', { name: 'sidebar-collapsed', value: state.sidebar.isCollapsed })
    }
  },

  /**
   * Hides the sidebar
   * @param {Boolean} persistent If true, the state of the sidebar is stored in the preferences
  */
  async collapseSidebar ({ state, commit, dispatch }, { persistent = true } = {}) {
    if (!state.sidebar.isCollapsed) {
      commit('toggleSidebar', { isCollapsed: true })
      if (persistent) {
        await dispatch('storeUserPreference', { name: 'sidebar-collapsed', value: true })
      }
    }
  },

  /**
   * Shows the sidebar
   * @param {Boolean} persistent If true, the state of the sidebar is stored in the preferences
   */
  async expandSidebar ({ state, commit, dispatch }, { persistent = true } = {}) {
    if (state.sidebar.isCollapsed) {
      commit('toggleSidebar', { isCollapsed: false })
      if (persistent) {
        await dispatch('storeUserPreference', { name: 'sidebar-collapsed', value: false })
      }
    }
  }
}
