import { randomInt } from './number'

export const HTMLColors = {
  'aliceblue': '#f0f8ff', 'antiquewhite': '#faebd7', 'aqua': '#00ffff', 'aquamarine': '#7fffd4', 'azure': '#f0ffff',
  'beige': '#f5f5dc', 'bisque': '#ffe4c4', 'black': '#000000', 'blanchedalmond': '#ffebcd', 'blue': '#0000ff', 'blueviolet': '#8a2be2', 'brown': '#a52a2a', 'burlywood': '#deb887',
  'cadetblue': '#5f9ea0', 'chartreuse': '#7fff00', 'chocolate': '#d2691e', 'coral': '#ff7f50', 'cornflowerblue': '#6495ed', 'cornsilk': '#fff8dc', 'crimson': '#dc143c', 'cyan': '#00ffff',
  'darkblue': '#00008b', 'darkcyan': '#008b8b', 'darkgoldenrod': '#b8860b', 'darkgray': '#a9a9a9', 'darkgreen': '#006400', 'darkkhaki': '#bdb76b', 'darkmagenta': '#8b008b', 'darkolivegreen': '#556b2f',
  'darkorange': '#ff8c00', 'darkorchid': '#9932cc', 'darkred': '#8b0000', 'darksalmon': '#e9967a', 'darkseagreen': '#8fbc8f', 'darkslateblue': '#483d8b', 'darkslategray': '#2f4f4f', 'darkturquoise': '#00ced1',
  'darkviolet': '#9400d3', 'deeppink': '#ff1493', 'deepskyblue': '#00bfff', 'dimgray': '#696969', 'dodgerblue': '#1e90ff',
  'firebrick': '#b22222', 'floralwhite': '#fffaf0', 'forestgreen': '#228b22', 'fuchsia': '#ff00ff',
  'gainsboro': '#dcdcdc', 'ghostwhite': '#f8f8ff', 'gold': '#ffd700', 'goldenrod': '#daa520', 'gray': '#808080', 'green': '#008000', 'greenyellow': '#adff2f',
  'honeydew': '#f0fff0', 'hotpink': '#ff69b4',
  'indianred ': '#cd5c5c', 'indigo': '#4b0082', 'ivory': '#fffff0', 'khaki': '#f0e68c',
  'lavender': '#e6e6fa', 'lavenderblush': '#fff0f5', 'lawngreen': '#7cfc00', 'lemonchiffon': '#fffacd', 'lightblue': '#add8e6', 'lightcoral': '#f08080', 'lightcyan': '#e0ffff', 'lightgoldenrodyellow': '#fafad2',
  'lightgrey': '#d3d3d3', 'lightgreen': '#90ee90', 'lightpink': '#ffb6c1', 'lightsalmon': '#ffa07a', 'lightseagreen': '#20b2aa', 'lightskyblue': '#87cefa', 'lightslategray': '#778899', 'lightsteelblue': '#b0c4de',
  'lightyellow': '#ffffe0', 'lime': '#00ff00', 'limegreen': '#32cd32', 'linen': '#faf0e6',
  'magenta': '#ff00ff', 'maroon': '#800000', 'mediumaquamarine': '#66cdaa', 'mediumblue': '#0000cd', 'mediumorchid': '#ba55d3', 'mediumpurple': '#9370d8', 'mediumseagreen': '#3cb371', 'mediumslateblue': '#7b68ee',
  'mediumspringgreen': '#00fa9a', 'mediumturquoise': '#48d1cc', 'mediumvioletred': '#c71585', 'midnightblue': '#191970', 'mintcream': '#f5fffa', 'mistyrose': '#ffe4e1', 'moccasin': '#ffe4b5',
  'navajowhite': '#ffdead', 'navy': '#000080',
  'oldlace': '#fdf5e6', 'olive': '#808000', 'olivedrab': '#6b8e23', 'orange': '#ffa500', 'orangered': '#ff4500', 'orchid': '#da70d6',
  'palegoldenrod': '#eee8aa', 'palegreen': '#98fb98', 'paleturquoise': '#afeeee', 'palevioletred': '#d87093', 'papayawhip': '#ffefd5', 'peachpuff': '#ffdab9', 'peru': '#cd853f', 'pink': '#ffc0cb', 'plum': '#dda0dd', 'powderblue': '#b0e0e6', 'purple': '#800080',
  'rebeccapurple': '#663399', 'red': '#ff0000', 'rosybrown': '#bc8f8f', 'royalblue': '#4169e1',
  'saddlebrown': '#8b4513', 'salmon': '#fa8072', 'sandybrown': '#f4a460', 'seagreen': '#2e8b57', 'seashell': '#fff5ee', 'sienna': '#a0522d', 'silver': '#c0c0c0', 'skyblue': '#87ceeb', 'slateblue': '#6a5acd', 'slategray': '#708090', 'snow': '#fffafa', 'springgreen': '#00ff7f', 'steelblue': '#4682b4',
  'tan': '#d2b48c', 'teal': '#008080', 'thistle': '#d8bfd8', 'tomato': '#ff6347', 'turquoise': '#40e0d0',
  'violet': '#ee82ee',
  'wheat': '#f5deb3', 'white': '#ffffff', 'whitesmoke': '#f5f5f5',
  'yellow': '#ffff00', 'yellowgreen': '#9acd32'
}

export const ColorPalette = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deep-orange',
  'brown',
  'grey',
  'blue-grey'
]

export const DarkColorPalette = [
  'red',
  'purple',
  'indigo',
  'blue',
  'green',
  'orange',
  'brown',
  'grey'
]

export const BrightColorPalette = [
  'light-blue',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'grey'
]

/**
 * Color service
 */
export class ColorService {
  /**
   * Returns base color palette
   * @returns {Array[String]}
   */
  get palette () {
    return ColorPalette
  }

  /**
   * Returns bright color palette
   * @returns {Array[String]}
   */
  get brightPalette () {
    return BrightColorPalette
  }

  /**
   * Returns dark color palette
   * @returns {Array[String]}
   */
  get darkPalette () {
    return DarkColorPalette
  }

  /**
   * Converts HTML color name or RGB color to hex
   * @param {String|Object} color HTML color name such as `red` or `teal` or RGB color object
   * @param {Boolean} usePound If true, the returned color starts with `#` sign
   * @returns {String} Hex representation of the color
   */
  toHex (color, usePound = true) {
    if (color == null || color === '') return

    let hex
    if (color) {
      if (typeof color === 'string' && typeof HTMLColors[color.toLowerCase()] != 'undefined') {
        hex = HTMLColors[color.toLowerCase()].toUpperCase().substring(1)
      } else if (this.isRGB(color)) {
        const { r, g, b } = color
        hex = ((1 << 24) + (r << 16) + (g << 8) + b)
          .toString(16)
          .slice(1)
          .toUpperCase()
      }
    }
    return hex ? (usePound ? '#' : '') + hex : color
  }

  /**
   * Lightens the specified HTML color by specified percentage
   * @param {String} color Color to lighten
   * @param {Number} percent Percentage by which to lighten the color (0..100)
   * @returns {String} Lightened color
   */
  lighten (color, percent) {
    if (!color) return
    if (!(percent >= 0 && percent <= 100)) throw new Error('Percentage must be between 0 and 100')
    if (percent === 0) return color

    const usePound = color[0] == '#'
    color = usePound ? color.slice(1) : color

    let r = parseInt(color.substring(1, 3), 16)
    let g = parseInt(color.substring(3, 5), 16)
    let b = parseInt(color.substring(5, 7), 16)

    r = Math.floor((255 - r) * (percent / 100)) + r
    g = Math.floor((255 - g) * (percent / 100)) + g
    b = Math.floor((255 - b) * (percent / 100)) + b

    r = Math.min(Math.max(r, 0), 255)
    g = Math.min(Math.max(g, 0), 255)
    b = Math.min(Math.max(b, 0), 255)

    return (usePound ? '#' : '') +
      ((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase()
  }

  /**
   * Darkens the specified HTML color by specified percentage
   * @param {String} color Color to darken
   * @param {Number} percent Percentage by which to darken the color (0..100)
   * @returns {String} Darkened color
   */
  darken (color, percent) {
    if (!color) return
    if (!(percent >= 0 && percent <= 100)) throw new Error('Percentage must be between 0 and 100')
    if (percent === 0) return color

    const usePound = color[0] == '#'
    color = usePound ? color.slice(1) : color

    let r = parseInt(color.substring(1, 3), 16)
    let g = parseInt(color.substring(3, 5), 16)
    let b = parseInt(color.substring(5, 7), 16)

    r = Math.floor(r * (100 - percent) / 100)
    g = Math.floor(g * (100 - percent) / 100)
    b = Math.floor(b * (100 - percent) / 100)

    r = Math.min(Math.max(r, 0), 255)
    g = Math.min(Math.max(g, 0), 255)
    b = Math.min(Math.max(b, 0), 255)

    return (usePound ? '#' : '') +
      ((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase()
  }

  /**
   * Sets transparency of the specified HTML color
   * @param {String} color Color to change transparency
   * @param {Number} percent Percent by which to change the transparency (-100, 100)
   * @returns {String} Changed color
   */
  changeAlpha (color, percent) {
    if (!color) return
    if (!(percent >= 0 && percent <= 100)) throw new Error('Percentage must be between 0 and 100')
    if (percent === 0) return color

    color = this.toHex(color)
    let usePound = false
    if (color[0] == '#') {
      color = color.slice(1)
      usePound = true
    }

    color = color.substring(0, 6)
    const opacity = Math.round(Math.min(Math.max(percent / 100, 0), 1) * 255)
    return ((usePound ? '#' : '') + color + opacity.toString(16)).toUpperCase()
  }

  /**
   * Checks whether color is an RGB object
   * @param {any} color Color to check
   * @returns {Boolean}
   */
  isRGB (color) {
    return typeof (color) === 'object' &&
      'r' in color &&
      'g' in color &&
      'b' in color
  }

  /**
   * Parses a HEX/color/color name to its RGB/A representation
   * @param {String} color Color to parse
   * @returns {Object}
   */
  toRGB (color) {
    if (!color) return
    if (typeof color !== 'string') {
      return this.isRGB(color) ? color : undefined
    }

    color = this.toHex(color).replace(/^#/, '')
    const value = parseInt(color, 16)
    const r = (value >> 16) & 255
    const g = (value >> 8) & 255
    const b = value & 255
    return { r, g, b }
  }

  /**
   * Returns a random color from the specified palette,
   * avoiding the already used colors
   * @param {Array[String]} palette Color palette
   * @param {Array[String]} usedColors Colors already used
   * @returns {String}
   */
  getRandomColor (palette = ColorPalette, usedColors = []) {
    const colors = palette.filter(c => !usedColors.includes(c))
    const index = randomInt(0, colors.length - 1)
    return colors[index] || DarkColorPalette[randomInt(0, DarkColorPalette.length - 1)]
  }

}

/**
 * Color service instance
 */
export const Color = new ColorService()

