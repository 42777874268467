import { AlertsAPI } from '@stellacontrol/client-api'
import { countString, pluralize } from '@stellacontrol/utilities'
import { Confirmation } from '@stellacontrol/client-utilities'
import { alertTypesToFlags } from '@stellacontrol/model'

/**
 * Initialize the applet
 */
export async function initializeApplet () {
}

/**
 * Returns a list of alert occurrences matching the conditions.
 * Filters out alerts which the current organization isn't allowed to see.
 * @param {Organization} organization Owner organization - if specified, only alerts associated with this organization will be returned
 * @param {Device} device Owner device - if specified, only alerts associated with this device will be returned
 * @param {AlertType} alertType Alert type - if specified, only alerts matching this type will be returned
 * @param {Number} maxAge Maximal age of alerts, in seconds - if specified, only alerts not older than this will be returned
 * @returns {Promise<Array[AlertOccurrence>]}
 */
export async function getAlertOccurrences ({ getters }, { organization, device, alertType, maxAge }) {
  const { currentOrganizationGuardian } = getters
  const alerts = await AlertsAPI.getAlertOccurrences({ organization, device, alertType, maxAge })
  return alerts?.filter(alert => currentOrganizationGuardian.canReceiveAlert(alert.alertType))
}

/**
 * Returns most recent alert occurrence matching the conditions
 * Filters out alerts which the current organization isn't allowed to see.
 * @param {Organization} organization Owner organization - if specified, only alerts associated with this organization will be returned
 * @param {Device} device Owner device - if specified, only alerts associated with this device will be returned
 * @param {AlertType} alertType Alert type - if specified, only alerts matching this type will be returned
 * @param {Number} maxAge Maximal age of alert, in seconds - if specified, only alerts not older than this will be returned
 * @param {Number} count Maximal number of the recent alerts to return
 * @returns {Promise<Array[AlertOccurrence]>}
 */
export async function getRecentAlertOccurrences ({ getters }, { organization, device, alertType, maxAge, count }) {
  const { currentOrganizationGuardian } = getters
  const alerts = await AlertsAPI.getRecentAlertOccurrences({ organization, device, alertType, maxAge, count }) || []
  return alerts.filter(alert => currentOrganizationGuardian?.canReceiveAlert(alert.alertType))
}

/**
 * Retrieves a complete hierarchy of monitored devices grouped by organizations and places,
 * starting with the specified organization, or current organization
 * @param organization Organization
 */
export async function getMonitoredDevicesHierarchy ({ state, commit, getters }, { organization } = {}) {
  organization = organization || getters.currentOrganization
  const monitoredDevicesHierarchy = state.monitoredDevicesHierarchy ||
    await AlertsAPI.getMonitoredDevicesHierarchy({
      organizationId: organization.id,
      withAlertConfiguration: true
    })
  commit('storeMonitoredDevicesHierarchy', { monitoredDevicesHierarchy })
  return monitoredDevicesHierarchy
}

/**
 * Retrieves details of a monitored device,
 * together with its alerts etc.
 * @param id Device identifier
 * @param serialNumber Alternative to ID, device identifier
 */
export async function getMonitoredDevice (_, { id, serialNumber } = {}) {
  const { device } = await AlertsAPI.getMonitoredDevice({ id, serialNumber }) || {}
  if (device) {
    return device
  }
}

/**
 * Gets system-wide default alert configurations
 */
export async function requireDefaultAlertConfigurations ({ commit, getters }) {
  if (!getters.hasDefaultAlertConfigurations) {
    const configurations = await AlertsAPI.getDefaultAlertConfigurations()
    commit('storeDefaultAlertConfigurations', { configurations })
    return configurations
  }
  return getters.defaultAlertConfigurations
}

/**
 * Retrieves alert configurations for the specified device
 * @param {Device} device Device
 * @returns {Promise<Array[AlertConfiguration>]}
 */
export async function getDeviceAlertConfigurations (_, { device } = {}) {
  if (!device) throw new Error('Device is required')
  const configurations = await AlertsAPI.getDeviceAlertConfigurations({ device })
  return configurations
}


/**
 * Saves configuration of alerts for the specified device
 * @param {Array[AlertConfiguration]} configurations List of alert configurations to save
 * @param {Array[Device]} devices Device whose alert configurations are being saved
 * @returns {Promise<Array[AlertConfiguration]>} Saved alert configurations
 */
export async function saveDeviceAlertConfigurations ({ dispatch }, { configurations = [], devices } = {}) {
  if (!devices) throw new Error('Devices are required')
  try {
    await dispatch('busy', { action: 'save' })
    const deviceConfigurations = await AlertsAPI.saveDeviceAlertConfigurations({ configurations, devices })

    // Signal the update of device data to other parts of the UI
    for (const device of devices) {
      const alertsCustomized = alertTypesToFlags(deviceConfigurations.map(c => c.alertType))
      const alertsMuted = alertTypesToFlags(deviceConfigurations.filter(c => !c.isEnabled).map(c => c.alertType))
      device.updateSnapshot({ alertsCustomized, alertsMuted })
      dispatch('updateDevices', [devices])
    }

    return deviceConfigurations
  } finally {
    await dispatch('done')
  }
}

/**
 * Deletes the alert occurrence
 * @param {AlertOccurrence} alert Alert to delete
 * @param {Boolean} confirm If true, user has to confirm
 * @param {Boolean} silent If true, no notifications will be showing
 * @returns {Promise<AlertOccurrence>} Deleted alert occurrence
 */
export async function deleteAlertOccurrence ({ dispatch } = {}, { alert, confirm, silent } = {}) {
  if (alert) {
    const yes = await Confirmation.ask({
      title: 'Delete?',
      message: `Do you want to delete the alert [${alert.message}]?`,
      confirm
    })
    if (yes) {
      await dispatch('busy', { action: 'delete', message: 'Deleting the alert ...', data: alert, silent })
      const deletedAlert = await AlertsAPI.deleteAlertOccurrence({ alert })
      if (deletedAlert) {
        dispatch('alertsDeleted', { alerts: [deletedAlert] })
        await dispatch('done', { message: 'The alert has been deleted', silent })
      } else {
        await dispatch('done', { silent })
      }
      return deletedAlert
    }
  }
}

/**
 * Deletes the alert occurrences
 * @param {Array[AlertOccurrence]} alerts Alerts to delete
 * @param {Boolean} confirm If true, user has to confirm
 * @param {Boolean} silent If true, no notifications will be showing
 */
export async function deleteAlertOccurrences ({ dispatch } = {}, { alerts, confirm, silent } = {}) {
  if (alerts) {
    const yes = await Confirmation.ask({
      title: 'Delete?',
      message: `Do you want to delete ${countString(alerts, 'alert')}?`,
      confirm
    })
    if (yes) {
      await dispatch('busy', { action: 'delete', message: `Deleting the ${pluralize(alerts, 'alert')} ...`, data: alerts, silent })
      const deletedAlerts = await AlertsAPI.deleteAlertOccurrences({ alerts })
      if (deletedAlerts) {
        dispatch('alertsDeleted', { alerts })
        await dispatch('done', { message: `The ${pluralize(alerts, 'alert has been', 'alerts have been')} deleted`, silent })
      } else {
        await dispatch('done', { silent })
      }
      return deletedAlerts
    }
  }
}

/**
 * Triggered after alerts have been deleted
 * @param {Array[AlertOccurrence]} alerts Deleted alerts
 */
export async function alertsDeleted ({ commit }, { alerts } = {}) {
  commit('alertsDeleted', { alerts })
}

/**
 * Retrieves the alert statistics of a specified device
 * @param {Device} device Device whose alert statistics to get
 * @param {String} name Name of the statistics to retrieve, for example 'sustained-reduced-power'
 * @returns {Promise<DeviceAlertStatistics>} Alert statistics for the device
 */
export function getDeviceAlertStatistics (_, { device, name }) {
  return AlertsAPI.getDeviceAlertStatistics({ device, name })
}

/**
 * Deletes the alert statistics of a specified device
 * @param {Device} device Device whose alert statistics to get
 * @param {String} name Name of the statistics to delete, for example 'sustained-reduced-power'
 * @returns {Promise<DeviceAlertStatistics>} Alert statistics for the device
 */
export async function deleteDeviceAlertStatistics (_, { device, name }) {
  return AlertsAPI.deleteDeviceAlertStatistics({ device, name })
}
