import { formatDate } from '@stellacontrol/utilities'
import { Preference, DeviceHierarchy } from '@stellacontrol/model'
import { isRouteLeft, Notification } from '@stellacontrol/client-utilities'
import { DeviceAPI } from '@stellacontrol/client-api'
import { AppletRoute } from '../../router'

export const actions = {
  /**
   * Initializes and populates the inventory view
   * @param {Array[String]} selection Initial selection, list of device serial numbers
   * @param {String} organizationId Initial selection, identifier of organization
   * @param {String} placeId Initial selection, identifier of place
   * @param {Boolean} preserveSelection If true, devices previously selected in the inventory will remain selected
   * @param {Boolean} filterBySelection If true, the inventory will be filtered by the specified device selection
   */
  async initializeInventoryView ({ state, commit, dispatch, getters }, { selection, organizationId, placeId, preserveSelection = false, filterBySelection = false } = {}) {
    // Make sure devices and organizations are ready
    await dispatch('requireOrganizationProfiles')
    await dispatch('requireOrganizations')
    await dispatch('requireDevices')

    // Get previously selected items and clear the grid
    const previousSelection = preserveSelection ? state.items.filter(item => item.isSelected) : []
    commit('inventoryReloading', { clear: true })

    const { guardian, currentUser: user, configuration, devices, organizationHierarchy } = getters
    const deviceTags = configuration.entities.tag.items

    // Initialize inventory state
    commit('initializeInventoryView', { guardian, deviceTags })

    // Apply stored column settings
    await dispatch('restoreInventoryLayout')

    // Populate with data
    commit('populateInventoryView', { guardian, user, devices, organizationHierarchy, preserveSelection })

    // Select initial items if specified
    if (organizationId) {
      selection = devices.filter(d => d.ownerId === organizationId).map(d => d.serialNumber)
    } else if (placeId) {
      selection = devices.filter(d => d.placeId === placeId).map(d => d.serialNumber)
    } else if (preserveSelection) {
      selection = devices.filter(d => previousSelection.some(i => i.serialNumber === d.serialNumber)).map(d => d.serialNumber)
    }

    await dispatch('selectInventory', { selection })

    // Apply stored filters and filter by current selection if requested
    if (selection?.length > 0 && filterBySelection) {
      commit('clearInventoryFilters')
      commit('setInventoryFilter', { column: { name: 'serialNumber' }, value: selection.join(' ') })
    }
    commit('applyInventoryFilters')
    commit('inventoryReloading', { done: true })
  },

  /**
   * Selects the specified devices in the inventory
   * @param {Array[String]} selection List of device serial numbers
   * @param {String} organizationId Initial selection, identifier of organization
   * @param {String} placeId Initial selection, identifier of place
   * @param {Boolean} preserveSelection If true, devices previously selected in the inventory will remain selected
   */
  async selectInventory ({ commit, getters }, { selection, organizationId, placeId, preserveSelection } = {}) {
    if (!preserveSelection) {
      commit('deselectInventoryItems')
    }

    const { devices } = getters

    if (organizationId) {
      selection = devices.filter(d => d.ownerId === organizationId).map(d => d.serialNumber)
    } else if (placeId) {
      selection = devices.filter(d => d.placeId === placeId).map(d => d.serialNumber)
    }

    if (selection && selection.length) {
      commit('selectInventoryItems', {
        items: selection.map(serialNumber => ({ serialNumber }))
      })
    }
  },

  /**
   * Reset state if view closed
   */
  async navigationEnded ({ commit }, { from, to } = {}) {
    if (isRouteLeft(AppletRoute.Inventory, from, to)) {
      commit('resetInventoryView')
    }
  },

  /**
   * Triggered when inventory selection has changed
   * @param {Array[Device]} devices Currently selected devices
   * @param {Array[DevicePresenter]} items Currently selected inventory items
   */
  // eslint-disable-next-line no-unused-vars
  async inventorySelectionChanged (_, { devices, action } = {}) {
  },

  /**
   * Triggered when inventory action has been executed on devices
   * @param {Array[Device]} devices Target devices of the action
   * @param {Action} action Action executed on devices
   */
  // eslint-disable-next-line no-unused-vars
  async inventoryActionExecuted ({ dispatch }, { devices, action } = {}) {
  },

  /**
   * Opens a view with the inventory
   */
  async showInventory ({ dispatch }) {
    await dispatch('gotoRoute', { name: AppletRoute.Inventory })
  },

  /**
   * Opens a view for adding a batch of devices to inventory
   */
  async addDevicesToInventory ({ commit, dispatch }) {
    commit('clearRecentlyAddedDevices')
    await dispatch('gotoRoute', { name: AppletRoute.AddToInventory })
  },

  /**
   * Opens a view for importing legacy devices to inventory
   */
  async importDevicesToInventory ({ commit, dispatch }) {
    commit('clearRecentlyAddedDevices')
    await dispatch('gotoRoute', { name: AppletRoute.ImportToInventory })
  },

  /**
   * Changes the sorting order of the inventory grid
   * @param sortBy Sort column
   * @param sortOrder Sort order
   * @param persist If true (default) change will be stored in user preferences
   */
  async sortInventory ({ commit, dispatch }, { sortBy, sortOrder, persist = true } = {}) {
    commit('sortInventory', { sortBy, sortOrder })
    if (persist) {
      await dispatch('storeInventoryLayout')
    }
  },

  /**
   * Changes number of rows per page in the inventory grid
   * @param {Number} rowsPerPage Rows per page
   * @param {Boolean} persist If true (default) change will be stored in user preferences
   */
  async paginateInventory ({ commit, dispatch }, { rowsPerPage = 50, persist = true } = {}) {
    commit('paginateInventory', { rowsPerPage })
    if (persist) {
      await dispatch('storeInventoryLayout')
    }
  },

  /**
   * Shows/hides a column of the inventory grid
   * @param {String} name Name of a column to show or hide
   * @param {Boolean} isVisible Visibility of the column.
   * If not specified, the current visibility will be toggled.
   * @param {Boolean} persist If true (default) change will be stored in user preferences
   */
  async toggleInventoryColumn ({ commit, dispatch }, { name, isVisible, persist = true } = {}) {
    commit('toggleInventoryColumn', { name, isVisible })
    if (persist) {
      await dispatch('storeInventoryLayout')
    }
  },

  /**
   * Toggles visibility of decommissioned devices in the inventory grid
   * @param {Boolean} isVisible Visibility of decommissioned devices.
   * If not specified, the current visibility will be toggled.
   * @param {Boolean} persist If true (default) change will be stored in user preferences
   */
  async toggleDecommisionedDevices ({ commit, dispatch }, { isVisible, persist = true } = {}) {
    commit('toggleDecommisionedDevices', { isVisible })
    commit('applyInventoryFilters')
    if (persist) {
      await dispatch('storeInventoryLayout')
    }
  },

  /**
   * Toggles visibility of non-connected devices in the inventory grid
   * @param {Boolean} isVisible Visibility of non-connected devices.
   * If not specified, the current visibility will be toggled.
   * @param {Boolean} persist If true (default) change will be stored in user preferences
   */
  async toggleNonConnectedDevices ({ commit, dispatch }, { isVisible, persist = true } = {}) {
    commit('toggleNonConnectedDevices', { isVisible })
    commit('applyInventoryFilters')
    if (persist) {
      await dispatch('storeInventoryLayout')
    }
  },

  /**
   * Toggles visibility of multi-device parts in the inventory grid
   * @param {Boolean} isVisible Visibility of multi-device parts.
   * If not specified, the current visibility will be toggled.
   * @param {Boolean} persist If true (default) change will be stored in user preferences
   */
  async toggleDeviceParts ({ commit, dispatch }, { isVisible, persist = true } = {}) {
    commit('toggleDeviceParts', { isVisible })
    commit('applyInventoryFilters')
    if (persist) {
      await dispatch('storeInventoryLayout')
    }
  },

  /**
   * Toggles visibility of simulated devices in the inventory grid
   * @param {Boolean} isVisible Visibility of simulated devices.
   * If not specified, the current visibility will be toggled.
   * @param {Boolean} persist If true (default) change will be stored in user preferences
   */
  async toggleSimulatedDevices ({ commit, dispatch }, { isVisible, persist = true } = {}) {
    commit('toggleSimulatedDevices', { isVisible })
    commit('applyInventoryFilters')
    if (persist) {
      await dispatch('storeInventoryLayout')
    }
  },

  /**
   * Filters the inventory on specified column
   * @param name Name of a column to filter on
   * @param value Filter value
   */
  async filterInventory ({ commit }, { column, value } = {}) {
    commit('setInventoryFilter', { column, value })
    commit('applyInventoryFilters')
  },

  /**
   * Clears all the filters currently applied to the inventory grid
   */
  async clearInventoryFilters ({ commit }) {
    commit('clearInventoryFilters')
    commit('applyInventoryFilters')
  },

  /**
   * Store the current inventory view layout in user preferences
   */
  async storeInventoryLayout ({ dispatch, state }) {
    const { gridOptions: options, gridColumnsList: columns } = state

    const optionPreferences = [
      Preference.from('sort-by', options.sortBy),
      Preference.from('sort-order', options.sortOrder),
      Preference.from('show-decommissioned-devices', Boolean(options.showDecommissionedDevices)),
      Preference.from('show-non-connected-devices', Boolean(options.showNonConnectedDevices)),
      Preference.from('show-device-parts', Boolean(options.showDeviceParts)),
      Preference.from('show-simulated-devices', Boolean(options.showSimulatedDevices)),
      Preference.from('rows-per-page', options.rowsPerPage)
    ]

    const columnPreferences = columns.map(({ name, isVisible }) =>
      Preference.from(`column-${name.toLowerCase()}-visible`, isVisible))

    const preferences = [
      ...optionPreferences,
      ...columnPreferences
    ]

    // Prefix all preferences with `inventory-grid`
    const items = preferences.map(({ name, value }) => ({ name: `inventory-${name}`, value }))

    await dispatch('storeUserPreferences', { items })
    return items
  },

  /**
   * Restores inventory view layout by applying previously
   * stored user preferences to grid options and columns
   */
  async restoreInventoryLayout ({ commit, state, getters }) {
    const { preferences, currentOrganizationGuardian } = getters
    commit('inventoryApplyPreference', { preferences, preference: 'sort-by', option: 'sortBy' })
    commit('inventoryApplyPreference', { preferences, preference: 'sort-order', option: 'sortOrder' })
    commit('inventoryApplyPreference', { preferences, preference: 'show-decommissioned-devices', option: 'showDecommissionedDevices' })
    commit('inventoryApplyPreference', { preferences, preference: 'show-non-connected-devices', option: 'showNonConnectedDevices' })
    commit('inventoryApplyPreference', { preferences, preference: 'show-device-parts', option: 'showDeviceParts' })
    commit('inventoryApplyPreference', { preferences, preference: 'show-simulated-devices', option: 'showSimulatedDevices' })
    commit('inventoryApplyPreference', { preferences, preference: 'rows-per-page', option: 'rowsPerPage' })

    // Reset visibility of columns to defaults, mind customer permissions!
    for (let { name, isVisible } of state.gridColumnsList) {
      if (name === 'isOnline') {
        isVisible = currentOrganizationGuardian.canUse('live-status')
      }
      commit('toggleInventoryColumn', { name, isVisible })
    }

    // Apply column visibility from preferences.
    for (const { name } of state.gridColumnsList) {
      // Skip columns which are forcefully hidden.
      commit('inventoryApplyPreference', { preferences, preference: 'visible', column: name, option: 'isVisible' })
    }
  },

  /**
   * Exports devices with live status
   * @param {Organization} organization Organization whose device hierarchy should be exported.
   * If not specified, the hierarchy of current organization will be exported.
   * @param {Boolean} silent If true, no UI notifications are displayed
   * @returns {Array} List of tuples containing the exported data, first tuple being a header line.
   */
  async exportDeviceStatus ({ dispatch, getters }, { organization, silent } = {}) {
    const { currentOrganization, organizations, places } = getters
    organization = organization || currentOrganization

    await dispatch('busy', { message: 'Retrieving devices ...', silent })
    const devices = await DeviceAPI.getDevices(organization)
    const hierarchy = DeviceHierarchy.fromOrganizations({ organization, organizations, places, devices })
    await dispatch('done', { silent })

    const count = devices.length
    const progress = await Notification.progress({ message: 'Retrieving device status ...', silent })
    try {
      const batchSize = 50
      let doneCount = 0
      const result = ['serialNumber;type;model;firmwareVersion;manufactured;lastStatus;messageFrequency;rebalanceFrequency;secondsAgo;sold;ownerId;ownership;']
      while (devices.length > 0) {
        const batch = devices
          .splice(0, batchSize)
          .filter(d => d.isConnectedDevice)
          .filter(d => !d.isDecommissioned)

        doneCount = doneCount + batch.length
        const donePercent = Math.min(100, Math.round(100 * (doneCount / count)))
        progress({ message: `Retrieving device status, ${donePercent} %`, silent })

        const items = await DeviceAPI.getDeviceStatus({ devices: batch })

        for (const { device: { serialNumber }, status } of items) {
          const device = batch.find(d => d.serialNumber === serialNumber)
          const ownership = device
            .getOwnershipPath(hierarchy)
            .map(o => o.name)
            .join(';')
          const fields = [
            device.serialNumber,
            device.type,
            device.model,
            status.identity.firmwareVersionLong,
            formatDate(device.manufacturedAt),
            status.connection.status,
            status.connection.messageFrequency,
            status.connection.rebalanceFrequency,
            status.timings.statusAge,
            formatDate(device.soldAt),
            device.ownerId,
            ownership
          ]
          result.push(fields.join(';'))
        }
      }

      progress()
      return result

    } catch (error) {
      dispatch('done', { silent })
      throw error
    }
  }
}
