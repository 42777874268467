import { Log, assign, parseDate, findBiggest } from '@stellacontrol/utilities'
import { Note } from './note'
import { Tag, Tags } from './tag'

/**
 * Basic database entity
 */
export class Entity {
  constructor (data = {}) {
    this.assign({
      ...data,
      createdAt: data.createdAt || new Date(),
      updatedAt: data.updatedAt || new Date()
    })
  }

  /**
   * Identifier used in navigation to new entities
   */
  static ID_NEW = 'new'

  /**
   * Returns core information about the entity
   * @returns {Entity}
   */
  core () {
    const entity = new this.constructor(this)
    delete entity.creator
    delete entity.updater
    delete entity.deleter
    delete entity.notes
    delete entity.tags
    return entity
  }

  /**
   * Returns the type of the specified entity
   * @param entity Entity whose type to return
   */
  getTypeOf (entity) {
    if (entity != null) {
      return entity.constructor ? entity.constructor.name.toLowerCase() : typeof entity
    }
  }

  /**
   * Returns the type of this entity
   */
  getType () {
    if (this.constructor) {
      return this.constructor.name.toLowerCase()
    }
  }

  /**
   * Database identifier
   * @type {String}
   */
  id

  /**
   * Indicates a new entity
   * @type {Boolean}
   */
  get isNew () {
    return !this.id || this.id === Entity.ID_NEW
  }

  /**
   * Creation time
   * @type {Date}
   */
  createdAt

  /**
   * Last modification time
   * @type {Date}
   */
  updatedAt

  /**
   * Deletion time
   * @type {Date}
   */
  deletedAt

  /**
   * Creator id
   * @type {String}
   */
  createdBy

  /**
   * Creator details
   * @type {User}
   */
  creator

  /**
   * Updater id
   * @type {String}
   */
  updatedBy

  /**
   * Updater details
   * @type {User}
   */
  updater

  /**
   * Indicates that entity is/should be deleted
   * @type {Boolean}
   */
  isDeleted

  /**
   * Deleter id
   * @type {String}
   */
  deletedBy

  /**
   * Deleter details
   * @type {User}
   */
  deleter

  /**
   * Notes associated with the entity
   * @type {Array[Note]}
   */
  notes

  /**
   * Returns true if any notes associated with the entity
   * @type {Boolean}
   */
  get hasNotes () {
    return this.notes?.some(note => !note?.isEmpty)
  }

  /**
   * Returns true if any notes associated with the entity
   * @param {User} user Optional user viewing the notes. If specified,
   * only notes available for this user are considered
   * @type {Boolean}
   */
  hasNotesOf (user) {
    const notes = user
      ? this.getVisibleNotes(user)
      : this.notes
    return notes?.some(note => !note?.isEmpty)
  }

  /**
   * Returns text of the first of the notes
   * @type {String}
   */
  get note () {
    const note = this.notes?.filter(note => !note?.isEmpty)[0]
    return note ? note.text : ''
  }

  /**
   * Assigns text of the first of the notes
   * @param {Note} value Note to assign
   */
  set note (value) {
    value = value?.toString().trim() || ''
    if (value) {
      if (!this.hasNotes) {
        this.notes = [new Note()]
      }
      const note = this.notes[0]
      note.text = value
    } else {
      if (this.hasNotes) {
        this.notes[0].text = ''
        this.notes[0].isDeleted = true
      }
    }
  }

  /**
   * Returns text of the last of the notes
   * @type {String}
   */
  get recentNote () {
    const note = findBiggest(this.notes || [], 'createdAt')
    return note ? note.text : ''
  }

  /**
   * Returns all notes available to the specified user
   * @param {User} user User viewing the notes
   * @returns {Array[Note]}
   */
  getVisibleNotes (user) {
    const note = (this.notes || []).find(n => user.isSuperAdministrator || n.isOwnedBy(user))
    return note
  }

  /**
   * Returns text of the first of the notes owned by the specified user
   * @param {User} user User who owns the note
   * @returns {Note} First note owned by the user
   */
  getNoteBy (user) {
    const note = (this.notes || []).find(n => n.isOwnedBy(user))
    return note
  }

  /**
   * Assigns text of the first of the notes owned by the specified user
   * @param {User} user User who owns the note
   * @param {String} text Note text
   * @returns {Note} Modified note
   */
  setNoteBy (user, text) {
    let note = (this.notes || []).find(n => n.isOwnedBy(user))
    if (!note) {
      note = new Note({ createdBy: user.id })
      if (!this.notes) {
        this.notes = []
      }
      this.notes.push(note)
    }
    note.text = text
    return note
  }


  /**
   * Tags associated with the entity
   * @type {Array[Tag]}
   */
  tags

  /**
   * Returns true if any tags associated with the entity
   * @type {Boolean}
   */
  get hasTags () {
    return (this.tags || []).length > 0
  }

  /**
   * Adds a tag to entity
   * @param {String} name Tag to add, duplicates will be ignored
   * @param {String} userId User adding the tag
   * @param {String} category Tag category
   * @returns {Tag} Added tag
   */
  addTag ({ name, userId, category } = {}) {
    if (!name) throw new Error('Tag name is required')
    if (!category) throw new Error('Tag category is required')

    const existing = this.hasTag({ name, userId })
    if (!existing) {
      if (!this.tags) {
        this.tags = []
      }
      const tag = new Tag({
        name,
        userId,
        category,
        entityId: this.id
      })
      this.tags.push(tag)
      return tag
    }
  }

  /**
   * Returns true if entity has the specified tag
   * @param {String} tag Tag to check
   * @param {String} userId Optional user who added the tag
   * @returns {Boolean}
   */
  hasTag ({ name, userId } = {}) {
    return Boolean(this.getTag({ name, userId }))
  }

  /**
   * Returns the specified tag
   * @param {String} tag Tag to find
   * @param {String} userId Optional user who added the tag
   * @returns {Tag}
   */
  getTag ({ name, userId } = {}) {
    const tag = (this.tags || []).find(t => t.name === name && (t.userId == null || t.userId === userId))
    if (tag) {
      tag.entityId = this.id
      return tag
    }
  }

  /**
   * Removes a tag from entity
   * @param {String} tag Tag to remove
   * @param {String} userId Optional user who added the tag
   */
  removeTag ({ name, userId } = {}) {
    this.tags = (this.tags || []).filter(t => !(t.name === name && (!t.userId || t.userId === userId)))
  }

  /**
   * Toggles a tag on the entity
   * @param {String} tag Tag to toggle
   * @param {String} userId Optional user who added the tag
   */
  toggleTag ({ name, userId, category } = {}) {
    if (this.hasTag({ name, userId })) {
      this.removeTag({ name, userId })
    } else {
      this.addTag({ name, userId, category })
    }
  }

  /**
   * Returns true if the entity has been marked as favorite,
   * optionally by the specified user
   * @param {User} user User
   * @returns {Boolean}
   */
  isFavorite (user) {
    return (this.tags || [])
      .some(t => t.name === Tags.Favorite && (user == null || t.userId === user.id))
  }

  /**
   * Normalizes the data after assignment, for example converting
   * the properties to their correct types
   */
  normalize () {
    this.createdAt = parseDate(this.createdAt) || new Date()
    this.updatedAt = parseDate(this.updatedAt) || new Date()
    if (this.notes && this.notes.map) {
      this.notes = this.castArray(this.notes, Note)
    }
  }

  /**
   * Assigns data to the instance
   * @param data Data to assign to the instance
   * @param map Value mappers
   */
  assign (data, map = { createdAt: parseDate, updatedAt: parseDate }) {
    if (data) {
      assign(this, data, map)
      this.normalize()
    }
  }

  /** Creates a clone of the instance */
  clone () {
    const data = JSON.parse(JSON.stringify(this))
    const clone = new this.constructor(data)
    clone.normalize()
    return clone
  }

  /**
   * Casts the specified instance into a given type.
   * Ignores and returns as-is if it's already a correct type
   * @param {Object} instance Instance to type-cast
   * @param {Function} constructor Constructor of the result class
   * @param {any} defaultValue Value to assign if instance is not specified
   * @returns {Object} Instance casted to the specified class
   */
  cast (instance, constructor, defaultValue) {
    if (instance != null && constructor) {
      if (instance.constructor == constructor) {
        return instance
      } else {
        return new constructor(instance)
      }
    } else {
      return defaultValue == null ? instance : defaultValue
    }
  }

  /**
   * Casts all instances in the specified array into a given type.
   * Ignores and returns as-is instances which are already a correct type.
   * @param {Array[Object]} instances Instance to type-cast
   * @param {Function} constructor Constructor of the result class
   * @param {any} defaultValue Value to assign if instance is not specified
   * @returns {Array[Object]} Instances casted to the specified class
   */
  castArray (instances, constructor, defaultValue) {
    if (instances != null && constructor) {
      if (Array.isArray(instances)) {
        return instances.map(instance =>
          instance == null
            ? instance
            : (instance.constructor === constructor ? instance : new constructor(instance))
        )
      } else {
        Log.warn(`Attempt to perform castArray of a non-array ${constructor.name} instance`, instances)
        // eslint-disable-next-line no-console
        console.trace()
      }
    } else {
      return defaultValue == null ? instances : defaultValue
    }
  }
}
