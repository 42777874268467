import { AntennaName, AntennaOrientationName, AttachmentDataTypeLabel } from '@stellacontrol/model'
import { getScanNeighbourCells } from './get-neighbour-cells'
import { getScanSurvey } from './get-survey'
import { plmnToOperator } from './utilities'

/**
 * Returns text content of an attachment with scan results,
 * in a format ready for clipboard
 * @param {Attachment} attachment
 * @returns {String}
 */
export function scanToText (attachment) {
  if (!attachment?.isScan) return

  const lines = []
  const addLine = (...values) => lines.push(values.join(';'))

  const { name, entity, folder, dataType } = attachment

  addLine('Name', name)
  addLine('Folder', folder)
  addLine('Scan Type', AttachmentDataTypeLabel[dataType])
  addLine('Device', entity.serialNumber)
  if (entity.getFirmwareVersion) {
    addLine('FW', entity.getFirmwareVersion())
  }

  const { antennaOrientation, antennaType, wasOutdoorScan, wasRepeaterPresent, distanceFromRepeater, notes } = attachment.data.results
  addLine('Location', wasOutdoorScan ? 'Outdoor' : 'Indoor')
  addLine('Antenna Type', AntennaName[antennaType] || 'Unknown')
  addLine('Antenna Direction', AntennaOrientationName[antennaOrientation] || 'Unknown')
  addLine('Repeater', wasRepeaterPresent ? `On, distance ${distanceFromRepeater}m` : 'Off')
  addLine('Notes', notes)

  // Neighbour cell results
  const neighbours = getScanNeighbourCells(attachment)
  if (neighbours?.length > 0) {
    addLine()
    addLine('Live Scan')
    for (const group of neighbours) {
      addLine(group.name, group.isRawScan ? 'RAW SCAN' : '')
      addLine('Operator', 'Band', 'Service', 'PCI', 'Power', 'Quality')
      for (const operator of Object.keys(group.cells)) {
        for (const cell of group.cells[operator]) {
          addLine(
            plmnToOperator(cell.plmn),
            cell.bandLabel,
            cell.service,
            cell.physicalCellId,
            cell.signalPower,
            cell.signalQuality
          )
        }
      }
    }
  }

  // Survey results
  const survey = getScanSurvey(attachment)
  if (Object.keys(survey || {}).length > 0) {
    addLine()
    addLine('Cell ID Scan')
    addLine('Operator', 'Band', 'Service', 'Cell ID', 'Power', 'Quality', 'RSSI', 'PCI')
    for (const cells of Object.values(survey)) {
      for (const cell of cells || []) {
        addLine(
          plmnToOperator(cell.plmn),
          cell.bandLabel,
          cell.service,
          cell.cellId,
          cell.signalPower,
          cell.signalQuality,
          cell.rssi,
          cell.physicalCellId
        )
      }
    }
  }

  return lines.join('\n')
}
