import { Log, isEnum } from '@stellacontrol/utilities'
import { Tag, Note, Announcement, AnnouncementStatus, Preference, Bug, AuditItem, Device, Place, User, Organization, OrganizationProfile, EntityType, Attachment, AttachmentType, ApplicationFlags } from '@stellacontrol/model'
import { APIClient } from './api-client'

/**
 * Common API client
 */
export class CommonAPIClient extends APIClient {
  /**
   * Returns API name served by this client
   */
  get name () {
    return 'Common'
  }

  /**
   * Retrieves client application configuration
   * @param client API client. If not provided, it will be taken from current configuration.
   * @param key API key. If not provided, it will be taken from current configuration.
   */
  async getClientConfiguration ({ client, key } = {}) {
    try {
      const url = this.endpoint('configuration')
      const { configuration, flags, environment } = await this.request({ url, client, key })
      return {
        configuration,
        environment,
        flags: new ApplicationFlags(flags)
      }
    } catch (error) {
      Log.error('Error retrieving client configuration')
      Log.exception(error)
    }
  }

  /**
   * Saves the specified tag
   * @param data Tag to save
   */
  async saveTag ({ tag }) {
    if (tag && tag.isValid) {
      try {
        const data = tag
        const method = 'post'
        const url = this.endpoint('tag')
        const { tag: savedTag } = await this.request({ method, url, data })
        return this.asTag(savedTag)
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Deletes the specified tag
   * @param data Tag to delete
   */
  async deleteTag ({ tag }) {
    if (tag && tag.isValid) {
      try {
        const data = tag
        const method = 'delete'
        const url = this.endpoint('tag')
        await this.request({ method, url, data })
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  /**
   * Saves the specified note
   * @param {Note} note Note to save
   * @returns {Promise<Note>} Saved note
   */
  async saveNote ({ note }) {
    try {
      const data = { ...note }
      const { id } = data
      const method = id == null ? 'post' : 'put'
      const url = this.endpoint('note', id)
      const { note: savedNote } = await this.request({ method, url, data })
      return this.asNote(savedNote)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes the specified note
   * @param data Note to delete
   */
  async deleteNote ({ note: { id } = {} }) {
    try {
      const method = 'delete'
      const url = this.endpoint('note', id)
      const { note } = await this.request({ method, url })
      return this.asNote(note)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns notes associated with the specified entity
   * @param {String} id Identifier of entity whose notes to retrieve
   * @returns {Array[Note]} Notes associated with the specified entity
   */
  async getNotesOf ({ id } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('entity', id, 'note')
      const { notes = [] } = await this.request({ method, url })
      return notes.map(note => this.asNote(note))
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes notes associated with the specified entity
   * @param {String} id Identifier of entity whose notes to delete
   */
  async deleteNotesOf ({ id } = {}) {
    try {
      const method = 'delete'
      const url = this.endpoint('entity', id, 'note')
      await this.request({ method, url })
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns a list of announcements
   * @returns {Promise<Array[Announcement]>} List of announcements
   */
  async getAnnouncements () {
    try {
      const method = 'get'
      const url = this.endpoint('announcement')
      const { announcements } = await this.request({ method, url, params: { all: true } })
      return this.asAnnouncements(announcements)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns a list of announcements applicable for the asking user
   * @param {Boolean} unacknowledged If true, only new and not yet acknowledged announcements are returned
   * @param {Boolean} expired If true, also expired announcements are returned, useful for the list of past announcements etc.
   * @returns {Promise<Array[Announcement]>} List of announcements applicable for the asking user
   */
  async getMyAnnouncements ({ unacknowledged, expired } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('announcement')
      const { announcements } = await this.request({ method, url, params: { unacknowledged, expired } })
      return this.asAnnouncements(announcements)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns details of a specified announcement
   * @param {String} id Announcement identifier
   * @returns {Promise<Announcement>} Announcement details
   */
  async getAnnouncement ({ id }) {
    try {
      const method = 'get'
      const url = this.endpoint('announcement', id)
      const { announcement } = await this.request({ method, url })
      return this.asAnnouncement(announcement)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes the announcement
   * @param {Announcement} announcement Announcement to delete
   * @returns {Promise<Announcement>} Details of the deleted announcement
   */
  async deleteAnnouncement ({ announcement }) {
    try {
      const method = 'delete'
      const url = this.endpoint('announcement', announcement.id)
      const { announcement: deletedAnnouncement } = await this.request({ method, url })
      return this.asAnnouncement(deletedAnnouncement)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves the announcement
   * @param {Announcement} announcement Announcement to save
   * @returns {Promise<Announcement>} Saved announcement
   */
  async saveAnnouncement ({ announcement }) {
    try {
      const method = announcement.isNew ? 'post' : 'put'
      const url = this.endpoint('announcement', announcement.isNew ? '' : announcement.id)
      const data = announcement
      const { announcement: savedAnnouncement } = await this.request({ method, url, data, retry: false })
      return this.asAnnouncement(savedAnnouncement)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Publishes the announcement
   * @param {Announcement} announcement Announcement to publish
   * @returns {Promise<Announcement>} Details of the published announcement
   */
  async publishAnnouncement ({ announcement }) {
    try {
      const method = 'put'
      const url = this.endpoint('announcement', announcement.id, 'publish')
      const data = { id: announcement.id }
      const { announcement: publishedAnnouncement } = await this.request({ method, url, data, retry: false })
      return this.asAnnouncement(publishedAnnouncement)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Acknowledges the announcement
   * @param {Announcement} announcement Announcement to acknowledge
   * @returns {Promise<Announcement>} Details of the acknowledged announcement
   */
  async acknowledgeAnnouncement ({ announcement }) {
    try {
      const method = 'put'
      const url = this.endpoint('announcement', announcement.id, 'ack')
      const data = { id: announcement.id }
      const { status } = await this.request({ method, url, data })
      return this.asAnnouncementStatus(status)
    } catch (error) {
      this.handleError(error)
    }
  }


  /**
   * Retrieves a preference of the specified principal
   * @param name Preference to retrieve
   * @param principal Principal owning the preference. If not specified, global preference is retrieved
   */
  async getPreference ({ name, principal }) {
    try {
      const method = 'get'
      const url = principal
        ? this.endpoint('preferences', 'principal', principal.id, name)
        : this.endpoint('preferences', name)
      const { preference } = await this.request({ method, url })
      return this.asPreference(preference)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves preferences of the specified principal
   * @param principal Principal owning the preferences. If not specified, global preference is retrieved
   * @param prefix A prefix of preferences to retrieve. If not specified, all preferences will be retrieved.
   */
  async getPreferences ({ principal, prefix }) {
    try {
      const method = 'get'
      const url = principal
        ? this.endpoint('preferences', 'principal', principal.id)
        : this.endpoint('preferences')
      const params = { prefix }
      const { preferences } = await this.request({ method, url, params })
      return this.asPreferences(preferences)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves a dictionary preferences of the specified principal
   * @param principal Principal retrieving the preferences
   * @param prefix A prefix of preferences to retrieve. If not specified, all preferences will be retrieved.
   */
  async getPreferencesDictionary ({ principal, prefix }) {
    try {
      const method = 'get'
      const url = principal
        ? this.endpoint('principal', principal.id, 'preferences')
        : this.endpoint('preferences')
      const params = { prefix }
      const { preferences } = await this.request({ method, url, params })
      return this.asPreferencesDictionary(preferences)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves a preference
   * @param preference Preference to save
   * @param principal Principal owning the preference. If not specified, global preference is saved.
   */
  async savePreference ({ preference, principal }) {
    try {
      const method = 'put'
      const data = {
        preferences: [
          {
            ...preference,
            value: preference.serializedValue
          }
        ]
      }
      const url = principal
        ? this.endpoint('principal', principal.id, 'preferences')
        : this.endpoint('preferences')
      const { preferences: savedPreferences } = await this.request({ method, url, data })
      return this.asPreferences(savedPreferences)[0]
    } catch (error) {
      // This is a non-critical error
      Log.exception(error)
    }
  }

  /**
   * Saves preferences
   * @param preferences Preferences to save
   * @param principal Principal owning the preferences. If not specified, global preference is saved.
   */
  async savePreferences ({ preferences = [], principal }) {
    try {
      const method = 'put'
      const data = {
        preferences: preferences.map(preference => ({
          ...preference,
          value: preference.serializedValue
        }))
      }
      const url = principal
        ? this.endpoint('principal', principal.id, 'preferences')
        : this.endpoint('preferences')
      const { preferences: savedPreferences } = await this.request({ method, url, data })
      return this.asPreferences(savedPreferences)
    } catch (error) {
      // This is a non-critical error
      Log.exception(error)
    }
  }

  /**
   * Saves and optionally sends the specified bug report
   * @param {Bug} bug Bug report to save
   * @param {Boolean} send If true, bug report is sent
   * @returns {Promise<Bug>} Saved bug
   */
  async submitBugReport ({ bug, send = true }) {
    try {
      const method = 'post'
      const url = this.endpoint('bug')
      const data = { bug, send }
      const result = await this.request({ method, url, data })
      return this.asBug(result?.bug)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Sends the specified bug report
   * @param {Bug} bug Bug report to send
   * @returns {Promise<Bug>} Sent bug
   */
  async sendBugReport ({ bug }) {
    try {
      const method = 'put'
      const url = this.endpoint('bug', bug.id)
      const { bug: sentBug } = await this.request({ method, url })
      return this.asBug(sentBug)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the specified bug report
   * @param {String} id Identifier of a bug report to retrieve
   * @returns {Promise<BugReport>} Bug report details
   */
  async getBugReport ({ id }) {
    try {
      const method = 'get'
      const url = this.endpoint('bug', id)
      const { bug } = await this.request({ method, url })
      return this.asBug(bug)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves the specified audit entry
   * @param {AuditItem} data Audit details to save
   * @returns {Promise<AuditItem>} Saved audit entry
   */
  async audit (data) {
    try {
      const method = 'post'
      const url = this.endpoint('audit')
      const { auditItem } = await this.request({ method, url, data })
      return this.asAuditItem(auditItem)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves audit trail matching the specified conditions
   * @param {AuditAction} action Action type
   * @param {String} actorId Identifier of the actor
   * @param {String} subjectId Identifier of the subject
   * @param {EntityType} subjectType Type of the subject
   * @param {String} targetId Identifier of the target
   * @param {EntityType} targetType Type of the target
   * @param {User} user User retrieving the audit trail
   * @returns {Promise<Array[AuditItem]>}
   */
  async getAuditTrail ({ action, actorId, subjectId, subjectType, targetId, targetType } = {}) {
    try {
      const method = 'get'
      const url = this.endpoint('audit')
      const params = {
        action,
        actorId,
        subjectId,
        subjectType,
        targetId,
        targetType
      }
      const { items } = await this.request({ method, url, params }) || {}
      return items?.map(item => this.asAuditItem(item))
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Searches for entities matching the specified condition
   * @param {EntityType} type Entity to search for
   * @param {String} condition Condition to search for. Entity type might also be encoded in the condition itself as a prefix
   * @returns {Array} List of matching entities
   */
  async find ({ type, condition } = {}) {
    if (!(type || condition)) return []

    try {
      const method = 'post'
      const url = this.endpoint('find')

      if (!type) {
        const prefix = condition.split(' ')[0]
        if (prefix && isEnum(EntityType, prefix)) {
          type = prefix
        }
      }

      const data = { type, condition }
      const { items = [] } = await this.request({ method, url, data })

      return items
        .map(item => {
          switch (type) {
            case EntityType.Device:
              return new Device(item)
            case EntityType.Place:
              return new Place(item)
            case EntityType.User:
              return new User(item)
            case EntityType.Organization:
              return new Organization(item)
            case EntityType.OrganizationProfile:
              return new OrganizationProfile(item)
          }
        })
        .filter(item => item)

    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Retrieves the specified attachment
   * @param {String} id Identifier of attachment to retrieve
   * @param {Boolean} withContent If true, attachment content is also retrieved
   * @returns {Promise<Attachment>} Saved attachment
   */
  async getAttachment ({ id, withContent }) {
    try {
      const method = 'get'
      const url = this.endpoint('attachment', id)
      const params = { content: withContent }
      const { attachment } = await this.request({ method, url, params })
      return this.asAttachment(attachment)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Returns URL for downloading attachment content.
   * @param {Attachment} attachment Attachment to download
   * @param {Boolean} remove Set to true to remove the attachment after the download
   * @param {Boolean} decode Set to true to download decoded attachment content
   * @returns {String} URL for downloading attachment content
   */
  getAttachmentUrl ({ attachment, remove, decode } = {}) {
    if (attachment?.id) {
      if (attachment.isS3Object && attachment.reference.startsWith('https://')) {
        return attachment.reference
      } else {
        const url = `${this.endpoint('attachment', attachment.id, 'download')}?key=${this.session.token}${decode ? '&decode=true' : ''}${remove ? '&remove=true' : ''}`
        return url
      }
    }
  }

  /**
   * Gets all attachments owned by the specified owner, associated with the specified entity
   * or linked with the specified principal
   * @param {String} ownerId Owner identifier
   * @param {String} entityId Entity identifier
   * @param {String} principalId Linked principal identifier
   * @param {Boolean} withContent If true, attachment content is also retrieved
   * @returns {Promise<Array[Attachment]>}
   */
  async getAttachments ({ ownerId, entityId, principalId, withContent }) {
    try {
      const method = 'get'
      const url = this.endpoint(
        'attachment',
        ownerId ? 'of' : '',
        entityId ? 'to' : '',
        principalId ? 'principal' : '',
        ownerId || entityId || principalId
      )
      const params = { content: withContent }
      const { attachments, owners } = await this.request({ method, url, params })
      return {
        attachments: this.asAttachments(attachments),
        owners
      }
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Saves the specified attachment
   * @param {Attachment} attachment Attachment to save
   * @param {Boolean} store Indicates whether to create a record in the application database.
   * Can be useful when attachment is marked as `external`, when its payload is to be stored in the external file store.
   * @param {Boolean} decode If attachment data is Base64-encoded and external attachment, check if we need to decode it before saving
   * @returns {Promise<Attachment>} Saved attachment
   */
  async saveAttachment ({ attachment, store, decode }) {
    try {
      const method = 'put'
      const data = new FormData()
      if (attachment.id) {
        data.append('id', attachment.id)
      }
      data.append('name', attachment.name)
      data.append('description', attachment.description)
      data.append('type', attachment.type)
      data.append('mimeType', attachment.mimeType)
      data.append('createdAt', attachment.createdAt.toISOString())
      if (attachment.size) data.append('size', attachment.size)
      if (attachment.ownerId) data.append('ownerId', attachment.ownerId)
      if (attachment.entityId) data.append('entityId', attachment.entityId)
      if (attachment.entityType) data.append('entityType', attachment.entityType)
      if (attachment.dataType) data.append('dataType', attachment.dataType)
      if (attachment.folder) data.append('folder', attachment.folder)
      if (attachment.reference) data.append('reference', attachment.reference)
      if (attachment.external) data.append('external', true)
      if (attachment.bucket != null) data.append('bucket', attachment.bucket)
      if (store != null) data.append('store', store)

      if (attachment.file) {
        data.append('content', attachment.file)
      } else if (attachment.content) {
        if (attachment.content.startsWith('data:')) {
          data.append('dataUrl', attachment.content)
        } else {
          data.append('dataUrl', `data:${attachment.mimeType};base64,${attachment.content}`)
        }
      }

      const url = this.endpoint('attachment')
      const params = { decode }
      const { attachment: savedAttachment } = await this.request({ method, url, params, data, retry: false })
      return this.asAttachment(savedAttachment)

    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Links the specified attachment to the specified principal.
   * @param {Attachment} attachment Attachment to link
   * @param {Principal} principal Principal to link the attachment to
   * @param {Boolean} allowEdit If true, the principal can not only view the attachment but also modify it
   * @param {Boolean} allowDelete If true, the principal can not only view the attachment but also delete it
   * @param {Boolean} removeExistingLinks If true, any existing links will be removed
   * @returns {Promise<Attachment>} Linked attachment
   */
  async linkAttachment ({ attachment, principal, allowEdit = false, allowDelete = false, removeExistingLinks }) {
    try {
      const method = 'put'
      const url = this.endpoint('attachment', attachment.id, 'principal')
      const data = {
        principalId: principal.id,
        allowEdit,
        allowDelete,
        removeExistingLinks
      }
      const { attachment: savedAttachment } = await this.request({ method, url, data })
      return this.asAttachment(savedAttachment)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Unlinks the specified attachment from the specified principal
   * or from all principals to which it is currently linked.
   * @param {Attachment} attachment Attachment to unlink
   * @param {Principal} principal Principal to unlink the attachment from.
   * If not specified, the attachment will be unlinked from all principals to which it is currently linked
   * @returns {Promise<Attachment>} Unlinked attachment
   */
  async unlinkAttachment ({ attachment, principal }) {
    try {
      const method = 'delete'
      const url = this.endpoint('attachment', attachment.id, 'principal')
      const data = { principalId: principal?.id }
      const { attachment: savedAttachment } = await this.request({ method, url, data })
      return this.asAttachment(savedAttachment)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes the specified attachment
   * @param {String} id Identifier of attachment to delete
   * @returns {Promise<Attachment>} Deleted attachment
   */
  async deleteAttachment ({ id }) {
    try {
      const method = 'delete'
      const url = this.endpoint('attachment', id)
      const { attachment } = await this.request({ method, url })
      return this.asAttachment(attachment)
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Deletes the specified attachments matching the specified conditions
   * @param {String} id Attachment identifier
   * @param {String} name Attachment name
   * @param {String} ownerId Identifier of the attachment owner
   * @param {String} entityId Identifier of the entity to which the attachment belongs
   * @param {String} folder Attachment folder
   * @param {String} reference Attachment reference
   * @param {Boolean} external Indicates an external attachment
   * @param {String} bucket Bucket from which to delete the attachment
   * @param {String} dataType Attachment data type
   * @returns {Promise}
   */
  async deleteAttachments ({ id, name, ownerId, entityId, folder, dataType, reference, external, bucket }) {
    try {
      const method = 'delete'
      const url = this.endpoint('attachments')
      const params = { id, name, ownerId, entityId, folder, dataType, reference, external, bucket }
      await this.request({ method, url, params })
    } catch (error) {
      this.handleError(error)
    }
  }

  /**
   * Stores values gathered by the specified application
   * @param {String} application Application name
   * @param {Dictionary<String,Number>|Array<Number>|Array<{String,Number}>} values Collected values, named or unnamed
   * @param {String} details Additional details to store
   * @returns {Promise}
   */
  async storeStatistics ({ application, values = [], details } = {}) {
    try {
      const method = 'post'
      const url = this.endpoint('statistics')
      const data = { application, values, details }
      await this.request({ method, url, data })
    } catch (error) {
      Log.exception(error)
    }
  }

  /**
   * Saves the specified attachment
   * @param {File} file PDF file
   * @param {Boolean} store If true, the images are stored in the application database as attachments
   * @param {Boolean} external If true, the images are stored in the external file store
   * @param {Array[Number]} pages Pages to convert to images. If not specified, all pages will be converted
   * @param {Number} quality Image quality on a scale `0 - 100`, where `0` is the worst quality + the smallest image, and `100` is the best quality but largest image. Default is `100`.
   * @param {Number} density Image DPI, default is `96`
   * @param {String} format Image format, either `png` or `jpg`
   * @param {Number} width Image width in pixels
   * @param {Number} height Image height in pixels. Required, if `preserveAspectRatio` is set to `false`
   * @param {Boolean} preserveAspectRatio If `true`, the image will retain the original aspect ratio
   * @returns {Promise<Array[Attachment]>} PDF image files, one for every page
   */
  async pdfToImages ({ file, store, external, pages, quality, density, format, width, height, preserveAspectRatio }) {
    if (!file) throw new Error('File is required')
    if (file.size === 0) throw new Error('File is empty')
    if (!file.type === 'application/pdf') throw new Error(`File type [${file.type}] is not allowed`)

    try {
      const method = 'put'
      const data = new FormData()
      data.append('name', file.name)
      data.append('description', file.description)
      data.append('type', AttachmentType.Image)
      data.append('mimeType', file.type)
      data.append('createdAt', file.lastModifiedDate)
      data.append('size', file.size)
      data.append('store', Boolean(store))
      data.append('external', Boolean(external))
      data.append('format', format)
      data.append('width', width)
      // Optional parameters
      if (pages != null) data.append('pages', pages)
      if (quality != null) data.append('quality', quality)
      if (density != null) data.append('density', density)
      if (height != null) data.append('height', height)
      if (preserveAspectRatio != null) data.append('preserveAspectRatio', preserveAspectRatio)
      data.append('content', file)

      const url = this.endpoint('pdf-to-image')
      const { images, error } = await this.request({
        method,
        url,
        data,
        retry: false,
        timeout: 120000
      })

      return {
        images: (images || []).map(image => this.asAttachment(image)),
        error
      }

    } catch (error) {
      this.handleError(error)
    }
  }

  asTag (item) {
    if (item) {
      return new Tag(item)
    }
  }

  asNote (item) {
    if (item) {
      return new Note(item)
    }
  }

  asNotes (items) {
    if (items) {
      return items.map(item => new Note(item))
    }
  }

  asAnnouncement (item) {
    if (item) {
      return new Announcement(item)
    }
  }

  asAnnouncements (items) {
    if (items) {
      return items.map(item => new Announcement(item))
    }
  }

  asAnnouncementStatus (item) {
    if (item) {
      return new AnnouncementStatus(item)
    }
  }

  asBug (item) {
    if (item) {
      return new Bug(item)
    }
  }

  asAuditItem (item) {
    if (item) {
      return new AuditItem(item)
    }
  }

  asPreference (item) {
    if (item) {
      return new Preference(item)
    }
  }

  asPreferences (items) {
    if (items) {
      return items.map(item => new Preference(item))
    }
  }

  asPreferencesDictionary (items) {
    if (items) {
      return items.reduce((all, item) => {
        const preference = new Preference(item)
        all[preference.name] = preference.value
        return all
      }, {})
    }
  }

  asAttachment (item) {
    const attachment = new Attachment(item)
    attachment.downloadUrl = attachment.canDownload
      ? this.getAttachmentUrl({ attachment })
      : null
    return attachment
  }

  asAttachments (items) {
    if (items) {
      return items.map(item => this.asAttachment(item))
    }
  }
}
