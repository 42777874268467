<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { isToday, isYesterday } from 'date-fns'
import { formatDateTime, formatTime, pluralize, getConciseDurationString } from '@stellacontrol/utilities'
import { isAlertSupported } from '@stellacontrol/alerts'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  data () {
    return {
      alertConfigurations: null
    }
  },

  computed: {
    ...mapState({
      // Age of the alerts to display
      age: state => state.deviceDashboard.lastAlertsAge,
      // Recently retrieved alerts of monitored devices
      lastAlerts: state => state.deviceStatus.alerts
    }),

    ...mapGetters([
      'toLocalDateTime',
      'guardian'
    ]),

    items () {
      const { lastAlert, alerts, age } = this
      const items = [
        { text: 'Recent alerts', class: { label: true } }
      ]

      if (lastAlert) {
        // Returns alert time in user-friendly format
        const alertTimeText = alert => {
          const alertTime = this.toLocalDateTime(alert.time)
          const alertTimeText = isToday(alertTime)
            ? formatTime(alertTime)
            : (isYesterday(alertTime) ? `Yesterday, ${formatTime(alertTime)}` : formatDateTime(alertTime, 'd MMM, HH:mm:ss'))
          return alertTimeText
        }

        const otherAlerts = alerts.filter(alert => alert.id !== lastAlert.id)
        items.push(
          {
            text: alertTimeText(lastAlert),
            class: { small: true }
          },
          {
            text: lastAlert.message,
            class: { small: true },
          }
        )

        if (otherAlerts.length > 0) {
          items.push({
            text: `+ <a class="item-link">${otherAlerts.length} more ${pluralize(otherAlerts.length, 'alert')}</a>`,
            class: { small: true, 'cursor-pointer': true },
          })
        }


      } else {
        items.push({
          text: `No alerts during the last ${getConciseDurationString(age)}`,
          class: { small: true }
        })
      }
      return items
    },

    // Recently logged alerts,
    // filtered by user permissions and current capabilities of the device
    alerts () {
      const { device, lastAlerts, status, guardian, alertConfigurations } = this
      const alerts = lastAlerts[device.serialNumber] || []
      return alerts
        .filter(alert => guardian.canReceiveAlert(alert.alertType))
        .filter(alert => {
          const configuration = alertConfigurations.find(c => c.alertType === alert.alertType)
          if (configuration != null) {
            return isAlertSupported(alert.alertType, configuration, device, status)
          }
        })
    },

    // Most recent alert
    lastAlert () {
      return this.alerts[0]
    },

    // Other alerts except last one
    otherAlerts () {
      return this.alerts.slice(1)
    },

    // Widget icon
    icon () {
      const { device } = this
      return device.canTriggerAlerts ? 'notifications_active' : 'notifications_off'
    },

    // Color of the widget icon
    color () {
      const { device, lastAlert } = this
      return device.canTriggerAlerts
        ? (lastAlert ? 'deep-orange-6' : 'light-green-7')
        : 'grey-5'
    },

    // Determines whether the widget can be shown
    canShow () {
      const { device, alertConfigurations } = this
      return device?.canTriggerAlerts && alertConfigurations
    }
  },

  methods: {
    ...mapActions([
      'getDeviceAlertConfigurations'
    ])
  },

  async created () {
    const { device, guardian } = this
    if (guardian.canUse('alerts') && device?.canTriggerAlerts) {
      this.alertConfigurations = await this.getDeviceAlertConfigurations({ device })
    }
  }
}
</script>

<template>
  <sc-widget-text v-if="canShow" iconMargin="0" :icon="icon" :iconColor="color" :items="items"
    :alerts="alerts" min-width="195px" max-width="300px">
  </sc-widget-text>
</template>

<style lang="scss" scoped></style>
