import Konva from 'konva'
import { range } from '@stellacontrol/utilities'
import { PlanItemType, PlanPortType, PlanLocation } from '@stellacontrol/planner'
import { Shape } from './shape'
import { ShapeLayout } from './shape-layout'

/**
 * Cable Plug
 * @param {PlanPlug} item Plan item details
 */
export class PlugShape extends Shape {
  constructor (item, dataCallback) {
    super(item, dataCallback)
    this.createShapes()
  }

  static get type () {
    return PlanItemType.Plug
  }

  __layout

  /**
   * Shape layout
   * @type {ShapeLayout}
   */
  get layout () {
    const { item } = this
    const shape = { x: 0, y: 0, width: 30, height: 30, }

    if (shape.width !== item.width || shape.height !== item.height) {
      throw new Error('Item dimensions here are different than in the item definition')
    }

    // Return the existing layout if unchanged
    if (this.__layout) {
      return this.__layout
    }

    // Prepare the shape layout
    const layout = new ShapeLayout({
      shape,
      ports: [
        {
          id: PlanPortType.In,
          location: PlanLocation.Bottom
        }
      ]
    })


    this.__layout = layout
    return layout
  }


  get defaults () {
    return {
      ...super.defaults,
      port: {
        width: 10,
        height: 10
      }
    }
  }

  /**
   * Shapes representing the plug
   * @type {Konva.Shape}
   */
  outerShape
  innerShape

  /**
   * Main shape in the {@link content}, such as device chassis etc.
   * @type {Konva.Shape}
   */
  get main () {
    return this.outerShape
  }

  /**
   * Shape representing the connection port on the plug
   * @type {Konva.Shape}
   */
  portShape

  /**
   * Shape representing the riser tag
   * @type {Konva.Text}
   */
  riserTag

  /**
   * Creates all shapes making up the plug
   */
  createShapes () {
    super.createShapes()

    const { item } = this
    const { width, height, thickness, backgroundStyle, lineStyle } = item

    this.outerShape = new Konva.Rect({
      width,
      height,
      fill: backgroundStyle.color
    })

    const createStripe = (x, y) => new Konva.Rect({ width: thickness, height: thickness, x, y, fill: lineStyle.color })
    const stripeCount = Math.floor((width / thickness) / 2)
    const stripeIds = [...range(0, stripeCount)]
    const stripes = [
      ...stripeIds.map(i => createStripe(thickness + i * thickness * 2, 1)),
      ...stripeIds.map(i => createStripe(thickness + i * thickness * 2, height - thickness)),
      ...stripeIds.map(i => createStripe(0, thickness + i * thickness * 2)),
      ...stripeIds.map(i => createStripe(width - thickness, thickness + i * thickness * 2))
    ]

    this.innerShape = new Konva.Rect({
      x: thickness,
      y: thickness,
      width: width - (thickness * 2),
      height: height - (thickness * 2),
      fill: 'white',
      stroke: backgroundStyle.color,
      strokeWidth: 1
    })

    const border = new Konva.Rect({
      width,
      height,
      stroke: backgroundStyle.color,
      strokeWidth: 1,
      fill: 'transparent'
    })

    this.content.add(
      this.outerShape,
      ...stripes,
      this.innerShape,
      border
    )
  }

  /**
   * Determines the text of the shape label
   * @param {PlanRenderer} renderer Renderer instance
   * @returns {String}
   */
  getLabelText ({ renderer }) {
    if (!renderer) throw new Error('Renderer is required')
    const { item } = this

    const riser = renderer.layout.getRiser(item.riser)
    return riser?.tagText
  }
}
