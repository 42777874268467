<script>
import { mapActions, mapState } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'
import { bandsToIdentifiers, getBandMegaParameters, StandardDeviceBands, getDeviceFamily, DeviceFamily } from '@stellacontrol/model'

const dialog = 'band-toggle'

export default {
  mixins: [
    DialogMixin
  ],

  data () {
    return {
      dialog,
      // Devices for which bands are to be selected
      devices: [],
      // Initially selected bands
      selectedIdentifiers: [],
      // Color representing a selected band
      selectedBandColor: 'green-9',
      // Dialog title
      title: 'Click on bands to turn on or shut down',
      // OK button label
      okLabel: 'OK',
      // Cancel button label
      cancelLabel: 'Close',
    }
  },

  computed: {
    ...mapState({
      // Status of all currently watched devices
      deviceStatus: state => state.deviceStatus.devices
    }),


    // Current device
    device () {
      const { devices } = this
      return devices[0]
    },

    // Device family, from first of the specified devices
    deviceFamily () {
      if (this.devices) {
        const device = this.device
        return device ? getDeviceFamily(device) : DeviceFamily.EU
      }
    },

    // Available bands, deducted from the devices passed to the dialog.
    // If no devices passed, all bands will be presented.
    bands () {
      const device = (this.devices || [])[0]
      if (device) {
        return device.bands
      } else {
        return StandardDeviceBands.SixBands
      }
    },

    // deviceStatus
    status () {
      return this.deviceStatus[this.device.serialNumber]
    },

    // Identifiers of the available bands
    identifiers () {
      return bandsToIdentifiers(this.bands)
    },

    // Band details
    details () {
      const { identifiers, deviceFamily } = this
      return identifiers.map(identifier => {
        const { label } = getBandMegaParameters(identifier, deviceFamily)
        return { identifier, label }
      })
    },

  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel',
      'sendDeviceCommand'
    ]),

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      this.devices = this.data.devices
      this.selectedIdentifiers = bandsToIdentifiers(this.data.selectedIdentifiers)
      this.selectedBandColor = this.data.selectedBandColor || this.selectedBandColor
      this.title = this.data.title || this.title
      this.okLabel = this.data.okLabel || this.okLabel
      this.cancelLabel = this.data.cancelLabel || this.cancelLabel
    },

    // Perform de band toggle
    async toggleBand (band) {

      const { identifier, label } = band

      const device = this.device
      const devices = [device]
      const command = {
        name: 'TOGGLE_BANDS',
        parameters: {
          [`_shutdown_${identifier}`]: this.isBandOnline(identifier)
        }
      }
      const status = { [device.serialNumber]: this.deviceStatus }
      const notificationExecuting = `${this.isBandOnline(identifier) === true ? 'Shutting down' : 'Turning on'} the band ${label}`
      const notificationExecuted = `The band ${label} ${this.isBandOnline(identifier) === true ? 'has been shut down' : 'has been turned on'}`
      const notificationFailed = `Failed ${this.isBandOnline(identifier) === true ? 'Shutting down' : 'Turning on'} the band ${label}`

      await this.sendDeviceCommand({
        command,
        devices,
        status,
        notificationExecuting,
        notificationExecuted,
        notificationFailed,
      })
    },

    isBandDisabled (identifier) {
      const { bands } = this.status
      return bands.status[identifier].isRFOff === true || bands.status[identifier].isShutdownAutomatically === true
    },


    // Get the current band status
    isBandOnline (identifier) {
      const { bands } = this.status
      return bands.status[identifier].isOnline
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" persistent @dialogShown="dialogShown()">
    <q-form ref="form">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ title }}
        </span>
      </q-banner>


      <div class="row q-mt-md q-pa-md">
        <div class="column align-center text-center q-pa-xs" v-for="band of details" :key="band.identifier">
          <span class="q-mb-sm">
            {{ band.label }}
          </span>

          <q-btn unelevated class="band-button" :label="isBandOnline(band.identifier) ? 'ON' : 'OFF'" :ripple="false"
            :disable="isBandDisabled(band.identifier)"
            :color="isBandOnline(band.identifier) ? 'green-7' : 'grey-7'"
            @click="toggleBand(band)">

            <sc-tooltip v-if="isBandDisabled(band.identifier)">
              The band is currently disabled
            </sc-tooltip>

            <sc-tooltip v-if="!isBandDisabled(band.identifier)">
              {{ isBandOnline(band.identifier) ? `Click to shut down` : `Click to turn on` }}
            </sc-tooltip>
          </q-btn>
        </div>
      </div>

      <div class="row q-my-md">
        <q-space></q-space>
        <q-btn :label="cancelLabel" unelevated class="q-mr-md" @click="cancel()" />
      </div>
    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.title {
  font-size: 18px;
}

.button-select-all {
  min-width: 140px;
}

.band-button {
  min-width: 70px;
  width: 70px;
}

.band-label {
  font-size: 10px;
  text-align: center;
}

/* Layout adjustments for small screens */
@media screen and (max-width: 600px) {
  .band-button {
    min-width: 54px;
    width: 54px;
  }
}
</style>