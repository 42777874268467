<script>
import { sortItems } from '@stellacontrol/utilities'
import { DeviceBandColor } from '@stellacontrol/model'
import ParameterEditor from './parameter-editor'

export default {
  mixins: [
    ParameterEditor
  ],

  props: {
    // Edited band
    band: {
      type: Object,
      required: true
    },

    // Whether editing should be enabled
    enabled: {
      default: true
    }
  },

  data () {
    return {
      items: [
        { label: 'Enabled', icon: 'fiber_manual_record', color: 'green-5', value: true },
        { label: 'Disabled', icon: 'fiber_manual_record', color: 'grey-9', value: false }
      ]
    }
  },

  computed: {
    hasRFSlotStatus () {
      return this.status?.mega['_rf_slot_enable'] != null
    },

    description () {
      const { details } = this.band || {}
      return details ? details.description : ''
    },

    bandColor () {
      const { status } = this.band || {}
      return status && status.isEnabled ? DeviceBandColor.Ok : DeviceBandColor.NotAvailable
    },

    bandLabel () {
      const { details } = this.band || {}
      return details ? details.bandLabel : ''
    },

    bandTooltip () {
      const { status } = this.band || {}
      return status ? (status.isEnabled ? 'Enabled' : 'Disabled') : ''
    },

    bandStyle () {
      const { bandColor } = this
      return {
        'background-color': bandColor
      }
    }
  },

  methods: {
    toggleRFSlot (isEnabled) {
      const bands = sortItems(Object.values(this.status.bands.status), 'index')
      const value = bands
        .filter(band => band.identifier === this.band.identifier ? isEnabled : band.isEnabled)
        .map(band => band.index)
        .join(',')
      this.change(value)
    }
  }
}
</script>

<template>
  <div class="parameter-editor" v-if="hasRFSlotStatus">

    <sc-parameter-value :label="`Band ${band.label}`" :reported="reported" :custom="custom">

      <div class="band-indicator" :style="bandStyle">
        <sc-tooltip :text="bandTooltip" anchor="top right" self="top left"></sc-tooltip>
      </div>

    </sc-parameter-value>

    <sc-parameter-selector v-if="enabled" :label="band.label" :allow-clear="false" :items="items"
      @select="value => toggleRFSlot(value)">
    </sc-parameter-selector>

  </div>
</template>

<style lang="scss" scoped>
.band-indicator {
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &:hover {
    opacity: 0.8;
  }
}
</style>