/**
 * Extracts a value of the specified object property
 * @param {Object} instance Instance to get the value from
 * @param {String} property Property path, can be nested path using dots
 * @param {any} defaultValue Default value to assume, if property was not found
 * @param {Boolean} throwIfNotFound If true, exception will be thrown if property does not exist and default value is not specified
 * @returns {any} The value of the property or the specified default value, if property not found
 */
export function getPropertyValue (instance, property, defaultValue, throwIfNotFound = true) {
  if (instance == null) {
    throw new Error('Instance not specified')
  }
  if (!property) {
    throw new Error('Property not specified')
  }

  const parts = property.split('.')
  let value
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i]
    const isLast = (i === parts.length - 1)
    if (isLast) {
      if (part in instance) {
        value = instance[part]
      }
    } else {
      instance = part in instance ? instance[part] : {}
    }
    if (instance == null) break
  }

  if (value !== undefined) {
    return value
  } else {
    if (defaultValue === undefined && throwIfNotFound !== false) {
      throw new Error(`${property} property not found`)
    } else {
      return defaultValue
    }
  }
}

/**
 * Sets the value of a specified object property
 * @param {Object} instance Instance to set the value in
 * @param {String} property Property path, can be nested path using dots
 * @param {any} value The value of the property to set
 * @param {Boolean} throwIfNotFound If true and  property is not found, exception is thrown
 * @returns {Object} Instance with assigned property
 */
export function setPropertyValue (instance, property, value, throwIfNotFound = true) {
  if (instance == null) {
    throw new Error('Instance not specified')
  }
  if (!property) {
    throw new Error('Property not specified')
  }

  const root = instance
  const parts = property.split('.')
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i]
    const isLast = (i === parts.length - 1)
    if (isLast) {
      if (!(part in instance) && throwIfNotFound) {
        throw new Error(`${property} property not found`)
      } else {
        instance[part] = value
      }
    } else {
      if (!(part in instance)) {
        if (throwIfNotFound) {
          throw new Error(`${property} property not found`)
        } else {
          instance[part] = {}
        }
      }
      instance = instance[part]
    }
  }

  return root
}

/**
 * Sorts instance properties alphabetically
 * @param {Object} instance Instance to sort
 * @returns {Object} Instance with sorted properties
 */
export function sortProperties (instance) {
  if (instance != null) {
    // Recursively loop over items if instance is an array
    if (Array.isArray(instance)) {
      const result = [...instance].sort()
      for (let i = 0; i < result.length; i++) {
        const item = instance[i]
        if (typeof item === 'object') {
          sortProperties(item, result[i])
        }
      }
      return result
    } else {
      // Sort object properties
      const result = {}
      const keys = Object.keys(instance)
      keys.sort()
      for (const key of keys) {
        const value = instance[key]
        // Recurse through object properties and arrays
        if (typeof value === 'object' || Array.isArray(value)) {
          result[key] = sortProperties(value)
        } else {
          result[key] = value
        }
      }
      return result
    }
  }
}

/**
 * Returns the object with the specified properties removed
 * @param {Object} instance
 * @param {Array[String]|Dictionary<String,any>} properties Properties to remove
 * @return {Object}
 */
export function withoutProperties (instance, properties = []) {
  if (instance != null) {
    properties = Array.isArray(properties)
      ? properties
      : Object.keys(properties)
    for (const property of properties) {
      delete instance[property]
    }
    return instance
  }
}

/**
 * Cleans instance of properties which are null, undefined,
 * empty arrays and empty objects
 * @param {Object} instance Instance to cleanup
 * @param {Boolean} emptyStrings If true, properties with empty strings are removed
 * @param {Boolean} emptyArrays If true, properties with empty arrays are removed
 * @param {Boolean} emptyObjects If true, properties with empty objects are removed
 * @returns {Object} Cleaned up instance
 */
export function cleanup (instance, { emptyStrings = false, emptyArrays = true, emptyObjects = true } = {}) {
  if (instance == null) {
    return
  }

  // If instance is an array, iterate and cleanup its items
  if (Array.isArray(instance)) {
    for (const item of instance) {
      cleanup(item, { emptyStrings, emptyArrays, emptyObjects })
    }
    return instance.filter(i => i != null)

  }

  // Otherwise cleanup object properties
  const entries = Object.entries(instance)

  for (const [key, value] of entries) {
    const type = typeof value

    if (value instanceof Date) {
      continue
    }

    // If value is null or undefined, delete the key
    if (value === null || value === undefined) {
      delete instance[key]
      continue
    }

    // If property is an empty string, cleanup
    if (emptyStrings && value === '' && type === 'string') {
      delete instance[key]
      continue
    }

    // If property is an array, iterate and cleanup its items
    if (Array.isArray(value)) {
      instance[key] = cleanup(value, { emptyStrings, emptyArrays, emptyObjects })
      if (emptyArrays && instance[key].length === 0) {
        delete instance[key]
      }
      continue
    }

    if (type === 'object') {
      // If property is an object...
      cleanup(value, { emptyStrings, emptyArrays, emptyObjects })
      if (emptyObjects && Object.keys(value).length === 0) {
        delete instance[key]
      }
    }
  }

  return instance
}

/**
 * Traverses the instance and runs the specified handler on each property
 * empty arrays and empty objects
 * @param {Object} instance Instance to traverse
 * @param {Function<Object, String, any, Boolean, Number>} handler Handler which receives the object,
 * the name, the value of a property, indication whether it is a simple property and the level at which it is found.
 * If the handler returns true, traversal is interupted.
 * @param {Number>} enter Handler triggered before recursively entering a nested property, receives the level
 * @param {Function<Number>} exit Handler triggered before recursively leaving a nested property, receives the level
 */
export function traverse (instance, { handler, enter, exit, level = 0, parent, key } = {}) {
  if (!handler) throw new Error('Handler is required')
  if (instance == null) return false

  if (enter) enter(level, key)

  let stop = false
  const isArray = Array.isArray(instance)
  const isObject = !isArray && typeof instance === 'object'
  const isPrimitive = !(isArray || isObject)

  stop = handler(parent, key, instance, isPrimitive, level)

  if (!stop) {
    if (isArray) {
      let index = 0
      for (const item of instance) {
        stop = traverse(item, { handler, enter, exit, level: level + 1, parent: instance, key: index++ })
        if (stop) {
          break
        }
      }
    } else if (isObject) {
      // Object - iterate properties
      const entries = Object.entries(instance)
      for (const [key, value] of entries) {
        stop = traverse(value, { handler, enter, exit, level: level + 1, parent: instance, key })
        if (stop) {
          break
        }
      }
    }
  }

  if (exit) exit(level, key)

  return stop
}

/**
 * Parses the object and converts to a tree of properties
 * @param {Object} instance Object to parse
 * @param {Boolean} skipNull Skips null and undefined properties
 * @param {Boolean} skipEmpty Skips empty arrays and objects
 * @returns {Object}
 */
export function objectToTree (instance, { skipNull = true, skipEmpty = true } = {}) {
  class ObjectProperty {
    constructor (data) {
      Object.assign(this, data)
      const { parent } = this
      if (parent) {
        if (!parent.children) parent.children = []
        parent.children.push(this)
      }
    }

    /**
     * Unique node identifier
     * @type {Number}
     */
    id

    /**
     * Property key
     * @type {String}
     */
    key

    /**
     * Property value, only present for primitive-type nodes
     * @type {Any}
     */
    value

    /**
     * Node label
     * @type {String}
     */
    label

    /**
     * Child properties
     * @type {Array[ObjectProperty]}
     */
    children

    toJSON () {
      const { id, key, value, label } = this
      let children
      if (this.children) {
        children = [...this.children].filter(child => {
          if (skipNull && child.value === null) return false
          if (skipEmpty && (!(child.children?.length > 0))) return false
          return true
        })
        children.sort((a, b) => a.value === undefined
          ? -1
          : (a.key < b.key) ? 1 : -1)
      }
      return {
        id,
        key,
        label,
        value,
        children
      }
    }
  }

  let id = 0
  let node

  traverse(instance, {
    handler: (instance, key, value, simple) => {
      node.property = key
      if (simple) {
        node.value = value
        node.label = `${key}: ${value.toString()}`
      } else {
        node.label = key
      }
    },
    enter: (level, key) => {
      node = new ObjectProperty({ level, id: id++, key, parent: node })
    },
    exit: () => {
      node = node.parent || node
    }
  })

  return node
}

/**
 * Returns a delta of two objects
 * @param {Object} recent Recent instance
 * @param {Object} previous Previous instance
 * @returns {Object} Instance with properties in the {@link recent} instance
 * which were not present in {@link previous} instance
 * @description The function performs a shallow scan of top-level properties only
 */
export function getDelta (recent, previous) {
  if (recent != null) {
    if (previous != null) {
      const delta = Object
        .entries(recent)
        .filter(([key, value]) => previous[key] != value)
        .reduce((all, [key, value]) => ({ ...all, [key]: value }), {})
      return delta
    } else {
      return { ...recent }
    }
  }
}

/**
 * Recursively merges the specified object instances
 * @param {Array[Object]} instances Instances to merge, from left to right
 * @returns {Object} Instances merged into one
 */
export function merge (...instances) {
  let i = instances.length - 1
  while (i > 0) {
    instances[i - 1] = mergeTwo(instances[i - 1], instances[i])
    i--
  }
  return instances[0]
}

/**
 * Merge a `source` object to a `target` recursively
 * @param {Object|Array} source Source object
 * @param {Object|Array} target Target object to merge the source into
 * @returns {Object|Array} Instances merged into one
 * @description Inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */
function mergeTwo (target = {}, source = {}) {
  const isObject = (obj) => obj && typeof obj === 'object'

  if (Array.isArray(source) && Array.isArray(target)) {
    return source.concat(target)
  }

  if (!isObject(target) || !isObject(source)) {
    return source
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key]
    const sourceValue = source[key]

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue)
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = merge(Object.assign({}, targetValue), sourceValue)
    } else {
      target[key] = sourceValue
    }
  })

  return target
}

/**
 * Initializes the instance with the specified data.
 * Optionally maps some values before assigning them.
 * @param {Object} instance Instance to initialize
 * @param {Object} data Data to assign to the instance
 * @param {Dictionary<String, Function>} map Value mappers
 * @returns {Object} Instance with assigned new data
 * @description Assignment is shallow, we're not recursing
 * into complex properties. For that, use {@link merge} instead.
 */
export function assign (instance, data = {}, map = {}) {
  if (instance != null && data != null) {
    // Map values before assignment
    for (const [key, mapper] of Object.entries(map)) {
      const value = data[key]
      if (value !== undefined) {
        data[key] = mapper(value)
      }
    }
    // Ignore assignments of readonly properties on the target instance
    for (const [key, value] of Object.entries(data)) {
      try {
        instance[key] = value
      } catch {
        // lint-disable-line no-empty
      }
    }
  }

  return instance
}

/**
 * Clones the specified instance.
 * @description Cloning is performed exclusively on data fields.
 * The result is not a clone of a class, but rather a JSON object
 * containing all data of the input object.
 * @param {Object} instance Instance to clone
 * @param {Boolean} full Normally, we use `JSON.stringify` followed by `JSON.parse` to clone objects.
 * This might have unwanted effects, if cloned classes have customized JSON serialization while we need
 * to retain all original values of the cloned instance. In such cases use `full` method to perform
 * a recursive property-by-property cloning.
 * @returns {Object} Cloned data object
 */
export function clone (instance, full) {
  if (instance == null) {
    return instance
  }

  if (full) {
    if (Array.isArray(instance)) {
      const result = []
      for (let i = 0; i < instance.length; i++) {
        result[i] = clone(instance[i], full)
      }
      return result
    }

    if (typeof instance === 'object') {
      const result = {}
      for (const [key, value] of Object.entries(instance)) {
        const descriptor = Object.getOwnPropertyDescriptor(instance, key)
        if (descriptor?.writable) {
          result[key] = clone(value, full)
        }
      }
      return result
    }
  }

  const serialized = JSON.stringify(instance)
  return serialized == null ? serialized : JSON.parse(serialized)
}

/**
 * Serializes the specified instance.
 * @param {Object} instance Instance to serialize
 * @param {Boolean} pretty If true, the output is ready for the human eye
 * @param {Boolean} withGetters If true, also readonly properties are serialized
 * @returns {String} Serialized instance
 */
export function serialize (instance, pretty, withGetters) {
  if (instance == null) return instance

  if (withGetters) {
    const extract = value => {
      if (Array.isArray(value)) {
        return value.map(item => extract(item))

      } else if (typeof value === 'object') {
        const result = { ...value }
        if (value?.constructor) {
          for (const [name, property] of Object.entries(Object.getOwnPropertyDescriptors(value.constructor.prototype))) {
            if (!property.writable) {
              result[name] = extract(value[name])
            }
          }
        }
        return result

      } else {
        return value
      }
    }

    instance = extract(instance)
  }

  return JSON.stringify(instance, null, pretty ? 2 : undefined)
}

/**
 * Returns true if specified object is empty or null.
 * To qualify, the value has to be null, undefined, empty object, empty array or empty string.
 * @param {Object} instance Instance to check
 * @return {Boolean} True if instance is empty or null
 */
export function isEmptyOrNull (instance) {
  if (instance == null) return true
  if (instance === '') return true
  if (Array.isArray(instance) && instance.length === 0) return true
  if (typeof instance === 'object' && Object.keys(instance).length === 0) return true
  return false
}

/**
 * Casts the specified instance into a given type.
 * Ignores and returns as-is if it's already a correct type
 * @param {Object} instance Instance to type-cast
 * @param {Function} constructor Constructor of the result class
 * @returns {Object} Instance casted to the specified class
 */
export function cast (instance, constructor) {
  if (instance != null && constructor != null && instance.constructor !== constructor) {
    if (typeof constructor !== 'function') throw new Error('Constructor must be a function')
    return new constructor(instance)
  } else {
    return instance
  }
}

/**
 * Casts all instances in the specified array into a given type.
 * Ignores and returns as-is instances which are already a correct type.
 * @param {Array[Object]} instances Instance to type-cast
 * @param {Function} constructor Constructor of the result class
 * @returns {Array[Object]} Instances casted to the specified class
 */
export function castArray (instances, constructor) {
  if (instances != null && constructor != null) {
    if (typeof constructor !== 'function') throw new Error('Constructor must be a function')
    const list = Array.from(instances)
    return list?.map(instance =>
      instance == null
        ? instance
        : (instance.constructor === constructor ? instance : new constructor(instance))
    )
  } else {
    return instances
  }
}
