<script>
import { range } from '@stellacontrol/utilities'
import { getBandLabel, getBandFrequencyLabel } from '@stellacontrol/model'
import { getMegaParameter } from '@stellacontrol/mega'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget
  ],

  props: {
    // Mini view for the device card
    micro: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    // LEDs present on the device
    LEDs () {
      const parameter = getMegaParameter('signal_leds', this.device, this.status)
      const LEDs = Array.from(range(0, parameter.max + 1))
      return LEDs
    },

    /**
     * Bands status parsed from MEGA
     */
    bandStatus () {
      return this.status?.bands
    },

    /**
     * Band identifiers
     */
    bandIdentifiers () {
      return this.bandStatus?.identifiers || []
    },

    /**
     * Returns true if any bands are available in the status
     */
    hasBands () {
      return this.bandIdentifiers.length > 0
    },

    /**
     * Status of device bands
     */
    bands () {
      return this.bandStatus?.status || {}
    },

    /**
     * Other details about states of device bands
     */
    details () {
      return this.bandStatus?.details || {}
    },

    /**
     * User-friendly and device-family-specific label of a band
     * @param identifier Band identifier
     */
    bandLabel () {
      const family = this.device?.family
      return identifier => getBandLabel(identifier, family)
    },

    /**
     * User-friendly and device-family-specific label of band's frequency
     * @param identifier Band identifier
     */
    bandFrequencyLabel () {
      const family = this.device?.family
      return identifier => getBandFrequencyLabel(identifier, family)
    },

    /**
     * Short label representing the general state of the band
     */
    bandStatusLabel () {
      return identifier => {
        const band = this.details[identifier]
        return (band ? band.label : 'N/A') || 'Status unknown'
      }
    },

    /**
     * Details of the state of the band
     */
    bandStatusDetails () {
      return identifier => {
        const band = this.details[identifier]
        if (band) {
          const details = `
          ${this.bandLabel(identifier)}: ${this.bandFrequencyLabel(identifier)}
          <br>
          ${band.details || band.description || ''}
        `
          return details
        }
      }
    },

    /**
     * CSS style of band status indicator
     */
    bandIndicatorStyle () {
      return identifier => {
        const band = this.details[identifier]
        return band
          ? {
            'background-color': band.color,
            'border': band.borderColor ? `solid ${band.borderColor} 2px` : 'none'
          }
          : {
          }
      }
    },

    /**
     * Indicates if the specified band is in uplink status
     */
    isUplink () {
      return identifier => this.bands[identifier].isUplink
    },

    /**
     * Checks if specified led on the band is on.
     * Led index starts from 1.
     */
    isLedOn () {
      return (identifier, index) => {
        const band = this.bands[identifier]
        return band.ledCount >= index + 1
      }
    },

    /**
     * Style of a band tooltip with status details
     */
    bandStatusTooltipStyle () {
      return identifier => ({
        identifier: true,
        'transform': 'translate(67px, -13px)',
        'font-size': '13px',
        'background-color': this.details[identifier].color,
        'color': 'white',
        'border-radius': 0
      })
    },

    // Returns a value of a parameter for the specified band with unit and a descriptive text
    describedParameter () {
      return (text, identifier, name, unit) => {
        const { bands } = this
        if (bands) {
          const values = bands[identifier].values
          let value = values[name]
          if (value == null || value === 0) {
            return text
          }

          return `${text} ${value} ${unit}`
        }
      }
    }
  }
}
</script>

<template>
  <sc-widget padding="0px" v-bind="{ ...$props, ...$attrs }" v-if="hasBands"
    :width="micro ? '172px' : '230px'">
    <div class="signal-levels column" :class="{ micro }">
      <div>
        <span v-if="!micro" class="title">
          Device Bands
        </span>
        <q-markup-table flat :bordered="false" square separator="none" class="bands">
          <tbody>
            <tr v-for="identifier in bandIdentifiers" :key="identifier">
              <td v-if="!micro">
                <span class="band">
                  <sc-tooltip :text="bandStatusDetails(identifier)" anchor="bottom middle"
                    self="top middle">
                  </sc-tooltip>
                  {{ bandLabel(identifier) }}
                </span>
              </td>

              <td class="dots">
                <div class="row">
                  <div class="dot" v-for="index in LEDs" :key="index"
                    :class="{ on: isLedOn(identifier, index) }">
                    <sc-tooltip text="The green dots indicate the downlink signal strength">
                    </sc-tooltip>
                  </div>

                  <div class="dot square uplink" :class="{ active: isUplink(identifier) }">
                    <sc-tooltip
                      :text="`${isUplink(identifier) ? 'Uplink ON. ' : ''}The indicator turns blue when the band is turned on. This happens when a person makes a call or uses mobile data.`" />
                  </div>

                  <div class="dot square" :style="bandIndicatorStyle(identifier)">
                    <sc-tooltip :text="bandStatusDetails(identifier)" anchor="bottom middle"
                      self="top middle">
                    </sc-tooltip>
                  </div>
                </div>
              </td>

              <td v-if="micro">
                <span class="band">
                  {{ bandLabel(identifier) }}
                  <sc-tooltip :text="bandStatusDetails(identifier)" anchor="bottom middle"
                    self="top middle">
                  </sc-tooltip>
                </span>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
  </sc-widget>
</template>

<style lang="scss" scoped>
.signal-levels {
  padding: 12px 8px 8px 12px;

  --dot-size: 16px;
  --band-margin: 4px;

  .title {
    font-size: 16px;
    font-weight: bold;
    color: #2a2a2a;
  }

  .bands {
    td {
      padding: 0 0 4px 4px;
      height: 40px;
    }

    .band {
      padding-right: var(--band-margin);
      cursor: pointer;
    }

    .dot {
      width: var(--dot-size);
      height: var(--dot-size);
      border-radius: calc(var(--dot-size)/2);
      cursor: pointer;

      &.square {
        border-radius: 2px;
      }

      margin-right: 4px;
      border: solid #bebebe 1px;
      background-color: white;

      &.on {
        border: solid #2ECD6F 1px;
        background-color: #2ECD6F;
      }

      &.active {
        background-color: #5991fa;
        border: solid #5991fa 1px;
      }

      &.uplink {
        margin-left: 8px;
      }
    }

    .indicator {
      height: 22px;
      width: 110px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 11px;
      border-radius: 4px;
    }
  }

  &.micro {
    padding: 2px 0 2px 2px;

    --dot-size: 12px;

    .title {
      display: none;
    }

    .bands {
      background-color: transparent;
      margin-top: 4px;

      .band {
        padding-right: 0;
        font-size: 12px;
      }

      td {
        height: 22px;
        padding: 4px;
      }

      .dots {
        padding-right: 0;
      }

      .dot {
        margin-right: 4px;

        &.uplink {
          margin-left: 4px;
        }
      }
    }
  }
}

/* Adjust dot sizes for variety of mobile screens */
@media screen and (width <=1024px) {
  .title {
    display: none;
  }

  .signal-levels {
    --dot-size: 15px;
  }
}

@media screen and (width <=520px) {
  .signal-levels {
    &.micro {
      --dot-size: 13px;
      --band-margin: 4px;
    }
  }
}

@media screen and (width <=420px) {
  .signal-levels {
    &.micro {
      --band-margin: 2px;
    }
  }
}

@media screen and (width <=400px) {
  .signal-levels {
    &.micro {
      --dot-size: 12px;
    }
  }
}

@media screen and (width <=380px) {
  .signal-levels {
    &.micro {
      --dot-size: 11px;
    }
  }
}

@media screen and (width <=360px) {
  .signal-levels {
    &.micro {
      --dot-size: 10px;
    }
  }
}

@media screen and (width <=350px) {
  .signal-levels {
    &.micro {
      --dot-size: 9px;
    }
  }
}
</style>
