import { sortItems, distinctValues } from '@stellacontrol/utilities'
import { Assignable } from '../common'

/**
 * Attachment group inside an {@link AttachmentFolder}
 */
export class AttachmentGroup extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  normalize () {
    super.normalize()
  }

  /**
   * Group name
   * @type {String}
   */
  name

  /**
   * Files in the group
   * @type {Array[Attachment]}
   */
  files
}

/**
 * Attachment folder
 */
export class AttachmentFolder extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  normalize () {
    super.normalize()
    this.icon = this.icon || 'folder'
  }

  /**
   * Folder identifier
   * @type {String}
   */
  id

  /**
   * Folder label
   * @type {String}
   */
  label

  /**
   * Folder icon
   * @type {String}
   */
  icon

  /**
   * Files in the folder
   * @type {Array[Attachment]}
   */
  files

  /**
   * Attachment groups inside the folder
   * @returns {Array[AttachmentGroup]}
   */
  get groups () {
    const { files } = this
    const groupNames = sortItems(distinctValues(files, 'folder'))
    const groups = groupNames.map(group => new AttachmentGroup({
      name: group || undefined,
      files: files.filter(a => a.folder === group)
    }))
    return groups
  }

  /**
   * Files filtered and sorted in the selected sort order
   * @returns {Array[Attachment]}
   */
  visibleFiles ({ filter, age, sortOrder = 'date-desc' } = {}) {
    const files = this.files.filter(f => f.matches(filter, age * 24 * 3600))
    const field = {
      'date-asc': 'createdAt',
      'date-desc': 'createdAt',
      'name-asc': 'name',
      'name-desc': 'name'
    }
    const descending = sortOrder.includes('-desc')
    return sortItems(files, field[sortOrder], descending)
  }

  /**
   * Visible file groups after applying filters
   * @returns {Array[AttachmentGroup]}
   */
  visibleGroups ({ filter, age, sortOrder = 'date-desc' } = {}) {
    const files = this.visibleFiles({ filter, age, sortOrder })
    const groupNames = sortItems(distinctValues(files, 'folder'))
    const groups = groupNames.map(group => new AttachmentGroup({
      name: group,
      files: files.filter(a => a.folder === group)
    }))
    return groups
  }

  /**
   * Determines whether the folder is empty
   * @type {Boolean}
   */
  get isEmpty () {
    return !(this.files?.length > 0)
  }
}
