import { differenceInSeconds } from 'date-fns'
import { isEnum, decodeBase64, encodeBase64, getUTCDateTime, stringContains, toJSON, parseDataUrl } from '@stellacontrol/utilities'
import { EntityType } from '../common/entity-type'
import { Entity } from '../common/entity'
import { MessageType } from '../messaging'
import { AttachmentLink } from './attachment-link'

/**
 * Attachment types
 */
export const AttachmentType = {
  Image: 'image',
  PDF: 'pdf',
  Text: 'text',
  Markdown: 'markdown',
  HTML: 'html',
  Document: 'document',
  Spreadsheet: 'spreadsheet',
  Firmware: 'firmware',
  Binary: 'binary',
  Archive: 'archive',
  JSON: 'json'
}

/**
 * Attachment type descriptions
 */
export const AttachmentTypeDescription = {
  [AttachmentType.Image]: 'Image',
  [AttachmentType.PDF]: 'PDF',
  [AttachmentType.Text]: 'Text',
  [AttachmentType.Markdown]: 'Markdown',
  [AttachmentType.HTML]: 'HTML',
  [AttachmentType.Document]: 'OpenDocument, Word',
  [AttachmentType.Spreadsheet]: 'Excel, CSV',
  [AttachmentType.Firmware]: 'Firmware',
  [AttachmentType.Binary]: 'Binary',
  [AttachmentType.Archive]: 'Archive',
  [AttachmentType.JSON]: 'JSON'
}

/**
 * Attachment type icons
 */
export const AttachmentIcon = {
  [AttachmentType.Image]: 'image',
  [AttachmentType.PDF]: 'picture_as_pdf',
  [AttachmentType.Text]: 'article',
  [AttachmentType.Markdown]: 'markdown',
  [AttachmentType.HTML]: 'code_blocks',
  [AttachmentType.Document]: 'news',
  [AttachmentType.Spreadsheet]: 'table',
  [AttachmentType.Firmware]: 'memory',
  [AttachmentType.Binary]: 'note',
  [AttachmentType.Archive]: 'folder_zip',
  [AttachmentType.JSON]: 'data_object'
}

/**
 * Attachment types for various file extensions
 */
export const AttachmentTypes = {
  [AttachmentType.Image]: ['jpg', 'jpeg', 'png', 'gif', 'tiff', 'webp', 'bmp'],
  [AttachmentType.PDF]: ['pdf'],
  [AttachmentType.Text]: ['txt'],
  [AttachmentType.Markdown]: ['md'],
  [AttachmentType.HTML]: ['html', 'htm', 'mht'],
  [AttachmentType.Document]: ['doc', 'docx', 'odt', 'pages', 'rtf'],
  [AttachmentType.Spreadsheet]: ['xls', 'xlsx', 'ods', 'csv'],
  [AttachmentType.Firmware]: ['firmware'],
  [AttachmentType.Binary]: ['bin', 'hex'],
  [AttachmentType.Archive]: ['tar', 'zip', 'gz'],
  [AttachmentType.JSON]: ['json']
}

/**
 * Attachment types treated as raw binary for download purposes
 */
export const BinaryAttachmentTypes = [
  AttachmentType.Firmware,
  AttachmentType.Binary,
  AttachmentType.Archive
]

/**
 * Attachment data types
 */
export const AttachmentDataType = {
  TTFullScan: 'tt-autoscan',
  TTSpectrum: 'tt-spectrum',
  TTNeighbours: 'tt-neighbours',
  TTSurvey: 'tt-survey'
}

/**
 * Attachment data type icons
 */
export const AttachmentDataTypeIcon = {
  [AttachmentDataType.TTFullScan]: 'radar',
  [AttachmentDataType.TTSpectrum]: 'equalizer',
  [AttachmentDataType.TTNeighbours]: 'settings_input_antenna',
  [AttachmentDataType.TTSurvey]: 'sensors'
}

/**
 * Attachment data type icons
 */
export const AttachmentDataTypeLabel = {
  [AttachmentDataType.TTFullScan]: 'Full Scan',
  [AttachmentDataType.TTSpectrum]: 'Spectrum Scan',
  [AttachmentDataType.TTNeighbours]: 'Live Scan',
  [AttachmentDataType.TTSurvey]: 'Cell ID Scan'
}

/**
 * Determines attachment type for the specified file name
 * @param {String} fileName File name
 * @param {AttachmentType} defaultType Default type to return if file type is not recognized
 * @returns {AttachmentType}
 */
export function getAttachmentType (fileName, defaultType = AttachmentType.Binary) {
  if (fileName != null && fileName.trim()) {
    const parts = /[^.]+$/gm.exec(fileName)
    const ext = fileName.includes('.') && parts.length > 0 ? parts[0]?.toLowerCase() : undefined
    if (ext) {
      const attachmentType = Object.values(AttachmentType).find(at => AttachmentTypes[at].includes(ext))
      return attachmentType || defaultType
    } else {
      return defaultType
    }
  }
}

/**
 * Returns true if binary attachment
 * @type {AttachmentType} type Attachment type to check
 * @type {Boolean}
 */
export function isBinaryAttachment (type) {
  return BinaryAttachmentTypes.includes(type)
}

/**
 * Determines attachment MIME type for the specified file name
 * @param {String} fileName File name
 * @param {String} defaultType Default MIME type to return if file type is not recognized
 * @returns {String}
 */
export function getAttachmentMimeType (fileName, defaultType = 'application/octet-stream') {
  if (fileName != null && fileName.trim()) {
    const parts = /[^.]+$/gm.exec(fileName)
    const ext = parts ? parts[0]?.toLowerCase() : undefined
    const attachmentType = getAttachmentType(fileName)

    if (ext && attachmentType) {
      if (attachmentType === AttachmentType.Image) {
        return `image/${ext}`
      } else if (attachmentType === AttachmentType.Text) {
        return 'text/plain'
      } else if (attachmentType === AttachmentType.HTML) {
        return 'text/html'
      } else if (attachmentType === AttachmentType.Markdown) {
        return 'text/markdown'
      } else if (attachmentType === AttachmentType.JSON) {
        return 'application/json'
      } else if (attachmentType === AttachmentType.Archive) {
        return 'application/zip'
      } else if ([AttachmentType.PDF, AttachmentType.Document, AttachmentType.Spreadsheet].includes(attachmentType)) {
        return `application/${ext}`
      }

      return defaultType
    }
  }
}

/**
 * Checks whether the specified file is one of supported attachment types
 * @param {String} fileName File name
 * @param {Array[AttachmentType]} allowedTypes Permitted attachment types, optional.
 * If not specified, all {@link AttachmentType} values are allowed
 * @returns {Boolean}
 */
export function isAllowedAttachmentType (fileName, ...allowedTypes) {
  const attachmentType = getAttachmentType(fileName, null)

  if (attachmentType) {
    if (allowedTypes?.length > 0) {
      return allowedTypes.includes(attachmentType)
    } else {
      return Object.values(AttachmentType).includes(attachmentType)
    }
  }

  return false
}

/**
 * Attachment
 */
export class Attachment extends Entity {
  constructor (data = {}) {
    super()
    this.assign({
      ...data,
      type: data.type || AttachmentType.Binary
    })

    if (!isEnum(AttachmentType, this.type)) throw new Error(`Invalid attachment type ${this.type}`)

    if (data.dataUrl) {
      // Content is a Base64-encoded data URL, save as-is
      this.content = data.dataUrl
      this.mimeType = 'application/base64'

    } else if (data.content) {
      // Re-create content from Base64-encoded string
      if (typeof data.content === 'string') {
        this.content = this.mimeType === 'application/base64'
          ? data.content
          : decodeBase64(data.content, false)

      } else if (data.content.data) {
        // Re-create content from uploaded file
        this.content = data.content.data
        this.size = data.content.size
      }
    }

    // Determine side
    if (this.content) {
      this.size = this.content.length
    } else {
      this.size = data.size ? parseInt(data.size) : undefined
    }

    // Parse other fields which might have been passed as string
    // when uploaded as form
    this.external = this.external === true || this.external === 1 || this.external === 'true'

    // Parse details from content
    const { isScan } = this
    if (isScan) {
      const { data } = this
      if (data?.results) {
        const { time, wasOutdoorScan, wasRepeaterPresent, antennaOrientation, antennaType } = data.results
        this.details = { time, wasOutdoorScan, wasRepeaterPresent, antennaOrientation, antennaType }
      }
    }

    // Selection status
    this.isSelected = Boolean(this.isSelected)
  }

  /**
   * Creates attachment from browser file object
   * @param {File} file
   * @param {Array[AttachmentType]} allowedTypes Permitted attachment types, optional. If not specified, all {@link AttachmentType} values are allowed
   * @returns {Attachment}
   */
  static async fromFile (file, ...allowedTypes) {
    if (!file) throw new Error('File is required')
    if (!isAllowedAttachmentType(file.name, ...allowedTypes)) throw new Error(`File ${file.name} is not a valid attachment`)

    const content = await file.arrayBuffer()
    return new Attachment({
      name: file.name,
      description: file.name,
      type: getAttachmentType(file.name),
      mimeType: file.type,
      createdAt: file.lastModifiedDate,
      updatedAt: file.lastModifiedDate,
      size: file.size,
      content,
      file
    })
  }

  /**
   * Creates attachment from data buffer
   * @param {Object} buffer
   * @param {AttachmentType} type Attachment type
   * @param {String} mimeType Attachment MIME type
   * @returns {Attachment}
   */
  static fromBuffer (buffer, name, type = AttachmentType.JSON, mimeType = 'application/json') {
    if (!buffer) throw new Error('Data buffer is required')

    return new Attachment({
      name,
      description: name,
      type,
      mimeType,
      createdAt: getUTCDateTime(),
      updatedAt: getUTCDateTime(),
      size: buffer.length,
      content: buffer
    })
  }

  normalize () {
    super.normalize()
    this.name = this.name?.trim() || null
    this.bucket = this.bucket?.trim() || null
    this.folder = this.folder?.trim() || null
    this.reference = this.reference?.trim() || null
    this.dataType = this.dataType?.trim() || null
    this.hash = this.hash?.trim() || null
    this.external = Boolean(this.external)
    this.links = this.castArray(this.links, AttachmentLink)
  }

  /**
   * Attachment name
   * @type {String}
   */
  name

  /**
   * File age in seconds
   * @type {Number}
   */
  get age () {
    const { createdAt } = this
    return createdAt
      ? differenceInSeconds(new Date(), createdAt)
      : 0
  }

  /**
   * Attachment description
   * @type {String}
   */
  description

  /**
   * Other relevant details
   * @type {Object}
   */
  details

  /**
   * Identifier of an entity to which the attachment is attached,
   * such as place, device etc.
   * @type {String}
   */
  entityId

  /**
   * Type of entity to which the attachment is attached,
   * such as place, device etc.
   * @type {String}
   */
  entityType

  /**
   * Entity to which the attachment is attached,
   * such as place, device etc.
   * @type {Entity}
   * @description RUNTIME
   */
  entity

  /**
   * Entity name
   * @type {String}
   */
  get entityName () {
    const { entity } = this
    return entity ? (entity.label || entity.name) : undefined
  }

  /**
   * Identifier of organization who owns the attachment
   * @type {String}
   */
  ownerId

  /**
   * Attachment type
   * @type {AttachmentType}
   */
  type

  /**
   * Checks whether attachment type is the specified one
   * @param {AttachmentType} type Type to check
   * @returns {Boolean}
   */
  is (type) {
    return this.type === type
  }

  /**
   * Returns true if binary attachment
   * @type {Boolean}
   */
  get isBinary () {
    return BinaryAttachmentTypes.includes(this.type)
  }

  /**
   * Returns true if scan results
   * @type {Boolean}
   */
  get isScan () {
    const { entityType, reference } = this
    return entityType === EntityType.Device && reference === MessageType.Scan
  }

  /**
   * Attachment mime type
   * @type {String}
   */
  mimeType

  /**
   * Attachment size
   * @type {Number}
   */
  size

  /**
   * Attachment binary content
   * @type {Buffer}
   */
  content

  /**
   * Returns true if attachment has {@link content}
   */
  get hasContent () {
    return this.content?.length > 0
  }

  /**
   * Base64-encoded binary content
   * @type {String}
   */
  get encodedContent () {
    const { content, mimeType } = this
    if (content) {
      return (mimeType === 'application/base64')
        ? content
        : encodeBase64(content)
    }
  }

  /**
   * Decoded binary content. The result contains `mimeType` of the decoded data and a `buffer` with the data.
   * @returns {Object}
   */
  getBinaryContent () {
    let { content, mimeType } = this

    if (content) {
      const result = { mimeType }

      if (mimeType === 'application/base64') {
        if (content.startsWith('data:')) {
          // The content is a Base64 string wrapped into data URL
          const { mimeType, data } = parseDataUrl(content)
          result.mimeType = mimeType
          result.buffer = data ? decodeBase64(data) : undefined

        } else {
          // The content is a Base64 string
          result.buffer = decodeBase64(content)
        }

        return result
      }
    }
  }

  /**
   * {@link content} parsed to data object.
   * Requires the {@link type} to be `json`.
   */
  get data () {
    if (this.type === AttachmentType.JSON && this.content) {
      return JSON.parse(this.content)
    }
  }

  /**
   * Data URL with Base64-encoded binary content
   * @type {String}
   */
  get dataUrl () {
    const { content, mimeType } = this
    if (content != null) {
      if (mimeType === 'application/base64') {
        if (typeof content === 'string') {
          return content.startsWith('data:')
            ? content
            : `data:${mimeType};base64,${content}`
        }
      } else if (mimeType) {
        return `data:${mimeType};base64,${encodeBase64(content)}`
      }
    }

    return undefined
  }

  /**
   * File object, from browser file upload control
   * @type {File}
   */
  file

  /**
   * Folder to which the file belongs, or other group designator
   * @type {String}
   */
  folder

  /**
   * S3 bucket where to store the attachment
   * @type {String}
   */
  bucket

  /**
   * Reference to a location or entity where attachment content is kept,
   * alternative to embedded storage in `content` property
   * @type {String}
   */
  reference

  /**
   * Checks whether the object is stored in S3 storage
   * @type {Boolean}
   */
  get isS3Object () {
    return this.external && this.reference?.startsWith('https://s3.')
  }

  /**
   * Custom identifier for data stored in the file,
   * used for example to differentiate files such as TT scan results
   * @type {String}
   */
  dataType

  /**
   * File hash, optional
   * @type {String}
   */
  hash

  /**
   * If true, the content of the attachment is stored in the external binary storage such as AWS S3,
   * rather than in our database
   * @type {Boolean}
   */
  external

  /**
   * Indicates that the attachment is selected
   * @type {Boolean}
   * @description RUNTIME
   */
  isSelected

  /**
   * Indicates that the attachment has been modified
   * @type {Boolean}
   * @description RUNTIME
   */
  isModified

  /**
   * Links to principals which are allowed to access the attachment
   * @type {Array[AttachmentLink]}
   */
  links

  /**
   * Indicates that the attachment is linked to a principal
   * @type {String}
   */
  isLinked

  /**
   * Indicates that attachment has been made available
   * to some principals
   * @type {Boolean}
   */
  get hasLinks () {
    return this.links?.length > 0
  }

  /**
   * JSON serialization makes sure to convert content to Base64-encoded string
   * @param {Boolean} content If true, content is included in the result
   * @returns {Object}
   */
  toJSON ({ content = true } = {}) {
    const result = {
      ...this,
      content: content ? this.encodedContent : undefined
    }

    // Remove runtime data
    delete result.file
    delete result.entity
    delete result.downloadUrl
    delete result.isSelected
    delete result.isModified

    return result
  }

  /**
   * Indicates that previewing this attachment is possible
   * @type {Boolean}
   */
  get canPreview () {
    if (this.id != null) {
      return [
        AttachmentType.Text,
        AttachmentType.Markdown,
        AttachmentType.HTML,
        AttachmentType.JSON
      ].includes(this.type)
    }
  }

  /**
   * Indicates that previewing this attachment is possible
   * using an IFRAME pointing directly to a document
   * @type {Boolean}
   */
  get canPreviewInFrame () {
    if (this.id != null) {
      return [
        AttachmentType.Image,
        AttachmentType.PDF
      ].includes(this.type)
    }
  }

  /**
   * Indicates that copying attachment content to clipboard is possible
   * @type {Boolean}
   */
  get canCopy () {
    if (this.id != null) {
      return [
        AttachmentType.Text,
        AttachmentType.Markdown,
        AttachmentType.HTML,
        AttachmentType.JSON
      ].includes(this.type)
    }
  }

  /**
   * Returns attachment data in a clipboard-ready format
   * @returns {String}
   */
  toText () {
    const { content } = this
    // Other attachments
    const text = this.is(AttachmentType.JSON)
      ? toJSON(content, 2, content)
      : content
    return text
  }

  /**
   * Indicates that downloading this attachment is possible
   * @type {Boolean}
   */
  get canDownload () {
    return this.id != null
  }

  /**
   * URL which can be used to download the attachment
   * @type {String}
   * @description RUNTIME
   */
  downloadUrl

  /**
   * Indicates that printing this attachment is possible
   * @type {Boolean}
   */
  get canPrint () {
    return this.isScan
  }

  /**
   * Indicates that assigning this attachment to a principal is possible
   * @type {Boolean}
   */
  get canLinkToPrincipal () {
    return this.isScan
  }

  /**
   * Indicates that deleting this attachment is possible
   * @type {Boolean}
   */
  get canDelete () {
    return this.id != null
  }

  /**
   * Returns the icon representing the attachment
   * @type {String}
   */
  get icon () {
    const { isScan, type, dataType } = this
    // Scan results
    if (isScan) {
      return AttachmentDataTypeIcon[dataType] || AttachmentDataTypeIcon[AttachmentDataType.TTFullScan]
    }
    // Other file types
    return AttachmentIcon[type] || AttachmentIcon[AttachmentType.Binary]
  }

  /**
   * Returns the data type label
   * @type {String}
   */
  get dataTypeLabel () {
    const { isScan, type, dataType } = this
    // Scan results
    if (isScan) {
      return AttachmentDataTypeLabel[dataType] || AttachmentDataTypeLabel[AttachmentDataType.TTFullScan]
    }
    return AttachmentTypeDescription[type]
  }

  /**
   * Checks whether the attachment matches the specified filter
   * @param {String} filter Filter to match
   * @param {Number} age Maximal file age, in seconds
   * @returns {Boolean}
   */
  matches (filter, age) {
    filter = (filter || '').trim().toLowerCase()

    if (filter) {
      const { name, entity: { name: entityName, label: entityLabel } = {}, dataTypeLabel } = this

      const parts = filter
        .split(' ')
        .map(p => p.trim())
        .filter(p => p !== 'or')

      for (const part of parts) {
        const matches = stringContains(name, part) ||
          stringContains(entityName, part) ||
          stringContains(entityLabel, part) ||
          stringContains(dataTypeLabel, part)
        if (matches) return true
      }

      return false
    }

    if (age > 0) {
      if (this.age > age) return false
    }

    return true
  }
}
