<script>
import { mapState } from 'vuex'
import { DeviceConnectionStatusColor, DeviceConnectionStatusName, DeviceConnectionStatus } from '@stellacontrol/model'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  data () {
    return {
      tooltip: '-'
    }
  },

  computed: {
    ...mapState({
      // Status of all devices
      deviceStatus: state => state.deviceStatus.devices || {}
    }),

    // Recently received live status
    status () {
      return this.deviceStatus[this.item.serialNumber]
    },

    // Recently recorded device snapshot.
    // This will be used to display the icon while waiting for the status to arrive.
    snapshot () {
      return this.item.device?.snapshot
    },

    // Icon representing the connection status
    icon () {
      const { item: { device, connection, status } } = this
      const name = status
        ? (device.partOf ? 'memory' : 'fiber_manual_record')
        : null

      const color = status
        ? DeviceConnectionStatusColor[connection] || DeviceConnectionStatusColor[DeviceConnectionStatus.NeverConnected]
        : null

      return {
        name,
        color
      }
    }
  },

  methods: {
    // Loads the icon tooltip showing the age of the status
    loadTooltip () {
      const { item: { connection }, status } = this
      this.tooltip = DeviceConnectionStatusName[DeviceConnectionStatus.Unknown]
      if (status) {
        const age = status.connection.isConnected || status.connection.isLost
          ? status.timings?.statusAgeString
          : null
        if (age != null) {
          this.tooltip = `${DeviceConnectionStatusName[connection]} ${age || ''}`
        }
      }
    }
  }
}

</script>

<template>
  <div class="row justify-center" v-if="item.device.isConnectedDevice">
    <q-icon :name="icon.name" size="sm" :style="{ color: icon.color }"></q-icon>
    <sc-tooltip :text="tooltip" @before-show="loadTooltip">
    </sc-tooltip>
  </div>
</template>

<style></style>