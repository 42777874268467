<script>
import { mapGetters } from 'vuex'
import { formatDateTime, formatTime } from '@stellacontrol/utilities'
import { isToday } from 'date-fns'
export default {
  props: {
    // List of alerts to be displayed
    alerts: {
      required: true
    },
    // Title of the popup
    title: {
      type: String
    },
    // Indicates if the serial number is displayed or not
    showSerialNumber: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'toLocalDateTime'
    ]),

    // Indicates if this popup is already populated with alerts
    hasAlerts () {
      const { alerts } = this
      return alerts == null || alerts?.length == 0
    }
  },

  data () {
    return {
    }
  },

  emits: [
    'showing',
    'shown',
    'hiding',
    'hidden'
  ],

  methods: {
    alertTimeText (time) {
      const alertTime = this.toLocalDateTime(time)
      const timeText = isToday(alertTime)
        ? formatTime(alertTime)
        : formatDateTime(alertTime, 'd MMM, HH:mm:ss')
      return timeText
    },

    onBeforeShow () {
      this.$emit('showing')
    },

    onShow () {
      this.$emit('shown')
    },

    onBeforeHide () {
      this.$emit('hiding')
    },

    onHide () {
      this.$emit('hidden')
    },

    show () {
      this.$refs.popup.show()
    },

    hide () {
      this.$refs.popup.hide()
    },

    toggle () {
      this.$refs.popup.toggle()
    }
  }
}
</script>

<template>
  <q-popup-proxy ref="popup" @before-show="onBeforeShow" show="onShow" @before-hide="onBeforeHide"
    @hide="onHide">
    <div class="alerts-popup bg-grey-7 text-white q-pa-md" @click="hide()">
      <header v-if="title" class="text-bold q-mb-md">
        {{ title }}
      </header>

      <section :class="{ 'alerts': true, 'show-serial': showSerialNumber }">

        <p v-if="hasAlerts">
          Loading ...
        </p>

        <template v-else v-for="item in alerts">
          <div class="message">{{ item.message }}</div>
          <div class="serial" v-if="showSerialNumber">
            {{ item.device.serialNumber }}
          </div>
          <div class="time">
            {{ alertTimeText(item.time) }}
          </div>
        </template>

      </section>
    </div>
  </q-popup-proxy>
</template>

<style lang="scss" scoped>
.alerts-popup {
  max-height: 350px;
  max-width: 95vw;
  overflow: hidden;
  overflow-y: auto;

  .alerts {
    display: grid;
    grid-template-columns: auto auto;
    gap: 4px;

    &.show-serial {
      grid-template-columns: auto auto auto;
    }

    .message {
      white-space: nowrap;
    }

    .serial {
      white-space: nowrap;
      padding-left: 8px;
    }

    .time {
      white-space: nowrap;
      padding-left: 8px;
      justify-self: end;
    }
  }
}

/* Layout adjustments for small screens */
@media screen and (width <=480px) {
  .alerts-popup {
    width: 100vw;

    .alerts {
      &.show-serial {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;

        .message:nth-child(3n+1) {
          grid-column: 1;
        }

        .serial:nth-child(3n+2) {
          grid-column: 2;
          justify-self: end;
        }

        .time:nth-child(3n+3) {
          grid-column: 1;
          justify-self: start;
          padding-left: 0;
          padding-bottom: 4px;
        }
      }
    }
  }
}
</style>