export function createState () {
  return {
    /**
     * Data for ABOUT screen
     */
    about: {}
  }
}

export const state = createState()

export const getters = {
}
