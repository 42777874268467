/**
 * Planner editing modes
 */
export const PlannerMode = {
  // The planner works in readonly mode
  ReadOnly: 'readonly',
  // The planner allows only a limited feature set
  Regular: 'regular',
  // The planner allows a full feature set
  Advanced: 'advanced'
}
