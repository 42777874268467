<script>
import { getId, hash } from '@stellacontrol/utilities'
import { Notification } from '@stellacontrol/client-utilities'
import StepMixin from './step-mixin'

export default {
  mixins: [
    StepMixin
  ],

  props: {
    // Initial selection
    files: {
      required: true
    }
  },

  data () {
    return {
      // Selected files
      selected: [],
      // Indicates whether we're currently dragging files into the image selector
      isDragging: false,
    }
  },

  computed: {
    // Maximal allowed image size in MB
    maxImageSize () {
      return this.plan.layout.getMainFloor().background.defaults.maxImageSize
    },

    maxImageSizeMB () {
      return Math.round(this.maxImageSize / 1048576)
    },

    // Indicates whether any files have been selected
    hasFiles () {
      return this.selected.length > 0
    },

    // Indicates whether just one file has been selected
    hasOneFile () {
      return this.selected.length === 1
    },

    // Indicates whether any image files have been selected
    hasImageFiles () {
      return this.selected.some(f => f.isImage)
    },

    // Indicates whether any PDF files have been selected
    hasPDFFiles () {
      return this.selected.some(f => f.isPDF)
    },

    // File tooltip
    getFileTooltip () {
      return file => {
        return `${file.name} ${file.size}b`
      }
    },

    // CSS style for the file preview
    getFileThumbnail () {
      return file => {
        const style = {}
        if (file.imageUrl) {
          style.backgroundImage = `url(${file.imageUrl})`
        }
        return style
      }
    }
  },

  methods: {
    // Calls file selection dialog
    selectFiles () {
      this.$refs.fileSelector.pickFiles()
    },

    // Handles drag/drop file selections
    onDragOver (e) {
      this.isDragging = true
      e.preventDefault()
    },

    onDropFiles (e) {
      e.preventDefault()
      const files = [...e.dataTransfer.files]
      files.forEach(file => {
        this.addFile(file)
      })
      this.isDragging = false
    },

    onDragLeave () {
      this.isDragging = false
    },

    onFilesSelected (files) {
      for (const file of files) {
        this.addFile(file)
      }
    },

    // Calculates hash of the specified file
    getHash (file) {
      return hash(`${file.name}${file.type}${file.size}${file.lastModified}`)
    },

    // Adds a selected file
    addFile (file) {
      const imageTypes = ['image/jpeg', 'image/png']
      const allowedTypes = [...imageTypes, 'application/pdf']
      let error

      // Don't allow duplicate uploads
      if (!file) return
      if (this.selected.some(f => f.name === file.name && f.size === f.size)) return

      // Keep file size in check
      if (file.size >= this.maxImageSize) {
        error = `Maximal allowed file size is ${this.maxImageSizeMB} MB`
      }

      if (!error && file.size === 0) {
        error = 'The file is empty'
      }

      if (!error && !allowedTypes.includes(file.type)) {
        error = 'Only JPG, PNG and PDF files are accepted'
      }

      if (error) {
        Notification.error({ message: `File ${file.name} is not allowed`, details: error })

      } else {
        file.id = getId()
        file.isImage = imageTypes.includes(file.type)
        file.isPDF = file.type === 'application/pdf'
        file.hash = this.getHash(file)

        if (file.isImage) {
          file.imageUrl = URL.createObjectURL(file)
        }

        this.selected.push(file)
        this.changed()
      }
    },

    // Removes the specified file from the file list
    removeFile (file) {
      if (file) {
        this.selected = this.selected.filter(f => f.id !== file.id)
        // Dispose of the data URL
        URL.revokeObjectURL(file.imageUrl)
        this.changed()
      }
    },

    // Notifies about the changed files
    changed () {
      this.$emit('selected', this.selected)
    }
  },

  emits: [
    'selected'
  ],

  watch: {
    files (files = []) {
      this.selected = [...files]
    }
  },

  created () {
    this.selected = [...this.files]
  }
}
</script>

<template>
  <div class="step-selecting" :class="{ dragging: isDragging }" @dragover="onDragOver"
    @drop="onDropFiles" @ondragleave="onDragLeave" @mouseleave="onDragLeave" @click="selectFiles()">

    <div class="instructions">
      <q-icon name="cloud_upload" size="xl" color="indigo-6"></q-icon>
      <span class="text-subtitle1 text-grey-7">
        Drop here the plan images or click to select.
      </span>
      <span class="text-subtitle1 text-grey-7">
        Allowed formats: JPG, PNG, PDF.
      </span>
      <span class="text-subtitle1 text-orange-8">
        Maximal image size: {{ maxImageSizeMB }}MB.
      </span>
      <q-file class="file-selector" ref="fileSelector" multiple append
        accept=".jpg, .jpeg, .png, .pdf" max-files="10" :max-file-size="maxImageSize"
        @update:modelValue="onFilesSelected">
      </q-file>
    </div>

    <div class="files" v-if="selected.length > 0">
      <div class="file" v-for="file in selected" :title="getFileTooltip(file)" @click.stop>
        <div class="title">
          <span class="file-name">
            {{ file.name }}
          </span>
          <span class="delete-button">
            <q-btn flat round dense icon="close" color="red-7" size="sm"
              @click.stop="removeFile(file)"></q-btn>
          </span>
        </div>
        <div class="thumbnail" :style="getFileThumbnail(file)">
          <q-icon v-if="file.isPDF" name="picture_as_pdf" color="indigo-5" size="lg">
          </q-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.step-selecting {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: unset;
  position: relative;

  &:hover {
    background-color: #f8f8f8;
    border-color: #808080;
  }

  &.dragging {
    background-color: #d0d0d0;
    border-color: #707070;
  }

  .instructions {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .file-selector {
      display: none;
    }
  }

  .files {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    padding: 5px;

    .file {
      width: 140px;
      height: 140px;
      border: solid #b0b0b0 1px;
      background-color: #e8e8e8;
      display: flex;
      flex-direction: column;


      &:hover {
        background-color: #e0e0e0;
        border-color: #3b47b6;
      }

      .title {
        flex: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        font-size: 11px;
        background-color: #c0c0c0;
        border-bottom: solid #808080 1px;
        padding: 4px;
        height: 34px;

        .file-name {
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .delete-button {
          flex-basis: 32px;
        }
      }

      .thumbnail {
        flex: 1;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>