<script>
import { mapActions } from 'vuex'
import { Log, delay } from '@stellacontrol/utilities'
import { Notification } from '@stellacontrol/client-utilities'
import { Attachment } from '@stellacontrol/model'
import StepMixin from './step-mixin'

export default {
  mixins: [
    StepMixin
  ],

  props: {
    // Files to upload
    files: {
      required: true
    }
  },

  data () {
    return {
      // Upload progress
      uploadedCount: 0,
      uploadProgress: 0,
      uploadFinished: false,
      currentFile: null,
      // Uploaded images
      images: [],
    }
  },

  computed: {
    // Indicates whether any files have been selected
    hasFiles () {
      return this.files.length > 0
    },

    // Indicates whether just one file has been selected
    hasOneFile () {
      return this.files.length === 1
    },
  },

  methods: {
    ...mapActions([
      'uploadPlanImage'
    ]),

    // Uploads the files to the file store
    async uploadFiles () {
      const { plan, files, hasFiles } = this
      if (!hasFiles) return

      try {
        this.images = []
        this.image = null
        this.uploadedCount = 0
        this.uploadFinished = false
        this.uploadProgress = 5

        if (this.development) {
          Log.debug('Uploading files in DEVELOPMENT mode, without AWS S3')
        }

        for (const file of files) {
          this.currentFile = file

          if (this.development) {
            // Local development, without uploads to AWS S3
            if (file.isImage) {
              const image = await Attachment.fromFile(file)
              image.hash = file.hash
              image.reference = URL.createObjectURL(file)
              this.images.push(image)
              await delay(200)
            }
          } else {
            // Production, file uploads to AWS S3
            const { images = [], error } = await this.uploadPlanImage({ plan, file })
            if (error) {
              throw new Error(error)
            } else {
              this.images.push(...images)
            }
            await delay(100)
          }

          // Report progress
          this.uploadedCount++
          this.uploadProgress = Math.max(5, Math.round(100 * (this.uploadedCount / files.length)))
          this.uploadFinished = this.uploadProgress === 100

          // Check if user hasn't reset or closed the dialog in the meantime
          if (this.isClosed || !this.hasFiles) {
            break
          }
        }

        // Assign floor names
        for (let i = 0; i < this.images.length; i++) {
          const image = this.images[i]
          // Set properties for the preview and the editor
          image.x = 0
          image.y = 0
          image.width = 0
          image.height = 0
          image.brightness = 0
          image.contrast = 0
          image.rotation = 0
          image.scale = 100
          image.floorName = `Floor ${i + 1}`
        }

        await delay(500)

      } catch (error) {
        Log.error(error)
        Notification.error({ message: 'Upload failed', details: error.toString() })

      } finally {
        this.uploaded()
      }
    },

    // Notifies about the uploaded images
    uploaded () {
      this.$emit('uploaded', this.images)
    }
  },

  emits: [
    'uploaded'
  ],

  created () {
    // Upload the files
    this.$nextTick(() => {
      this.uploadFiles()
    })
  }
}
</script>

<template>
  <div class="step-uploading">
    <span class="text-subtitle1 text-grey-7">
      {{ uploadFinished ? 'Uploading finished.' : 'Uploading, please wait ...' }}
    </span>
    <span class="text-subtitle1 text-grey-7">
      {{ uploadFinished ? `${count(files, 'image')} uploaded.` : currentFile?.name || ''
      }}
    </span>

    <q-circular-progress class="q-ma-lg" :indeterminate="hasOneFile && !uploadFinished"
      :show-value="!hasOneFile || uploadFinished"
      :class="{ 'text-indigo-7': !uploadFinished, 'text-green-7 ': uploadFinished }"
      font-size="24px" size="120px" :value="uploadProgress"
      :color="uploadFinished ? 'green-7' : 'indigo-7'"
      :track-color="uploadFinished ? 'green-2' : 'indigo-2'">
      {{ uploadProgress }}%
    </q-circular-progress>
  </div>
</template>

<style scoped lang="scss">
.step-uploading {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>