<script>
import { mapActions } from 'vuex'
import StepMixin from './step-mixin'

export default {
  mixins: [
    StepMixin
  ],

  props: {
    // Images to edit
    images: {
    },
    // Import settings
    settings: {
      required: true
    },
    // Indicates that we're now processing the edited image
    isProcessing: {
      default: false
    }
  },

  data () {
    return {
    }
  },

  computed: {
    // Image tooltip
    getImageTooltip () {
      return image => {
        if (image) {
          return `${image.description}`
        }
      }
    },

    // CSS style for the image thumbnail
    getImageThumbnail () {
      return (image) => {
        if (image) {
          const style = {}
          const filters = []
          const transform = []

          const url = image.dataURL || image.reference
          style['background-size'] = 'cover'
          style['background-image'] = `url(${url})`

          // Apply image transformations
          if (image.rotation !== 0) {
            transform.push(`rotate(${image.rotation}deg)`)
          }

          // Apply (some) image filters
          if (image.bw) {
            filters.push('grayscale(100%)')
          }

          if (filters.length > 0) {
            style.filter = filters.join(' ')
          }

          if (transform.length > 0) {
            style.transform = transform.join(' ')
          }

          return style
        }
      }
    }
  },

  emits: [
    'changed'
  ],

  methods: {
    ...mapActions([
      'removePlanImage'
    ]),

    // Triggered when import settings are changed
    changed (settings) {
      this.$emit('changed', settings)
    }
  }
}
</script>

<template>
  <div class="step-finish">
    <div v-if="isProcessing" class="processing text-grey-7 text-h6">
      <sc-busy title="Processing the images ..." size="md"></sc-busy>
    </div>

    <template v-else>
      <div class="settings">
        <span class="text-subtitle1">
          Import settings
        </span>
        <span class="q-mt-sm">
          <q-checkbox dense label="Add legends"
            :model-value="settings.addLegends"
            @update:model-value="addLegends => changed({ addLegends })">
          </q-checkbox>
        </span>
        <span class="q-mt-xs">
          <q-checkbox dense label="Add roof" :model-value="settings.addRoof"
            @update:model-value="addRoof => changed({ addRoof })">
          </q-checkbox>
        </span>
      </div>

      <div class="images q-mt-lg">
        <span class="images-header text-subtitle1">
          Floors to import
        </span>
        <div class="images-inner q-mt-xs">
          <template v-for="image in images">
            <div class="image" v-for="part in image.parts" :key="part.index"
              :title="getImageTooltip(part)">
              <div class="title">
                <span class="image-name">
                  {{ part.floorName }}
                </span>
              </div>
              <div class="thumbnail" :style="getImageThumbnail(part)">
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>

  </div>
</template>

<style scoped lang="scss">
.step-finish {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .processing {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .settings {
    flex: 0;
    display: flex;
    flex-direction: column;
  }

  .images {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .images-header {
      flex: 0;
    }

    .images-inner {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-items: flex-start;
      gap: 10px;
      overflow: auto;

      .image {
        width: 140px;
        height: 140px;
        margin: 0px 0px 10px 0px;
        border: solid #b0b0b0 1px;
        background-color: #e8e8e8;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .title {
          flex-basis: 34px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          font-size: 11px;
          background-color: #c0c0c0;
          border-bottom: solid #808080 1px;
          padding: 4px;
          overflow: hidden;

          .image-name {
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .thumbnail {
          flex: 1;
          background-repeat: no-repeat;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>