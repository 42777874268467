<script>
import { mapActions, mapGetters } from 'vuex'
import { formatDate, findBiggest } from '@stellacontrol/utilities'
import { Confirmation } from '@stellacontrol/client-utilities'
import { getDeviceLabel } from '@stellacontrol/model'
import { Secure } from '@stellacontrol/security-ui'
import DeviceWidget from './device-widget'

export default {
  mixins: [
    DeviceWidget,
    Secure
  ],

  computed: {
    ...mapGetters([
      'getFeature'
    ]),

    // Premium subscriptions currently associated with the viewed device
    subscriptions () {
      const { device, organizationGuardian } = this
      return organizationGuardian.getDeviceSubscriptions(device) || []
    },

    // Premium subscriptions currently active
    activeSubscriptions () {
      return this.subscriptions.filter(s => s.isActive)
    },

    // Indicates whether device has any subscriptions
    hasSubscriptions () {
      return this.subscriptions.length > 0
    },

    // Indicates whether device has any subscriptions
    hasActiveSubscriptions () {
      return this.activeSubscriptions.length > 0
    },

    // Finds the subscription to show
    lastSubscription () {
      if (this.allSubscriptionsExpired) {
        // if all expired, show the most recently expired one
        return findBiggest(this.subscriptions, 'expiresAt')
      } else {
        // otherwise show the most recently activated one
        return findBiggest(this.subscriptions, 'startedAt')
      }
    },

    // Indicates whether all subscriptions have expired on this device
    allSubscriptionsExpired () {
      const { hasSubscriptions, subscriptions } = this
      return hasSubscriptions && subscriptions.every(s => s.isExpired)
    },

    // Indicates that premium service can be started on this device,
    // assigned to the device when it was sold to its current owner
    hasPendingPremiumService () {
      const { pendingPremiumService, organizationGuardian } = this
      return pendingPremiumService && organizationGuardian.mustUse('premium-services-buy')
    },

    // Indicates whether current user has right to start the pending premium service
    canStartPremiumService () {
      return this.hasPendingPremiumService &&
        ((this.isMyDevice && this.canUse('premium-services-buy') ||
          this.canUse('premium-services-sell')))
    },

    // Indicates whether selected devices belong to the current organization
    isMyDevice () {
      return this.device?.ownerId === this.currentOrganization.id
    },

    // Indicates whether the organization is permitted to purchase premium services themselves
    canBuyServices () {
      return this.isMyDevice &&
        this.mustUse('premium-services-buy') &&
        this.canUse('premium-services-self-activate')
    },

    // Indicates whether the organization is permitted to purchase premium services
    // for the currently viewed device
    canBuyNewService () {
      return this.canBuyServices && !(this.hasActiveSubscriptions || this.canStartPremiumService)
    },

    // Widget items
    items () {
      const { requiresPremiumSubscriptions, canBuyServices, getFeature, hasSubscriptions, activeSubscriptions, lastSubscription, allSubscriptionsExpired, hasPendingPremiumService, pendingPremiumService, canStartPremiumService } = this
      const items = []

      if (hasPendingPremiumService) {
        // Show services waiting to be activated
        const { isFree, neverExpires, periodDescription } = pendingPremiumService
        items.push(
          { text: `${isFree ? 'Free premium' : 'Premium'} service is available for this device.`, class: 'text-green-8' },
          {
            text: canStartPremiumService
              ? `Activate it to get ${neverExpires ? 'unlimited' : periodDescription} access to:`
              : 'Ask your reseller to activate and get access to:'
          },
          ...pendingPremiumService.features.map(f => ({ text: `• ${getFeature(f).description}` })),
        )

      } else if (hasSubscriptions) {
        // If device has associated subscriptions ...
        items.push({
          text: allSubscriptionsExpired ? 'Premium services expired' : 'Premium services',
          class: { 'label': true, 'text-red-8': allSubscriptionsExpired }
        })

        if (allSubscriptionsExpired) {
          // Show details of expired subscriptions, if all expired
          items.push(
            {
              text: `${lastSubscription.service.features.map(f => getFeature(f).description).join(', ')} no longer available`
            },
            canBuyServices
              ? null
              : { text: requiresPremiumSubscriptions ? 'Contact your reseller to extend the subscription' : '' }
          )

        } else {
          // Show currently active subscriptions
          items.push(
            ...activeSubscriptions.flatMap(s => {
              const name = s.shortLabel
              const expires = s.expiresAt ? `${s.isExpired ? 'Expired on' : 'Active until'} ${formatDate(s.expiresAt)}` : ''
              const tooltip = s.service.features.map(f => `• ${getFeature(f).description}`).join('<br>')
              return [
                { text: name, tooltip },
                { text: expires, tooltip, class: { 'tiny': true } },
              ]
            })
          )
        }

      } else {
        items.push(
          { text: 'No premium services active.' },
          canBuyServices ? '' : { text: requiresPremiumSubscriptions ? 'Please contact your reseller' : '' },
          canBuyServices ? '' : { text: requiresPremiumSubscriptions ? 'to purchase premium services.' : '' }
        )
      }

      for (const item of items) {
        if (item && !item.class) {
          item.class = { 'small': true }
        }
      }

      return items.filter(item => item)
    },

    // Icon representing premium service
    icon () {
      const { hasPendingPremiumService } = this
      return hasPendingPremiumService ? 'sentiment_satisfied_alt' : 'paid'
    },

    // Color of the icon representing premium service
    iconColor () {
      const { hasPendingPremiumService, hasSubscriptions, hasActiveSubscriptions, allSubscriptionsExpired, requiresPremiumSubscriptions, subscriptions = [] } = this
      const defaultColor = 'light-green-7'
      const serviceColor = (hasSubscriptions && subscriptions.length === 1)
        ? (subscriptions[0].service.getColor() || defaultColor)
        : defaultColor

      if (hasPendingPremiumService) return serviceColor
      if (allSubscriptionsExpired) return 'orange-5'
      if (hasActiveSubscriptions) return serviceColor
      if (requiresPremiumSubscriptions) return 'orange-5'

      return 'grey-5'
    },

    // Widget background color
    bgColor () {
      const { allSubscriptionsExpired, hasPendingPremiumService, requiresPremiumSubscriptions, hasActiveSubscriptions } = this
      if (hasPendingPremiumService) return '#fff9dd'
      if (allSubscriptionsExpired) return '#ffe4dd'
      if (hasActiveSubscriptions) return '#e7ffdd'
      if (requiresPremiumSubscriptions) return '#ffe4dd'
    },

    // Widget style
    cssStyle () {
      const { hasPendingPremiumService } = this
      return hasPendingPremiumService
        ? { width: '476px !important' }
        : undefined
    }
  },

  methods: {
    ...mapActions([
      'startDeviceSubscriptions',
      'reloadRoute',
      'showDialog'
    ]),

    // Starts the premium service attached to the device
    async startPremiumService () {
      const { hasPendingPremiumService, pendingPremiumService, device } = this
      if (hasPendingPremiumService) {
        const message = `Start ${pendingPremiumService.label} on ${getDeviceLabel(device)}?`
        const ask = await Confirmation.ask({ message })
        if (ask) {
          await this.startDeviceSubscriptions({ devices: [device] })
          await this.reloadRoute()
        }
      }
    },

    // Opens dialog for purchasing premium service
    async purchasePremiumService () {
      const { canBuyNewService, device } = this
      if (canBuyNewService) {
        const { isOk } = await this.showDialog({ dialog: 'purchase-premium-service', data: { device } })
        if (isOk) {
          await this.reloadRoute()
        }
      }
    }
  }
}
</script>

<template>
  <sc-widget-text :icon="icon" :iconColor="iconColor" :items="items" :bg-color="bgColor"
    :style="cssStyle">

    <template #toolbar>
      <div class="toolbar row q-pb-sm q-mt-sm q-mb-sm items-center justify-center"
        v-if="canStartPremiumService">
        <q-btn flat dense unelevated class="success" label="Activate" size="md" icon="play_circle"
          @click="startPremiumService()"></q-btn>
      </div>
      <div class="toolbar row q-pb-sm q-mt-sm q-mb-sm items-center justify-center"
        v-if="canBuyNewService">
        <q-btn flat dense unelevated class="success" label="Buy premium services" icon="payments"
          @click="purchasePremiumService()"></q-btn>
      </div>
    </template>

  </sc-widget-text>
</template>

<style lang="scss" scoped></style>
