import { PlannerMode } from '@stellacontrol/model'
import { PlanFloors } from '@stellacontrol/planner'
import { createState } from './planner.state'

/**
 * Stores plans of the current organization
 * @param {Array[Plan]} plans Plans to store
 */
export function storePlans (state, { plans = [] } = {}) {
  state.plans = plans
}

/**
 * Stores a plan
 * @param {Plan} plan Plan to store
 */
export function storePlan (state, { plan } = {}) {
  if (plan) {
    const index = state.plans.findIndex(item => item.id === plan.id)
    if (index === -1) {
      state.plans.push(plan)
    } else {
      state.plans[index] = plan
    }
    if (state.plan?.id === plan.id) {
      state.plan = plan
    }
  }
}

/**
 * Selects the plan for editing
 * @param {Plan} plan Plan to edit
 * @param {String} floorId Floor to edit
 */
export function editPlan (state, { plan, floorId } = {}) {
  state.plan = plan
  if (state.plan) {
    floorId = floorId || state.floorId || PlanFloors.CrossSection
    state.floorId = plan.layout.hasFloor(floorId) ? floorId : PlanFloors.CrossSection
  } else {
    state.floorId = null
  }
}

/**
 * Selects the plan floor for editing
 * @param {String} floorId Floor identifier
 */
export function editPlanFloor (state, { floorId } = {}) {
  if (state.plan) {
    state.floorId = floorId || PlanFloors.CrossSection
  } else {
    state.floorId = null
  }
}

/**
 * Resets the state to original shape.
 * @description
 * Presence of this mutation is obligatory on stores,
 * to ensure purging of any user-bound data when
 * user logs in and out and in. Otherwise expect
 * unexpected side effects with data of the previously
 * logged in user appearing at places.
 */
export function reset (state) {
  Object.assign(state, createState())
}

export function resetPlans (state) {
  Object.assign(state, createState())
}

/**
 * Sets the planner editing mode
 * @param {PlannerMode} mode Planner editing mode
 */
export function setPlannerMode (state, { mode } = {}) {
  state.plan.settings.mode = mode || PlannerMode.Regular
}

/**
 * Maximizes the plan editor / restores to normal view
 * @param {Boolean} isMaximized Planner view size
 */
export function setPlannerView (state, { isMaximized } = {}) {
  state.isMaximized = isMaximized
}

/**
 * Stores the items recently selected on the plan
 * @param {Array[PlanItem]} items Selected items
 */
export function storePlanSelection (state, { items = [] } = {}) {
  state.recentlySelectedItems = items.map(item => item.id)
}

/**
 * Triggered when user scrolls the plan
 * @param {PlanFloor} floor Floor on which the plan has been scrolled
 * @param {Number} x Plan scroll position, horizontal
 * @param {Number} y Plan scroll position, vertical
 */
export function scrollPlan (state, { floor, x, y } = {}) {
  if (floor && x != null && y != null) {
    const floorSettings = state.plan.settings.getFloor(floor)
    floorSettings.scroll = { x, y }
  }
}

/**
 * Triggered when plan palette has been moved
 * @param {PlanFloor} floor Floor on which the palette has been moved
 * @param {Object} position Palette position, `{ left, top, right, bottom }`
 */
export function movePlanPalette (state, { position } = {}) {
  if (position) {
    state.plan.settings.palettePosition = position
  }
}

/**
 * Triggered when plan palette has been collapsed or expanded
 * @param {Boolean} isExpanded Palette state
 */
export function togglePlanPalette (state, { isExpanded } = {}) {
  state.plan.settings.paletteExpanded = Boolean(isExpanded)
}

/**
 * Toggles the section in the plan palette
 * @param {PlanFloor} floor Floor on which the palette is
 * @param {String} section Section to toggle
 * @param {Boolean} isExpanded Requested section state, optional. If specified, it will be assigned to the section state.
 * If not specified, the current state of the section will be toggled.
 */
export function togglePlanPaletteSection (state, { floor, section, isExpanded } = {}) {
  if (section) {
    const { expandedSections } = state.plan.settings.getFloor(floor)

    if (isExpanded == null) {
      isExpanded = !expandedSections[section]
    }

    for (const key in expandedSections) {
      expandedSections[key] = false
    }

    expandedSections[section] = isExpanded
  }
}

/**
 * Toggles visibility of the specified plan layer
 * @param {PlanFloor} floor Floor on which the layer has been toggled
 * @param {String} id Identifier of a layer to toggle
 * @param {Boolean} isVisible Requested layer visibility, optional. If specified, it will be assigned
 * If not specified, the current visibility of the layer will be toggled.
 */
export function togglePlanLayer (state, { floor, id, isVisible } = {}) {
  if (id) {
    const { hiddenLayers } = state.plan.settings.getFloor(floor)

    if (isVisible == null) {
      isVisible = !hiddenLayers[id]
    }

    hiddenLayers[id] = isVisible
  }
}

/**
 * Triggered when user copies something into the plan clipboard
 */
export function planCopyToClipboard (state) {
  state.canPaste = true
}

/**
 * Triggered when user pastes the content from the plan clipboard
 */
export function planPasteFromClipboard (state) {
  state.canPaste = false
}
