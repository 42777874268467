// Plan image import step mixin
export default {
  props: {
    // Edited plan
    plan: {
      required: true
    },
    // Use when in development, to skip uploading of the images to S3
    development: {
    },
    // Current editing step
    step: {
    }
  },

  methods: {
    // Proceeds to the next step
    async goForward () {
      return true
    },

    // Proceeds to the previous step
    async goBack () {
      return true
    }
  }
}
