<script>
import { mapActions } from 'vuex'
import { DialogMixin } from '@stellacontrol/client-utilities'

const dialog = 'note'

export default {
  mixins: [
    DialogMixin
  ],

  data () {
    return {
      dialog,
      // Edited note text
      text: null,
      // Dialog title
      title: 'Edit notes',
      // OK button label
      okLabel: 'Save',
      // Cancel button label
      cancelLabel: 'Cancel'
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      this.text = this.data.text || ''
      this.title = this.data.title || this.title
      this.okLabel = this.data.okLabel || this.okLabel
      this.cancelLabel = this.data.cancelLabel || this.cancelLabel
    },

    // Validates and OKs the dialog
    async ok () {
      const data = {
        text: this.text,
      }
      this.dialogOk({ dialog, data })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
    <sc-dialog :dialog="dialog" persistent @dialogShown="dialogShown()">
      <q-form ref="form">
        <q-banner class="bg-indigo-6">
            <span class="text-white title">
              {{ title }}
            </span>
        </q-banner>

        <div class="q-pa-md">
          <div>
            <q-input
              class="note-editor"
              v-model="text"
              type="textarea"
              outlined
              bg-color="white"
              :input-style="{ width: '700px', height: '450px' }"
              v-focus>
            </q-input>
          </div>

          <div class="row q-mt-md">
            <q-space></q-space>
            <q-btn :label="cancelLabel" unelevated class="q-mr-md" @click="cancel()"></q-btn>
            <q-btn :label="okLabel" unelevated class="primary" @click="ok()"></q-btn>
          </div>
        </div>

      </q-form>
    </sc-dialog>
</template>

<style scoped lang="scss">
.title {
  font-size: 18px;
}
</style>
