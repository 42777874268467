import { safeParseFloat, safeParseInt, parseEnum, parseBoolean } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'
import { PlanHorizontalAlignment, PlanVerticalAlignment } from './plan-alignment'


/**
 * Properties of a text
 */
export class PlanTextStyle extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Creates default style
   * @type {PlanTextStyle}
   */
  static get Default () {
    return new PlanTextStyle()
  }

  /**
   * Creates small text style
   * @type {PlanTextStyle}
   */
  static get Small () {
    return new PlanTextStyle({
      size: 11
    })
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      color: 'black',
      size: 15,
      lineHeight: 1.4,
      opacity: 1,
      alignment: PlanHorizontalAlignment.Left,
      verticalAlignment: PlanVerticalAlignment.Top,
      underline: false,
      sizeChanged: false
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.color = this.color || defaults.color
    this.size = Math.max(1, safeParseInt(this.size, defaults.size))
    this.lineHeight = Math.max(0, safeParseFloat(this.lineHeight, defaults.lineHeight))
    this.opacity = Math.max(0, Math.min(1, safeParseFloat(this.opacity, defaults.opacity)))
    this.alignment = parseEnum(PlanHorizontalAlignment, this.alignment, defaults.alignment)
    this.verticalAlignment = parseEnum(PlanVerticalAlignment, this.verticalAlignment, defaults.verticalAlignment)
    this.underline = parseBoolean(this.underline, defaults.underline)
    this.sizeChanged = parseBoolean(this.sizeChanged, defaults.sizeChanged)
  }

  /**
   * Checks if style is the same as the specified one
   * @param {PlanTextStyle} style
   * @returns {Boolean}
   */
  sameAs (style) {
    if (style) {
      return this.color === style.color &&
        this.size === style.size &&
        this.lineHeight === style.lineHeight &&
        this.opacity === style.opacity &&
        this.alignment === style.alignment &&
        this.verticalAlignment === style.verticalAlignment &&
        this.underline === style.underline
    }
  }

  /**
     * Color
     * @type {String}
     */
  color

  /**
   * Font size
   * @type {Number}
   */
  size

  /**
   * Line height
   * @type {Number}
   */
  lineHeight

  /**
   * Opacity, from 0 to 1
   * @type {Number}
   */
  opacity

  /**
   * Text alignment
   * @type {PlanHorizontalAlignment}
   */
  alignment

  /**
   * Text alignment
   * @type {PlanVerticalAlignment}
   */
  verticalAlignment

  /**
   * Indicates whether the text should be underlined
   * @type {Boolean}
   */
  underline

  /**
   * Font family
   * @type {String}
   */
  font

  /**
   * Indicates whether user has modified the font size manually
   * @type {Boolean}
   */
  sizeChanged
}

