/**
 * Comparison operator for version numbers
 */
export const VersionComparisonOperator = {
  Equal: '=',
  Newer: '>',
  Older: '<',
  AtLeast: '>=',
  AtMost: '<=',
  NotEqual: '!='
}

/**
 * Represent a version of component, application etc.
 */
export class Version {
  /**
   * Initializes the instance with values
   */
  constructor (data = {}) {
    Object.assign(this, data)
  }

  /**
   * Major version number
   */
  major

  /**
   * Minor version number
   */
  minor

  /**
   * Build number
   */
  build

  /**
   * Patch number
   */
  patch

  /**
   * Number of parts in the version string
   */
  length

  /**
   * Indicates whether all parts of the version are valid numbers
   */
  numeric

  /**
   * Returns string representation of the version
   * @type {String}
   */
  toFullString () {
    const { major, minor, build, patch } = this
    return [major || 0, minor || 0, build || 0, patch]
      .filter(part => part != null)
      .join('.')
  }

  /**
   * Returns string representation of the version
   * limited to the specified number of version parts
   * @param  {Number} count Number of version parts to return, starting from `major`
   * @returns {String} Version string
   */
  toCustomString (count) {
    const { major, minor, build, patch } = this
    return [major || 0, minor || 0, build || 0, patch]
      .filter(part => part != null)
      .slice(0, count)
      .join('.')
  }

  /**
   * Returns string representation of the version
   * limited to the customary three version parts
   * @returns {String} Version string
   */
  toShortString () {
    const { major, minor, build } = this
    return [major || 0, minor || 0, build || 0]
      .filter(part => part != null)
      .join('.')
  }

  /**
   * Parses version string into {@link Version} instance
   * @param version Version string, i.e. 6.2.3 or 7.0.5.4
   * @returns {@link Version} instance
   */
  static parse (version) {
    if (version) {
      if (version.startsWith('v')) {
        version = version.substr(1)
      }
      if (version.startsWith('.')) {
        version = version.substr(1)
      }

      const parts = version.split('.')
      const [major, minor, build, patch] = parts

      const parseComponent = component => {
        if (component) {
          const number = parseInt(component)
          return isNaN(number) ? undefined : number
        } else {
          return 0
        }
      }

      const result = new Version({
        major: parseComponent(major),
        minor: parseComponent(minor),
        build: parseComponent(build),
        patch: parseComponent(patch),
        length: parts.length,
        numeric: parts.every(part => isNaturalNumber(part))
      })

      return result
    }
  }

  /**
   * Parses MEGA version string into {@link Version} instance
   * @param version MEGA version string, i.e. mega-v6.3 or simply 6.3
   * @returns {@link Version} instance
   */
  static parseMega (version) {
    if (version) {
      version = version.startsWith('mega-v') ? version.substr(6) : version
      return Version.parse(version)
    }
  }

  /**
   * Returns true if version is valid and contains all required components
   */
  get isValid () {
    const { major, minor, build } = this
    return major != null && minor != null && build != null
  }

  /**
   * Compares with the specified version
   * @param {Version} version Version to compare with
   * @param {Number} level Indicates up to which level versions should be compared:
   *  1 - compare only major version number
   *  2 - compare major and minor version number
   *  3 - compare major, minor and build version number
   *  4 - compare major, minor, build and patch version number
   * @returns {Number} Comparison result, similar to String.compare:
   *  0 - if versions are the same
   *  1 - if version a is newer than version b
   *  -1 - if version a is older than version b
   */
  compareWith (version, level = 4) {
    const { major, minor, build, patch, isValid } = this
    if (isValid && version && version.isValid) {
      let result = numberCompare(major, version.major)
      if (result === 0 && level > 1) {
        result = numberCompare(minor, version.minor)
        if (result === 0 && level > 2) {
          result = numberCompare(build, version.build)
          if (result === 0 && level > 3) {
            result = numberCompare(patch, version.patch)
          }
        }
      }
      return result
    }
  }

  /**
   * Returns true if version is different than specified one
   * @param {Version} version Version to compare with
   * @param {Number} level Indicates up to which level versions should be compared:
   *  1 - compare only major version number
   *  2 - compare major and minor version number
   *  3 - compare major, minor and build version number
   *  4 - compare major, minor, build and patch version number
   * @returns {Boolean} True if version is different than specified one
   */
  isDifferentThan (version, level = 4) {
    return this.compareWith(version, level) !== 0
  }

  /**
   * Returns true if version is the same as specified one
   * @param {Version} version Version to compare with
   * @param {Number} level Indicates up to which level versions should be compared:
   *  1 - compare only major version number
   *  2 - compare major and minor version number
   *  3 - compare major, minor and build version number
   *  4 - compare major, minor, build and patch version number
   * @returns {Boolean} True if version is the same as specified one
   */
  isSameAs (version, level = 4) {
    return this.compareWith(version, level) === 0
  }

  /**
   * Returns true if version is newer than the specified one
   * @param {Version} version Version to compare with
   */
  isNewerThan (version) {
    return this.compareWith(version) > 0
  }

  /**
   * Returns true if version is newer or equal to the specified one
   * @param {Version} version Version to compare with
   */
  isNewerOrEqualTo (version) {
    return this.compareWith(version) >= 0
  }

  /**
   * Returns true if version is older than the specified one
   * @param {Version} version Version to compare with
   */
  isOlderThan (version) {
    return this.compareWith(version) < 0
  }

  /**
   * Returns true if version is older or equal to the specified one
   * @param {Version} version Version to compare with
   */
  isOlderOrEqualTo (version) {
    return this.compareWith(version) <= 0
  }

  /**
   * Assigns version number to specified record
   * @param {Object} target Object where version number parts (major, minor, build, patch) should be assigned to
   */
  assignTo (target) {
    if (target) {
      const { major, minor, build, patch } = this
      if (major != null && minor != null && build != null) {
        target.major = major
        target.minor = minor
        target.build = build
        target.patch = patch == null ? 0 : patch
      } else {
        target.major = 0
        target.minor = 0
        target.build = 0
        target.patch = 0
      }
    }
  }
}

/**
 * Compares two versions
 * @param a Version to compare
 * @param b Version to compare to
 * @param level Indicates up to which level versions should be compared:
 *  1 - compare only major version number
 *  2 - compare major and minor version number
 *  3 - compare major, minor and build version number
 *  4 - compare major, minor, build and patch version number
 * @returns Comparison result, similar to String.compare:
 *  0 - if versions are the same
 *  1 - if version a is newer than version b
 *  -1 - if version a is older than version b
 */
export function versionCompare (a, b, level = 4) {
  const versionA = Version.parse(a)
  const versionB = Version.parse(b)
  return versionA ? versionA.compareWith(versionB, level) : -1
}

/**
 * Compares two MEGA versions
 * @param a MEGA version to compare
 * @param b MEGA version to compare to
 * @param level Indicates up to which level versions should be compared:
 *  1 - compare only major version number
 *  2 - compare major and minor version number
 *  3 - compare major, minor and build version number
 * @returns Comparison result, similar to String.compare:
 *  0 - if versions are the same
 *  1 - if version a is newer than version b
 *  -1 - if version a is older than version b
 */
export function megaVersionCompare (a, b, level = 3) {
  const versionA = Version.parseMega(a)
  const versionB = Version.parseMega(b)
  return versionA ? versionA.compareWith(versionB, level) : -1
}

/**
 * Safe number comparison
 * @param a Number to compare to
 * @param b Number to compare with
 */
function numberCompare (a, b) {
  if (a != null && b != null) {
    return (a > b) ? 1 : (a < b ? -1 : 0)
  } else if (a != null && b == null) {
    return 1
  } else {
    return -1
  }
}

/**
 * Checks whether the specified version matches the given condition
 * @param a Version to check
 * @param b Version to compare with
 * @param operator Comparison operator
 * @param level Indicates up to which level versions should be compared:
 *  1 - compare only major version number
 *  2 - compare major and minor version number
 *  3 - compare major, minor and build version number
 *  4 - compare major, minor, build and patch version number
 * @returns True if the specified version matches the required one
 */
export function versionMatches (a, b, operator = VersionComparisonOperator.Equal, level = 4) {
  const result = versionCompare(a, b, level)
  switch (operator) {
    case VersionComparisonOperator.Equal:
      return result === 0
    case VersionComparisonOperator.Newer:
      return result > 0
    case VersionComparisonOperator.Older:
      return result < 0
    case VersionComparisonOperator.AtLeast:
      return result >= 0
    case VersionComparisonOperator.AtMost:
      return result <= 0
    case VersionComparisonOperator.NotEqual:
      return result !== 0
  }
  return false
}

/**
 * Checks whether the specified MEGA version matches the given condition
 * @param a MEGA version to check
 * @param b MEGA version to compare with
 * @param operator Comparison operator
 * @param level Indicates up to which level versions should be compared:
 *  1 - compare only major version number
 *  2 - compare major and minor version number
 *  3 - compare major, minor and build version number
 * @returns True if the specified version matches the required one
 */
export function megaVersionMatches (a, b, operator = VersionComparisonOperator.Equal, level = 3) {
  const result = megaVersionCompare(a, b, level)
  switch (operator) {
    case VersionComparisonOperator.Equal:
      return result === 0
    case VersionComparisonOperator.Newer:
      return result > 0
    case VersionComparisonOperator.Older:
      return result < 0
    case VersionComparisonOperator.AtLeast:
      return result >= 0
    case VersionComparisonOperator.AtMost:
      return result <= 0
    case VersionComparisonOperator.NotEqual:
      return result !== 0
  }
  return false
}


/**
 * Returns true if specified version string is valid
 * @param {String} value Value to check
 * @param {Number} length Required number of sections in the version string
 * @param {Boolean} numbersOnly If true, only numeric sections will be accepted
 * @returns {Version} Parsed version, if it matches the requirements
 */
export function isValidVersion (value, length = 4, numbersOnly = true) {
  const version = Version.parse(value)
  const isValidVersion = version &&
    (length != null ? version.length === length : true) &&
    (numbersOnly ? version.numeric : true)
  return isValidVersion
    ? version
    : undefined
}

/**
 * Returns true if specified value can be parsed as natural number
 * and is truly a natural number. Eg, 'a123' will still parse as
 * number with {@link parseInt} without a glitch, so it's not enough.
 * @param value Value to check
 */
function isNaturalNumber (value) {
  if (value) {
    const s = value.toString()
    const i = parseInt(s)
    if (!isNaN(i) && i >= 0) {
      return Array.from(s).every(ch => '0123456789'.includes(ch))
    }
  }
  return false
}
