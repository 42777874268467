<script>
import { AntennaName, AntennaOrientationName } from '@stellacontrol/model'
import { formatTime } from '@stellacontrol/utilities'
import { getScanSelectedOperators } from '@stellacontrol/devices'

export default {
  props: {
    // Section collapse status, dictionary
    collapsed: {
      type: Object,
      default: () => { }
    },
    // Data file
    file: {
    }
  },

  data () {
    return {
      // Tree nodes
      nodes: []
    }
  },

  computed: {
    // Device
    device () {
      return this.file?.entity
    },

    // Scan results
    results () {
      return this.file?.data?.results
    },

    // Indicates whether any scan results are available
    hasResults () {
      return this.results != null
    },

    // Scan summary
    summary () {
      const { device, file } = this
      const { time, antennaOrientation, antennaType, wasOutdoorScan, wasRepeaterPresent, distanceFromRepeater, notes } = this.results
      const places = file.hasLinks
        ? file.links.map(l => `${l.organization.name} - ${l.principal.name}`).join(', ')
        : null
      return [
        { label: 'Device', value: device?.serialNumber },
        { label: 'Firmware Version', value: device?.getFirmwareVersion() },
        { label: 'Place', value: places },
        { label: 'Location', value: wasOutdoorScan ? 'Outdoor' : 'Indoor' },
        { label: 'Scan Time', value: formatTime(time) },
        { label: 'Antenna Type', value: AntennaName[antennaType] || '-' },
        { label: 'Antenna Direction', value: AntennaOrientationName[antennaOrientation] || '-' },
        { label: 'Repeater Status', value: wasRepeaterPresent ? 'On' : 'Off' },
        { label: 'Distance from Internal Antennas', value: wasRepeaterPresent ? `${distanceFromRepeater}m` : null },
        { label: 'Notes', value: notes },
      ].filter(item => item.value != null)
    },

    // Scanned operators
    operators () {
      return Object
        .entries(this.results?.operatorNames)
        .map(([id, name]) => ({ id, name }))
    },

    // Scanned operators
    selectedOperators () {
      const { file } = this
      return getScanSelectedOperators(file)
    },

    // All operators
    allOperators () {
      const { file } = this
      return Object.values(file.operatorNames)
    },

    // Checks whether the operator was selected for cell scan
    operatorSelected () {
      return operator => this.selectedOperators?.includes(operator)
    },

    // Checks whether any operator was selected for cell scan
    anyOperatorSelected () {
      return this.selectedOperators?.length > 0
    }
  },

  emits: [
    'toggle'
  ],

  methods: {
    // Toggles a section
    toggle ({ section }) {
      this.$emit('toggle', { section })
    },

    // Populates the view
    populate () {
    }
  },

  created () {
    this.populate()
  }
}

</script>

<template>
  <div class="summary" v-if="hasResults">
    <table class="scan">
      <tr v-for="item in summary">
        <td>
          {{ item.label }}
        </td>
        <td>
          {{ item.value }}
        </td>
      </tr>

      <tr v-if="anyOperatorSelected">
        <td>
          Operators
        </td>
        <td>
          <div v-for="operator in operators" class="row items-center">
            <q-checkbox readonly :model-value="operatorSelected(operator.name)" size="q-sm" dense
              class="q-mr-sm">
            </q-checkbox>
            <span>
              {{ operator.name }}
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.summary {
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 8px;

  table {
    border-collapse: collapse;

    tr:hover {
      background-color: #fafafa;
    }

    td,
    th {
      min-width: 120px;
      border: solid #0000001f 1px;
      text-align: left;
      vertical-align: top;
      padding: 8px;
    }
  }
}
</style>
