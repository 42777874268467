import { Entity } from '../common/entity'
import { User } from '../organization'
import { Place } from '../device'
import { Organization } from '../organization'
import NewPlanModel from './new-floor-plan-model.json'

/**
 * Floor plan
 */
// TODO OBSOLETE, REMOVE
export class FloorPlan extends Entity {
  constructor (data = {}) {
    super()

    this.assign({
      ...data,
      model: data.model || JSON.stringify(NewPlanModel),
      modelVersion: data.modelVersion || 1,
      placeId: data.placeId || undefined
    })
  }

  /**
   * Creates a new empty floor plan associated with the specified organization and place
   */
  static createEmpty ({ name, description, organization, place } = {}) {
    const plan = new FloorPlan({
      name,
      description,
      organization,
      organizationId: organization ? organization.id : undefined,
      place,
      placeId: place ? place.id : undefined,
      model: JSON.stringify(NewPlanModel),
      modelVersion: 1
    })
    return plan
  }

  /**
   * Creates a new floor plan using the other plan's model
   */
  static createFrom ({ name, description, organization, place, model } = {}) {
    const plan = new FloorPlan({
      name,
      description,
      organization,
      organizationId: organization ? organization.id : undefined,
      place,
      placeId: place ? place.id : undefined,
      model,
      modelVersion: 1
    })
    return plan
  }

  /**
   * Normalizes the data after assignment
   */
  normalize () {
    super.normalize()
    if (this.creator) {
      this.creator = this.cast(this.creator, User)
    }
    if (this.updater) {
      this.updater = this.cast(this.updater, User)
    }
    if (this.deleter) {
      this.deleter = this.cast(this.deleter, User)
    }
    if (this.place) {
      this.place = this.cast(this.place, Place)
    }
    if (this.organization) {
      this.organization = this.cast(this.organization, Organization)
    }
  }

  /**
   * Detailed description
   */
  description

  /**
   * Identifier of organization which owns the floor plan
   */
  organizationId

  /**
   * Organization which owns the floor plan
   */
  organization

  /**
   * Identifier of a place associated with the floor plan
   */
  placeId

  /**
   * Place associated with the floor plan
   */
  place

  /**
   * Serialized floor plan definition,
   * as created by the Floor Planner tool
   */
  model

  /**
   * Floor plan version, used for optimistic locking
   * when plan is edited simultaneously by more people
   */
  modelVersion
}
