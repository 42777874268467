<script>
import { mapGetters, mapActions } from 'vuex'
import { wait } from '@stellacontrol/utilities'
import { ViewMixin } from '@stellacontrol/client-utilities'

const name = 'unsubscribe-notifications'

export default {
  mixins: [
    ViewMixin
  ],

  data () {
    return {
      name,
      unsubscribed: false
    }
  },

  computed: {
    ...mapGetters([
      'isInitialized',
      'isLoggedIn',
      'isBusy',
      'application',
      'branding',
      'isSmallScreen'
    ]),

    // Unsubscribe request
    request () {
      return this.data.request
    },

    // Background class of the window, indicating the current activity
    backgroundClass () {
      const { isBusy, unsubscribed } = this
      return {
        'bg-grey-1': isBusy,
        'bg-green-1': unsubscribed
      }
    }
  },

  methods: {
    ...mapActions([
      'gotoLogin',
      'unsubscribeNotifications',
      'busy',
      'done'
    ]),

    cancel () {
      this.gotoLogin()
    },

    async unsubscribe () {
      try {
        this.unsubscribed = false
        const { request } = this
        await this.busy()
        await wait(2000)
        await this.unsubscribeNotifications({ request })
      } finally {
        await this.done()
        this.unsubscribed = true
      }
    },
  }
}
</script>

<template>
  <sc-view :name="name" no-header v-cloak v-if="isInitialized && request">
    <q-layout>
      <q-page-container>
        <q-page class="column items-center justify-center">
          <q-card :bordered="!(isLoggedIn || isSmallScreen)" class="form-unsubscribe" :class="backgroundClass">
            <q-form ref="form" class="form-content q-gutter-sm q-pa-lg">

              <div class="row items-center justify-center" v-if="!isLoggedIn && isSmallScreen">
                <span class="text-h5 q-mt-md header-activate-user">
                  {{ application.name }}
                </span>
              </div>

              <div class="row items-center" v-else-if="!isLoggedIn">
                <span class="text-h5 q-pb-lg header-activate-user">
                  {{ application.name }}
                </span>
                <q-space></q-space>
                <div class="logo-image" v-if="branding.logo"
                  :style="{ 'background-image': `url('${branding.logo}')` }">
                </div>
              </div>

              <div v-if="isBusy" class="text-subtitle1 title-activate-user q-mt-lg">
                Unsubscribing <b>{{ request.userName }}</b>
                from {{ request.subtype }} notifications,
                please wait ...
              </div>
              <div v-else-if="unsubscribed" class="text-subtitle1 title-activate-user q-mt-lg">
                User <b>{{ request.userName }}</b> has been unsubscribed
                from {{ request.subtype }} notifications.
              </div>
              <div v-else class="text-subtitle1 title-activate-user q-mt-lg">
                Unsubscribe <b>{{ request.userName }}</b>
                from {{ request.subtype }} notifications?
              </div>

              <q-space v-if="!isSmallScreen"></q-space>

              <div class="row buttons q-gutter-lg justify-center q-mt-md">
                <q-btn unelevated class="button-close" size="md"
                  :label="unsubscribed ? 'Close' : 'Cancel'" :disabled="isBusy" @click="cancel()">
                </q-btn>
                <q-btn v-if="!unsubscribed" unelevated class="button-unsubscribe primary" size="md"
                  label="Unsubscribe" :disabled="isBusy" @click="unsubscribe()">
                </q-btn>
              </div>
            </q-form>
          </q-card>
        </q-page>
      </q-page-container>
    </q-layout>
  </sc-view>
</template>

<style scoped lang="scss">
.form-unsubscribe {
  width: 50%;
  max-width: 500px;
  min-height: 280px;
  display: flex;
  flex-direction: column;

  .form-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .logo-image {
      width: 200px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: right;
    }

    .button-close,
    .button-unsubscribe {
      min-width: 180px;
    }
  }
}

/* Layout adjustments for screen below HD resolution */
@media screen and (max-width: 1365px) {
  .form-unsubscribe {
    width: 80%;
    max-width: 80%;
  }
}

/* Layout adjustments for small screens */
@media screen and (max-width: 1024px) {
  .form-unsubscribe {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
  }

}
</style>