<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { formatDate } from '@stellacontrol/utilities'
import { ViewMixin, ListAction } from '@stellacontrol/client-utilities'
import { Secure } from '../../components/secure-component'
import { Organization, OrganizationSortOrder, OrganizationIcons, OrganizationColors, sortOrganizations, getOrganizationIcon } from '@stellacontrol/model'

const name = 'organizations'

export default {
  mixins: [
    ViewMixin,
    Secure
  ],

  data () {
    return {
      name,
      OrganizationIcons,
      OrganizationColors
    }
  },

  computed: {
    ...mapState({
      organizationColumns: state => state.organizations.organizationColumns,
      collapsedOrganizations: state => state.organizations.collapsedOrganizations
    }),

    ...mapGetters([
      'organizations',
      'organizationHierarchy',
      'organizationStatistics',
      'listFilter',
      'loggedInAsMaster'
    ]),

    // Indicates whether any organizations are defined
    hasOrganizations () {
      return (this.organizations || []).length > 0
    },

    // Indicates whether current organization can add child organizations
    canAddOrganizations () {
      return this.canUse('can-add-organizations')
    },

    // Indicates that user is currently filtering the list
    hasFilter () {
      return Boolean((this.listFilter(name) || '').trim())
    },

    // Current filter text
    filter () {
      return (this.listFilter(name) || '').trim()
    },

    // List of favorite organizations
    favoriteOrganizations () {
      const { currentOrganization, currentUser, organizationHierarchy: hierarchy } = this
      // Sort organizations by hierarchy
      const organizations = sortOrganizations(this.organizations, OrganizationSortOrder.Name, { currentOrganization, hierarchy })
      const favorites = organizations
        .filter(o => o.isFavorite(currentUser))
        .map(o => {
          const favorite = new Organization(o)
          favorite.showInFavorites = true
          return favorite
        })
      return favorites
    },

    // Indicates whether there are any favorite organizations
    hasFavoriteOrganizations () {
      return (this.favoriteOrganizations || []).length > 0
    },

    // Some columns are not visible under certain circumstances
    visibleColumns () {
      return this.organizationColumns
        .filter(c => this.isColumnVisible(c))
    },

    // List of organizations currently in display
    visibleOrganizations () {
      const { currentOrganization, organizationHierarchy: hierarchy, favoriteOrganizations } = this
      // Sort organizations by hierarchy
      const organizations = sortOrganizations(this.organizations, OrganizationSortOrder.Hierarchy, { currentOrganization, hierarchy })
      // Hide collapsed child organizations, merge favorite organizations on top
      const allOrganizations = [
        ...favoriteOrganizations,
        ...organizations]
        .filter(o => o.showInFavorites || this.parentsExpanded(o))
      return allOrganizations
    },

    // Returns unique key for organization
    getOrganizationKey () {
      return organization => {
        const { currentUser } = this
        const level = this.organizationHierarchy.find(organization.id)?.hierarchyLevel
        return `${organization.id}-${level}${organization.isFavorite(currentUser) ? '-f' : ''}`
      }
    },

    // Returns icon representing the organization
    getOrganizationIcon () {
      return organization => organization.showInFavorites
        ? 'star'
        : getOrganizationIcon(organization)
    },

    // Returns color representing the organization
    getOrganizationColor () {
      return organization => organization.showInFavorites
        ? 'orange-5'
        : OrganizationColors[organization.level] || 'indigo-5'
    },

    // Returns organization level in the hierarchy
    getOrganizationLevel () {
      return organization => {
        return this.organizationHierarchy.find(organization.id)?.hierarchyLevel
      }
    },

    // Returns indentation to apply to organization,
    // to visualise the hierarchy of organizations.
    // This will be zero when filtering organizations by text.
    organizationIndent () {
      return organization => {
        const levelIndent = organization.showInFavorites
          ? 0
          : this.getOrganizationLevel(organization) * 24
        return 8 + levelIndent
      }
    },

    // Dictionary of organizations administrators
    administrators () {
      const administrators = this.organizations
        .map(({ id, name, primaryAdministrator = [] }) => {
          const activeAdministrator = primaryAdministrator
          const anyAdministrator = activeAdministrator || administrators[0]
          if (activeAdministrator) {
            return {
              organization: { id, name },
              text: activeAdministrator.name
            }
          } else if (anyAdministrator) {
            return {
              organization: { id, name },
              text: `${anyAdministrator.name}, not activated`,
              warning: `Administrator account ${anyAdministrator.name} has not yet been activated`
            }
          } else {
            return {
              organization: { id, name },
              text: 'No administrator',
              warning: `No administrator has been created yet in ${name}`
            }
          }
        })
        .reduce((all, a) => ({ ...all, [a.organization.id]: a }), {})
      return administrators
    },

    // Returns root organization
    rootOrganization () {
      return this.organizations.find(o => this.getOrganizationLevel(o) === 0)
    },

    // Returns top-level organizations under root organization
    topLevelOrganizations () {
      return this.organizations.filter(o => this.getOrganizationLevel(o) === 1)
    },

    // Returns true if specified organization is now expanded
    isOrganizationExpanded () {
      return organization => organization.showInFavorites || !this.collapsedOrganizations.includes(organization.id)
    },

    // Returns true if specified organization is editable for the currently logged in user
    isOrganizationEditable () {
      return organization => {
        const { currentOrganization } = this
        return currentOrganization.isSuperOrganization || organization.id !== currentOrganization.id
      }
    },

    // Returns true if specified organization can be deleted by the currently logged in user
    isOrganizationDeletable () {
      return organization => {
        const { currentOrganization } = this
        return organization.id !== currentOrganization.id
      }
    },

    // Organization context menu actions
    actions () {
      const editAction = {
        ...ListAction.Edit,
        label: 'Edit organization',
        isVisible: organization => this.isOrganizationEditable(organization)
      }

      const editNotesAction = {
        name: 'edit-notes',
        label: 'Edit Notes',
        icon: 'comment',
        isVisible: organization => this.isOrganizationEditable(organization)
      }

      const deleteAction = {
        ...ListAction.Delete,
        isVisible: organization => this.isOrganizationDeletable(organization)
      }

      const loginAsAction = {
        name: 'login-as',
        icon: 'login',
        separator: true,
        label: organization => `Log in to ${organization.name}`,
        isVisible: organization => this.canLoginToOrganization(organization)
      }

      return [
        editAction,
        editNotesAction,
        loginAsAction,
        deleteAction
      ]
    }
  },

  methods: {
    ...mapActions([
      'showDialog',
      'createOrganization',
      'getOrganization',
      'editOrganization',
      'removeOrganization',
      'saveOrganizationNotes',
      'loginToOrganization'
    ]),

    formatDate,

    // Determines whether the specified column should be visible.
    isColumnVisible (column) {
      return Boolean(column)
    },

    // Determines whether organization should be visible or hidden, depending on parent status
    parentsExpanded ({ parentOrganizationId }) {
      const parent = parentOrganizationId ? this.organizations.find(o => o.id === parentOrganizationId) : undefined
      return parent ? (this.isOrganizationExpanded(parent) && this.parentsExpanded(parent)) : true
    },

    // Applies filter to organizations.
    // Takes care of showing the hierarchy up and down, if organization is a match.
    // Default filtering of the list will only show matching rows and hide all other rows.
    isOrganizationVisible (organization, filter) {
      const { visibleOrganizations: organizations } = this
      filter = (filter || '').trim().toLowerCase()

      let isMatch = !filter
      if (!isMatch) {
        isMatch = organization.name.toLowerCase().includes(filter)
      }

      if (!isMatch) {
        isMatch = organization.administrator?.name?.toLowerCase()?.includes(filter)
      }

      if (!isMatch) {
        const ancestors = organization.getMyAncestors(organizations, false)
        isMatch = ancestors.some(o => o.name.toLowerCase().includes(filter))
      }

      if (!isMatch) {
        const descendants = organization.getMyDescendants(organizations, true)
        isMatch = descendants.some(o => o.name.toLowerCase().includes(filter))
      }

      return isMatch
    },

    // Checks whether user is allowed to log in to the specified organization
    canLoginToOrganization (organization) {
      return organization &&
        organization.id !== this.currentOrganization.id && // Cannot log on to self,
        this.canUse('login-to-child-organizations') && // Must have permission to log in to child organizations,
        !this.loggedInAsMaster && // Must not already be logged-in-as,
        !this.administrators[organization.id].warning // Administrator of the organization must be in order
    },

    // Edits organization notes
    async editNotes ({ organization: { id } }) {
      const { currentUser: user } = this
      const organization = await this.getOrganization({ id })
      const note = organization.getNoteBy(user)
      const title = `Notes: ${organization.name}`
      const { isOk, data } = await this.showDialog({
        dialog: 'note',
        data: {
          text: (note || {}).text,
          title
        }
      })
      if (isOk) {
        organization.setNoteBy(user, data.text)
        this.saveOrganizationNotes({ organization })
      }
    },

    executeAction (organization, action) {
      switch (action.name) {
        case 'edit':
          return this.editOrganization({ organization })
        case 'edit-notes':
          return this.editNotes({ organization })
        case 'delete':
          return this.removeOrganization({ organization, confirm: true })
        case 'login-as':
          return this.loginToOrganization({ organization, confirm: !this.isSuperAdministrator })
        default:
          throw new Error(`Unhandled action ${action.name}`)
      }
    }
  }
}

</script>

<template>
  <sc-view :name="name" v-if="isLoggedIn">
    <template #toolbar>
      <q-btn label="Add Customer" icon="add" class="primary" unelevated
        @click="createOrganization()" v-if="canAddOrganizations">
      </q-btn>
    </template>

    <div class="q-ma-md" v-if="!hasOrganizations">
      There are no child organizations yet.
      <div class="q-mb-md" v-if="canAddOrganizations">You can add here your resellers, customers and
        other dependent organizations.</div>
      <q-btn color="deep-purple-8" icon="add" label="Add Customer" @click="createOrganization()"
        v-if="canAddOrganizations"></q-btn>
    </div>

    <template #header>
      <sc-list-filter v-if="hasOrganizations" :name="name" :allowChangingViewType="false">

      </sc-list-filter>
    </template>

    <!-- Organization hierarchy -->
    <sc-list class="organization-hierarchy" v-if="hasOrganizations" :name="name"
      :columns="visibleColumns" :items="visibleOrganizations" :actions="actions"
      :hide-pagination="true" :row-key="row => getOrganizationKey(row)"
      :filterMethod="isOrganizationVisible" @action="executeAction">
      <template v-slot:body-cell-hierarchy="props">
        <q-td :props="props" :class="{ favorite: props.row.showInFavorites }"
          :style="{ 'width': '350px', 'padding-left': `${organizationIndent(props.row)}px` }">
          <div class="row items-center no-wrap">
            <q-icon size="22px" :name="getOrganizationIcon(props.row)"
              :color="getOrganizationColor(props.row)"></q-icon>
            <span class="q-ml-sm">
              <router-link v-if="isOrganizationEditable(props.row)" class="item-link"
                :to="{ name: 'organization', params: { id: props.row.id } }"
                @click.stop>
                {{ props.row.name }}
                <sc-tooltip :text="`Edit organization ${props.row.name}`"></sc-tooltip>
              </router-link>
              <span v-else>{{ props.row.name }}</span>
            </span>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-profile="props">
        <q-td :props="props" :class="{ favorite: props.row.showInFavorites }">
          <router-link v-if="props.row.profile && canUse('organization-profiles')" class="item-link"
            :to="{ name: 'organization-profile', params: { id: props.row.profileId } }"
            @click.stop>
            {{ props.row.profile.fullName }}
            <sc-tooltip :text="`Edit profile ${props.row.profile.fullName}`"></sc-tooltip>
          </router-link>
          <span v-else>{{ props.row.profile?.fullName || '-' }}</span>
        </q-td>
      </template>

      <template v-slot:body-cell-administrator="props">
        <q-td :props="props" :class="{ administrator: true, favorite: props.row.showInFavorites }"
          :style="{ 'width': '120px' }">
          <span :class="{ warning: administrators[props.row.id].warning }">
            {{ administrators[props.row.id].text }}
            <sc-tooltip :text="administrators[props.row.id].warning"
              v-if="administrators[props.row.id].warning"></sc-tooltip>
          </span>
        </q-td>
      </template>

      <template v-slot:body-cell-isEnabled="props">
        <q-td :props="props" :class="{ favorite: props.row.showInFavorites }">{{ props.row.isEnabled
    ?
    'Active' : 'Inactive'
          }}</q-td>
      </template>

      <template v-slot:body-cell-devices="props">
        <q-td :props="props" :class="{ favorite: props.row.showInFavorites }">
          <span>
            {{ organizationStatistics[props.row.id].ownDeviceCount }}
            <sc-tooltip>
              {{ organizationStatistics[props.row.id].ownDeviceCount }} own
              {{ pluralize(organizationStatistics[props.row.id].ownDeviceCount, 'device', 'devices')
              }}
            </sc-tooltip>
          </span>
        </q-td>
      </template>

      <template v-slot:body-cell-created="props">
        <q-td :props="props" :class="{ favorite: props.row.showInFavorites }">{{
    formatDate(props.row.createdAt)
  }}</q-td>
      </template>

      <template v-slot:body-cell-updated="props">
        <q-td :props="props" :class="{ favorite: props.row.showInFavorites }">{{
    formatDate(props.row.updatedAt)
  }}</q-td>
      </template>

      <template v-slot:body-cell-actions="props">
        <q-td :props="props" class="actions" :class="{ favorite: props.row.showInFavorites }">
          <div class="row items-center no-wrap justify-end">
            <a class="item-link q-mr-md" v-if="canLoginToOrganization(props.row)"
              @click="loginToOrganization({ organization: props.row, confirm: !isSuperAdministrator })">
              Log in
            </a>
            <sc-action-dropdown :data="props.row" :actions="actions"
              @action="action => executeAction(props.row, action)"></sc-action-dropdown>
          </div>
        </q-td>
      </template>
    </sc-list>

    <sc-note-dialog></sc-note-dialog>
  </sc-view>
</template>

<style lang='scss' scoped>
td.q-td.administrator {
  .warning {
    color: orangered;
  }
}

td.q-td.favorite {
  background-color: #f6fded;
}
</style>
