import { Rectangle, Circle, clone } from '@stellacontrol/utilities'
import { Assignable } from '@stellacontrol/model'
import { PlanLocation } from '@stellacontrol/planner'

/**
 * Shape layout
 */
export class ShapeLayout extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  normalize () {
    super.normalize()
    this.shape = this.shape.radius
      ? this.cast(this.shape, Circle)
      : this.cast(this.shape, Rectangle)
    this.ports = this.castArray(this.ports, ShapePortLayout, [])

    if (this.bounds == null && this.radius == null) throw new Error('Shape size must be specified')
  }

  /**
   * Shape size and coordinates within the shape container.
   * Shapes can be rectangular or circular.
   * @type {Rectangle|Circle}
   */
  shape

  /**
   * Indicates that shape is circular
   * @type {Boolean}
   */
  get isCircular () {
    return this.bounds.radius != null
  }

  /**
   * Rectangular boundaries of the shape
   * @type {Rectangle}
   */
  get bounds () {
    const { shape } = this
    return shape.radius != null
      ? shape.bounds
      : shape
  }

  /**
   * Returns the central point of the shape
   * @type {Point}
   */
  get center () {
    return this.bounds.center
  }

  /**
   * Definitions of ports on the shape.
   * @type {Array[ShapePortLayout]}
   */
  ports

  /**
   * Checks whether the shape has any ports
   * @type {Boolean}
   */
  get hasPorts () {
    return this.ports && this.ports.length > 0
  }

  /**
   * Number of ports on the shape
   * @type {Number}
   */
  get portCount () {
    return this.ports ? this.ports.length : 0
  }

  /**
   * Layout of the specified port on the shape
   * @param {String} id Port identifier
   * @param {PlanScale|Number} scale Current scale of the item
   * @returns {ShapePortLayout}
   */
  getPort (id, scale) {
    const ports = this.scaled(scale).ports
    const port = ports ? ports.find(port => port.id === id) : undefined
    return port
  }

  /**
   * Scale at which the shape is rendered
   * @type {PlanScale}
   */
  __scale

  /**
   * Shape layout scaled at {@link scale}
   * @type {ShapeLayout}
   */
  __scaled

  /**
   * Returns shape layout scaled at {@link scale}
   * @param {PlanScale|Number} scale Current scale of the item
   * @returns {PlanScale}
   */
  scaled (scale) {
    if (!scale || scale.isNormal) return this

    // Reuse the previously calculated scaled layout, if scale still the same
    if (scale.sameAs(this.__scale) && this.__scaled) return this.__scaled

    const scaled = new ShapeLayout(clone(this))

    if (scaled.shape) {
      // Reduce the shape, shift to maintain the center in place
      const center = scaled.shape.center
      scaled.shape.scale(scale).round()
      scaled.shape.centerAt(center)

      for (const port of scaled.ports || []) {
        port.shape?.scale(scale, true).round()
      }
    }

    this.__scale = scale
    this.__scaled = scaled

    return scaled
  }

  /**
   * Returns the bounds of the specified port
   * @param {String} id Port identifier
   * @param {Number} scale Scale of the item on which the port exists
   * @returns {Rectangle|Circle}
   */
  getPortBounds ({ id, scale }) {
    const portLayout = this.getPort(id, scale)
    if (portLayout) {
      if (portLayout.shape) {
        const bounds = portLayout.shape.radius
          ? Circle.from(portLayout.shape)
          : Rectangle.centeredAt(portLayout.shape)
        return bounds
      }
    }
  }

  /**
   * Returns the location of the specified port
   * @param {String} id Port identifier
   * @returns {PlanLocation}
   */
  getPortLocation ({ id, scale }) {
    const portLayout = this.getPort(id, scale)
    return portLayout?.location
  }

  /**
   * Returns the connection point on the specified port - a point where a connector leads to
   * @param {String} id Port identifier
   * @param {Point} coordinates Absolute coordinates of plan item to which the port belongs
   * @param {Number} rotation Rotation of the item on which the port exists
   * @param {Number} scale Scale of the item on which the port exists
   * @returns {Point}
   */
  getConnectionPoint ({ id, coordinates, rotation, scale }) {
    if (!coordinates) throw new Error('Item coordinates are required')

    const bounds = this.getPortBounds({ id, scale })
    let point

    // If port is visible, return connection point in the middle of the port
    if (bounds) {
      point = bounds.center.round()
    } else {
      // If port is not visible, draw the connector into the center of the shape
      point = this.shape.center
    }

    if (point) {
      // Make the point absolute
      point.moveBy(coordinates)

      // Apply rotation and other transforms
      if (rotation != 0) {
        point.rotate(rotation, coordinates)
      }
    }

    return point
  }
}

/**
 * Shape port layout
 */
export class ShapePortLayout extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  get defaults () {
    return {
      width: 12,
      height: 12,
      radius: 12
    }
  }

  normalize () {
    super.normalize()

    if (!this.id) throw new Error('Port identifier is required')
    if (!this.location) throw new Error('Port location is required')

    const { width, height } = this.defaults
    this.shape = this.cast(this.shape, Rectangle)
    this.location = this.location || PlanLocation.Bottom

    // Apply default port sizes unless they were customized
    if (this.shape && this.shape.width == null) {
      this.shape.width = width
    }

    if (this.shape && this.shape.height == null) {
      this.shape.height = height
    }
  }

  /**
   * Port identifier
   * @type {String}
   */
  id

  /**
   * Port location relative to the shape
   * @type {PlanLocation}
   */
  location

  /**
   * Port size and position on the shape.
   * If not specified, the port will not be visible on the shape.
   * @type {Rectangle}
   */
  shape
}
