import { createState } from './device-dashboard.state'

export const mutations = {
  /**
   * Initializes the Device Dashboard view
   * @param {Organization} organization Organization to which the viewed device belongs
   * @param {OrganizationGuardian} organizationGuardian Organization's guardian
   * @param {Place} place Place to which the viewed device belongs
   * @param {Device} device Device to view
   * @param {Device} devicePart Multi-device part to view
   * @param {PremiumService} pendingPremiumService Pending premium service associated with the device
   */
  initializeDeviceDashboard (state, { organization, organizationGuardian, place, device, devicePart, pendingPremiumService } = {}) {
    state.organization = organization
    state.organizationGuardian = organizationGuardian
    state.place = place
    state.device = device
    state.devicePart = devicePart
    state.pendingPremiumService = pendingPremiumService
  },

  /**
   * Stores the scan results sent by device
   * @param {Device} device Device
   * @param {Array[Attachment]} scans Scan results
   */
  storeDeviceScanResults (state, { device, scans = [] } = {}) {
    if (!device) return
    if (state.device?.id !== device?.id) return

    state.scans = scans
  },

  /**
   * Starts watching device scan results
   * @param {Clock} clock Watch clock
   */
  watchDeviceScanResults (state, { clock } = {}) {
    state.scansWatcher?.stop()
    state.scansWatcher = clock
  },

  /**
   * Stops watching device scan results
   */
  unwatchDeviceScanResults (state) {
    state.scansWatcher?.stop()
    state.scansWatcher = null
  },

  reset (state) {
    Object.assign(state, createState())
  }
}
