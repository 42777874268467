export function createState () {
  return {
    /**
     * Viewed organization
     * @type {Organization}
     */
    organization: null,

    /**
     * Viewed organization's guardian
     * @type {Guardian}
     */
    organizationGuardian: null,

    /**
     * Viewed place
     * @type {Place}
     */
    place: null,

    /**
     * Viewed device
     * @type {Device}
     */
    device: null,

    /**
     * Viewed device part
     * @type {Device}
     */
    devicePart: null,

    /**
     * Pending premium service associated with the viewed device
     * @type {PremiumService}
     */
    pendingPremiumService: null,

    /**
     * Age of the recent alerts fetched for the viewed device, in seconds
     * @type {Number}
     */
    lastAlertsAge: 7 * 24 * 3600,

    /**
     * Network scans collected by the device
     * @type {Array[Attachment]}
     */
    scans: null,

    /**
     * Timer for watching network scans collected by the device
     * @type {Number}
     */
    scansWatcher: null
  }
}

export const state = createState()

export const getters = {
}
