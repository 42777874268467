import { ConfirmationService } from './confirmation'
import { NotificationService } from './notification'
import { LoadingBarService } from './loading-bar'
import { ClipboardService } from './clipboard'
import { ExporterService } from './exporter'
import { DownloadService } from './download'
import { AnimationService } from './animation'
import { ClientColorService } from './color'
import { IdentifierService } from './identifier'
import { StorageService } from './storage'
import { ImageProcessorService } from './image-processor'
import { IndexDbService } from './indexdb'

export const Confirmation = new ConfirmationService()
export const Notification = new NotificationService()
export const LoadingBar = new LoadingBarService()
export const Clipboard = new ClipboardService()
export const Exporter = new ExporterService()
export const Download = new DownloadService()
export const Animation = new AnimationService()
export const Color = new ClientColorService()
export const Identifier = new IdentifierService()
export const Storage = new StorageService()
export const ImageProcessor = new ImageProcessorService()
export const IndexDb = new IndexDbService()
