import { PlannerMode } from '@stellacontrol/model'

// Plan list columns
const columns = [
]

export function createState () {
  return {
    // Plan list columns
    columns,

    // Plans of the current organization
    plans: [],

    // Edited plan
    plan: null,

    // Edited plan floor
    floorId: null,

    // Indicates that plan editor is maximized
    isMaximized: false,

    // Identifiers of the recently selected items.
    // Used to preserve selection while changing floors
    recentlySelectedItems: [],

    // Indicates that there's content in the clipboard
    // that can be pasted into the plan
    canPaste: false
  }
}

export const state = createState()

export const getters = {
  // Plan editing mode.
  // If user cannot use Advanced mode, enforce regular mode!
  planEditingMode: (state, getters) => {
    const mode = state.plan.settings.mode
    const canUseAdvancedMode = mode !== PlannerMode.ReadOnly &&
      getters.guardian.canUse('planner-advanced')

    return (mode === PlannerMode.Advanced && !canUseAdvancedMode)
      ? PlannerMode.Regular
      : mode
  },
}
