import { Point, countString } from '@stellacontrol/utilities'

/**
 * Plan tooltip
 */
export class PlanTooltip {
  constructor (data = {}) {
    this.text = data.text
    this.position = data.position
  }

  /**
   * Tooltip text, can contain HTML markup
   * @type {String}
   */
  text

  /**
   * Tooltip coordinates
   * @type {Point}
   */
  position

  /**
   * Indicates that the tooltip is valid and ready to show
   * @type {Boolean}
   */
  get isValid () {
    return this.text?.trim() && this.position != null
  }

  /**
   * Hides the tooltip
   */
  hide () {
    this.text = null
    this.position = null
  }
}

/**
 * Returns a tooltip for the specified plan item
 * @param {PlanItem} item Item to show the tooltip for
 * @param {Point} position Position at which to show the tooltip
 * @param {PlanLayout} layout The entire plan layout
 * @param {PlanFloor} floor The currently displayed floor
 * @returns {PlanTooltip}
 */
export function getPlanItemTooltip ({ item, position, layout, floor }) {
  if (!(item && position && layout && floor)) return

  let text

  // Cable plug
  if (item.isPlug) {
    const riser = layout.getRiser(item.riser)
    if (riser) {
      text = [
        `Cable riser ${riser.tagText} (${countString(riser.connectors, 'cable')})`
      ].join('<br>')
    }
  }

  // Part of a cross-section cable,
  // Cross-section cable
  if (item.isConnector && (item.partOf || item.isCrossFloor)) {
    const connector = item.isCrossFloor ? item : layout.getItem(item.partOf)
    if (connector) {
      const startItem = connector.start.item
      const endItem = connector.getOtherItem(startItem)
      if (startItem && endItem) {
        const startItemLabel = `${startItem.toHumanString(true)} ${startItem.tag ? ` (${startItem.tagText})` : ''}`
        const endItemLabel = `${endItem.toHumanString(true)} ${endItem.tag ? ` (${endItem.tagText})` : ''}`
        const startFloor = layout.getFloor(startItem.floorId)
        const endFloor = layout.getFloor(endItem.floorId)
        const riser = item.partOf ? layout.getRiser(item.goesIntoRiser) : layout.getRiserOf(connector)
        if (riser) {
          text = [
            `${startItemLabel} on the ${startFloor.label}`,
            `${riser ? `going through riser ${riser.tagText}` : ''}`,
            `to ${endItemLabel} on the ${endFloor.label}`,
            item.isCrossFloor && connector.realLength ? `Length: ${connector.realLength}m` : '',
            item.partOf && connector.realLength ? `Length: ${item.realLength}m on this floor, ${connector.realLength}m in total` : '',
          ]
            .filter(l => l)
            .join('<br>')
        }
      }
    }
  }

  if (text) {
    // Determine tooltip coordinates unless specified explicitly
    const itemCoordinates = item.getCoordinates(floor.isCrossSection)
    position = Point
      .from(position || itemCoordinates)
      .moveBy({ x: 15, y: 15 })

    return new PlanTooltip({
      text,
      position
    })
  }
}
