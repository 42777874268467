/**
 * Device features as reported by the device
 */
export class DeviceStatusFeatures {
  /**
   * Initializes the instance
   * @param serialNumber Device serial number
   * @param mega Live device data retrieved from Shadow API
   * @param data Initial values of the properties
   */
  constructor (serialNumber, mega, data = {}) {
    this.serialNumber = serialNumber
    Object.assign(this, data)
    if (mega) {
      this.parse(mega)
    }
  }

  /**
   * Creates am instance populated with deserialized data
   */
  static from (data = {}) {
    const status = new DeviceStatusFeatures()
    Object.assign(status, data)
    return status
  }

  /**
   * Feature fields and their indices in the flags array
   */
  static Fields = {
    Ethernet: 0,
    Battery: 1,
    Gps: 2,
    Fan: 3,
    ManualGroupAttenuationControl: 4,
    FeedbackDetection: 5,
    PortSense: 6,
    Modem: 7,
    RfBypassMode: 8,
    GlobalRfOnOff: 9,
    NoManualAttenuation: 10,
    LedCount4: 11,
    NoThermometer: 12
  }

  /**
   * All flags
   * @type {Array[Number]}
   */
  flags

  /**
   * Ethernet connection is present
   * @type {Boolean}
   */
  Ethernet

  /**
   * Onboard battery is present
   * @type {Boolean}
   */
  Battery

  /**
   * Connection for GPS antenna is present
   * @type {Boolean}
   */
  Gps

  /**
   * Connection for external fan is present
   * @type {Boolean}
   */
  Fan

  /**
  * Allows group attenuation
  * @type {Boolean}
  */
  ManualGroupAttenuationControl

  /**
  * Can detect oscillation/feedback
  * @type {Boolean}
  */
  FeedbackDetection

  /**
  * Has port sense functionality
  * @type {Boolean}
  */
  PortSense

  /**
  * Has a modem
  * @type {Boolean}
  */
  Modem

  /**
  * RF can be set to pass-through, no amplification or filtering.
  * Applies to combiners only.
  * @type {Boolean}
  */
  RfBypassMode

  /**
  * Master power control of +5V for RF (and some other peripherals) -
  * used for dual-board setups.
  * @type {Boolean}
  */
  GlobalRfOnOff

  /**
   * No manual attenuation control
   * @type {Boolean}
   */
  NoManualAttenuation

  /**
   * LED count 4 (default is 6)
   * @type {Boolean}
   */
  LedCount4

  /**
   * Indicates that device does not have an onboard thermometer,
   * therefore PCB temperature is not available
   * @type {Boolean}
   */
  NoThermometer

  /**
   * Parses feature flags into a boolean array
   * @param {Object} mega Device status
   * @returns {Array[Boolean]}
   */
  static parseFlags (mega = {}) {
    const flags = (mega['product_features'] || '')
      .split(',')
      .map(item => item != null ? Boolean(parseInt(item)) : undefined)
    return flags
  }

  /**
   * Parses raw MEGA record received from the device
   * @param {Object} mega MEGA record
   */
  parse (mega = {}) {
    this.megaVersion = mega['@version']
    if (!this.megaVersion) return

    this.flags = DeviceStatusFeatures.parseFlags(mega)

    for (const [field, index] of Object.entries(DeviceStatusFeatures.Fields)) {
      if (this.flags[index] != null) {
        this[field] = this.flags[index]
      }
    }
  }

  /**
   * Indicates whether device features information is not available
   * @type {Boolean}
   */
  get isEmpty () {
    return (this.flags || []).length === 0
  }

  /**
   * Indicates whether the specified device feature is present
   * @param {@String} feature Feature to check
   * @type {Boolean}
   */
  isPresent (feature) {
    if (!feature) throw new Error('Feature name is required')
    if (!DeviceStatusFeatures.Fields[feature]) throw new Error(`Feature [${feature}] is unknown`)
    return this[feature] != null
  }

  /**
   * Indicates whether the specified device feature is enabled
   * @param {@String} feature Feature to check
   * @type {Boolean}
   */
  isEnabled (feature) {
    if (!feature) throw new Error('Feature name is required')
    if (!DeviceStatusFeatures.Fields[feature]) throw new Error(`Feature [${feature}] is unknown`)
    return this[feature] === true
  }

  /**
   * Indicates whether the specified device feature is not enabled
   * @param {@String} feature Feature to check
   * @type {Boolean}
   */
  isNotEnabled (feature) {
    if (!feature) throw new Error('Feature name is required')
    if (!DeviceStatusFeatures.Fields[feature]) throw new Error(`Feature [${feature}] is unknown`)
    return this[feature] === false
  }
}
