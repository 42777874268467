<script>
import { getDuration } from '@stellacontrol/utilities'

export default {
  props: {
    /**
     * Initial value, duration in seconds
     */
    modelValue: {
      type: Number,
      required: true
    },

    /**
     * Duration
     */
    min: {
      type: Number,
      default: 0
    },

    /**
     * Visible time parts
     */
    parts: {
      type: Array,
      default: () => ['days', 'hours', 'minutes', 'seconds']
    },

    /**
     * Maximal duration
     */
    max: {
      type: Number,
      default: 10000000
    },

    // Control is read-only
    readonly: {
      type: Boolean,
      default: false
    },

    // Control is disabled
    disabled: {
      type: Boolean,
      default: false
    },

    // Custom CSS classes to add to inputs
    inputClass: {
      type: Object,
      default: () => { }
    },

    // Custom CSS styles to add to input
    inputStyle: {
      type: Object,
      default: () => { }
    }
  },

  data () {
    return {
      // Entered values
      values: {
        days: {
          value: 0,
          label: 'Days',
          min: 0,
          max: 1000000,
          focused: false
        },
        hours: {
          value: 0,
          label: 'Hours',
          min: 0,
          max: 24,
          focused: false
        },
        minutes: {
          value: 0,
          label: 'Minutes',
          min: 0,
          max: 60,
          focused: false
        },
        seconds: {
          value: 0,
          label: 'Seconds',
          min: 0,
          max: 60,
          focused: false
        },
      },
      // Adding timer, for continuous mouse-down adding of values
      addingTimer: null
    }
  },

  computed: {
    // Entered duration, in seconds
    duration () {
      const parts = Object
        .entries(this.values)
        .map(([part, { value }]) => ({ part, value }))
      const duration = parts.reduce((all, item) => ({ ...all, [item.part]: item.value }), {})
      return this.getDuration(duration)
    },

    // Table CSS style
    tableStyle () {
      const { parts } = this
      return {
        width: `${parts.length * 60}px`
      }
    },

    // Cell CSS style
    cellClass () {
      return part => {
        const { focused } = this.values[part]
        return { focused }
      }
    }
  },

  methods: {
    // Calculates duration in seconds
    getDuration ({ days, hours, minutes, seconds } = {}) {
      return days * 86400 + hours * 3600 + minutes * 60 + seconds
    },

    // Assigns the specified duration to the editor,
    // enforcing minimal and maximal value etc.
    assign (duration) {
      const { values, min, max } = this

      if (min != null) {
        duration = Math.max(min, duration)
      }
      if (max != null) {
        duration = Math.min(max, duration)
      }

      const parsedDuration = getDuration(duration) || {}
      for (const [part, value] of Object.entries(parsedDuration)) {
        if (values[part]) {
          if (part === 'days') {
            const { totalDays } = parsedDuration
            values[part].value = totalDays || 0
          } else {
            values[part].value = value || 0
          }
        }
      }

      return duration
    },

    // Focuses/defocuses part
    focus (name, isFocused) {
      const { parts, values } = this
      for (const part of parts) {
        values[part].focused = isFocused && part === name
      }
    },

    // Triggered on manual editing of the specified part
    edit (name, value) {
      if (value == null || isNaN(value)) {
        value = 0
      }
      const item = this.values[name]
      item.value = Math.min(Math.max(item.min, value), item.max - 1)
      this.assign(this.duration)
      this.$emit('update:model-value', this.duration)
    },

    // Adds value to the specified part
    add (name, value) {
      const item = this.values[name]
      item.value = Math.min(Math.max(item.min, item.value + value), item.max)
      this.assign(this.duration)
      this.$emit('update:model-value', this.duration)
    },

    // Starts adding timer, which keeps adding value to the clicked item
    // as long as mouse is held down
    startAdding (name, value) {
      clearInterval(this.addingTimer)
      this.add(name, value)
      this.addingTimer = setTimeout(() => {
        this.addingTimer = setInterval(() => this.add(name, value), 100)
      }, 500)
    },

    // Stops adding timer, after the mouse has been released
    stopAdding () {
      clearInterval(this.addingTimer)
      this.addingTimer = null
    },

    // Selects all text in the specified input control
    selectText (input) {
      if (input) {
        input.select()
      }
    }
  },

  watch: {
    modelValue (duration) {
      this.assign(duration)
    }
  },

  created () {
    this.assign(this.modelValue)
  }
}
</script>

<template>
  <table class="parts" :style="tableStyle">
    <tbody>
      <tr class="inputs">
        <td v-for="part in parts" :key="part" :class="cellClass(part)">
          <div class="part">
            <label>{{ values[part].label }}</label>
            <q-icon class="button minus" dense unelevated flat name="remove" size="20px"
              color="indigo-7" @mousedown="() => startAdding(part, -1)"
              @mouseup="() => stopAdding()">
            </q-icon>
            <input type="number" :class="inputClass" :style="inputStyle" :min="values[part].min"
              :max="values[part].max" :value="values[part].value" :readonly="readonly"
              :disabled="disabled" @input="e => edit(part, parseInt(e.target.value))"
              @click="e => selectText(e.target)"
              @xclick="e => e.target.setSelectionRange(0, e.target.value.length)"
              @focus="() => focus(part, true)" @blur="() => focus(part, false)">
            <q-icon class="button plus" dense unelevated flat name="add" size="20px"
              color="indigo-7" @mousedown="() => startAdding(part, 1)"
              @mouseup="() => stopAdding()">
            </q-icon>
          </div>
        </td>
      </tr>
      <tr>
        <td v-for="part in parts" :key="part"></td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
table.parts {
  border-collapse: collapse;

  td,
  th {
    padding: 0;
    border: none;
    font-weight: normal;
  }

  tr.inputs {
    td {
      border: solid #0000003d 1px;
      width: 60px;
      height: 50px;

      .part {
        width: 100%;
        height: 100%;
        position: relative;
        transition: all 0.1s ease-out;
      }

      &.focused {
        .part {
          background-color: #e4e4e4;
        }
      }

      label {
        font-size: 11px;
        color: gray;
        position: absolute;
        left: 4px;
        top: 4px;
        right: 4px;
        text-align: center;
        z-index: 2;
      }

      &.focused {
        label {
          color: #1976d2;
        }
      }

      input {
        margin-top: 15px;
        width: 100%;
        border: none;
        padding: 8px;
        outline: 0;
        text-align: center;
        background-color: transparent;
      }

      .button {
        position: absolute;
        z-index: 2;
        display: none;
        cursor: pointer;
        transition: all 0.1s ease-out;

        &.minus {
          left: 2px;
          top: 22px;
        }

        &.plus {
          right: 2px;
          top: 22px;
        }

        &:hover {
          opacity: 0.8;
          box-shadow: 0 0 2px silver;
        }
      }

      &:hover {
        .button {
          display: block;
        }
      }
    }
  }
}

/* Hide spinner controls */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>