<script>
export default {
  props: {
    // Parameter label
    label: {
      type: String,
      required: true
    },
    // Reported value of the parameter
    reported: {
      required: true
    },
    // Custom value of the parameter
    custom: {
      required: true
    },
    // Value formatter for display
    format: {
      type: Function
    },
    // Indicates whether to show that the value has been customized
    // For some parameters this is irrelevant
    showCustomized: {
      type: Boolean,
      default: true
    },
    // Unit to show along the current value
    unit: {
      type: String
    }
  },


  computed: {
    // Indicates whether parameter value has been changed
    isCustomized () {
      return this.custom != null
    },

    // Indicates whether parameter value has been changed
    // but not yet picked up by the device
    isChanging () {
      const { custom, reported } = this
      return custom != null && custom != reported
    },

    // Current value, formatted for display
    reportedString () {
      const { format, reported, isChanging } = this
      return format ? format(reported, isChanging) : reported
    },

    // Desired value, formatted for display
    customString () {
      const { format, custom, isChanging } = this
      return format ? format(custom, isChanging) : custom
    }
  }
}
</script>

<template>
  <div class="parameter-value row items-center" :class="{ customized: isCustomized && showCustomized }">
    <slot>
      <template v-if="isChanging">
        <span class="text-bold">
          {{ customString }}
          <sc-tooltip :text="`Desired ${label.toLowerCase()}: ${customString}`" />
        </span>
        <q-icon name="arrow_right" color="grey-7" size="md" class="q-ml-xs q-mr-xs pulse">
          <sc-tooltip :text="`Changing ${label.toLowerCase()} from ${reported} to ${custom} ...`" />
        </q-icon>
        <span>
          <sc-tooltip :text="`Current ${label.toLowerCase()}: ${reportedString}`" />
          {{ reportedString == null ? 'not set' : reportedString }}
        </span>
      </template>
      <template v-else>
        <span>
          {{ reportedString }} {{ unit }}
        </span>
      </template>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
@keyframes pulse-horizontal {
  from {
    transform: translateX(-2px);
  }
  to {
    transform: translateX(2px);
  }
}

.pulse {
  animation: pulse-horizontal 0.75s alternate-reverse infinite ease-in-out;
}

.parameter-value {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
  padding: 7px 8px 7px 8px;

  &.customized {
    background-color: #fff9c4;
  }
}

</style>