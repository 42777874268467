import { distinctItems, Point, Rectangle, isInsideRectangle } from '@stellacontrol/utilities'
import Konva from 'konva'
import { Layer } from './layer'
import { PlanEvent } from '../events'

/**
 * Selection layer renderer
 */
export class SelectionLayer extends Layer {
  constructor (data) {
    super(data)
    this.items = []
  }

  // Default for layer elements
  get defaults () {
    return {
      // Item selection box
      selectionBox: {
        fill: '#c5cae9',
        opacity: 0.5,
        stroke: '#414a84',
        strokeWidth: 1
      },

      // Item transformer
      transformer: {
        rotateEnabled: true,
        flipEnabled: false,
        centeredScaling: false,
        // shouldOverdrawWholeArea: true,
        padding: 8
      }
    }
  }

  /**
   * Stage
   * @type {Konva.Stage}
   */
  __stage = null

  /**
   * Returns the stage
   * @returns {Konva.Stage}
   */
  get stage () {
    if (this.__stage) {
      return this.__stage
    }
    let element = this.content
    while (element) {
      if (element.nodeType === 'Stage') {
        this.__stage = element
        return element
      } else {
        element = element.parent
      }
    }
    return undefined
  }

  __isSelecting = false
  __isMoving = false

  /**
   * Initial position from which the user started selecting elements with the mouse
   * @type {Point}
   */
  __selectionStart = null

  /**
   * Box drawn on the stage when user is selecting elements with the mouse
   * @type {Konva.Rect}
   */
  __selectionBox = null

  /**
   * Previous position at which the moving elements were
   * @type {Point}
   */
  __previousPosition = null

  /**
   * Indicates whether user is currently selecting items
   * @type {Boolean}
   */
  get isSelecting () {
    return this.__isSelecting
  }

  /**
   * Indicates whether user is currently moving items
   * @type {Boolean}
   */
  get isMoving () {
    return this.__isMoving
  }

  /**
   * Indicates the start of moving items by user dragging the transformer box
   * @param {Point} position Mouse position
   */
  startMoving (position) {
    const { items } = this
    if (position && this.canEdit(items)) {
      this.__isMoving = true
      this.__dragFrom = position
      this.notifyEvent('moving', { items, position })
    }
  }

  /**
   * Moves the selected items using the specified coordinates
   * of a mouse event
   * @param {Point} position Mouse position
  */
  move (position) {
    const { items, isMoving } = this
    if (isMoving && this.canEdit(items) && position) {
      if (this.isSelecting) {
        this.cancel()
      }
      const delta = position.delta(this.__dragFrom)
      this.__dragFrom = position
      this.notifyEvent('move', { items, position, delta })
    }
  }

  /**
   * Indicates the end of moving items by user dragging the transformer box
   * @param {Point} position Mouse position
  */
  finishMoving (position) {
    const { items, isMoving } = this
    if (isMoving && position != null && this.canEdit(items)) {
      this.__dragFrom = undefined
      this.notifyEvent('moved', { items, position })
    }
    this.__isMoving = false
  }

  /**
   * Indicates that selection box is now visible
   * @type {Boolean}
   */
  get isSelectionBoxVisible () {
    const { __selectionBox: box } = this
    return box.visible() &&
      box.width() > 0 &&
      box.height() > 0
  }

  /**
   * Element transformer
   * @type {Konva.Transformer}
   */
  __transformer

  /**
   * Indicates whether transformer is currently visible
   * @type {Boolean}
   */
  get isTransforming () {
    return this.__transformer?.isTransforming()
  }

  /**
   * Bounding box of the transformer shape
   * @type {Rectangle}
   */
  get bounds () {
    const { __transformer: t } = this

    if (t && t.width() > 0 && t.height() > 0) {
      const { x, y } = t.getAbsolutePosition()
      return new Rectangle({
        x,
        y,
        width: t.width(),
        height: t.height()
      })

    } else {
      return null
    }
  }
  /**
   * Position of the transformer shape
   * @type {Point}
   */
  get position () {
    const { __transformer: t } = this
    if (t) {
      return new Point(t.getAbsolutePosition())
    } else {
      return null
    }
  }

  /**
   * Selected items
   * @type {Array[PlanItem]}
   */
  items

  /**
   * Indicates whether there are any items currently selected
   * @type {Boolean}
   */
  get hasSelectedItems () {
    return this.items.length > 0
  }

  /**
   * First/single selected item
   * @type {PlanItem}
   */
  get selectedItem () {
    return this.items[0]
  }

  /**
   * Indicates that the specified item is selected
   * @param {PlanItem} item
   * @type {Boolean}
   */
  isItemSelected (item) {
    return item && this.items.some(i => i.id === item.id)
  }

  /**
   * Indicates that there are multiple items now selected
   * @type {Boolean}
   */
  get manySelected () {
    return this.items.length > 1
  }

  /**
   * Checks whether the specified position is inside the transformer
   * @param {Point} position Position to check
   */
  isPositionInside (position) {
    const { selectedItem, __transformer: t } = this
    if (selectedItem && t) {
      const rectangle = new Rectangle(t.getClientRect())
      const isInside = isInsideRectangle(position, rectangle)
      return isInside
    }
  }

  /**
   * Checks whether user can interact with the specified items
   * @param {Array[Item]} items
   * @returns {Boolean}
   */
  canEdit (items) {
    return items?.length > 0 &&
      items.every(i => i.canSelect && !i.isLocked)
  }

  __dragFrom

  /**
   * Creates own shapes of the layer.
   * Override in specific layer to create fixed elements which are rendered
   * on the layer before rendering any custom elements added by the user
   * @return {Promise<Array[Shape|Konva.Shape]>}
   */
  async createOwnShapes () {
    // Remove previous elements
    this.destroy(this.__selectionBox, this.__transformer)

    const { defaults } = this
    const transformer = new Konva.Transformer(defaults.transformer)

    // Start moving the selected elements
    transformer.on('dragstart', (e) => {
      const position = PlanEvent.getEventPosition(e)
      this.startMoving(position)
    })

    // Move the selected elements
    transformer.on('dragmove', (e) => {
      const position = PlanEvent.getEventPosition(e)
      this.move(position)
      e.cancelBubble = true
    })

    // Finish moving the selected elements
    transformer.on('dragend', (e) => {
      const position = PlanEvent.getEventPosition(e)
      this.finishMoving(position)
    })

    const selectionBox = new Konva.Rect(defaults.selectionBox)

    this.__selectionBox = selectionBox
    this.__transformer = transformer

    return [
      selectionBox,
      transformer
    ]
  }

  /**
   * Refreshes the transformer.
   * Useful for example when elements change sizes.
   */
  refresh () {
    this.__transformer.forceUpdate()
  }

  /**
   * Clears the layer
   */
  clear () {
    super.clear()
    this.__transformer.nodes([])
    this.__selectionBox.visible(false)
    this.__isSelecting = false
    this.__isMoving = false
    this.items = []
    this.hasSelection = false
  }

  /**
   * Selects the specified shapes
   * @param {Array[Shape]} shapes Shapes to select
   * @param {Boolean} preserveSelection If true, shapes are added to the previous selection
   */
  select (shapes, preserveSelection) {
    const { __transformer: transformer } = this
    if (!preserveSelection) {
      this.deselect()
    }

    // Extract the items behind the selected shapes
    shapes = shapes.filter(s => s)
    const shapeItems = shapes.map(s => s.item)
    const selectedItems = preserveSelection
      ? [...this.items, ...shapeItems]
      : shapeItems
    this.items = distinctItems(selectedItems, 'id')
    const item = this.items[0]

    // Show the transformer around all shapes to act upon.
    // Do not include connectors - they'll be acted upon
    // as a result of manipulating the connected items
    const shapesToWrap = shapes
      .filter(s => !s.item.isConnector)
      .map(s => s.content)

    // Prevent displaying the transformer, if single item selected
    // and it has its own way of indicating selections - such as lines, connectors etc.
    const showTransformer = shapesToWrap.length > 0 && (this.items.length > 1 || !item.hasCustomSelector)
    if (showTransformer) {
      const canResize = shapes.every(shape => shape.item.canResize)
      const canRotate = shapes.every(shape => shape.item.canRotate)
      const keepRatio = shapes.every(shape => shape.item.keepRatio)

      transformer.resizeEnabled(canResize)
      transformer.rotateEnabled(canRotate)
      transformer.keepRatio(keepRatio)
      if (keepRatio) {
        transformer.enabledAnchors(['top-left', 'top-right', 'bottom-left', 'bottom-right'])
      } else {
        transformer.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'])
      }

      if (preserveSelection) {
        transformer.nodes([...transformer.nodes(), ...shapesToWrap])
      } else {
        transformer.nodes(shapesToWrap)
      }
      transformer.forceUpdate()
    }

    transformer.moveToTop()

    // Notify about succesful selection
    this.notifyEvent('select', { items: this.items })
  }

  /**
   * Deselects all currently selected shapes
   */
  deselect () {
    const { __selectionBox: box } = this
    box.width(0)
    box.height(0)
    box.visible(false)

    this.items = []
    this.hasSelection = false
    this.__isSelecting = false
    this.__isMoving = false

    this.notifyEvent('deselect')
  }

  /**
   * Sets visibility of the layer
   * @param {Boolean} value
   */
  setVisibility (value) {
    super.setVisibility(value)
    this.deselect()
  }

  /**
   * Initiates selecting elements with the mouse
   * @param {Point} position Point at which the mouse went down
   */
  start (position) {
    const { content, __selectionBox: box } = this
    content.moveToTop()
    box.x(position.x)
    box.y(position.y)
    box.width(0)
    box.height(0)
    box.visible(true)

    this.__selectionStart = position
    this.__isSelecting = true
  }

  /**
   * Updates the selection box during selection of elements with the mouse
   * @param {Point} position Point at which the mouse currently is
   */
  update (position) {
    if (!this.__isSelecting) return

    const { __selectionStart: start, __selectionBox: box } = this
    if (start && position) {
      const delta = position.delta(start)
      if (delta.distance() >= 5) {
        // Show the selection box if moved far enough
        // If box drawn in the other direction, normalize it
        const bounds = Rectangle.from({
          x: start.x,
          y: start.y,
          width: delta.x,
          height: delta.y
        }).normalize()

        box.position(bounds)
        box.size(bounds)

        if (!box.visible()) {
          box.visible(true)
        }
      } else {
        if (box.visible()) {
          box.visible(false)
        }
      }
    }
  }

  /**
   * Finishes selecting elements with the mouse
   * @param {PlanRenderer} renderer Plan renderer
   * @param {Point} position Position at which the mouse was released
   * @returns {Array[PlanItem]} Selected items
   */
  finish (renderer, position) {
    if (!this.__isSelecting) return

    let items
    const { isSelectionBoxVisible, __selectionBox: box, __selectionStart: start, __transformer: transformer } = this

    // Select items inside the selection box, and connectors leading to these items
    if (start && position && isSelectionBoxVisible) {
      const { x, y } = start
      const rectangle = new Rectangle({ x, y, width: position.x - x, height: position.y - y })

      // Get equipment within the bounds of the selection rectangle
      const equipment = renderer.selectableItems
        .filter(i => !i.isConnector && i.getBounds(renderer.isCrossSection).overlapsWith(rectangle))

      // Include connectors but only when both of the copied ends are included amongst the copied items.
      const connectors = distinctItems(
        equipment.flatMap(i => renderer.floor.getConnectorsOf(i)), 'id')
        .filter(i => equipment.find(({ id }) => id === i.start.itemId) && equipment.find(({ id }) => id === i.end.itemId))

      items = [...equipment, ...connectors]

      // Don't allow selecting just cables
      if (items.every(i => i.isConnector)) {
        items = []
      }

      renderer.selectItems({ items })
      transformer.forceUpdate()
    }

    box.width(0)
    box.height(0)
    box.visible(false)

    this.__selectionStart = null
    this.__isSelecting = false

    return items
  }

  /**
   * Stops selecting elements without marking anything as selected
   */
  cancel () {
    const { __selectionBox: box, __transformer: transformer } = this

    transformer.forceUpdate()

    box.width(0)
    box.height(0)
    box.visible(false)

    this.__selectionStart = null
    this.__isSelecting = false
  }
}
