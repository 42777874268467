<script>
import { mapActions } from 'vuex'
import { countString } from '@stellacontrol/utilities'
import { FormMixin, DialogMixin } from '@stellacontrol/client-utilities'

const dialog = 'file-print-dialog'

export default {
  mixins: [
    FormMixin,
    DialogMixin
  ],

  data () {
    return {
      dialog,
      files: [],
      options: {
        // TT Scan print options
        ttscan: {
          // Print the Summary section
          summary: true,
          // Print the Live Scan section
          neighbours: true,
          // Print Spectrum section
          spectrum: true,
          // Print Survey section
          survey: true,
        }
      }
    }
  },

  computed: {
    // Indicates that there are TT scans amongst the files to print
    ttScans () {
      return this.files.some(f => f.isScan)
    }
  },

  methods: {
    ...mapActions([
      'dialogOk',
      'dialogCancel'
    ]),

    // Called when dialog is shown
    dialogShown () {
      // Pass on initial data
      const { data: { files } } = this
      this.files = files
      if (files.length === 1) {
        this.title = `Print ${files[0].isScan ? 'TT Scan Report ' : ''}${files[0].name}`
      } else {
        this.title = `Print ${countString(files, 'file')}`
      }
    },

    // Validates and OKs the dialog
    ok () {
      const { files, options } = this
      this.dialogOk({ dialog, data: { files, options } })
    },

    // Cancels the dialog
    cancel () {
      this.dialogCancel({ dialog })
    }
  }
}
</script>

<template>
  <sc-dialog :dialog="dialog" @dialogShown="dialogShown()">
    <q-form class="form" ref="form">
      <q-banner class="bg-indigo-6">
        <span class="text-white title">
          {{ title }}
        </span>
      </q-banner>

      <div class="q-pa-lg">
        <div v-if="ttScans" class="column">
          <span class="text-bold q-mb-sm">
            Print Options
          </span>
          <q-checkbox label="Print Summary" v-model="options.ttscan.summary"></q-checkbox>
          <q-checkbox label="Print Live Scan" v-model="options.ttscan.neighbours"></q-checkbox>
          <q-checkbox label="Print Spectrum Scan" v-model="options.ttscan.spectrum"></q-checkbox>
          <q-checkbox label="Print Cell ID Scan" v-model="options.ttscan.survey"></q-checkbox>
        </div>

        <div class="row q-mt-xl">
          <q-space></q-space>
          <q-btn label="Cancel" unelevated class="q-mr-md" @click="cancel()" />
          <q-btn label="Print" unelevated class="primary" @click="ok()" />
        </div>
      </div>

    </q-form>
  </sc-dialog>
</template>

<style scoped lang="scss">
.title {
  font-size: 16px;
}

.form {
  min-width: 500px;
}
</style>