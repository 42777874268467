import { Log } from './log'

/**
 * Clock identifier counter
 */
let ClockId = 1000

/**
 * Creates a clock
 * @param options Clock options
 */
export function createClock (options) {
  return new Clock(options)
}

/**
 * SAMPLE USE:

    clock = new Clock({
      name: 'KLOK',
      frequency: 1000,
      isSuspended: true
    })

    clock.addAlert({
      name: 'yo',
      interval: 5,
      times: 2,
      isSuspended: false,
      suspendOnException: false
    }),
    clock.addAlert({
      name: 'bro',
      interval: 10,
      times: null,
      isSuspended: false,
      suspendOnException: false
    },
    () => console.log('Bro!')
    )

    // General clock events
    clock.addEventListener('tick', ({ clock }) => console.log('TICK', `${clock.name}, #${clock.ticks}`))
    clock.addEventListener('alert', ({ clock, alert }) => console.log('ALERT', `${clock.name}.${alert.name} #${alert.count}`))
    clock.addEventListener('alertSuspended', ({ clock, alert }) => console.log('ALERT', `${clock.name}.${alert.name} SUSPENDED`))
    clock.addEventListener('alertResumed', ({ clock, alert }) => console.log('ALERT', `${clock.name}.${alert.name} RESUMED`))
    clock.addEventListener('alertFinished', ({ clock, alert }) => console.log('ALERT', `${clock.name}.${alert.name} FINISHED after ${alert.times} times`))

    // Events triggered when alert is triggered
    clock.addEventListener('alert-yo', ({ clock, alert }) => console.log('ALERT', `Yo!`))
    clock.addEventListener('alert-bro', ({ clock, alert }) => {
      // If alert handler returns true, the alert will be suspended after this execution
      return true
    }))

    clock.start()
    clock.suspend()
    clock.resume()
    clock.stop()

 */

/**
 * Generic clock utility, with ability to trigger alerts at
 * specified intervals, and ability to suspend and resume all or individual alerts.
 * Usable on both server and client.
 */
export class Clock {
  /**
   * Creates a clock
   * @param {String} name Clock name
   * @param {Number} frequency Clock tick frequency in milliseconds
   * @param {Array[Alert]} alerts Clock alerts
   * @param {Boolean} isStopped Creates the clock but does not start it automatically
   * @param {Boolean} throwOnExceptions If true, the clock will thrown on exceptions during alert executions.
   * Otherwise exceptions will be suppressed while alerts might be suspended if told to do so.
   * @emits tick Notifies about the ticking of the clock
   * @emits start Notifies about starting of the clock
   * @emits stop Notifies about stopping of the clock
   * @emits suspend Notifies about suspending of the clock
   * @emits resume Notifies about resuming of the clock
   * @emits alert Notifies about an alert triggered
   * @emits alertError Notifies about exceptions during alert execution
   * @emits alertSuspended Notifies about suspending of an alert
   * @emits alertResumed Notifies about resuming of an alert
   * @emits alertFinished Notifies about finishing of all cycles of an alert
   */
  constructor ({ name = 'clock', frequency = 1000, alerts, isStopped = true, throwOnExceptions } = {}) {
    if (!name) throw new Error('Clock name is required')
    if (!(frequency > 0)) throw new Error(`Clock tick frequency ${frequency} is invalid`)

    this.__id = ClockId++
    this.__name = name
    this.__frequency = frequency
    this.__alerts = alerts || []
    this.__isSuspended = false
    this.__throwOnExceptions = throwOnExceptions

    this.__timer = null
    this.__ticks = 0
    this.__eventHandlers = {
      tick: [],
      start: [],
      stop: [],
      suspend: [],
      resume: [],
      alert: [],
      alertError: [],
      alertSuspended: [],
      alertResumed: [],
      alertFinished: []
    }

    if (!isStopped) {
      this.start()
    }
  }

  /**
   * Checks whether the specified name is valid event
   * @param {String} name
   * @returns {Boolean}
   */
  isValidEvent (name) {
    if (name) {
      const isAlertEvent = name?.startsWith('alert-')
      return isAlertEvent || Object.keys(this.__eventHandlers).includes(name)
    }
    return false
  }

  /**
   * Adds the specified event listener
   * @param {String} name Event name
   * @param {Function} handler Event handler
   */
  addEventListener (name, handler) {
    if (!this.isValidEvent(name)) throw new Error(`Unsupported event: [${name}]`)
    if (!handler) throw new Error('Event handler is required')

    if (!this.__eventHandlers[name]) {
      this.__eventHandlers[name] = []
    }
    const handlers = this.__eventHandlers[name]
    if (!handlers.includes(handler)) {
      handlers.push(handler)
      return handler
    }
  }

  /**
   * Removes the specified event listener.
   * If handler is specified, only this specific handler is removed,
   * otherwise all handlers for the event are removed.
   * @param {String} name Event name
   * @param {Function} handler Event handler
   */
  removeEventListener (name, handler) {
    if (!this.isValidEvent(name)) throw new Error(`Unsupported event: [${name}]`)

    const handlers = this.__eventHandlers[name]
    if (handlers) {
      if (handler) {
        this.__eventHandlers[name] = this.__eventHandlers[name].filter(h => h !== handler)
        return handler
      } else {
        this.__eventHandlers[name] = []
      }
    }
  }

  /**
   * Adds event listener for a specific alert
   * @param alertName Alert name
   * @param handler Event handler
   */
  addAlertListener (alertName, handler) {
    return this.addEventListener(`alert-${alertName}`, handler)
  }

  /**
   * Adds event listener for a specific alert
   * @param alertName Alert name
   * @param handler Event handler
   */
  removeAlertListener (alertName, handler) {
    return this.removeEventListener(`alert-${alertName}`, handler)
  }

  /**
   * Removes all event listeners
   */
  removeEventListeners () {
    for (const name of Object.keys(this.__eventHandlers)) {
      this.__eventHandlers[name] = []
    }
  }

  /**
   * Emits an event
   * @param name Event name
   * @param event Event data
   */
  async emit (name, event) {
    const handlers = this.__eventHandlers[name]
    let result = false
    if (handlers) {
      for (const handler of handlers) {
        if (handler) {
          // Checks if any of the handlers signals the alert to be suspended
          if (await handler(event)) {
            result = true
          }
        }
      }
    }
    return result
  }

  /**
   * Clock identifier
   * @type {String}
   */
  get id () {
    return this.__id
  }

  /**
   * Clock name
   * @type {String}
   */
  get name () {
    return this.__name
  }

  /**
   * Clock tick frequency in milliseconds
   * @type {Number}
   */
  get frequency () {
    return this.__frequency
  }

  /**
   * Returns true if timer is now ticking
   * @type {Boolean}
   */
  get isTicking () {
    return Boolean(this.__timer)
  }

  /**
   * Number of ticks since the clock has been started
   * @type {Number}
   */
  get ticks () {
    return this.__ticks
  }

  /**
   * If true, clock will be initially suspended
   * @type {Boolean}
   */
  get isSuspended () {
    return this.__isSuspended
  }

  /**
   * If true, the clock will thrown on exceptions during alert executions.
   * Otherwise exceptions will be suppressed while alerts might be suspended if told to do so.
   * @type {Boolean}
   */
  get throwOnExceptions () {
    return this.__throwOnExceptions
  }
  set throwOnExceptions (value) {
    this.__throwOnExceptions = Boolean(value)
  }

  /**
   * Alerts
   * @type {Array[Alert]}
   */
  get alerts () {
    return this.__alerts
  }

  /**
   * Returns active alerts
   * @type {Array[Alert]}
   */
  get activeAlerts () {
    return this.alerts.filter(alert => !alert.isSuspended)
  }

  /**
   * Returns suspended alerts
   * @type {Array[Alert]}
   */
  get suspendedAlerts () {
    return this.alerts.filter(alert => alert.isSuspended)
  }

  /**
   * Indicates if any alerts are defined
   * @type {Boolean}
   */
  get hasAlerts () {
    return this.__alerts.length > 0
  }

  /**
   * Indicates if any alerts are active
   * @type {Boolean}
   */
  get hasActiveAlerts () {
    return this.activeAlerts.length > 0
  }

  /**
   * Indicates if any alerts are suspended
   * @type {Boolean}
   */
  get hasSuspendedAlerts () {
    return this.suspendedAlerts.length > 0
  }

  /**
   * Returns alert count
   * @type {Number}
   */
  get alertCount () {
    return this.alerts.length
  }

  /**
   * Returns active alert count
   * @type {Number}
   */
  get activeAlertCount () {
    return this.activeAlerts.length
  }

  /**
   * Returns suspended alert count
   * @type {Number}
   */
  get suspendedAlertCount () {
    return this.suspendedAlerts.length
  }

  /**
   * Starts the timer at specified frequency
   * @param {Number} frequency Timer frequency
   * @param {Function} handler Timer handler
   * @returns {Number} Timer handle
   */
  startTimer (frequency, handler) {
    this.stopTimer()
    const timer = setInterval(() => handler(), frequency)
    this.__timer = timer
    return timer
  }

  /**
   * Stops the timer
   */
  stopTimer () {
    const timer = this.__timer
    this.__timer = undefined
    if (timer) {
      if (timer.unref) {
        timer.unref()
      }
      clearInterval(timer)
    }
  }

  /**
   * Starts the clock
   */
  start () {
    this.stop()
    this.reset()
    this.startTimer(this.__frequency, () => this.tick())
    return this.emit('start', ClockEvent.create(this))
  }

  /**
   * Stops the clock
   */
  stop () {
    this.stopTimer()
    return this.emit('stop', ClockEvent.create(this))
  }

  /**
   * Ticks the timer
   */
  async tick () {
    if (!this.isTicking) {
      return
    }


    this.__ticks++
    await this.emit('tick', ClockEvent.create(this))

    for (const alert of this.alerts) {
      alert.tick()
      if (!this.isSuspended && alert.isTimeForAlert) {
        this.runAlert(alert.name)
      }
    }
  }

  /**
   * Suspends the running timer.
   * Alert events won't be triggered any more,
   * but the timer still ticks and emits `tick` event.
   * @param {Boolean} deep If true, also all alerts are explicitly suspended.
   * Use this carefully. If clock is suspended in deep mode,
   * resuming it will resume ALL the alerts, whether they were
   * suspended or not at the time of suspending the clock.
   */
  async suspend (deep = false) {
    if (this.isTicking && !this.isSuspended) {
      this.__isSuspended = true

      if (deep) {
        for (const alert of this.alerts) {
          alert.suspend()
          await this.emit('alertSuspended', AlertEvent.create(this, alert))
        }
      }

      await this.emit('suspend', AlertEvent.create(this))
    }
  }

  /**
   * Resumes the suspended timer
   * @param {Boolean} deep If true, also all alerts are explicitly resumed,
   * whether they were suspended individually or in batch with `suspend(deep=true)`.
   */
  async resume (deep = false) {
    if (this.isTicking) {
      if (this.isSuspended) {
        this.__isSuspended = false

        if (deep) {
          for (const alert of this.alerts) {
            alert.resume()
            await this.emit('alertResumed', AlertEvent.create(this, alert))
          }
        }

        await this.emit('resume', AlertEvent.create(this))
      }
    } else {
      this.start()
    }
  }

  /**
   * Resets the clock and all alerts.
   * Warning! It does not touch the suspended or busy status of the clock and alerts!
   */
  async reset () {
    this.__ticks = 0

    for (const alert of this.alerts) {
      alert.reset()
    }
  }

  /**
   * Finds alert with the specified name
   * @param {String} name Name of an alert to find
   * @type {Alert}
   */
  getAlert (name) {
    if (!name) throw new Error('Alert name is required')
    return this.alerts.find(alert => alert.name === name)
  }

  /**
   * Returns true if alert with the specified name exists
   * @param {String} name Name of an alert to find
   * @type {Boolean}
   */
  hasAlert (name) {
    if (!name) throw new Error('Alert name is required')
    return Boolean(this.getAlert(name))
  }

  /**
   * Returns true if alert with the specified name is currently suspended
   * @param {String} name Name of an alert to find
   * @type {Boolean}
   */
  isAlertSuspended (name) {
    if (!name) throw new Error('Alert name is required')
    const alert = this.getAlert(name)
    if (alert) {
      return this.isSuspended || alert.isSuspended
    }
  }

  /**
   * Returns true if alert with the specified name is currently active
   * @param {String} name Name of an alert to find
   * @type {Boolean}
   */
  isAlertActive (name) {
    if (!name) throw new Error('Alert name is required')
    const alert = this.getAlert(name)
    if (alert) {
      return !(this.isSuspended || alert.isSuspended)
    }
  }

  /**
   * Returns true if alert with the specified name is currently busy
   * @param {String} name Name of an alert to find
   * @type {Boolean}
   */
  isAlertBusy (name) {
    if (!name) throw new Error('Alert name is required')
    const alert = this.getAlert(name)
    if (alert) {
      return alert.isBusy
    }
  }

  /**
   * Adds new alert
   * @param {Alert} alert Alert to add
   * @param {Function} alertEventListener Optional alert event listener to register, associated with this alert
   * @returns {Alert} Added alert
   */
  addAlert (alert, alertEventListener) {
    if (!alert) throw new Error('Alert is required')
    if (!alert.name) throw new Error('Alert name is required')
    if (this.hasAlert(alert.name)) throw new Error(`Alert [${alert.name}] already exists`)
    if (alert.interval <= 0) return

    alert = new Alert(alert)
    this.alerts.push(alert)
    if (alertEventListener) {
      this.addEventListener(`alert-${alert.name}`, () => alertEventListener(this, alert))
    }

    return alert
  }

  /**
   * Deletes the specified alert
   * @param {String} name Name of an alert to delete
   * @type {Alert} Deleted alert
   */
  deleteAlert (name) {
    if (!name) throw new Error('Alert name is required')
    const index = this.alerts.findIndex(alert => alert.name === name)
    const alert = this.alerts[index]
    if (!alert) return

    if (index > -1) {
      this.alerts.splice(index, 1)
    }

    return alert
  }

  /**
   * Suspends the specified alert
   * @param {String} name Name of an alert to suspend
   * @type {Alert} Suspended alert
   */
  async suspendAlert (name) {
    if (!name) throw new Error('Alert name is required')
    const alert = this.getAlert(name)
    if (!alert) return

    alert.suspend()
    await this.emit('alertSuspended', AlertEvent.create(this, alert))
    return alert
  }

  /**
   * Resumes the specified alert
   * @param {String} name Name of an alert to resume
   * @type {Alert} Resumed alert
   */
  async resumeAlert (name) {
    if (!name) throw new Error('Alert name is required')
    const alert = this.getAlert(name)
    if (!alert) return

    alert.resume()
    await this.emit('alertResumed', AlertEvent.create(this, alert))
    return alert
  }

  /**
   * Resets the specified alert
   * @param {String} name Name of an alert to reset
   * @type {Alert} Reset alert
   */
  async resetAlert (name) {
    if (!name) throw new Error('Alert name is required')
    const alert = this.getAlert(name)
    if (!alert) return

    alert.reset()
    return alert
  }

  /**
   * Runs the specified alert
   * @param {String} name Alert name
   * @param {Boolean} force If true, alert will be run even if it's suspended
   * @type {Alert} Run alert
   */
  async runAlert (name, force) {
    if (!name) throw new Error('Alert name is required')
    const alert = this.getAlert(name)
    if (!alert) return

    if (alert.isSuspended && !force) return

    let isAlertDone
    try {
      alert.start()
      // Emit generic alert event and specific alert event
      isAlertDone = await this.emit('alert', AlertEvent.create(this, alert))
      isAlertDone = await this.emit(`alert-${alert.name}`, AlertEvent.create(this, alert)) || isAlertDone
      alert.finish()
      // Check if alert should be suspended after this execution.
      // This can be signalled by returning true from alert handler.
      if (isAlertDone) {
        alert.suspend()
      }
      // Check if alert is done performing all the requested cycles.
      if (alert.isFinite && alert.count >= alert.times) {
        await this.emit('alertFinished', AlertEvent.create(this, alert))
      }
    } catch (error) {
      await this.emit('alertError', AlertErrorEvent.create(this, alert, error))

      alert.error(error)
      if (alert.suspendOnException) {
        alert.suspend()
        await this.emit('alertSuspended', AlertEvent.create(this, alert))
      }

      if (this.throwOnExceptions) {
        throw error
      } else {
        this.handleError(error)
      }
    }

    return alert
  }

  /**
   * Runs all alerts
   * @param {Boolean} force If true, alert will be run even if it's suspended
   */
  async runAlerts (force) {
    for (const { name } of this.alerts) {
      this.runAlert(name, force)
    }
  }

  /**
   * Handles errors
   * @param {Error} error Error to handle
   */
  handleError (error) {
    if (error) {
      Log.exception(error)
    }
  }
}

/**
 * Clock alert
 * @param {String} name Alert name
 * @param {Number} interval Alert interval, specified as amount of clock ticks
 * @param {Number} times If specified, alert will automatically suspend after running the specified amount of times
 * @param {Boolean} isSuspended If true, timer will be initially suspended
 * @param {Boolean} suspendOnException If true (default), alert will be suspended when any exception happens during alert handler
 */
export class Alert {
  constructor ({ name, interval = 0, times, isSuspended, suspendOnException = true } = {}) {
    if (!name) throw new Error('Alert name is required')

    this.__name = name
    this.__interval = interval
    this.__times = times
    this.__count = 0
    this.__isSuspended = isSuspended
    this.__suspendOnException = suspendOnException
    this.__countdown = interval
    this.__isBusy = false
    this.__error = null
  }

  /**
   * Alert name
   * @type {String}
   */
  get name () {
    return this.__name
  }

  /**
   * Alert event interval in ticks
   * @type {Number}
   */
  get interval () {
    return this.__interval
  }

  /**
   * Countdown to next triggering of the alert
   * @type {Number}
   */
  get countdown () {
    return this.__countdown
  }

  /**
   * If specified, timer will automatically stop after running the specified amount of event times
   * @type {Number}
   */
  get times () {
    return this.__times
  }

  /**
   * Number of times the alert has triggered so far
   * @type {Number}
   */
  get count () {
    return this.__count
  }

  /**
   * If true, alert will be suspended on exception
   * @type {Boolean}
   */
  get suspendOnException () {
    return this.__suspendOnException
  }

  /**
   * Returns true if alert is finite and will stop automatically
   * after being triggered the specified amount of times
   * @type {Boolean}
   */
  get isFinite () {
    return this.times != null
  }

  /**
   * If true, alert will be initially suspended
   * @type {Boolean}
   */
  get isSuspended () {
    return this.__isSuspended
  }

  /**
   * If true, alert is now being executed
   * @type {Boolean}
   */
  get isBusy () {
    return this.__isBusy
  }

  /**
   * Returns true if time has come for the alert
   * @type {Boolean}
   */
  get isTimeForAlert () {
    if (!(this.interval > 0)) return false
    if (this.isSuspended) return false
    if (this.isBusy) return false
    if (this.isFinite && this.count === this.times) return false
    return this.countdown <= 0
  }

  /**
   * Suspends the alert
   */
  async suspend () {
    this.__isSuspended = true
  }

  /**
   * Resumes the suspended alert
   */
  async resume () {
    this.__isSuspended = false
    this.__error = null
  }

  /**
   * Resets the alert to its initial state
   * Warning! It does not touch the suspended status of the alert!
   * @param {Boolean} keepBusy If true, the alert resets but is kept busy
   */
  async reset () {
    this.__error = null
    this.__countdown = this.interval
    this.__count = 0
  }

  /**
   * Ticks the alert countdown
   */
  async tick () {
    if (!this.isSuspended) {
      if (this.__countdown <= 0) {
        this.__countdown = this.interval
      } else {
        this.__countdown--
      }
    }
  }

  /**
   * Signals that alert is about to be executed
   */
  async start () {
    if (this.isTimeForAlert) {
      this.__isBusy = true
      this.__countdown = this.interval
      this.__count++
    }
  }

  /**
   * Signals that alert is done
   */
  async finish () {
    this.__isBusy = false
    this.__error = null
  }

  /**
   * Signals that alert has failed
   */
  async error (error) {
    this.__isBusy = false
    this.__error = error
  }
}

/**
 * Clock event
 */
class ClockEvent {
  constructor (clock) {
    this.clock = clock
  }

  /**
   * Clock which triggered the event
   * @type {Clock}
   */
  clock

  /**
   * Creates new clock event
   * @param {Object} data Event data
   * @returns Clock event
   */
  static create (data) {
    return new ClockEvent(data)
  }
}

/**
 * Alert event
 */
class AlertEvent extends ClockEvent {
  constructor (clock, alert) {
    super(clock)
    this.alert = alert
  }

  /**
   * Alert which triggered the event
   * @type {Alert}
   */
  alert

  /**
   * Creates new alert event
   * @param {Object} data Event data
   * @param {Alert} alert Alert related to event
   * @returns {AlertEvent} Clock event
   */
  static create (data, alert) {
    return new AlertEvent(data, alert)
  }
}

/**
 * Alert error event
 */
class AlertErrorEvent extends AlertEvent {
  constructor (clock, alert, error) {
    super(clock, alert)
    this.error = error
  }

  /**
   * Error triggered during alert
   * @type {Error}
   */
  error

  /**
   * Creates new alert event
   * @param {Object} data Event data
   * @param {Alert} alert Alert related to event
   * @param {Error} error Error related to event
   * @returns {AlertErrorEvent} Clock event
   */
  static create (data, alert, error) {
    return new AlertErrorEvent(data, alert, error)
  }
}

