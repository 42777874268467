import { Point, Size, safeParseInt, safeParseFloat, parseBoolean, Color } from '@stellacontrol/utilities'
import { Assignable, Attachment } from '@stellacontrol/model'

/**
 * Plan background layout
 */
export class PlanBackground extends Assignable {
  constructor (data = {}) {
    super(data)
    this.assign(data)
  }

  /**
   * Object defaults
   */
  get defaults () {
    return {
      showImage: true,
      imageScale: 1,
      imageRotation: 0,
      imagePosition: Point.Zero,
      maxImageSize: 1048576 * 10,
      imageSize: Size.Zero,
      transparentColors: ['#FFFFFF']
    }
  }

  normalize () {
    super.normalize()
    const { defaults } = this
    this.showImage = this.showImage != null ? parseBoolean(this.showImage) : defaults.showImage
    this.image = this.cast(this.image, Attachment)
    this.imageScale = safeParseFloat(this.imageScale, defaults.imageScale)
    this.imageRotation = safeParseInt(this.imageRotation, defaults.imageRotation)
    this.imagePosition = this.customize(this.imagePosition, Point, defaults.imagePosition)
    this.imageSize = this.cast(this.imageSize, Size, defaults.imageSize)
    this.transparentColors = (this.transparentColors || defaults.transparentColors).map(c => Color.toRGB(c))
  }

  /**
   * Serializes the item to JSON
   * @returns {Object}
   */
  toJSON () {
    const result = {
      ...this
    }

    // Delete defaults
    if (this.imagePosition.sameAs(Point.Zero)) {
      delete result.imagePosition
    }

    // Delete runtime properties
    delete this.wasImageChanged
    if (this.transparentColors?.length === 0) {
      delete this.transparentColors
    }

    return this.clearDefaults(result)
  }

  /**
   * Display background image
   * @type {Boolean}
   */
  showImage

  /**
   * Image data
   * @type {Attachment}
   */
  image

  /**
   * Indicates whether any image has been selected
   * @type {Boolean}
   */
  get hasImage () {
    return this.image != null
  }

  /**
   * Image position
   * @type {Point}
   */
  imagePosition

  /**
   * Image size
   * @type {Size}
   */
  imageSize

  /**
   * Image scale
   * @type {Number}
   */
  imageScale

  /**
   * Image rotation
   * @type {Number}
   */
  imageRotation

  /**
   * Indication that image has been changed by the user
   * @type {Boolean}
   * @description RUNTIME
   */
  wasImageChanged

  /**
   * Removes the image from the floor
   */
  clearImage () {
    this.image = null
    this.imageScale = 1
    this.imageRotation = 1
    this.imagePosition = Point.Zero
    this.wasImageChanged = false
  }

  /**
   * Assigns a new image to the background
   * @param {Attachment} file Image file
  */
  setImage (file) {
    if (file) {
      this.wasImageChanged = this.image != null
      this.image = file
      this.imagePosition = Point.Zero
      this.imageScale = 1
      this.imageRotation = 0
      this.clearTransparentColors()
      this.addTransparentColors('#FFFFFF')
    } else {
      this.clearImage()
    }
  }

  /**
   * List of colors of the image which should be rendered as transparent on the background image
   * @type {Array[String]}
   * @description RUNTIME. Once the image transparency is set,
   * the new transparent image is saved, overwriting the original one.
   */
  transparentColors

  /**
   * Indicates whether any transparent colors have been defined for the background image
   * @type {Boolean}
   */
  get hasTransparentColors () {
    return this.transparentColors?.length > 0
  }

  /**
   * Removes any user-defined transparent colors for the background image
   */
  clearTransparentColors () {
    this.transparentColors = [...this.defaults.transparentColors]
  }

  /**
   * Adds new transparent color for the background image
   * @param {Array[String]} colors Transparent color to add
   * @returns {Boolean} `true` if tranparent color has been added
   */
  addTransparentColors (...colors) {
    const ignoredColors = []
    let added = false
    if (!this.transparentColors) {
      this.transparentColors = []
    }
    for (const color of colors) {
      if (!this.transparentColors.includes(color) && !ignoredColors.includes(color)) {
        this.transparentColors = [...this.transparentColors, color]
        added = true
      }
    }
    return added
  }

  /**
   * Resets the background to default values
   */
  reset () {
    this.clearImage()
    this.clearTransparentColors()
  }
}
