import { subDays } from 'date-fns'
import { Log, createClock, capitalize, countString, pluralize, countAndPluralize, stringCompare } from '@stellacontrol/utilities'
import { ListViewMode, Confirmation, Notification, isRouteDataChanged } from '@stellacontrol/client-utilities'
import { DeviceFirmware, DeviceFirmwareGroupBy, UploadType, getDeviceLabel } from '@stellacontrol/model'
import { DeviceTransmissionAPI } from '@stellacontrol/client-api'
import { AppletRoute } from '../../router'

/**
 * Initialize the applet
 */
export async function initializeApplet ({ commit, dispatch }) {
  await dispatch('initializeList', [
    { name: 'firmwares', viewMode: ListViewMode.Normal },
    { name: 'upload-jobs', viewMode: ListViewMode.Normal, sortBy: 'updatedAt', sortDescending: true }
  ])

  // Retrieve previously stored tree settings
  const groupBy = await dispatch('getUserPreference', { name: 'firmwares-group-by', defaultValue: DeviceFirmwareGroupBy.Version })
  commit('groupFirmwaresBy', { groupBy })
}

/**
 * Retrieves the list of firmwares available to the specified organization.
 * If organization is not specified, firmwares available to the calling organization
 * will be returned instead.
 * @param {Organization} organization Organization whose firmwares to return
 */
export async function getDeviceFirmwares ({ commit, dispatch, getters }, { organization } = {}) {
  await dispatch('loading')
  await dispatch('getUpdateableDeviceModels')

  const { currentOrganization } = getters
  const firmwares = await DeviceTransmissionAPI.getDeviceFirmwares({
    organization,
    obsolete: currentOrganization.isSuperOrganization
  })
  commit('storeDeviceFirmwares', { firmwares })

  await dispatch('done')

  return firmwares || []
}

/**
 * Retrieves the device models which are updateable
 */
export async function getUpdateableDeviceModels ({ commit, getters }) {
  const { configuration: { entities: { device: { model: { all, nonUpdateable } } } } } = getters
  const models = all.filter(m => !nonUpdateable.some(nm => stringCompare(nm, m, false) === 0))
  commit('storeUpdateableDeviceModels', { models, nonUpdateable })
}

/**
 * Retrieves the specified firmware.
 * @param {String} id Firmware identifier
 * @param {Boolean} withContent If true, binary firmware content is also returned
 * @returns {Promise<DeviceFirmware>}
*/
export async function getDeviceFirmware ({ dispatch }, { id } = {}) {
  const firmware = await DeviceTransmissionAPI.getDeviceFirmware({ id })
  await dispatch('getUpdateableDeviceModels')
  return firmware
}

/**
 * Checks if firmware with specified identifier or version already exists.
 * @param {String} id Firmware identifier
 * @param {String} version Firmware version
 * @param {String} model Firmware device model
 * @returns {Promise<Boolean>}
 */
export async function deviceFirmwareExists (_, { id, version, model } = {}) {
  const result = await DeviceTransmissionAPI.deviceFirmwareExists({ id, version, model })
  return result
}

/**
 * Creates a new device firmware
 * @param {DeviceFirmware} firmware Initial properties of the newly created firmware
 */
export async function newDeviceFirmware ({ dispatch }, { firmware = {} } = {}) {
  const data = new DeviceFirmware({ ...firmware })
  await dispatch('getUpdateableDeviceModels')
  return data
}

/**
 * Shows a list of device firmwares available to current organization
 */
export async function showDeviceFirmwares ({ dispatch }) {
  await dispatch('gotoRoute', { name: AppletRoute.DeviceUpdates, query: { tab: 'firmware' } })
}

/**
 * Shows a list of device uploads available to current organization
 */
export async function showDeviceUploads ({ dispatch }) {
  await dispatch('gotoRoute', { name: AppletRoute.DeviceUpdates, query: { tab: 'upload-jobs' } })
}

/**
 * Edits a new firmware
 */
export async function createDeviceFirmware ({ dispatch }) {
  await dispatch('gotoRoute', { name: AppletRoute.DeviceFirmware, params: { id: 'new' } })
}

/**
 * Edits an existing firmware
 * @param {DeviceFirmware} firmware Firmware to edit
 */
export async function editDeviceFirmware ({ dispatch }, { firmware: { id } } = {}) {
  if (!id) throw new Error('Firmware is required')
  await dispatch('gotoRoute', { name: AppletRoute.DeviceFirmware, params: { id } })
}

/**
 * Saves the specified firmware
 * @param firmware Firmware to save
 */
export async function saveDeviceFirmware ({ commit }, { firmware } = {}) {
  if (!firmware) throw new Error('Firmware is required')

  const savedFirmware = await DeviceTransmissionAPI.saveDeviceFirmware({ firmware })
  if (savedFirmware) {
    commit('storeDeviceFirmware', { firmware: savedFirmware })
  }

  return savedFirmware
}

/**
 * Saves firmware file associated with the specified firmware
 * @param {DeviceFirmware} firmware Firmware
 * @param {File} file Firmware file to save
 * @returns {DeviceFirmware} Updated firmware
 */
export async function saveDeviceFirmwareFile ({ commit }, { firmware, file } = {}) {
  if (!firmware) throw new Error('Firmware is required')

  const savedFirmware = await DeviceTransmissionAPI.saveDeviceFirmwareFile({ firmware, file })
  if (savedFirmware) {
    commit('storeDeviceFirmware', { firmware: savedFirmware })
  }

  return savedFirmware
}

/**
 * Grants access to firmware to the specified organizations
 * @param firmware Firmware to grant access to
 * @param organizations Organizations which should have access to the firmware
 */
export async function grantAccessToDeviceFirmware ({ commit }, { firmware, organizations }) {
  await DeviceTransmissionAPI.grantAccessToDeviceFirmware({ firmware, organizations })
  commit('grantAccessToDeviceFirmware', { firmware, organizations })
}

/**
 * Revokes access to firmware to the specified organizations
 * @param firmware Firmware to revoke access to
 * @param organizations Organizations whose access to the firmware is to be revoked
 */
export async function revokeAccessToDeviceFirmware ({ commit }, { firmware, organizations }) {
  await DeviceTransmissionAPI.revokeAccessToDeviceFirmware({ firmware, organizations })
  commit('revokeAccessToDeviceFirmware', { firmware, organizations })
}

/**
 * Deletes the specified firmware
 * @param {DeviceFirmware} firmware Firmware to delete
 * @param {Boolean} confirm If true, user confirmation is required
 * @param {Boolean} silent If true, no notifications will be shown
 * @returns {DeviceFirmware} Deleted firmware
 */
export async function removeDeviceFirmware ({ commit, dispatch }, { firmware, confirm, silent } = {}) {
  if (!firmware) throw new Error('Firmware is required')
  if (!firmware.id) throw new Error('Firmware identifier is required')

  const yes = await Confirmation.ask({
    title: 'Delete',
    message: `Delete device firmware ${firmware.label}?`,
    confirm
  })

  if (yes) {
    await dispatch('busy', { message: `Deleting device firmware ${firmware.label} ...`, data: firmware, silent })
    const deletedFirmware = await DeviceTransmissionAPI.removeDeviceFirmware({ firmware })
    if (deletedFirmware) {
      commit('removeDeviceFirmware', { firmware: deletedFirmware })
      await dispatch('done', { message: `Device firmware ${firmware.label} has been deleted`, silent })
    } else {
      await dispatch('done')
    }

    return deletedFirmware
  }
}

/**
 * Deletes the specified firmwares
 * @param {Array[DeviceFirmware]} firmwares Firmwares to delete
 * @param {Boolean} confirm If true, user confirmation is required
 * @param {Boolean} silent If true, no notifications will be shown
 */
export async function removeDeviceFirmwares ({ commit, dispatch }, { firmwares = [], confirm, silent } = {}) {
  if (firmwares.length === 0) return
  const versions = Array.from(new Set(firmwares.map(f => f.versionString)))
  const yes = await Confirmation.ask({
    title: 'Delete',
    message: `Delete device firmwares v.${versions.join(', ')}?`,
    confirm
  })

  if (yes) {
    await dispatch('busy', { message: 'Deleting device firmwares ...', silent })

    for (const firmware of firmwares) {
      const deletedFirmware = await DeviceTransmissionAPI.removeDeviceFirmware({ firmware })
      if (deletedFirmware) {
        commit('removeDeviceFirmware', { firmware: deletedFirmware })
      }
    }
    await dispatch('done', { message: `Device firmwares v.${versions.join(', ')} deleted`, silent })
  }
}

/**
 * Changes status of the specified firmwares
 * @param {Array[DeviceFirmware]} firmwares Firmwares to update
 * @param {Boolean} isObsolete Firmware status: active / obsolete
 * @param {Boolean} confirm If true, user confirmation is required
 * @param {Boolean} silent If true, no notifications will be shown
 */
export async function setDeviceFirmwaresStatus ({ commit, dispatch }, { firmwares = [], isObsolete, confirm, silent } = {}) {
  if (firmwares.length === 0) return
  const versions = Array.from(new Set(firmwares.map(f => f.versionString)))
  const yes = await Confirmation.ask({
    title: isObsolete ? 'Mark as obsolete' : 'Mark as active',
    message: `Mark device firmwares v.${versions.join(', ')}? as ${isObsolete ? 'obsolete' : 'active'}`,
    confirm
  })

  if (yes) {
    await dispatch('busy', { message: 'Updating device firmwares ...', silent })

    for (const firmware of firmwares) {
      const updatedFirmware = await DeviceTransmissionAPI.setDeviceFirmwareStatus({ firmware, isObsolete })
      if (updatedFirmware) {
        commit('storeDeviceFirmware', { firmware: updatedFirmware })
      }
    }
    await dispatch('done', { message: `Device firmwares v.${versions.join(', ')} updated`, silent })
  }
}

/**
 * Groups firmwares list by specified property
 * @param {DeviceFirmwareGroupBy} groupBy Grouping property
 */
export async function groupDeviceFirmwaresBy ({ commit, dispatch }, { groupBy } = {}) {
  commit('groupFirmwaresBy', { groupBy })
  await dispatch('storeUserPreference', { name: 'firmwares-group-by', value: groupBy })
}

/**
 * Toggles visibility of firmwares inside the specified group
 * @param {Object} group Firmware group to toggle
 */
export async function toggleFirmwareGroup ({ commit }, { group } = {}) {
  commit('toggleFirmwareGroup', { group })
}

/**
 * Launches dialog for batch-editing properties of specified firmwares
 * @param {Array[DeviceFirmware]} firmwares Firmwares to edit
 * @param {String} title Alternative title for the dialog
 */
export async function editFirmwares ({ dispatch }, { firmwares = [], title } = {}) {
  if (firmwares && firmwares.length > 0) {
    const { isOk, data } = await dispatch('showDialog', {
      dialog: 'batch-edit-firmwares',
      data: {
        firmwares,
        title
      }
    })

    if (isOk && data) {
      const { firmwares, name, description, grantedOrganizations, revokedOrganizations } = data
      const progress = await Notification.progress({ message: 'Applying changes to firmware ...' })

      for (const { id } of firmwares) {
        const firmware = await DeviceTransmissionAPI.getDeviceFirmware({ id })
        if (firmware) {
          await progress({ message: `Updating firmware ${firmware.label} ...` })
          firmware.name = name === undefined ? firmware.name : name
          firmware.description = description === undefined ? firmware.description : description
          await dispatch('saveDeviceFirmware', { firmware })
          await dispatch('revokeAccessToDeviceFirmware', { firmware, organizations: revokedOrganizations })
          await dispatch('grantAccessToDeviceFirmware', { firmware, organizations: grantedOrganizations })
        }
      }

      progress()
      await Notification.success({ message: 'Firmware has been saved' })
    }
  }
}

/**
 * Returns URL which can be used to downloads firmware binary payload
 * @param {DeviceFirmware} firmware Firmware to download
 */
export async function getDeviceFirmwareUrl ({ getters }, { firmware } = {}) {
  if (!firmware) throw new Error('Firmware is required')

  const { currentUser: user } = getters
  if (user && firmware && firmware.hasFile) {
    const url = DeviceTransmissionAPI.getDeviceFirmwareUrl({ user, firmware })
    return url
  }
}

/**
 * Stores job days
 */
export async function storeJobDays ({ commit, dispatch }, { jobDays } = {}) {
  commit('storeJobDays', { jobDays })
  await dispatch('storeUserPreference', { name: 'upload-jobs-days', value: jobDays })
}

/**
 * Retrieves upload jobs belonging to the current organization,
 * optionally only those matching the specified status
 * @param {UploadType} type Upload job type
 * @param {UploadStatus} status Upload job status
 * @param {Boolean} allOrganizations If true, jobs of all organizations are returned
 * @param {Date} since Ignore jobs older than the specified date, optional
 * @returns {Promise<Array[UploadJob]>} Upload jobs
 */
export async function getUploadJobs ({ commit, state, dispatch }, { type, status, allOrganizations, since } = {}) {
  if (since == null) {
    if (state.jobDays == null) {
      state.jobDays = await dispatch('getUserPreference', { name: 'upload-jobs-days', defaultValue: 90 })
    }
    since = state.jobDays > 0
      ? subDays(new Date(), state.jobDays)
      : undefined
  }

  const jobs = await DeviceTransmissionAPI.getUploadJobs({ type, status, since, allOrganizations })
  commit('storeUploadJobs', { jobs })
  return jobs
}

/**
 * Retrieves the details of a specified upload job
 * @param {String} id Identifier of the job to retrieve
 * @returns {Promise<UploadJob>} Upload job
 */
export async function getUploadJob ({ commit }, { id } = {}) {
  const job = await DeviceTransmissionAPI.getUploadJob({ id })
  if (job) {
    commit('storeUploadJob', { job })
  }
  return job
}

/**
 * Initiates upload of firmware to one or more devices
 * @param {String} version Firmware version to upload to devices
 * @param {Number} defer Time to defer the uploads by (in seconds)
 * @param {Array[Device]} devices Devices to upload the firmware to
 * @param {Boolean} silent If true, no notifications will be shown
 * @description If device already has the selected firmware, it will be skipped
 * @returns {Array[UploadJob]} Initiated upload jobs
 */
export async function createFirmwareUploadJobs ({ commit, dispatch, state }, { version, defer, devices, silent }) {
  await dispatch('busy', { message: 'Scheduling firmware update ...', silent })
  const jobs = []
  const ignoredDevices = []

  for (const device of devices) {
    const firmware = state.firmwares.find(f => f.isVersion(version) && f.isAllowedForDevice(device.model))
    if (firmware) {
      if (firmware.isVersion(device.firmwareVersionLong)) {
        ignoredDevices.push(device)
      } else {
        const deviceJobs = await DeviceTransmissionAPI.createUploadJobs({
          id: firmware.id,
          type: UploadType.Firmware,
          defer,
          devices: [device]
        })
        jobs.push(...(deviceJobs || []))
      }
    }
  }

  if (jobs && jobs.length > 0) {
    await dispatch('done', { message: `${capitalize(countString(jobs, 'upload'))} ${pluralize(jobs, 'has', 'have')} been scheduled`, silent })
    commit('storeUploadJobs', { jobs })
  } else {
    if (ignoredDevices.length > 0) {
      await dispatch('done', { warning: `${countAndPluralize(ignoredDevices, 'device already has', 'devices already have')} the selected firmware`, silent })
    } else {
      await dispatch('done', { warning: 'Firmware update could not be scheduled', silent })
    }
  }

  return jobs
}

/**
 * Removes the specified upload job
 * @param job Job to remove
 * @param {Boolean} confirm If true, user confirmation is required
 * @param {Boolean} silent If true, no notifications will be shown
 * @returns {UploadJob} Deleted job
 */
export async function removeUploadJob ({ commit, dispatch }, { job, confirm, silent } = {}) {
  const yes = await Confirmation.ask({
    title: 'Delete',
    message: `Delete upload of ${job.label} to ${getDeviceLabel(job.device)}?`,
    confirm
  })

  if (yes) {
    await dispatch('busy', { message: `Deleting ${job.label} upload ...`, data: job, silent })
    job = await DeviceTransmissionAPI.removeUploadJob({ job })
    if (job) {
      commit('removeUploadJob', { job })
      await dispatch('done', { message: `Upload of ${job.label} has been deleted`, silent })
    } else {
      await dispatch('done')
    }
    return job
  }
}

/**
 * Schedules a retry of the specified upload job
 * @param job Job to retry
 * @param {Boolean} reset If true, attempts counter will be reset back to defaults
 * @param {Boolean} confirm If true, user confirmation is required
 * @param {Boolean} silent If true, no notifications will be shown
 * @returns {UploadJob} Retried job
 */
export async function retryUploadJob ({ commit, dispatch, getters }, { job, reset, confirm, silent } = {}) {
  const { currentUser } = getters
  const yes = await Confirmation.ask({
    title: 'Retry',
    message: `Try again uploading the ${job.label} to ${getDeviceLabel(job.device)}?`,
    confirm
  })

  if (yes) {
    await dispatch('busy', { message: `Scheduling ${job.label} upload ...`, data: job, silent })
    const reason = `User ${currentUser.name} requested retry`
    job = await DeviceTransmissionAPI.retryUploadJob({ job, reset, reason })
    if (job) {
      commit('retryUploadJob', { job })
      await dispatch('done', { message: `Upload of ${job.label} has been scheduled`, silent })
    }
    return job
  }
}

/**
 * Updates the status of the specified upload job
 * @param {UploadJob} job Job to update, with status details in it
 * @param {String} message Additional message to record, such as error or progress details
 * @returns {UploadJob} Updated job
 */
export async function setUploadStatus ({ commit }, { job: { id, status, progress, retryAttempts, retryInterval }, message } = {}) {
  const job = await DeviceTransmissionAPI.setUploadStatus({
    job: {
      id,
      status,
      progress,
      retryAttempts,
      retryInterval
    },
    message
  })

  if (job) {
    commit('storeUploadJob', { job })
  }

  return job
}

/**
 * Retrieves status of all jobs
 * which are currently in progress
 * @returns {Array} Upload jobs's status
 */
export async function getUploadStatus ({ state, commit } = {}) {
  const { lastStatusCheck } = state
  commit('getUploadStatus')
  const { states, jobs } = await DeviceTransmissionAPI.getUploadStatus({ lastStatusCheck })

  if (states) {
    for (const status of states) {
      commit('storeUploadStatus', { status })
    }
  }

  if (jobs) {
    for (const job of jobs) {
      commit('storeUploadJob', { job })
    }
  }

  return states
}

/**
   * Starts polling upload job status periodically
   * @param name Name of a view or process which has initiated the polling
   * @param interval Polling interval in slow mode, in seconds
   * @param hasUploadsInProgress Optional callback for checking whether there are actually
   * any uploads in progress. If present, it's checked before firing any API calls, to save on resources.
   */
export async function watchUploadStatus ({ state, commit, dispatch }, { name = 'upload-jobs', interval = 10, hasUploadsInProgress } = {}) {
  if (!name) throw new Error('Process initiating the status polling is required')
  if (!interval || interval < 0) return
  if (state.polling[name]) return

  const clock = createClock({ name, frequency: 1000 })
  clock.addAlert({ name: 'upload-status', interval })
  clock.addAlertListener('upload-status', async () => {
    const check = hasUploadsInProgress ? hasUploadsInProgress() : true
    if (check) {
      try {
        await dispatch('getUploadStatus')
      } catch (error) {
        Log.warn('Error fetching upload status', error.message)
      }
    }
  })
  await clock.runAlert('upload-status')
  await clock.start()

  commit('watchUploadStatus', { name, clock })

  Log.debug(`[${name}] Polling for upload status every ${interval}s`)
}

/**
 * Stops polling upload status
 * @param name Name of a view or process which has initiated the polling.
 * If not specified, all processes are stopped.
 */
export async function unwatchUploadStatus ({ commit, state }, { name } = {}) {
  const processes = name
    ? [state.polling[name]]
    : Object.values(state.polling)

  for (const { name, clock } of processes.filter(p => p)) {
    if (clock) {
      clock.stop()
      Log.debug(`[${name}] Stopped polling for upload status`)
      commit('unwatchUploadStatus', { name })
    }
  }
}

/**
 * On route changes stop all the polling for upload status
 */
export async function navigationStarted ({ dispatch }, { from, to } = {}) {
  if (isRouteDataChanged(from, to)) {
    await dispatch('unwatchUploadStatus')
  }
}

/**
 * When session ends, stop any ongoingpolling for device status and settings
 */
export async function endSession ({ dispatch }) {
  await dispatch('unwatchUploadStatus')
}

