import { mapState, mapGetters, mapMutations } from 'vuex'
import { Secure } from '@stellacontrol/security-ui'
import { AlertLabel } from '@stellacontrol/model'


export default {
  mixins: [
    Secure
  ],

  props: {
    // Statistics part being shown
    part: {
    },
    // Indicates whether user can delete the data
    canDelete: {
      type: Boolean
    }
  },

  data () {
    return {
      AlertLabel
    }
  },

  computed: {
    ...mapState({
      // Statistics filters
      filter: state => state.alertsView.statisticsFilter,
      detailsFilter: state => state.alertsView.statisticsFilter.detailsFilter,
      // Retrieved alert statistics
      statistics: state => state.alertsView.mergedStatistics
    }),

    ...mapGetters([
      'visibleAlertStatisticsDetails'
    ]),

    // Items to show
    // Filter can be multipart, with parts separated by space functioning as OR
    items () {
      const { statistics } = this
      const items = this.visibleAlertStatisticsDetails(statistics)
      return items || []
    }
  },

  methods: {
    ...mapMutations([
      'getVisibleAlertStatisticsDetails'
    ])
  }
}
